// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { createContext, Dispatch, FC, SetStateAction, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { push } from 'connected-react-router'

import { dismiss, snackBarAction } from 'V2/actions/snackbar'
import { isBlockerPopupClosed, logout } from 'V2/actions/userClientDetails'
import { CloseIcon } from 'V2/components/iconPaths/Custom/BasicIcons/CloseIcon'
import { HamburgerMenuIcon } from 'V2/components/iconPaths/Custom/BasicIcons/HamburgerMenuIcon'
import { LogoutIcon } from 'V2/components/iconPaths/Custom/BasicIcons/LogoutIcon'
import { RecipientSingleIcon } from 'V2/components/iconPaths/Custom/BasicIcons/RecipientSingleIcon'
import { SettingsIcon } from 'V2/components/iconPaths/Custom/BasicIcons/SettingsIcon'
import { SnackbarContainer } from 'V2/components/molecules/Snackbar/Snackbar'
import AddBankAccountBanner from 'V2/components/organisms/AddBankAccountBanner/AddBankAccountBanner'
import { appRoutes, autoSweepBlockedUrls } from 'V2/constants/appRoutes'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import { allowedLegalentitiesAutoSweep } from 'V2/constants/autoSweepBankAccount'
import { BankAccountObj } from 'V2/constants/banner'
import { JapanType1License, JP, MY } from 'V2/constants/paymentMethodFilters'
import { sidebarNavMapping } from 'V2/constants/sidebarNavMapping'
import { trackEvents } from 'V2/helpers/amplitudeEvents'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import GA from 'V2/helpers/gaTracker'
import { Menu } from 'V2/interfaces/components/Molecules.interface'
import AddBankForm from 'V2/pages/Settings/BankAccount/AddBankForm'
import { cancelAxiosRequest } from 'V2/services/axiosInstance'
import { Logger } from 'V2/services/logger'
import { ProfileDropdown } from '../../molecules/ProfileDropdown/ProfileDropdown'
import { GetMoreMobileMenus, SideBar, SmeNavBar } from '../../organisms/SideBar/SideBar'
import {
  BlurDiv,
  Container,
  Content,
  ContentWrapper,
  CustomMenuIcon,
  Header,
  LogOutContainer,
  MobileNavWrapper,
  NavContentContainer,
  TemplateWrapper,
} from './Styles'

const FILE_NAMESPACE = 'Pages.Defaults'

export const AlertBox = React.memo((): JSX.Element => {
  const reduxSnackList = useSelector((state) => state.snackbar)
  const dispatch = useDispatch()

  const dismissSnackbar = (messageId: string): void => {
    Logger(FILE_NAMESPACE).debug('dismiss snackbar for messageId', messageId)
    dispatch(dismiss(messageId))
  }

  return (
    <SnackbarContainer
      position={'bottom-right'}
      snackList={reduxSnackList}
      dismissSnackbar={dismissSnackbar}
    />
  )
})

export const TemplateContext = createContext<Dispatch<SetStateAction<boolean>>>(() => undefined)

const SmeTemplate: FC = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [navOpen, setNavOpen] = useState(false)
  const [moremenuopen, setMoreMenuOpen] = useState(false)
  const [accountOpen, setAccountOpen] = useState(true)
  const [openBankForm, setOpenBankForm] = useState(false)
  const sidebarNavLinks = useSelector((state) => state.userClientDetails.menus || [])
  const companyName = useSelector(
    (state) => state.userClientDetails.activeClient?.client_name || ''
  ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  const userClientDetails = useSelector((state) => state.userClientDetails || {})
  const userName = useSelector((state) => {
    const name = state.userClientDetails.userDetails?.name || ''
    return name.length > 13 ? name.substring(0, 13).concat('...') : name
  }).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
  const autoSweepBankAccountNumber = useSelector(
    (state) => state?.userClientDetails?.autosweep_bank_account?.account_number || ''
  )
  const isBannerClosed = useSelector(
    (state) => state?.userClientDetails?.isBlockerBannerClosed || false
  )
  const clientLegalEntity = clientLegalEntityMapper(
    userClientDetails?.activeClient?.client_legal_entity || ''
  )
  const licenseType = useSelector(
    (state) => state.userClientDetails?.activeClient?.license_type ?? ''
  )
  const isEligibleCountry = allowedLegalentitiesAutoSweep.includes(clientLegalEntity)
  const blockSendAndReceive =
    isEligibleCountry &&
    !autoSweepBankAccountNumber &&
    (autoSweepBlockedUrls as string[]).includes(history.location.pathname || '') &&
    !isBannerClosed
  const sidebarLinks = sidebarNavLinks.map((item: Menu) => {
    return { ...item, automationId: sidebarNavMapping[item.key].automationId }
  })

  const mobileNavLinks = sidebarLinks.filter((item) => sidebarNavMapping[item.key]?.isMobileMenu)

  const moreMobileMenu = sidebarLinks.filter((item) => !sidebarNavMapping[item.key]?.isMobileMenu)

  const logoutClickHandler = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ): void => {
    Logger(FILE_NAMESPACE).debug('logoutClickHandler', event)
    cancelAxiosRequest()
    dispatch(logout())
  }
  const userProfileDropdownProps = {
    id: automationIdSelectors.userProfileDropdown.profileDropdown,
    label: '',
    width: 200,
    placeholder: '',
    maxMenuHeight: 400,
    value: {
      value: 'user',
      label: userName,
      icon: <RecipientSingleIcon />,
    },
    options: [
      {
        value: 'setting',
        label: 'Settings',
        icon: <SettingsIcon />,
        onClick: (): void => {
          dispatch(push(appRoutes.settings))
        },
        id: automationIdSelectors.userProfileDropdown.settingsButton,
      },
      {
        value: 'logout',
        label: 'Logout',
        icon: <LogoutIcon />,
        onClick: logoutClickHandler,
        id: automationIdSelectors.userProfileDropdown.logoutButton,
      },
    ],
  }
  const AutoSweepBannerClose = (): void => {
    const { pathname } = history.location
    setAccountOpen(false)
    if (
      clientLegalEntity === MY ||
      (clientLegalEntity === JP && licenseType === JapanType1License)
    ) {
      if (history.action !== 'POP') {
        history.goBack()
        setAccountOpen(true)
      } else {
        history.push(appRoutes.dashboard)
        setAccountOpen(true)
      }
    } else {
      history.push(pathname)
      dispatch(isBlockerPopupClosed())
    }
  }
  return (
    <TemplateWrapper>
      <Container>
        <Header>
          <div
            onClick={(): void => setNavOpen(!navOpen)}
            id={automationIdSelectors.sideBar.collapsibleMenuButton}
          >
            <CustomMenuIcon
              defaultSize={24}
              children={!navOpen ? <HamburgerMenuIcon /> : <CloseIcon />}
              viewBox={'0 0 24 24'}
            />
          </div>
          <LogOutContainer>
            <ProfileDropdown {...userProfileDropdownProps} />
          </LogOutContainer>
        </Header>
        <NavContentContainer>
          <SideBar
            open={navOpen}
            setMoreOpen={setMoreMenuOpen}
            navLinks={sidebarLinks}
            setNavOpen={setNavOpen}
          />
          {navOpen && <BlurDiv onClick={(): void => setNavOpen(false)} />}
          <GetMoreMobileMenus
            moremenuopen={moremenuopen ? 1 : 0}
            MoreMenuLinks={moreMobileMenu}
            setMoreOpen={setMoreMenuOpen}
            companyName={companyName}
            onLogoutClick={logoutClickHandler}
            onSettingsClick={(): void => {
              setMoreMenuOpen(false)
              dispatch(push(appRoutes.settings))
              trackEvents('settings_landing_page')
              //GA Event
              GA.track('EVENT', {
                action: 'settings_landing_page',
                category: 'Portal',
                label: 'Settings landing page',
              })
            }}
          />
          <TemplateContext.Provider value={setMoreMenuOpen}>
            <ContentWrapper open={navOpen}>
              <Content>{children}</Content>
            </ContentWrapper>
          </TemplateContext.Provider>
        </NavContentContainer>
        <MobileNavWrapper>
          <SmeNavBar
            setMoreOpen={setMoreMenuOpen}
            navLinks={mobileNavLinks}
            setNavOpen={setNavOpen}
            moremenuopen={moremenuopen ? 1 : 0}
          />
        </MobileNavWrapper>
        <AlertBox />
      </Container>
      {blockSendAndReceive && (
        <AddBankAccountBanner
          closeBanner={AutoSweepBannerClose}
          btnTitle={BankAccountObj.btnTitle}
          openModal={accountOpen}
          header={BankAccountObj.header}
          body={BankAccountObj.textWithTags}
          onSubmitButtonHandler={(): void => setOpenBankForm(true)}
        />
      )}
      {openBankForm && (
        <AddBankForm
          closeModal={(): void => setOpenBankForm(false)}
          onSuccess={(): void => {
            snackBarAction('Your account details have been added', 'success', 3000, dispatch)
            setOpenBankForm(false)
          }}
        />
      )}
    </TemplateWrapper>
  )
}

export { SmeTemplate }
