/* eslint-disable @typescript-eslint/camelcase */
import { Reducer } from 'redux'

import { FxRate, PaymentTransactionError } from 'interfaces/redux/Dependents.interface'
import { MultiPayments } from 'interfaces/redux/Reducers.interface'
import { MultiPaymentTypes } from 'store/actionTypes'

const InitialState = {
  // payment method
  allSavedCards: [],
  paymentStep: 1,
  paymentMethod: 'creditCard',
  // payment step
  loading: false,
  netSettlementCall: false,
  endTransactionTimer: undefined,
  destinationCurrencies: [],
  // individual send transaction
  transactions: [],
  sourceCurrency: null,
  individualPaymentExchangeValue: [],
  receiveCurrency: null,
  // accounting data
  accountingData: null,
  //invoice data
  invoiceId: '',
}

const multiPaymentsReducer: Reducer<MultiPayments> = (state = InitialState, action) => {
  switch (action.type) {
    // default actions
    case MultiPaymentTypes.LOADING_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      }
    case MultiPaymentTypes.LOADING_DONE:
      return {
        ...state,
        loading: false,
        netSettlementCall: false,
      }
    case MultiPaymentTypes.LOADING_FEE_IN_PROGRESS:
      return {
        ...state,
        netSettlementCall: true,
      }
    case MultiPaymentTypes.LOADING_FEE_DONE:
      return {
        ...state,
        netSettlementCall: false,
      }
    case MultiPaymentTypes.UPDATE_PAYMENT_STEP:
      return {
        ...state,
        paymentStep: action.payload,
      }
    case MultiPaymentTypes.PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case MultiPaymentTypes.UPDATE_RECEIVE_CURRENCY:
      return {
        ...state,
        receiveCurrency: action.payload,
      }
    // benefificiary actions
    case MultiPaymentTypes.ADD_BENE_TO_LIST:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      }
    case MultiPaymentTypes.ADD_DESTINATION_CURRENCY_TO_LIST:
      return {
        ...state,
        destinationCurrencies: [...state.destinationCurrencies, action.payload],
      }
    case MultiPaymentTypes.REMOVE_DESTINATION_CURRENCY_FROM_LIST:
      return {
        ...state,
        destinationCurrencies: state.destinationCurrencies.filter(
          (currency) => currency !== action.payload
        ),
      }
    case MultiPaymentTypes.DELETE_BENE_FROM_LIST:
      return {
        ...state,
        transactions: state.transactions.filter(
          (item) => item.transactionBeneficiaryId !== action.payload
        ),
      }
    // fxrate actions
    case MultiPaymentTypes.FETCH_AND_LOCK_FXRATES:
      return {
        ...state,
        loading: false,
        endTransactionTimer: action.payload.resetEndTimer
          ? action.payload.endTransactionTimer
          : state.endTransactionTimer,
        transactions: state.transactions.map((item) => ({
          ...item,
          exchangeValue: action.payload.resetEndTimer ? undefined : item.exchangeValue,
          // if fxrate success
          fxRate:
            (
              action.payload.fxRates as {
                success: boolean
                data: FxRate
                error: PaymentTransactionError
              }[]
            ).find(
              (fxrate) =>
                fxrate.success &&
                fxrate.data.destination_currency ===
                  item.beneficiary.payouts[0].destination_currency
            )?.data ?? item.fxRate,
          // if fxrate error
          error:
            (
              action.payload.fxRates as {
                success: boolean
                data: FxRate
                error: PaymentTransactionError
              }[]
            ).find(
              (fxrate) =>
                !fxrate.success &&
                fxrate.data.destination_currency ===
                  item.beneficiary.payouts[0].destination_currency
            )?.error ?? item.error,
        })),
      }
    // set amount actions
    case MultiPaymentTypes.SET_AMOUNT_BY_BENEFICIARYID:
      return {
        ...state,
        transactions: state.transactions.map((item) => {
          return item.transactionBeneficiaryId === action.payload.transactionBeneficiaryId
            ? {
                ...item,
                exchangeValue: action.payload.exchangeValue,
                isSourceInput: action.payload.isSourceInput,
                isDestinationInput: action.payload.isDestinationInput,
                lastEntered: action.payload.lastEntered || item.lastEntered,
                feeId: action.payload.feeId,
                error: undefined,
              }
            : item
        }),
      }
    case MultiPaymentTypes.INPUT_STATUS_BY_BENEFICIARYID:
      return {
        ...state,
        transactions: state.transactions.map((item) => {
          return item.transactionBeneficiaryId === action.payload.transactionBeneficiaryId
            ? {
                ...item,
                isSourceInput: action.payload.isSourceInput,
                isDestinationInput: action.payload.isDestinationInput,
              }
            : item
        }),
      }
    // payout actions
    case MultiPaymentTypes.VALIDATE_PAYOUT_REQUEST:
      return {
        ...state,
        transactions: state.transactions.map((item) => {
          return item.transactionBeneficiaryId === action.payload.transactionBeneficiaryId
            ? {
                ...item,
                validatePayout: action.payload.validatePayout,
              }
            : item
        }),
      }
    case MultiPaymentTypes.CREATE_PAYOUT_REQUEST:
      return {
        ...state,
        transactions: state.transactions.map((item) => ({
          ...item,
          createPayoutResponse:
            item.transactionBeneficiaryId === action.payload.transactionBeneficiaryId
              ? action.payload.createPayoutResponse
              : item.createPayoutResponse,
          validatePayout:
            item.transactionBeneficiaryId === action.payload.transactionBeneficiaryId
              ? action.payload.validatePayout
              : item.validatePayout,
        })),
      }
    case MultiPaymentTypes.VALIDATE_PAYOUT_BATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        individualPaymentExchangeValue: action.payload,
        paymentStep: 3,
        batchId:
          state.transactions.length > 1
            ? state.transactions[0].validatePayout?.transaction.external_reference_id
            : undefined,
      }
    case MultiPaymentTypes.CREATE_PAYOUT_BATCH_COMPLETE:
      return {
        ...state,
        loading: false,
        paymentStep: 5,
      }
    // payment related actions
    case MultiPaymentTypes.UPDATE_SOURCE_CURRENCY:
      return {
        ...state,
        sourceCurrency: action.payload,
      }

    // error
    case MultiPaymentTypes.VALIDATE_PAYOUT_BATCH_FAILURE:
    case MultiPaymentTypes.CREATE_PAYOUT_BATCH_FAILURE:
    case MultiPaymentTypes.GET_ALL_SAVED_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case MultiPaymentTypes.SET_AMOUNT_ERROR_BY_BENEFICIARYID:
    case MultiPaymentTypes.VALIDATE_PAYOUT_FAILURE:
    case MultiPaymentTypes.CREATE_PAYOUT_FAILURE:
      return {
        ...state,
        transactions: state.transactions.map((item) => {
          return item.transactionBeneficiaryId === action.payload.transactionBeneficiaryId
            ? {
                ...item,
                error: action.payload.error,
              }
            : item
        }),
      }
    case MultiPaymentTypes.UPDATE_ACCOUNTING_DATA:
      return {
        ...state,
        accountingData: action.payload,
      }
    case MultiPaymentTypes.GET_INVOICE_DATA:
      return {
        ...state,
        invoiceId: action.payload,
      }
    case MultiPaymentTypes.GET_STRIPE_ID: {
      const updatedState = {
        ...state,
        transactions:
          state.transactions.length === 0
            ? [{ stripe_id: action.payload }]
            : state.transactions.map((transaction) => ({
                ...transaction,
                stripe_id: action.payload,
              })),
      }
      return updatedState
    }

    case MultiPaymentTypes.RESET_STATE:
      return InitialState
    default:
      return state
  }
}

export { multiPaymentsReducer as multiPayments }
