/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'

import {
  bankRoutingCodeFields,
  contactModalScreens,
  currencyWithIdentificationType,
} from 'V2/constants/contacts'
import { USD_SWIFT_ENABLED } from 'V2/constants/userClientDetails'
import ajvValidation from 'V2/helpers/ajvValidation'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import ContactSchemaService from 'V2/services/contactSchema'
import { fetchValidationSchema } from 'V2/services/fetchValidationSchema'
import { Logger } from 'V2/services/logger'

const FILE_NAMESPACE = 'useFormSchema'

/**Concept: The schema is divided into three screens as it appears in UI
 * First: This is just to set the recipient country and currency
 * Second: Recipient Details screen
 * Third: Bank Details screen
 */
const useFormSchema = (
  beneficiaryCountryCode: string,
  beneficiaryCurrency: string,
  editData?: any,
  beneAccountType?: string,
  billDetails?: { name: string }
): any => {
  const clientLegalEntity = useSelector(
    (state) => state.userClientDetails.activeClient?.client_legal_entity
  )
  const usdSwiftEnabled =
    useSelector((state) => {
      return (
        state.userClientDetails.featureFlagConfiguration?.featureFlagInfo?.find(
          (featureFlag) => featureFlag.feature === USD_SWIFT_ENABLED
        )?.enabled ?? false
      )
    }) &&
    beneficiaryCurrency === 'USD' &&
    beneficiaryCountryCode === 'US'
  /** This stores schema to create Recipient Details(second) screen */
  const [recipientDetailsFormSchema, changeRecipientDetailsFormSchema] = useState<any>({})
  /** This stores schema to create Bank Details(third) screen */
  const [bankDetailsFormSchema, changeBankDetailsFormSchema] = useState<any>({})
  /** this store response schema and all modified schemas  */
  const [schemaConfig, changeSchemaConfig] = useState<any>({})
  const [anyOfFields, changeAnyOfFields] = useState({
    anyOf: [],
    fields: [],
  })
  /**  Recipients details screen form values and errors */
  const [recipientDetailValues, changeRecipientDetailValues] = useState({
    ...(billDetails ? { beneficiary_name: billDetails.name } : {}),
    error: {},
  })
  /** Bank details screen form values and errors*/
  const [bankDetailsValues, changeBankDetails] = useState({
    error: {},
  })
  /**This is to disable the name field for adding bene with bill details  */
  const [disableBeneName, setDisableBeneName] = useState(billDetails && true)

  /**This stores basic bene details */
  const [beneficiaryBasicDetails, changeBeneficiaryBasicDetails] = useState<any>({
    disableBankName: true,
    client_legal_entity: clientLegalEntityMapper(clientLegalEntity || 'USD'),
    beneficiary_country_code: beneficiaryCountryCode,
    destination_country: beneficiaryCountryCode,
    destination_currency: beneficiaryCurrency,
  })

  useEffect(() => {
    changeBeneficiaryBasicDetails((prevState: any) => ({
      ...prevState,
      beneficiary_country_code: beneficiaryCountryCode,
      destination_country: beneficiaryCountryCode,
      destination_currency: beneficiaryCurrency,
    }))
  }, [beneficiaryCountryCode, beneficiaryCurrency])

  /** This function fetches the form schema from api
   * Divides the response schema into screens and stores it to above defined screen  states
   */
  const getFormSchema = async (
    destinationCurrency: string,
    destinationCountry: string,
    payoutMethod = 'LOCAL'
  ): Promise<any> => {
    let enableBankLookup = true

    const response = await fetchValidationSchema(
      destinationCurrency,
      payoutMethod,
      destinationCountry
    )

    if (response.status === 'error') {
      changeRecipientDetailsFormSchema({})
      return response
    }

    Logger(FILE_NAMESPACE).silly(
      'flatternSchema:arg:state',
      JSON.stringify({
        ...beneficiaryBasicDetails,
        beneficiary_account_type: beneAccountType,
      })
    )

    /** It filter outs anyOf, properties, required fields from response */
    const flattenedSchema = ContactSchemaService.flatternSchema(response, {
      ...beneficiaryBasicDetails,
      beneficiary_account_type: beneAccountType,
    })

    /** Setting anyOfField
     * anyOf condition only works for Bank details (Second) Screen
     * */

    const { anyOf = [] } = flattenedSchema

    const anyOfFieldsArray = ContactSchemaService.anyOfFieldsFromSchema(flattenedSchema, anyOf)

    /** Setting anyOf config in state */
    changeAnyOfFields({ anyOf, fields: anyOfFieldsArray })

    /** Divides flattenedSchema into number  of screens */
    const screenFields = ContactSchemaService.screenFields({
      contactModalScreens,
      flattenedSchema,
    })

    Logger(FILE_NAMESPACE).silly('screenFields', screenFields)

    const screensCopy = [...screenFields?.contactModalScreens]
    Logger(FILE_NAMESPACE).silly('screensCopy', screensCopy)

    /** This filter outs and provides bankRoutingDetails from schema properties */
    const { routingCodeTypeState, bankRoutingDetails = {} } =
      ContactSchemaService.bankRoutingFields({
        bankRoutingCodeFields,
        flattenedSchema,
        // TO confirm State
      })

    Logger(FILE_NAMESPACE).silly('routingCodeTypeState', routingCodeTypeState)
    Logger(FILE_NAMESPACE).silly('bankRoutingDetails', bankRoutingDetails)

    /** BankDetailsSchema with properties and required fields */
    const bankDetailsScreen = screensCopy.find((screen) => screen.step === 3)

    Logger(FILE_NAMESPACE).silly('bankDetailsScreen', bankDetailsScreen)

    const bankDetailsScreenIndex = screensCopy.findIndex((screen) => screen.step === 3)

    Logger(FILE_NAMESPACE).silly('bankDetailsScreenIndex', bankDetailsScreenIndex)

    /** Bank routing required fields */
    const bankRoutingRequiredFields = Object.keys(bankRoutingDetails).map(
      (bankRoutingField) => bankRoutingField
    )

    Logger(FILE_NAMESPACE).silly('bankRoutingRequiredFields', bankRoutingRequiredFields)

    /** enableBankLookup  and getBankNameDisabled variables to decide if the bank name field should be disabled.*/
    if ('ignoreLookup' in (response?.properties?.beneficiary_bank_code ?? {})) {
      enableBankLookup = response?.properties?.beneficiary_bank_code.ignoreLookup
    }

    for (const field in bankRoutingDetails) {
      if ('ignoreLookup' in (bankRoutingDetails[field] ?? {})) {
        enableBankLookup = !enableBankLookup
      }
    }

    /** Setting the disableBankName property in beneficiaryBasicDetails state*/
    changeBeneficiaryBasicDetails((prevState: any) => {
      let getBankNameDisabled
      if (
        bankRoutingRequiredFields.length < 1 ||
        (bankRoutingRequiredFields.length === 2 &&
          bankRoutingRequiredFields[0].includes('identification') &&
          bankRoutingRequiredFields[1].includes('identification'))
      ) {
        getBankNameDisabled = false
      }

      return { ...prevState, disableBankName: getBankNameDisabled || enableBankLookup }
    })

    const updatedBankDetailsScreen = {
      ...bankDetailsScreen,
      schema: {
        ...bankDetailsScreen?.schema,
        properties: {
          ...bankDetailsScreen?.schema?.properties,
          ...bankRoutingDetails,
        },
        required: [
          ...new Set([...bankDetailsScreen?.schema?.required, ...bankRoutingRequiredFields]),
        ],
      },
    }
    Logger(FILE_NAMESPACE).silly('updatedBankDetailsScreen', updatedBankDetailsScreen)

    screensCopy[bankDetailsScreenIndex] = updatedBankDetailsScreen

    /** This is every computed object into one object */
    const formSchemaConfig = {
      schema: response,
      ...screenFields,
      contactModalScreens: screensCopy,
      bankRoutingProperties: bankRoutingDetails,
      ...(routingCodeTypeState ? routingCodeTypeState : {}),
    }

    Logger(FILE_NAMESPACE).silly('formSchemaConfig', formSchemaConfig)

    /** This function gives schema based on screen steps.
     *  1 is for recipient details screen
     *  2 is for bank details screen
     */
    const getSchema = (stepNumber: number): any => {
      return formSchemaConfig.contactModalScreens[stepNumber].schema.required.map(
        (item: string | number) => {
          return {
            [item]: formSchemaConfig.contactModalScreens[stepNumber].schema.properties[item],
          }
        }
      )
    }

    const recipientDetailsSchema = getSchema(1)
    const usdSwiftEnabledSchema = getSchema(2)
    if (usdSwiftEnabled) {
      usdSwiftEnabledSchema.splice(
        1,
        0,
        {
          routing_code_type_2: {
            $id: '#/properties/routing_code_type_2',
            type: 'string',
            title: 'Routing Code Type 2',
            const: 'SWIFT',
            errorMessage: 'Routing Code should be SWIFT for payouts to this country.',
          },
        },
        {
          routing_code_value_2: {
            $id: '#/properties/routing_code_value_2',
            type: 'string',
            title: 'Routing Code Value 2',
            pattern: '^[A-Za-z0-9]{8}$|^[A-Za-z0-9]{11}$',
            errorMessage:
              'SWIFT should not contain special characters. Length of SWIFT should be 8 or 11 characters.',
          },
        }
      )
    }
    const bankDetailsSchema = usdSwiftEnabled ? usdSwiftEnabledSchema : getSchema(2)

    Logger(FILE_NAMESPACE).silly('recipientDetailsSchema', recipientDetailsSchema)
    Logger(FILE_NAMESPACE).silly('bankDetailsSchema', bankDetailsSchema)

    /** Updating schemas into states */
    changeBankDetailsFormSchema(() => bankDetailsSchema)
    changeSchemaConfig(() => formSchemaConfig)
    changeRecipientDetailsFormSchema(() => recipientDetailsSchema)

    return [formSchemaConfig, recipientDetailsSchema]
  }

  /** This stores error object for Recipient Details(second) screen  */
  const generateRecipientDetailsError: any = {}
  /** This stores error object for Bank Details(third) screen  */
  const generateBankDetailsError: any = {}

  /**This function is to verify the field inputs of recipientDetails(second) and bankDetails(third) screens
   * we do ajvValidation on the form value and see if they match the pattern of the schema,
   * after validation it sets the error to state
   */
  const formValidation = (step: any): any => {
    /** Step 1 is for recipientDetails validation*/
    /** Step 2 is for bankDetails validation*/
    const step1 = editData ? step === 1 : step === 2
    const step2 = editData ? step === 2 : step === 3
    if (step1) {
      const { error, ...values }: any = recipientDetailValues
      // TODO: to implement any of condition  for this particular step
      const schemaForRecipientDetailsValidation = {
        properties: pick(
          schemaConfig.schema.properties,
          schemaConfig.contactModalScreens[1].schema.required
        ),
        required: schemaConfig.contactModalScreens[1].schema.required,
        type: 'object',
      }

      /** Adding the email field from schema properties even if it's not in required field */
      const schemaWithEmailField = {
        ...schemaForRecipientDetailsValidation,
        properties: {
          ...schemaForRecipientDetailsValidation.properties,
          beneficiary_email: schemaConfig.schema.properties['beneficiary_email'],
        },
      }

      const validationErrors = ajvValidation(schemaWithEmailField, pickBy(values))

      /** Modifying the error object*/
      for (const key in validationErrors) {
        generateRecipientDetailsError[key] = validationErrors[key]
          ? {
              message: validationErrors[key],
              code: 'AJV_VALIDATE',
            }
          : undefined
      }

      if (disableBeneName && generateRecipientDetailsError['beneficiary_name']) {
        setDisableBeneName(false)
      }

      /** Setting the errors into state with recipientDetailValues*/
      changeRecipientDetailValues(() => {
        return {
          ...recipientDetailValues,
          error: generateRecipientDetailsError,
        }
      })
    }
    if (step2) {
      const { error, ...values }: any = bankDetailsValues

      /** NOTE: any of condition only validates for Bank details screen */
      const { anyOf, fields } = anyOfFields

      const bankDetailFieldsArray = bankDetailsFormSchema.map((fieldObj: any) => {
        return Object.getOwnPropertyNames(fieldObj)[0]
      })

      const bankDetailsWithoutAnyOfFields = new Array(...bankDetailFieldsArray).filter(
        (val: any) => !new Array(...fields).includes(val)
      )

      Logger(FILE_NAMESPACE).silly('bankDetailFieldsArray', bankDetailFieldsArray)

      Logger(FILE_NAMESPACE).silly('bankDetailsWithoutAnyOfFields ', bankDetailsWithoutAnyOfFields)

      const schemaForBankDetailsValidation = {
        ...(anyOf && anyOf.length > 1 && { anyOf: anyOf }),
        properties: pick(schemaConfig.schema.properties, bankDetailsWithoutAnyOfFields),
        required: bankDetailsWithoutAnyOfFields,
        type: 'object',
      }

      Logger(FILE_NAMESPACE).silly(
        'schemaForBankDetailsValidation ',
        schemaForBankDetailsValidation
      )

      // TODO:  This is a last minute patch for brazil:  need to fix this in later steps.
      if (values.beneficiary_identification_type === 'CNPJ') {
        schemaForBankDetailsValidation.properties.beneficiary_identification_value.errorMessage =
          'Length of Identification Value should not be less than 14 and more than 20 characters.'
      }

      const validationErrors = ajvValidation(schemaForBankDetailsValidation, pickBy(values))

      Logger(FILE_NAMESPACE).silly('AJV  validation  Error ', validationErrors)

      /** Modifying the error object*/
      for (const key in error) {
        if (
          !currencyWithIdentificationType.includes(beneficiaryCurrency) &&
          ['beneficiary_identification_type', 'beneficiary_identification_value'].includes(key)
        ) {
          generateBankDetailsError[key] = ''
        } else {
          generateBankDetailsError[key] = validationErrors[key]
            ? {
                message: validationErrors[key],
                code: 'AJV_VALIDATE',
              }
            : undefined
        }
      }
      /** Setting the errors into state with bankDetailsValues */
      changeBankDetails(() => {
        return {
          ...bankDetailsValues,
          error: generateBankDetailsError,
        }
      })
    }
  }

  /** This function is used for look up field validations before calling the api to get bank name
   * This checks if the lookup field input matches the pattern present in the schema.
   */
  const lookUpFieldValidation = (formState: any, changeState: any, key: string): boolean | void => {
    const { error, ...values }: any = bankDetailsValues

    const bankDetailFieldsArray = bankDetailsFormSchema.map((item: any, index: any) => {
      return Object.getOwnPropertyNames(item)[0]
    })
    Logger(FILE_NAMESPACE).silly('bankDetailFieldsArray', bankDetailFieldsArray)

    const schema = {
      properties: pick(schemaConfig.schema.properties, bankDetailFieldsArray),
      required: bankDetailFieldsArray,
      type: 'object',
    }
    const validationErrors = ajvValidation(schema, pickBy(values))

    Logger(FILE_NAMESPACE).silly('validationErrors', validationErrors)

    /** Updating the error state (message and code) of lookup Field*/
    changeState(() => {
      return {
        ...formState,
        error: {
          ...formState.error,
          [key]: validationErrors[key]
            ? {
                message: validationErrors[key],
                code: 'AJV_VALIDATE',
              }
            : undefined,
        },
      }
    })
    return validationErrors[key]
  }

  /** This function is used to map if there is any validation error coming from backend
   * It finds out the field which have error and update the error into states
   * It also navigate to that screen which has that error field
   */
  const mapFormApiErrors = (
    changeStep: (arg0: number) => void,
    changeFormError: (arg0: string) => void,
    apiErrors: { errors: any },
    editBene = false
  ): any => {
    if (!apiErrors?.errors) {
      throw new Error('')
    }
    const { errors } = apiErrors
    let foundFieldError = false
    let navigateToRecipientDetailsScreen = false

    let bankDetailsValuesErrorCopy = { ...bankDetailsValues.error }
    errors.map((item: any, index: any): any => {
      /** Checks if the recipientDetails(second) form has any fields which has error to map */
      if (item.path in (recipientDetailValues ?? {})) {
        foundFieldError = true
        navigateToRecipientDetailsScreen = true
        const updatedRecipientDetailValues = {
          ...recipientDetailValues,
          error: {
            ...recipientDetailValues.error,
            [item.path]: {
              message: item.message,
              code: item.code,
            },
          },
        }

        // Setting updatedRecipientDetailValues into state.
        changeRecipientDetailValues(() => {
          return updatedRecipientDetailValues
        })
      }

      /** Checks if the bankDetails(third) form has the any fields which has error to map*/
      if (item.path in (bankDetailsValues ?? {})) {
        foundFieldError = true

        bankDetailsValuesErrorCopy = {
          ...bankDetailsValuesErrorCopy,
          [item.path]: {
            message: item.message,
            code: item.code,
          },
        }
      }
      return ''
    })

    /** Setting updated bankDetailsValuesErrorCopy into into state which contains value and error.*/
    changeBankDetails(() => {
      return {
        ...bankDetailsValues,
        error: {
          ...bankDetailsValuesErrorCopy,
        },
      }
    })

    /** Navigating to error screen*/
    if (navigateToRecipientDetailsScreen) {
      changeStep(editBene ? 1 : 2)
    } else if (foundFieldError) {
      if (editBene) changeStep(2)
      return
    } else {
      throw new Error('')
    }
  }

  Logger(FILE_NAMESPACE).silly('bankDetailsFormSchema', bankDetailsFormSchema)

  return [
    getFormSchema, //  to get and set the form modified schemas
    recipientDetailsFormSchema, // schema of  recipientDetail screen
    recipientDetailValues, // form values of recipientDetail screen
    changeRecipientDetailValues, // to modify values of recipientDetail screen
    formValidation, // to perform form validation on recipientDetail and  bankDetails screen
    bankDetailsFormSchema, // schema of  bankDetails screen
    bankDetailsValues, // form values of bankDetails screen
    changeBankDetails, // to modify values of bankDetails screen
    mapFormApiErrors, // api error mapping into fields
    lookUpFieldValidation, // validation for lookup field before calling lookup api
    generateRecipientDetailsError, // error object for recipientDetails screen
    generateBankDetailsError, // error object for bankDetails screen
    disableBeneName, // to disable the bene name field to add bene for bill payment
    schemaConfig,
    anyOfFields,
  ]
}

export { useFormSchema }
