import styled from 'styled-components/macro'

export const FlagCurrencyName = styled.span`
  display: flex;
  align-items: center;
`

export const FlagCurrencyBoldName = styled(FlagCurrencyName)`
  font-weight: 600;
  padding-left: 8px;
`

export const FlagCurrencyOptionWrapper = styled.div`
  display: flex;
  align-items: center;
`
