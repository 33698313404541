import styled from 'styled-components/macro'

import { floors } from 'V2/constants/floors'
import { great } from 'V2/helpers/mediaquery'

export const Error = styled.div`
  color: ${({ theme }): string => theme.revamp.staticColors.redPepperCorn};
  padding: 0 16px;
  font-size: 13px;
  line-height: 20px;
`

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const DropdownWrapper = styled.div<{ width: number }>`
  position: relative;
  width: ${({ width }): string => (width ? `${width}px` : '100%')};
`
export const BlurDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }): string => theme.revamp.staticColors.black};
  opacity: 0.8;
  z-index: ${floors.top};
  @media ${great.greaterThanMobile} {
    display: none;
  }
`
export const Label = styled.label<{ hasValue: boolean; error: boolean; isFocused: boolean }>`
  color: ${({ hasValue, error, isFocused, theme }): string =>
    error
      ? theme.revamp.staticColors.redPepperCorn
      : isFocused || hasValue
      ? theme.revamp.staticColors.black
      : theme.revamp.primary.grey.root};
  font-size: ${({ hasValue }): string => (hasValue ? '13px' : '15px')};
  transition: 0.1s ease-out;
  transform: translateY(-50%) scale(0.9) !important;
  transform-origin: left top;
  position: absolute;
  margin: 0 12px;
  padding: 0 4px;
  background: ${({ theme, hasValue, isFocused }): string =>
    hasValue || isFocused ? theme.revamp.staticColors.white : 'transparent'};
  top: ${({ isFocused, hasValue, error }): string =>
    isFocused || hasValue ? '0%' : error ? '37%' : '50%'};
  z-index: ${floors.first};
`
