export const bizpaySendCurrencies = [
  // Bizpay
  {
    value: 'USD',
    label: 'United States Dollar (USD)',
    labelToOpenAccount: 'US Dollar',
    localForCountry: 'US',
  },
  {
    value: 'INR',
    label: 'India Rupees (INR)',
    labelToOpenAccount: 'India Rupees',
    localForCountry: 'IN',
  },
  {
    value: 'AUD',
    label: 'Australian Dollar (AUD)',
    labelToOpenAccount: 'Australian Dollar',
    localForCountry: 'AU',
  },
  {
    value: 'GBP',
    label: 'British Pound Sterling (GBP)',
    labelToOpenAccount: 'British Pound',
    localForCountry: 'GB',
  },
  {
    value: 'MYR',
    label: 'Malaysian Ringgit (MYR)',
    localForCountry: 'MY',
  },
  {
    value: 'PHP',
    label: 'Philippine Peso (PHP)',
    localForCountry: 'PH',
  },
  {
    value: 'THB',
    label: 'Thailand Baht (THB)',
    localForCountry: 'TH',
  },
  {
    value: 'SGD',
    label: 'Singapore Dollar (SGD)',
    labelToOpenAccount: 'Singapore Dollar',
    localForCountry: 'SG',
  },
  {
    value: 'HKD',
    label: 'Hong Kong Dollar (HKD)',
    labelToOpenAccount: 'Hong Kong Dollar',
    localForCountry: 'HK',
  },
  {
    value: 'NPR',
    label: 'Nepalese Rupee (NPR)',
    localForCountry: 'NP',
  },
  {
    value: 'LKR',
    label: 'Sri Lankan Rupee (LKR)',
    localForCountry: 'LK',
  },
  {
    value: 'ARS',
    label: 'Argentine Peso (ARS)',
    localForCountry: 'AR',
  },
  {
    value: 'COP',
    label: 'Colombian Peso (COP)',
    localForCountry: 'CO',
  },
  {
    value: 'CLP',
    label: 'Chilean Peso (CLP)',
    localForCountry: 'CL',
  },
  {
    value: 'MXN',
    label: 'Mexican Peso (MXN)',
    localForCountry: 'MX',
  },
  {
    value: 'PEN',
    label: 'Peruvian Sol (PEN)',
    localForCountry: 'PE',
  },
  {
    value: 'CAD',
    label: 'Canadian Dollar (CAD)',
    localForCountry: 'CA',
  },
  {
    value: 'BRL',
    label: 'Brazilian Real (BRL)',
    localForCountry: 'BR',
  },
  {
    value: 'KRW',
    label: 'South Korean Won (KRW)',
    localForCountry: 'KR',
  },
  {
    value: 'UYU',
    label: 'Uruguayan Peso (UYU)',
    localForCountry: 'UY',
  },
  {
    value: 'PKR',
    label: 'Pakistan Rupee (PKR)',
    localForCountry: 'PK',
  },
  // Send
  {
    value: 'EUR',
    label: 'Euro (EUR)',
    labelToOpenAccount: 'Euro',
    localForCountry: 'EU',
  },
  {
    value: 'IDR',
    label: 'Indonesian Rupiah (IDR)',
    localForCountry: 'ID',
  },
  {
    value: 'VND',
    label: 'Vietnamese Dong (VND)',
    localForCountry: 'VN',
  },
  {
    value: 'PLN',
    label: 'Polish Zloty (PLN)',
    localForCountry: 'PL',
  },
  {
    value: 'JPY',
    label: 'Japanese Yen (JPY)',
    labelToOpenAccount: 'Japanese Yen',
    localForCountry: 'JP',
  },
  {
    value: 'SEK',
    label: 'Swedish Krona (SEK)',
    localForCountry: 'SE',
  },
  {
    value: 'CZK',
    label: 'Czech Koruna (CZK)',
    localForCountry: 'CZ',
  },
  {
    value: 'DKK',
    label: 'Danish Krone (DKK)',
    localForCountry: 'DK',
  },
  {
    value: 'RON',
    label: 'Romanian Le (RON)',
    localForCountry: 'RO',
  },
  {
    value: 'HUF',
    label: 'Hungarian Forint (HUF)',
    localForCountry: 'HU',
  },
  {
    value: 'CHF',
    label: 'Swiss Franc (CHF)',
    localForCountry: 'CH',
  },
  {
    value: 'TRY',
    label: 'Turkish Lira (TRY)',
    localForCountry: 'TR',
  },
  {
    value: 'AED',
    label: 'United Arab Emirates Dirham (AED)',
    localForCountry: 'AE',
  },
  {
    value: 'NOK',
    label: 'Norwegian Krone (NOK)',
    localForCountry: 'NO',
  },
  {
    value: 'NZD',
    label: 'New Zealand Dollar (NZD)',
    labelToOpenAccount: 'New Zealand Dollar',
    localForCountry: 'NZ',
  },
  {
    value: 'BGN',
    label: 'Bulgarian Lev (BGN)',
    localForCountry: 'BG',
  },
  {
    value: 'HRK',
    label: 'Croatian Kuna (HRK)',
    localForCountry: 'HR',
  },
  {
    value: 'ZAR',
    label: 'South African Rand (ZAR)',
    localForCountry: 'ZA',
  },
  {
    value: 'XOF',
    label: 'Ivory Coast CFA Franc (XOF)',
    localForCountry: 'CI',
  },
  // {
  //   value: 'TWD',
  //   label: 'New Taiwan Dollar (TWD)',
  //   localForCountry: 'TW',
  // },
  // {
  //   value: 'FJD',
  //   label: 'Fijian Dollar (FJD)',
  //   localForCountry: 'FJ',
  // },
  {
    value: 'SAR',
    label: 'Saudi Riyal (SAR)',
    localForCountry: 'SA',
  },
  // we cant remove this yet coz we have to show already created transactions
  { value: 'CNY', label: 'Chinese Yuan (CNY)', localForCountry: 'CN' },
]

export const disabledBizpaySendCurrencies: Array<{
  value: string
  label: string
  localForCountry: string
}> = [
  { value: 'CNY', label: 'Chinese Yuan (CNY)', localForCountry: 'CN' },
  { value: 'HRK', label: 'Croatian Kuna (HRK)', localForCountry: 'HR' },
]

export const accountTypeDisabledSendCurrencies: {
  [key: string]: { currencyDetails: {}; accountType: string }
} = {
  PKR: {
    currencyDetails: {
      value: 'PKR',
      label: 'Pakistan Rupee (PKR)',
      localForCountry: 'PK',
    },
    accountType: 'Company',
  },
}

export const minimumAmountForCurrency: Array<{
  currency: string
  amount: number
}> = [{ currency: 'CNY', amount: 200 }]
