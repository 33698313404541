// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import ReactDOM from 'react-dom'
import * as amplitude from '@amplitude/analytics-browser'
import type {} from 'styled-components/cssprop'

import GA from 'V2/helpers/gaTracker'
import apm from './apm'
import App from './App'
import * as serviceWorker from './serviceWorker'
// Amplitude configurations
const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY ?? ''
amplitude.init(AMPLITUDE_API_KEY)

apm.setInitialPageLoadName('initial load')

if (
  process.env.REACT_APP_ENV &&
  ['production', 'qa', 'preprod'].includes(process.env.REACT_APP_ENV)
) {
  GA.create(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '', true)
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
