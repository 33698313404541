// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { forwardRef } from 'react'
import { useTheme } from 'styled-components/macro'

import { AtomsInput } from 'V2/interfaces/components/Atoms.interface'
import { BaseInput, InputWrapper, Label, Message, Wrapper } from './Styles'

export const Input = forwardRef<HTMLInputElement, AtomsInput>(
  (
    {
      maxLength = 50,
      minLength = 0,
      showMessage = true,
      message,
      isError = false,
      disabled = false,
      label,
      placeholder = ' ',
      children,
      ...props
    },
    ref
  ) => {
    const theme = useTheme().revamp
    return (
      <Wrapper>
        <InputWrapper>
          <BaseInput
            ref={ref}
            maxLength={maxLength}
            minLength={minLength}
            isError={isError}
            disabled={disabled}
            placeholder={placeholder}
            {...props}
          />
          <Label>{label}</Label>
          {children}
        </InputWrapper>
        {showMessage && (
          <Message textColor={isError ? theme.staticColors.redPepperCorn : theme.primary.grey.root}>
            {message}
          </Message>
        )}
      </Wrapper>
    )
  }
)
