// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import { Bold } from 'V2/components/organisms/PromoBanner/Styles'

export const pricingBannerObj = {
  header: 'Pricing update',
  textWithTags: 'Enjoy zero fees on all cross-currency transfers with our latest pricing update.',
  btnTitle: 'Got it!',
}

const textWithTags = (
  <>
    You can now receive payments in <Bold>JPY</Bold> and <Bold>NZD</Bold> in addition to
  </>
)

export const nzdBanner = {
  primaryButtonTitle: 'Got it!',
  inlineText: 'Go global and get paid like a local!',
  textWithTags: textWithTags,
}

export const bulkBannerObj = {
  header: 'Streamline your payments with Bulk transfers',
  textWithTags:
    'Streamline your bulk payment processes with our hassle-free solution, allowing you to effortlessly manage large-scale transactions with ease and efficiency. Save time and resources.',
  btnTitle: 'Got it',
}

export const VFABannerObj = {
  header: 'New and improved bank transfer funding!',
  textWithTags:
    'You no longer need to enter a reference number when funding your transactions via Bank transfer - just make sure to use our new bank account number for your transactions and enjoy a smoother, hassle-free payment experience.',
  btnTitle: 'Got it!',
}

export const addBankAccountBannerObj = {
  header: 'Add your bank account details',
  content:
    'To comply with our internal policy requirements, we need your company’s bank account details. We will transfer your funds to your bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  btnTitle: 'Add now',
}

export const welcomeBannerScreen1 = {
  header: 'Welcome to an upgraded Instarem experience',
  textWithTags:
    'Embrace the future with simplified business payments. Now offering new currencies like CNY, TRY, JPY, and real-time transfers in IDR, HKD, and AUD.',
  primaryButtonTitle: 'Next',
}
export const welcomeBannerScreen2 = {
  header: 'Get paid in 8 currencies',
  textWithTags:
    'Collect payments in SGD, USD, GBP, EUR, AUD, HKD, NZD, and JPY and convert funds instantly. ',
  primaryButtonTitle: 'Next',
  secondaryButtonTitle: 'Back',
}
export const welcomeBannerScreen3 = {
  header: 'Make up to 1,000 transactions in one go',
  textWithTags: 'Enjoy the convenience of our bulk payments feature!',
  textWithInlineTags:
    'Say goodbye to time-consuming manual entries and embrace an efficient, streamlined process.',
  primaryButtonTitle: 'Got it',
  secondaryButtonTitle: 'Back',
}
export const SARBannerObj = {
  header: 'New currency added',
  btnTitle: 'Got it',
  textWithTags: 'You can now make SAR payments to both businesses and individuals in Saudi Arabia.',
}
export const BankAccountObj = {
  header: 'Add your bank account',
  btnTitle: 'Add now',
  textWithTags:
    'Our regulators require us to link your bank account details to your Instarem account. We will transfer your funds to your linked bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
}
