import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCurrentClientAccountList } from 'V2/actions/userClientDetails'
import { Logger } from 'V2/services/logger'

const FILE_NAMESPACE = 'useDefaultReload'

export const useDefaultReload = (): void => {
  const dispatch = useDispatch()

  const loggedIn = useSelector((state) => state.userClientDetails.authenticated)
  useEffect(() => {
    Logger(FILE_NAMESPACE).silly('useDefaultReload called')

    if (loggedIn) {
      Logger(FILE_NAMESPACE).silly('logged in user, updating the recent details')
      dispatch(getCurrentClientAccountList())
    }

    // Logger at the place of cleverTap
    Logger(FILE_NAMESPACE).silly('SME Portal loaded')

    window.addEventListener('beforeunload', () => {
      // Logger at the place of cleverTap
      Logger(FILE_NAMESPACE).silly('SME Portal closed')
    })
  }, [dispatch, loggedIn])
}
