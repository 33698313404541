/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { AxiosError } from 'axios'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { useTheme } from 'styled-components/macro'

import { apiRoutes } from 'constants/apiRoutes'
import { ActiveClient } from 'interfaces/redux/Dependents.interface'
import { HeaderTypes } from 'store/actionTypes'
import { defaultTimeout, snackBarAction } from 'V2/actions/snackbar'
import { Button } from 'V2/components/atoms/Button/Button'
import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { Input } from 'V2/components/atoms/Input/Input'
import { ProgressBar } from 'V2/components/atoms/ProgressBar/ProgressBar'
import { Radio } from 'V2/components/atoms/Radio/Radio'
import { Spinner } from 'V2/components/atoms/Spinner/Spinner'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import { InfoIcon } from 'V2/components/iconPaths/Custom/BasicIcons/InfoIcon'
import { LogoIcon } from 'V2/components/iconPaths/Custom/NavIcons/LogoIcon'
import { Modal } from 'V2/components/molecules/Modal/Modal'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import { allowedLegalentitiesAutoSweep } from 'V2/constants/autoSweepBankAccount'
import { bizpayClientLegalEntities } from 'V2/constants/bizpayClientLegalEntity'
import { bizpaySendCurrencies } from 'V2/constants/bizpaySendCurrencies'
import { emailTemplates } from 'V2/constants/emailTemplates'
import { snackbarErrorMessages } from 'V2/constants/formErrorList'
import { HK } from 'V2/constants/paymentMethodFilters'
import ajvValidation from 'V2/helpers/ajvValidation'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import { useFormSchema } from 'V2/hooks/useFormSchema'
import BankDetails from 'V2/pages/Contacts/BankDetails/BankDetails'
import {
  ContactFormHeaderMobile,
  FormContent,
  FormContentWrapper,
  HeaderLeftElem,
  ModalFormWrapper,
  NotInMobile,
} from 'V2/pages/Contacts/Styles'
import { getUsers } from 'V2/services/beneficiary'
import { triggerEmail } from 'V2/services/common'
import { Logger } from 'V2/services/logger'
import {
  AccountNameWrapper,
  Footer,
  Header,
  NoteWrapper,
  RadioButtonTitle,
  RadioContent,
  RadioWrapperDiv,
} from './Styles'

const FILE_NAMESPACE = 'AddBankForm'

const AddBankForm: React.FC<{
  closeModal: () => void
  onSuccess: () => void
  editBankDetailsValue?: {
    bankId: string
    bankName: string
    bankAccountNumber: string
    routingType: string
    routingValue: string
    bankAccountHolderName: string
  } | null
  onFailure?: () => void
}> = ({ closeModal, editBankDetailsValue, onSuccess, onFailure }): JSX.Element => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isEditFlow = !!editBankDetailsValue?.bankAccountNumber
  const clientBaseCurrency = useSelector(
    (state) => state.userClientDetails.activeClient?.client_base_currency || ''
  )
  const { client_legal_entity } = useSelector(
    (state) => state.userClientDetails.activeClient || ({} as ActiveClient)
  )
  const activeClient = useSelector((state) => state.userClientDetails.activeClient)

  const [payoutMethod, changePayoutMethod] = useState('')
  const [initalLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [destinationCurrency, changeDestinationCurrency] = useState(clientBaseCurrency)

  const clientLegalEntity = clientLegalEntityMapper(client_legal_entity)
  const [
    getFormSchema,
    ,
    ,
    ,
    ,
    bankDetailsFormSchema,
    bankDetailsValues,
    changeBankDetails,
    ,
    lookUpFieldValidation,
    ,
    ,
    ,
    schemaConfig,
    anyOfFields,
  ] = useFormSchema(clientLegalEntity, destinationCurrency, false, 'Company')
  const localCurrency = bizpayClientLegalEntities.find(
    (legalEntity) => legalEntity.countryCode === clientLegalEntity
  )?.defaultCurrency

  const availableCurrencies =
    bizpayClientLegalEntities
      .find((item) => item.countryCode === clientLegalEntity)
      ?.currencyOptions.map((currency) => {
        return bizpaySendCurrencies.find((currencyObj) => currencyObj.value === currency)
      }) || []

  const handleBankFormSubmit = useCallback(
    async (values: {
      bankName: string
      bankAccountNumber: string
      routingType: string
      routingValue: string
      bankAccountHolderName?: string
    }): Promise<void> => {
      setLoading(true)
      const promises: Promise<{
        success: boolean
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: any
        error: string
      }>[] = []
      const { bankName, bankAccountNumber, routingType, routingValue, bankAccountHolderName } =
        values
      const isAutoSweep = allowedLegalentitiesAutoSweep.includes(clientLegalEntity)

      if (
        isAutoSweep ||
        (clientLegalEntity === 'SG' && clientBaseCurrency !== destinationCurrency)
      ) {
        const autoSweepBankParams = {
          client_name: activeClient?.client_name,
          client_label: activeClient?.client_label,
          portal_type: activeClient?.portal_type || 'SME',
          utcOffset: activeClient?.utcOffset,
          is_active: activeClient?.is_active,
          auto_reversal_days: activeClient?.auto_reversal_days,
          default_bookfx_type: activeClient?.default_bookfx_type,
          supplier_payments_customer: activeClient?.supplier_payments_customer,
          institution_type: 'SME',
          identification_type: activeClient?.identification_type,
          identification_number: activeClient?.identification_number,
          engagement_type: activeClient?.engagement_type,
          id: activeClient?._id,
          is_api_payout_approval_required: activeClient?.is_api_payout_approval_required,
          topup_currency: activeClient?.topup_currency,
          acquisition_type: activeClient?.acquisition_type,
          topup_pricing: activeClient?.topup_pricing,
          client_base_currency: destinationCurrency,
          autosweep_bank_account: {
            bank_name: bankName,
            account_number: bankAccountNumber,
            routing_code_type: routingType,
            routing_code_value: routingValue,
            bank_account_holder_name: bankAccountHolderName,
          },
        }

        promises.push(axios.post(apiRoutes.clientUpdate, autoSweepBankParams))
      }

      const bankDetailsParams = {
        bankAccountHolderName: values.bankAccountHolderName,
        bankName: values.bankName,
        accountNumber: values.bankAccountNumber,
        routingCodeType1: values.routingType,
        routingCodeValue1: values.routingValue,
        bankCode: bankDetailsValues?.beneficiary_bank_code ?? undefined,
      }
      if (isEditFlow) {
        promises.push(
          axios.put(
            apiRoutes.EditDeleteBank(activeClient?._id || '', editBankDetailsValue?.bankId),
            bankDetailsParams
          )
        )
      } else {
        const clientDetails = {
          accountType: 'Company',
          name: activeClient?.client_name || '',
          countryCode: clientLegalEntity,
          currencyCode: [destinationCurrency],
        }
        promises.push(
          axios
            .post(apiRoutes.addAndGetBankDetails(activeClient?._id ?? ''), {
              ...bankDetailsParams,
              ...clientDetails,
            })
            .then((res) =>
              axios.put(
                apiRoutes.EditDeleteBank(activeClient?._id || '', res.data[0].accountId),
                bankDetailsParams
              )
            )
        )
      }

      Promise.all(promises)
        .then(async () => {
          if (isAutoSweep) {
            const autosweep_bank_account = await axios
              .get(apiRoutes.getClientDetails(activeClient?._id ?? ''))
              .then((res) => res.data.data.autosweep_bank_account)
            dispatch({
              type: HeaderTypes.UPDATE_AUTO_SWEEP_BANK_DETAILS,
              payload: autosweep_bank_account,
            })
            Logger(FILE_NAMESPACE).silly('AutoSweepBankAccountSuccess', autosweep_bank_account)
          }
          if (clientBaseCurrency !== destinationCurrency) {
            dispatch({
              type: HeaderTypes.UPDATE_CLIENT_BASE_CURRENCY,
              payload: destinationCurrency,
            })
          }
          if (activeClient) {
            const usersList = await getUsers(activeClient._id).then((res) =>
              res?.data?.data?.map((item: { email: string }): string => {
                return item.email
              })
            )

            const emailTemplatePayload = {
              email_message: {
                params: {
                  clientName:
                    activeClient.client_name.charAt(0).toUpperCase() +
                    activeClient.client_name.slice(1),
                  accountNumber: bankDetailsValues?.beneficiary_account_number,
                },
                targets: {
                  to: usersList,
                },
                template: isEditFlow
                  ? emailTemplates.bankAccountEdited
                  : emailTemplates.bankAccountLinked,
              },
            }
            triggerEmail(emailTemplatePayload, activeClient._id)
          }
          onSuccess && onSuccess()
        })
        .catch((e) => {
          Logger(FILE_NAMESPACE).error('BankAccountSuccess', e)

          const errorMessage = (e as AxiosError).response?.data.message
          if (errorMessage.includes('Duplicate')) {
            onFailure && onFailure()
          } else {
            snackBarAction(snackbarErrorMessages.apiFailError, 'error', defaultTimeout, dispatch)
          }
        })
        .finally(() => setLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, destinationCurrency, bankDetailsValues]
  )

  const onBankFormSubmit = useCallback((): void => {
    const errorObject: any = {}

    const { error, ...values }: any = bankDetailsValues

    /** NOTE: any of condition only validates for Bank details screen */
    const { anyOf, fields } = anyOfFields

    const bankDetailFieldsArray = bankDetailsFormSchema.map((fieldObj: any) => {
      return Object.getOwnPropertyNames(fieldObj)[0]
    })

    const bankDetailsWithoutAnyOfFields = new Array(...bankDetailFieldsArray).filter(
      (val: any) => !new Array(...fields).includes(val)
    )

    const schemaForBankDetailsValidation = {
      ...(anyOf && anyOf.length > 1 && { anyOf: anyOf }),
      properties: pick(schemaConfig.schema.properties, bankDetailsWithoutAnyOfFields),
      required: bankDetailsWithoutAnyOfFields,
      type: 'object',
    }

    const validationErrors = ajvValidation(schemaForBankDetailsValidation, pickBy(values))

    /** Modifying the error object*/
    for (const key in error) {
      errorObject[key] = validationErrors[key]
        ? {
            message: validationErrors[key],
            code: 'AJV_VALIDATE',
          }
        : undefined
    }

    /** Setting the errors into state with bankDetailsValues */
    changeBankDetails(() => {
      return {
        ...bankDetailsValues,
        error: errorObject,
      }
    })

    if (
      isEmpty(errorObject?.beneficiary_account_number) &&
      isEmpty(errorObject?.routing_code_value_1)
    ) {
      const bankFormDetails = {
        bankAccountNumber: bankDetailsValues?.beneficiary_account_number,
        bankName: bankDetailsValues?.beneficiary_bank_name,
        routingType: bankDetailsValues?.beneficiary_bank_code
          ? 'BANK CODE'
          : bankDetailsValues?.routing_code_type_1,
        routingValue: bankDetailsValues?.beneficiary_bank_code
          ? bankDetailsValues?.beneficiary_bank_code
          : bankDetailsValues?.routing_code_value_1,
        bankAccountHolderName: bankDetailsValues?.bank_account_holder_name,
      }
      handleBankFormSubmit(bankFormDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankDetailsValues, handleBankFormSubmit])

  const getSchema = async (payoutMethod: string): Promise<void> => {
    setInitialLoading(true)
    await getFormSchema(destinationCurrency, payoutMethod, clientLegalEntity)
    setInitialLoading(false)
  }

  useEffect(() => {
    const newPayoutMethod = localCurrency === destinationCurrency ? 'LOCAL' : 'SWIFT'
    getSchema(newPayoutMethod)
    changePayoutMethod(newPayoutMethod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationCurrency])

  useEffect(() => {
    if (isEditFlow) {
      const commonBankDetails = {
        beneficiary_account_number: editBankDetailsValue.bankAccountNumber,
        beneficiary_bank_name: editBankDetailsValue.bankName,
        bank_account_holder_name: editBankDetailsValue.bankAccountHolderName,
      }

      const updatedBankDetails = {
        ...bankDetailsValues,
        routing_code_value_1: editBankDetailsValue.routingValue,
        ...commonBankDetails,
      }

      if (editBankDetailsValue.routingType === 'BANK CODE') {
        updatedBankDetails.beneficiary_bank_code = editBankDetailsValue.routingValue
        updatedBankDetails.routing_code_value_1 = editBankDetailsValue.routingValue
      }

      changeBankDetails(updatedBankDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttonDisabled =
    isEmpty(bankDetailsValues?.beneficiary_account_number) ||
    isEmpty(bankDetailsValues?.beneficiary_bank_name) ||
    isEmpty(bankDetailsValues?.bank_account_holder_name) ||
    (bankDetailsValues?.beneficiary_bank_code
      ? isEmpty(bankDetailsValues?.beneficiary_bank_code)
      : isEmpty(bankDetailsValues?.routing_code_value_1))

  const enableIfHasChanges =
    editBankDetailsValue?.bankAccountHolderName === bankDetailsValues.bank_account_holder_name &&
    editBankDetailsValue?.bankName === bankDetailsValues?.beneficiary_bank_name &&
    (editBankDetailsValue?.routingType === 'BANK CODE'
      ? editBankDetailsValue?.routingValue === bankDetailsValues?.beneficiary_bank_code
      : editBankDetailsValue?.routingValue === bankDetailsValues?.routing_code_value_1)

  return (
    <ModalFormWrapper deleteModal={false}>
      <Modal
        headerChild={
          <>
            <HeaderLeftElem>
              <NotInMobile>
                <CustomIcon width={120} height={21} viewBox="0 0 120 21">
                  <LogoIcon />
                </CustomIcon>
              </NotInMobile>
            </HeaderLeftElem>
            <ContactFormHeaderMobile>
              <Typography type="h1" weight="semibold">
                {isEditFlow ? `Edit your account details` : `Add your bank account`}
              </Typography>
            </ContactFormHeaderMobile>
          </>
        }
        onModalClose={(): void => closeModal()}
      >
        <>
          {initalLoading ? (
            <>
              <Spinner show={initalLoading} />
              <ProgressBar
                numberOfSteps={1}
                activeStepIndex={1}
                progressBarWidth={100}
                showDot={false}
                progressBarColor={theme.revamp.secondary.tangerine.root}
              />
            </>
          ) : (
            <>
              <Spinner show={loading} />
              <ProgressBar
                numberOfSteps={1}
                activeStepIndex={1}
                progressBarWidth={100}
                showDot={false}
                progressBarColor={theme.revamp.secondary.tangerine.root}
              />
              <FormContentWrapper>
                <div>
                  <Header>
                    <Typography
                      type="h1"
                      weight="semibold"
                      id={automationIdSelectors.contactsTitles.addRecipient}
                    >
                      {isEditFlow ? `Edit your account details` : `Add your bank account`}
                    </Typography>
                  </Header>
                </div>
                <FormContent>
                  <NoteWrapper>
                    <CustomIcon>
                      <InfoIcon />
                    </CustomIcon>
                    <Typography type="body" weight="medium">
                      Make sure the bank account belongs to your company.
                    </Typography>
                  </NoteWrapper>

                  {['HK', 'SG'].includes(clientLegalEntity) && (
                    <RadioWrapperDiv>
                      <RadioButtonTitle type="caption" weight="medium">
                        Please select your bank account currency
                      </RadioButtonTitle>
                      <RadioContent>
                        {availableCurrencies.map((item, index) => {
                          return (
                            <Radio
                              id={`SME_bank_account_base_currency_form_${item?.value}`}
                              label={item?.label}
                              key={`${item}-${index}`}
                              checked={destinationCurrency === item?.value || false}
                              value={item?.value}
                              onChange={(e): void => {
                                changeDestinationCurrency(e.target.value)
                              }}
                              disabled
                            ></Radio>
                          )
                        })}
                      </RadioContent>
                    </RadioWrapperDiv>
                  )}
                  {!isEmpty(bankDetailsFormSchema) && (
                    <>
                      <AccountNameWrapper>
                        <Input
                          label="Bank account holder name"
                          height="48px"
                          value={bankDetailsValues.bank_account_holder_name}
                          onChange={(e): void => {
                            changeBankDetails({
                              ...bankDetailsValues,
                              bank_account_holder_name: e.target.value,
                            })
                          }}
                        />
                        <Typography type="caption" weight="regular">
                          Name must match your registered business name
                        </Typography>
                      </AccountNameWrapper>

                      <BankDetails
                        isEdit={false}
                        fieldValidation={lookUpFieldValidation}
                        formError={''}
                        bankDetailsFormSchema={bankDetailsFormSchema.filter(
                          (item: object) =>
                            !(
                              localCurrency === destinationCurrency &&
                              (Object.keys(item).includes('routing_code_type_1') ||
                                Object.keys(item).includes('routing_code_value_1')) &&
                              clientLegalEntity === HK
                            )
                        )}
                        bankDetailsValues={bankDetailsValues}
                        ChangeBankDetails={changeBankDetails}
                        destinationCountry={clientLegalEntityMapper(client_legal_entity || '')}
                        payoutMethod={payoutMethod}
                        destinationCurrency={destinationCurrency}
                        isAutoSweepEditFlow={isEditFlow}
                      />
                    </>
                  )}
                </FormContent>
                <Footer>
                  <Button
                    id={automationIdSelectors.autoSweepBankDetailsPage.addBankAccountCta}
                    variant="primary"
                    size="large"
                    title={isEditFlow ? 'Save changes' : 'Add your account'}
                    onClick={onBankFormSubmit}
                    disabled={buttonDisabled || enableIfHasChanges}
                  />
                </Footer>
              </FormContentWrapper>
            </>
          )}
        </>
      </Modal>
    </ModalFormWrapper>
  )
}

export default AddBankForm
