// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

import { appRoutes } from 'V2/constants/appRoutes'
import { getUTMQueryInString } from 'V2/helpers/utmHelpers'

export const DefaultRoute: React.FC<{}> = () => {
  const loggedIn = useSelector((state) => state.userClientDetails?.authenticated || false)

  return (
    <Route
      path="/"
      render={(): JSX.Element =>
        loggedIn ? (
          <Redirect to={appRoutes.dashboard} />
        ) : (
          <Redirect to={`${appRoutes.authLogin}${getUTMQueryInString()}`} />
        )
      }
    />
  )
}
