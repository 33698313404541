import NodeRsa from 'node-rsa'

export const encryptLocalData = (toBeEncryptedData: string | object): string => {
  const data =
    // object can be array or json object | null is also returned as object when using typeof
    typeof toBeEncryptedData === 'object' && toBeEncryptedData !== null
      ? JSON.stringify(toBeEncryptedData)
      : toBeEncryptedData
  const nodeRsaPublicKey = new NodeRsa(process.env.REACT_APP_LOCALSTORAGE_RSA_PUBLIC_KEY ?? '')
  return nodeRsaPublicKey.encrypt(data, 'base64')
}

export const decryptLocalData = (toBeDecryptedData: string | null): string => {
  if (!toBeDecryptedData) {
    return ''
  }
  const nodeRsaPrivateKey = new NodeRsa(process.env.REACT_APP_LOCALSTORAGE_RSA_PRIVATE_KEY ?? '')
  return nodeRsaPrivateKey.decrypt(toBeDecryptedData, 'utf8')
}
