/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Ajv from 'ajv'

function ajvValidation(schema: any, data: object): any {
  const ajv = new Ajv({ allErrors: true })
  const validate = ajv.compile(schema)
  const valid = validate(data)
  const errors: any = !valid ? validate.errors : []

  return errors
    .map((error: any) => {
      if (error?.keyword === 'required') {
        const fieldName = error?.params?.missingProperty
        return {
          [fieldName]: error?.message,
        }
      } else {
        const fieldName = error?.dataPath?.slice(error?.dataPath?.lastIndexOf('.') + 1)
        if (!fieldName) return {}

        return {
          [fieldName]: schema?.properties?.[fieldName]?.errorMessage ?? error?.message,
        }
      }
    })
    .reduce((acc: object, val: object) => Object.assign(acc, val), {})
}

export default ajvValidation
