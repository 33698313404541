import { css } from 'styled-components/macro'

import HellixBlackEOT from 'assets/fonts/Hellix-Black.eot'
import HellixBlackTTF from 'assets/fonts/Hellix-Black.ttf'
import HellixBlackWOFF from 'assets/fonts/Hellix-Black.woff'
import HellixBlackItalicEOT from 'assets/fonts/Hellix-BlackItalic.eot'
import HellixBlackItalicTTF from 'assets/fonts/Hellix-BlackItalic.ttf'
import HellixBlackItalicWOFF from 'assets/fonts/Hellix-BlackItalic.woff'
import HellixBoldEOT from 'assets/fonts/Hellix-Bold.eot'
import HellixBoldTTF from 'assets/fonts/Hellix-Bold.ttf'
import HellixBoldWOFF from 'assets/fonts/Hellix-Bold.woff'
import HellixBoldItalicEOT from 'assets/fonts/Hellix-BoldItalic.eot'
import HellixBoldItalicTTF from 'assets/fonts/Hellix-BoldItalic.ttf'
import HellixBoldItalicWOFF from 'assets/fonts/Hellix-BoldItalic.woff'
import HellixExtraBoldEOT from 'assets/fonts/Hellix-ExtraBold.eot'
import HellixExtraBoldTTF from 'assets/fonts/Hellix-ExtraBold.ttf'
import HellixExtraBoldWOFF from 'assets/fonts/Hellix-ExtraBold.woff'
import HellixExtraBoldItalicEOT from 'assets/fonts/Hellix-ExtraBoldItalic.eot'
import HellixExtraBoldItalicTTF from 'assets/fonts/Hellix-ExtraBoldItalic.ttf'
import HellixExtraBoldItalicWOFF from 'assets/fonts/Hellix-ExtraBoldItalic.woff'
import HellixLightEOT from 'assets/fonts/Hellix-Light.eot'
import HellixLightTTF from 'assets/fonts/Hellix-Light.ttf'
import HellixLightWOFF from 'assets/fonts/Hellix-Light.woff'
import HellixLightItalicEOT from 'assets/fonts/Hellix-LightItalic.eot'
import HellixLightItalicTTF from 'assets/fonts/Hellix-LightItalic.ttf'
import HellixLightItalicWOFF from 'assets/fonts/Hellix-LightItalic.woff'
import HellixMediumEOT from 'assets/fonts/Hellix-Medium.eot'
import HellixMediumTTF from 'assets/fonts/Hellix-Medium.ttf'
import HellixMediumWOFF from 'assets/fonts/Hellix-Medium.woff'
import HellixMediumItalicEOT from 'assets/fonts/Hellix-MediumItalic.eot'
import HellixMediumItalicTTF from 'assets/fonts/Hellix-MediumItalic.ttf'
import HellixMediumItalicWOFF from 'assets/fonts/Hellix-MediumItalic.woff'
import HellixRegularEOT from 'assets/fonts/Hellix-Regular.eot'
import HellixRegularTTF from 'assets/fonts/Hellix-Regular.ttf'
import HellixRegularWOFF from 'assets/fonts/Hellix-Regular.woff'
import HellixRegularItalicEOT from 'assets/fonts/Hellix-RegularItalic.eot'
import HellixRegularItalicTTF from 'assets/fonts/Hellix-RegularItalic.ttf'
import HellixRegularItalicWOFF from 'assets/fonts/Hellix-RegularItalic.woff'
import HellixSemiBoldEOT from 'assets/fonts/Hellix-SemiBold.eot'
import HellixSemiBoldTTF from 'assets/fonts/Hellix-SemiBold.ttf'
import HellixSemiBoldWOFF from 'assets/fonts/Hellix-SemiBold.woff'
import HellixSemiBoldItalicEOT from 'assets/fonts/Hellix-SemiBoldItalic.eot'
import HellixSemiBoldItalicTTF from 'assets/fonts/Hellix-SemiBoldItalic.ttf'
import HellixSemiBoldItalicWOFF from 'assets/fonts/Hellix-SemiBoldItalic.woff'
import HellixThinEOT from 'assets/fonts/Hellix-Thin.eot'
import HellixThinTTF from 'assets/fonts/Hellix-Thin.ttf'
import HellixThinWOFF from 'assets/fonts/Hellix-Thin.woff'
import HellixThinItalicEOT from 'assets/fonts/Hellix-ThinItalic.eot'
import HellixThinItalicTTF from 'assets/fonts/Hellix-ThinItalic.ttf'
import HellixThinItalicWOFF from 'assets/fonts/Hellix-ThinItalic.woff'

export const commonFonts = css`
  @font-face {
    font-family: 'Hellix';
    src: url(${HellixRegularEOT});
    src: local('Hellix Regular'), local('Hellix-Regular'),
      url(${HellixRegularEOT}?#iefix) format('embedded-opentype'),
      url(${HellixRegularWOFF}) format('woff'), url(${HellixRegularTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixSemiBoldItalicEOT});
    src: local('Hellix SemiBold Italic'), local('Hellix-SemiBoldItalic'),
      url(${HellixSemiBoldItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixSemiBoldItalicWOFF}) format('woff'),
      url(${HellixSemiBoldItalicTTF}) format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixLightItalicEOT});
    src: local('Hellix Light Italic'), local('Hellix-LightItalic'),
      url(${HellixLightItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixLightItalicWOFF}) format('woff'), url(${HellixLightItalicTTF}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixMediumItalicEOT});
    src: local('Hellix Medium Italic'), local('Hellix-MediumItalic'),
      url(${HellixMediumItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixMediumItalicWOFF}) format('woff'),
      url(${HellixMediumItalicTTF}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixBlackItalicEOT});
    src: local('Hellix Black Italic'), local('Hellix-BlackItalic'),
      url(${HellixBlackItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixBlackItalicWOFF}) format('woff'), url(${HellixBlackItalicTTF}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixRegularItalicEOT});
    src: local('Hellix Regular Italic'), local('Hellix-RegularItalic'),
      url(${HellixRegularItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixRegularItalicWOFF}) format('woff'),
      url(${HellixRegularItalicTTF}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixExtraBoldItalicEOT});
    src: local('Hellix ExtraBold Italic'), local('Hellix-ExtraBoldItalic'),
      url(${HellixExtraBoldItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixExtraBoldItalicWOFF}) format('woff'),
      url(${HellixExtraBoldItalicTTF}) format('truetype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixSemiBoldEOT});
    src: local('Hellix SemiBold'), local('Hellix-SemiBold'),
      url(${HellixSemiBoldEOT}?#iefix) format('embedded-opentype'),
      url(${HellixSemiBoldWOFF}) format('woff'), url(${HellixSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixBoldItalicEOT});
    src: local('Hellix Bold Italic'), local('Hellix-BoldItalic'),
      url(${HellixBoldItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixBoldItalicWOFF}) format('woff'), url(${HellixBoldItalicTTF}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixLightEOT});
    src: local('Hellix Light'), local('Hellix-Light'),
      url(${HellixLightEOT}?#iefix) format('embedded-opentype'),
      url(${HellixLightWOFF}) format('woff'), url(${HellixLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixThinEOT});
    src: local('Hellix Thin'), local('Hellix-Thin'),
      url(${HellixThinEOT}?#iefix) format('embedded-opentype'),
      url(${HellixThinWOFF}) format('woff'), url(${HellixThinTTF}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixBoldEOT});
    src: local('Hellix Bold'), local('Hellix-Bold'),
      url(${HellixBoldEOT}?#iefix) format('embedded-opentype'),
      url(${HellixBoldWOFF}) format('woff'), url(${HellixBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixExtraBoldEOT});
    src: local('Hellix ExtraBold'), local('Hellix-ExtraBold'),
      url(${HellixExtraBoldEOT}?#iefix) format('embedded-opentype'),
      url(${HellixExtraBoldWOFF}) format('woff'), url(${HellixExtraBoldTTF}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixBlackEOT});
    src: local('Hellix Black'), local('Hellix-Black'),
      url(${HellixBlackEOT}?#iefix) format('embedded-opentype'),
      url(${HellixBlackWOFF}) format('woff'), url(${HellixBlackTTF}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixThinItalicEOT});
    src: local('Hellix Thin Italic'), local('Hellix-ThinItalic'),
      url(${HellixThinItalicEOT}?#iefix) format('embedded-opentype'),
      url(${HellixThinItalicWOFF}) format('woff'), url(${HellixThinItalicTTF}) format('truetype');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Hellix';
    src: url(${HellixMediumEOT});
    src: local('Hellix Medium'), local('Hellix-Medium'),
      url(${HellixMediumEOT}?#iefix) format('embedded-opentype'),
      url(${HellixMediumWOFF}) format('woff'), url(${HellixMediumTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
`
export const resetCss = css`
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  button,
  input,
  textarea {
    box-sizing: inherit;
    font-family: inherit;
  }
`
