// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const SendMoneyIcon: React.FC<{ stroke?: string }> = ({ stroke = 'white' }) => (
  <>
    <path
      d="M3.84 20.25L19.75 4.34"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.75 19.34V4.34003H4.75"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
