import { SharedLabelValueDescriptionOption } from 'V2/interfaces/components/Shared.interface'

export const goodsPurchase: SharedLabelValueDescriptionOption[] = [
  { value: '5013', description: 'Vehicle supplies', label: 'Vehicle supplies' },
  { value: '5021', description: 'Office furniture', label: 'Office furniture' },
  { value: '5039', description: 'Construction materials', label: 'Construction materials' },
  { value: '5044', description: 'Photography supplies', label: 'Photography supplies' },
  { value: '5046', description: 'Commercial equipment', label: 'Commercial equipment' },
  { value: '5047', description: 'Hospital equipment', label: 'Hospital equipment' },
  { value: '5065', description: 'Electrical parts', label: 'Electrical parts' },
  { value: '5072', description: 'Hardware', label: 'Hardware' },
  {
    value: '5074',
    description: 'Plumbing & heating supplies',
    label: 'Plumbing & heating supplies',
  },
  { value: '5085', description: 'Industrial supplies', label: 'Industrial supplies' },
  { value: '5099', description: 'Durable goods', label: 'Durable goods' },
  { value: '5111', description: 'Stationery', label: 'Stationery' },
  {
    value: '5122',
    description: 'Drug Proprietaries & sundries',
    label: 'Drug Proprietaries & sundries',
  },
  { value: '5131', description: 'Dry goods', label: 'Dry goods' },
  { value: '5137', description: 'Clothing', label: 'Clothing' },
  { value: '5139', description: 'Footwear', label: 'Footwear' },
  { value: '5169', description: 'Chemicals', label: 'Chemicals' },
  { value: '5192', description: 'Books & newspapers', label: 'Books & newspapers' },
  { value: '5193', description: 'Florist supplies', label: 'Florist supplies' },
  { value: '5198', description: 'Paints supplies', label: 'Paints supplies' },
  { value: '5199', description: 'Nondurable goods', label: 'Nondurable goods' },
  {
    value: '7399',
    description: 'Others',
    label: 'Others',
  },
]

export const servicePurchase: SharedLabelValueDescriptionOption[] = [
  {
    value: '1711',
    description: 'Heating, plumbing & air-conditioning',
    label: 'Heating, plumbing & air-conditioning',
  },
  { value: '1731', description: 'Electrical', label: 'Electrical' },
  {
    value: '1740',
    description: 'Masonry, stonework, tile setting, plastering & insulation',
    label: 'Masonry, stonework, tile setting, plastering & insulation',
  },
  { value: '1750', description: 'Carpentry & floor work', label: 'Carpentry & floor work' },
  {
    value: '1761',
    description: 'Roofing, siding, & sheet metal work',
    label: 'Roofing, siding, & sheet metal work',
  },
  { value: '1771', description: 'Concrete work', label: 'Concrete work' },
  { value: '2741', description: 'Publishing & printing', label: 'Publishing & printing' },
  { value: '2791', description: 'Typesetting & plate making', label: 'Typesetting & plate making' },
  { value: '2842', description: 'Cleaning & sanitation', label: 'Cleaning & sanitation' },
  {
    value: '4214',
    description: 'Motor freight carriers & trucking',
    label: 'Motor freight carriers & trucking',
  },
  { value: '4215', description: 'Courier services', label: 'Courier services' },
  { value: '5051', description: 'Metal services', label: 'Metal services' },
  {
    value: '7375',
    description: 'Information retrieval services',
    label: 'Information retrieval services',
  },
  {
    value: '7379',
    description: 'Computer maintenance & repair',
    label: 'Computer maintenance & repair',
  },
  {
    value: '7399',
    description: 'Others',
    label: 'Others',
  },
]
export const citiCorporateCards = [
  {
    value: 45475040,
    label: '45475040',
  },
  {
    value: 45475050,
    label: '45475050',
  },
  {
    value: 45475080,
    label: '45475080',
  },
  {
    value: 44848820,
    label: '44848820',
  },
  {
    value: 45475020,
    label: '45475020',
  },
]
export const defaultBpspFeePricing = [
  {
    aq_type: 'BANK',
    fee: 1.5,
  },
  {
    aq_type: 'NONBANK_SPECIALMCC',
    fee: 1.5,
  },
  {
    aq_type: 'NONBANK',
    fee: 2.5,
  },
]

export const disableCitiPartnerCurrency = ['FJD', 'TWD']

export const swiftFeeCurrencies = ['USD', 'GBP', 'EUR']
