import { Reducer } from 'react'

import { FlowType } from 'Onboarding/pages/corridors/au/AuForm'
import { UpdateSimplifiedValue } from 'store/actionTypes'

export interface SimplifiedFlowIntialState {
  simplifiedFlow?: FlowType
  stakeholderType: string
  businessName: string
}

const initialState: SimplifiedFlowIntialState = {
  simplifiedFlow: '',
  stakeholderType: '',
  businessName: '',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const simplifiedFlowReducer: Reducer<any, any> = (state = initialState, action) => {
  switch (action.type) {
    case UpdateSimplifiedValue.UPDATE_SIMPLIFIED_VALUE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
