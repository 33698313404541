import { SmeAppState } from 'react-redux'
import { History } from 'history'

import { resetAddMoney } from 'V2/actions/addMoney'
import { resetSendMoney } from 'V2/actions/multiPayments'
import { getCurrentClientAccountList } from 'V2/actions/userClientDetails'
import { appRoutes } from 'V2/constants/appRoutes'
import { Logger } from './logger'

const FILE_NAMESPACE = 'browserLocationV2'

const node = {
  previous: window.location.pathname,
}
export const clearSessionData = (): void => {
  sessionStorage.removeItem('transactionData')
  sessionStorage.removeItem('transactionFund')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BrowserLocation = (history: History<unknown>, store: any): void => {
  history.listen((location, action) => {
    try {
      const state: SmeAppState = store.getState()
      const { multiPayments, userClientDetails, addMoney, ach } = state
      if (
        userClientDetails.authenticated &&
        !userClientDetails.loading &&
        location.pathname !== appRoutes.send &&
        location.pathname !== appRoutes.groupSend &&
        location.pathname !== appRoutes.individualSend &&
        location.pathname !== appRoutes.transactionApproval
      ) {
        // Update account balance on every page change except for send
        store.dispatch(getCurrentClientAccountList())
      }
      const sidebarNavMappingWithoutSend = userClientDetails?.menus
        ?.filter((item) => item.url !== appRoutes.groupSend)
        ?.map((item) => item.url)
      const sidebarNavMappingWithSend = userClientDetails?.menus?.map((item) => item.url)
      // if navigating away from /r/individualsend
      if (
        ((sidebarNavMappingWithoutSend ?? []).includes(location.pathname) ||
          multiPayments.transactions.length > 0) &&
        node.previous === appRoutes.individualSend
      ) {
        store.dispatch(resetSendMoney())
        clearSessionData()
        Logger(FILE_NAMESPACE).debug('navigating away from individual send, payments data cleared')
      }

      // if navigating to /r/individualsend
      if (
        ((sidebarNavMappingWithoutSend ?? []).includes(node.previous) ||
          multiPayments.transactions.length > 0) &&
        location.pathname === appRoutes.individualSend
      ) {
        store.dispatch(resetSendMoney())
        clearSessionData()
        Logger(FILE_NAMESPACE).debug('navigating to individual send, payments data cleared')
      }
      // if navigating away from /r/groupsend
      if (
        ((sidebarNavMappingWithoutSend ?? []).includes(location.pathname) ||
          multiPayments.transactions.length > 0) &&
        node.previous === appRoutes.groupSend
      ) {
        if (ach.redirection) {
          store.dispatch(resetSendMoney())
        }
        clearSessionData()
        Logger(FILE_NAMESPACE).debug('navigating away from group send, payments data cleared')
      }

      // if navigating to /r/groupsend
      if (
        ((sidebarNavMappingWithoutSend ?? []).includes(node.previous) ||
          multiPayments.transactions.length > 0) &&
        location.pathname === appRoutes.groupSend
      ) {
        if (ach.redirection) {
          store.dispatch(resetSendMoney())
        }
        clearSessionData()
        Logger(FILE_NAMESPACE).debug('navigating to group send, payments data cleared')
      }
      // if navigating away from /r/addMoney
      if (
        [...(sidebarNavMappingWithSend ?? []), addMoney.returnUrl].includes(location.pathname) &&
        node.previous === appRoutes.addMoney
      ) {
        store.dispatch(resetAddMoney())
        clearSessionData()
        Logger(FILE_NAMESPACE).debug('navigating away from addMoney, data cleared')
      }

      Logger(FILE_NAMESPACE).debug('routes changed', { userClientDetails, location, action })
    } catch (error) {
      Logger(FILE_NAMESPACE).error('trycatch failed', error)
    } finally {
      node.previous = location.pathname
    }
  })
}
