import styled from 'styled-components/macro'

import { ModalBody } from 'V2/components/molecules/Modal/Styles'
import { great, less } from 'V2/helpers/mediaquery'

export const ModalWrapper = styled.div`
  ${ModalBody} {
    max-height: unset;
    min-height: unset;
    @media ${great.greaterThanMobile} {
      min-height: 625px;
    }
  }
`

export const Screen = styled.div<{ height: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: overlay;
  @media ${less.lessThanMobile} {
    height: ${(props): string => props.height};
  }
`

export const CloseBtnWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 24px;
  cursor: pointer;
  @media ${great.greaterThanMobile} {
    position: unset;
    top: unset;
    right: unset;
    margin-left: auto;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${less.lessThanMobile} {
    display: none;
  }
`

export const FrameImg = styled.img`
  display: flex;
  margin-bottom: 80px;
  max-width: 100px;
`

export const WebImg = styled.img`
  display: flex;
  min-width: 300px;
  height: 300px;
  padding-left: 32px;
  @media ${less.lessThanMobile} {
    display: none;
  }
`

export const MobileImg = styled.img`
  display: flex;
  max-width: 250px;
  height: 250px;
  @media ${great.greaterThanMobile} {
    display: none;
  }
`

export const GetPaidWrapper = styled.div`
  max-width: 600px;
  max-height: 48px;
  text-align: center;
  @media ${less.lessThanMobile} {
    display: flex;
    max-width: 296px;
    min-height: 56px;
    margin-bottom: 20px;
  }
`
export const SubTextWrapper = styled.div<{ isVfaBanner?: boolean }>`
  max-width: ${({ isVfaBanner }): string => (isVfaBanner ? 'unset' : '600px')};
  max-height: ${({ isVfaBanner }): string => (isVfaBanner ? 'unset' : '58px')};
  text-align: center;
  padding: 10px 40px;
  @media ${less.lessThanMobile} {
    max-width: 296px;
    min-height: 96px;
    padding: 0px;
    margin-bottom: 20px;
  }
`

export const InlineText = styled.span`
  display: inline;
  @media ${less.lessThanMobile} {
    display: flex;
    justify-content: center;
  }
`

export const GotItBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0 24px;
  gap: 20px;
  @media ${less.lessThanMobile} {
    margin: 24px;
    gap: 5px;
    & > button {
      width: 104px;
      margin: 5px;
    }
  }
`

export const Bold = styled.b`
  font-weight: 600;
`
export const MobileImgFrame = styled.b`
  margin-top: 40px;
`
