// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const ContactsIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <path
      d="M3.375 7.875C3.375 8.96902 3.8096 10.0182 4.58318 10.7918C5.35677 11.5654 6.40598 12 7.5 12C8.59402 12 9.64323 11.5654 10.4168 10.7918C11.1904 10.0182 11.625 8.96902 11.625 7.875C11.625 6.78098 11.1904 5.73177 10.4168 4.95818C9.64323 4.1846 8.59402 3.75 7.5 3.75C6.40598 3.75 5.35677 4.1846 4.58318 4.95818C3.8096 5.73177 3.375 6.78098 3.375 7.875V7.875Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 20.25C0.75 18.4598 1.46116 16.7429 2.72703 15.477C3.9929 14.2112 5.70979 13.5 7.5 13.5C9.29021 13.5 11.0071 14.2112 12.273 15.477C13.5388 16.7429 14.25 18.4598 14.25 20.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.251 4.41599C14.8739 4.01127 15.5944 3.78226 16.3367 3.75312C17.0789 3.72397 17.8152 3.89579 18.4679 4.25043C19.1206 4.60506 19.6654 5.12935 20.0448 5.76794C20.4242 6.40653 20.6241 7.13572 20.6235 7.87852C20.6229 8.62132 20.4217 9.35016 20.0411 9.98809C19.6606 10.626 19.1149 11.1494 18.4616 11.5029C17.8083 11.8564 17.0717 12.0269 16.3295 11.9965C15.5873 11.966 14.8672 11.7358 14.245 11.33"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 13.667C15.9886 13.443 17.0148 13.4444 18.0028 13.6708C18.9908 13.8973 19.9151 14.3432 20.7074 14.9754C21.4997 15.6076 22.1396 16.4099 22.5798 17.323C23.0199 18.236 23.249 19.2364 23.25 20.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
