// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import { useTheme } from 'styled-components/macro'

import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { Input } from 'V2/components/atoms/Input/Input'
import { InputErrorIcon } from 'V2/components/iconPaths/Custom/StatusIllustrationsIcons/InputErrorIcon'
import { MoleculesInputWithIcon } from 'V2/interfaces/components/Molecules.interface'
import { IconDiv } from './Styles'

export const InputWithIcon: React.FC<MoleculesInputWithIcon> = ({
  label,
  iconPath,
  id,
  showIcon = true,
  ...props
}) => {
  const theme = useTheme().revamp
  return (
    <Input label={label} {...props} id={id}>
      {showIcon ? (
        <label htmlFor={id}>
          <IconDiv disabled={props?.disabled}>
            {iconPath || (
              <CustomIcon
                children={
                  <InputErrorIcon
                    stroke={
                      props.isError ? theme.staticColors.redPepperCorn : theme.primary.grey.root
                    }
                  />
                }
              />
            )}
          </IconDiv>
        </label>
      ) : (
        <></>
      )}
    </Input>
  )
}
