/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */

// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { BlueBox } from 'V2/components/atoms/BlueBox/BlueBox'
import { Button } from 'V2/components/atoms/Button/Button'
import Dropdown from 'V2/components/atoms/DropDown/Dropdown'
import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { InfoIconFilledV2 } from 'V2/components/iconPaths/Custom/BasicIcons/InfoIconFilled'
import { InputWithIcon } from 'V2/components/molecules/InputWithIcon/InputWithIcon'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import { currencyWithIdentificationType, domesticAchText } from 'V2/constants/contacts'
import { formErrorList } from 'V2/constants/formErrorList'
import { US, USD } from 'V2/constants/paymentMethodFilters'
import { USD_SWIFT_ENABLED } from 'V2/constants/userClientDetails'
import { capitalizeOnlyFirstLetter } from 'V2/helpers/capitalizeFirstLetter'
import { OrganismsBankDetails } from 'V2/interfaces/components/Organisms.interface'
import { fetchBankLookup } from 'V2/services/fetchBankLookUp'
import { Logger } from 'V2/services/logger'
import {
  BankDetailsHeader,
  BankDetailsInputWrapper,
  BankDetailsWrapper,
  ButtonWrapper,
  Container,
  DynamicField,
  FieldLayout,
  StaticField,
} from './Styles'

const FILE_NAMESPACE = 'BankDetails'
/**
 * This function creates the bank details (third) screen
 * @param bankDetailsFormSchema it contains the schema to create only third screen
 * @param bankDetailsValues state which stores form values and related errors
 * @param ChangeBankDetails function to update form values and errors
 * @returns
 */
export const BankDetails: React.FC<OrganismsBankDetails> = ({
  isEdit,
  bankDetailsFormSchema,
  bankDetailsValues,
  ChangeBankDetails,
  destinationCurrency,
  formError,
  fieldValidation,
  destinationCountry,
  payoutMethod,
  isAutoSweepEditFlow,
}: any) => {
  const [bankAccNumberEdit, setBankAccNumberEdit] = useState(false)

  const usdSwiftEnabled =
    useSelector((state) => {
      return (
        state.userClientDetails.featureFlagConfiguration?.featureFlagInfo?.find(
          (featureFlag) => featureFlag.feature === USD_SWIFT_ENABLED
        )?.enabled ?? false
      )
    }) &&
    destinationCurrency === 'USD' &&
    destinationCountry === 'US'
  let showBlueBox = false
  const isRoutingcodeExists = !bankDetailsFormSchema.some(
    (field: any) => 'routing_code_type_1' in field
  )

  /**This contains relation  between fields
   * Ex: routing_code_type_1 is related to routing_code_value_1
   * It also keep track of showing label and attaching lookup button for
   * routing_code_value_1 and routing_code_value_2
   * this also contains customized errors for each fields
   */
  const fieldRelation: any = {
    beneficiary_bank_name: {
      renderLabel: true,
      renderType: '',
      label: 'Bank Name',
      errors: {
        [formErrorList.requiredBeneficiary]: formErrorList.recipientCustomErrorMessage.bankName,
      },
    },
    beneficiary_account_number: {
      renderLabel: true,
      renderType: '',
      errors: {
        [formErrorList.requiredAccountNumber]:
          formErrorList.recipientCustomErrorMessage.accountNumber,
      },
    },
    beneficiary_bank_account_type: {
      renderLabel: true,
      renderType: '',
      errors: {
        [formErrorList.requiredBankAccountType]:
          formErrorList.recipientCustomErrorMessage.bankAccountType,
      },
    },
    beneficiary_bank_code: {
      relationship: 'beneficiary_bank_code',
      renderType: '',
      renderLabel: true,
      showLookup: isRoutingcodeExists ? true : false,
      errors: {
        [formErrorList.requiredBankCode]: formErrorList.recipientCustomErrorMessage.bankCode,
      },
    },
    routing_code_type_1: {
      renderType: '',
      renderLabel: true,
      showLookup: true,
      errors: {},
    },
    routing_code_value_1: {
      relationship: 'routing_code_type_1',
      renderType: 'input',
      renderLabel: true,
      errors: {
        [formErrorList.requiredRoutingValue1]:
          formErrorList.recipientCustomErrorMessage.routingValue1,
      },
    },
    routing_code_type_2: {
      renderType: '',
      renderLabel: true,
      errors: {},
    },
    routing_code_value_2: {
      renderLabel: true,
      relationship: 'routing_code_type_2',
      renderType: 'input',
      errors: {
        [formErrorList.requiredRoutingValue2]:
          formErrorList.recipientCustomErrorMessage.routingValue2,
      },
    },
    beneficiary_identification_type: {
      renderLabel: true,
      renderType: '',
      errors: {
        [formErrorList.requiredIdentificationType]:
          formErrorList.recipientCustomErrorMessage.identificationType,
      },
    },
    beneficiary_identification_value: {
      renderLabel: true,
      relationship: 'beneficiary_identification_type',
      renderType: 'input',
      errors: {
        [formErrorList.requiredIdentificationValue]:
          formErrorList.recipientCustomErrorMessage.identificationValue,
      },
    },
  }

  useEffect(() => {
    const { error, ...restOfValues } = bankDetailsValues
    /**This function gives routing type from schema to show in UI for
     * routing_code_type_1, routing_code_type_2, beneficiary_identification_type
     * Ex: routing_code_type_1 can be SWIFT, BSB Code etc
     * */
    const getRoutingValueType = (fieldName: string): string => {
      if (
        ['routing_code_type_1', 'routing_code_type_2', 'beneficiary_identification_type'].includes(
          fieldName
        )
      ) {
        const fieldObj = bankDetailsFormSchema.find((fieldSchema: any) => {
          return fieldSchema[fieldName]
        })

        return fieldObj[fieldName].const ? fieldObj[fieldName].const : ''
      }

      return ''
    }

    ChangeBankDetails((prevBankDetailsState: any) => {
      /**
       * Initiating all form values as empty string('') and
       * all errors as undefined
       */
      if (Object.entries(restOfValues).every(([k, v]) => v === null || '')) {
        const newValueState = bankDetailsFormSchema
          .map((fieldObj: any) => Object.keys(fieldObj)[0])
          .reduce((fieldsValueObj: any, fieldName: any): any => {
            fieldsValueObj[fieldName] = getRoutingValueType(fieldName) || ''
            return fieldsValueObj
          }, {})

        const newErrorState = bankDetailsFormSchema
          .map((fieldObj: any) => Object.keys(fieldObj)[0])
          .reduce((fieldsErrorObj: any, fieldName: any): any => {
            fieldsErrorObj[fieldName] = undefined
            return fieldsErrorObj
          }, {})

        return {
          ...newValueState,
          error: {
            ...newErrorState,
          },
        }
      }

      /**
       * if schema is updated and user tries to edit already created beneficiary,
       * newly added fields are not reflected in bankDetailsValues.
       * we want these new fields to be added in the bankDetailsValues.
       **/
      const newOldBankDetailsValues = bankDetailsFormSchema
        .map((fieldObj: any) => Object.keys(fieldObj)[0])
        .reduce((fieldsValueObj: any, fieldName: any): any => {
          fieldsValueObj[fieldName] =
            prevBankDetailsState[fieldName] || getRoutingValueType(fieldName) || ''
          return fieldsValueObj
        }, {})

      return {
        ...prevBankDetailsState,
        ...newOldBankDetailsValues,
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**This Function is called on lookup button click
   * This gets the bank name from Api call and update it to state
   */
  const getBankName = async (fieldName: any): Promise<void> => {
    /** Relation to pick routing types for routing values */
    const routingTypeRelations: Record<string, string> = {
      routing_code_value_1: 'routing_code_type_1',
      routing_code_value_2: 'routing_code_type_2',
      beneficiary_bank_code: 'BANK CODE',
    }

    /** Validating input value of lookup field before Api call*/
    const validationError = fieldValidation(bankDetailsValues, ChangeBankDetails, fieldName)

    if (validationError) return
    const bankSearchData = {
      country_code: destinationCountry,
      search_key:
        bankDetailsValues[routingTypeRelations[fieldName]] || routingTypeRelations[fieldName],
      search_value: bankDetailsValues[fieldName],
      routing_code_type:
        bankDetailsValues[routingTypeRelations[fieldName]] || routingTypeRelations[fieldName],
      payout_method: payoutMethod ?? 'LOCAL',
      currency_code: destinationCurrency,
      bank_code: bankDetailsValues['beneficiary_bank_code'],
    }
    Logger(FILE_NAMESPACE).silly('bankSearchData', {
      fieldName,
      bankSearchData,
      destinationCurrency,
    })
    /** Api call to get bank name */
    const data = await fetchBankLookup(bankSearchData)

    /** updating error to state, If Api gives error */
    if (data?.status === 'error' || data === undefined) {
      ChangeBankDetails({
        ...bankDetailsValues,
        error: {
          ...bankDetailsValues.error,
          [fieldName]: {
            message: data?.errors[0]?.message || 'Cannot Find Bank Name',
            code: data?.errors[0]?.code || 'DATA_ERROR',
          },
        },
      })
      return
    }

    /** Updating bank name into the bankDetailsValues state*/
    ChangeBankDetails({
      ...bankDetailsValues,
      beneficiary_bank_name: data?.bank_name || '',
      error: {
        ...bankDetailsValues.error,
        [fieldName]: undefined,
      },
    })
  }

  /** This function takes field objects and create label and input fields
   * Input type can be dropdown or input text box with label
   */
  const createUIField = (fieldObj: any, index: number, fieldName: React.ReactText): JSX.Element => {
    const objValue: any = Object.values(fieldObj)[0]

    /**If Obj contains enums, It is a dropdown field */
    if (fieldObj[fieldName].enum) {
      fieldRelation[fieldName].renderType = 'dropDown'
    }
    /**If Obj contains const, It means it's routing type and it will only a label */
    if (fieldObj[fieldName].const) {
      fieldRelation[fieldName].renderType = 'label'
    }

    /** If field have relation then it's label will not be rendered
     * It's label will be rendered from routing relation
     */
    if (fieldRelation[fieldName]?.relationship) {
      const renderType = fieldRelation[fieldRelation[fieldName]?.relationship]?.renderType
      if (renderType === 'label') {
        fieldRelation[fieldName].renderLabel = false
      }
    }

    const relationship = fieldRelation[fieldName].relationship

    const label =
      bankDetailsFormSchema.find(
        (schema: { [key: string]: Record<string, any> }) => schema?.[relationship]
      )?.[relationship]?.const ?? ''

    let getLabel = fieldRelation[fieldName].renderLabel ? fieldObj[fieldName].title : label
    if (destinationCountry === US && destinationCurrency === USD && getLabel === 'ACH CODE') {
      getLabel = 'Domestic ACH code'
      showBlueBox = true
    }
    /** LookUp Button scenarios*/
    if (
      fieldName === 'routing_code_type_1' ||
      fieldName === 'routing_code_type_2' ||
      fieldName === 'beneficiary_bank_code'
    ) {
      /** ignoreLookup will be either undefined or true in fieldObj */
      if ('ignoreLookup' in (fieldObj[fieldName] ?? {})) {
        fieldRelation[fieldName].showLookup = false
      }
    }

    Logger(FILE_NAMESPACE).info(
      'createUIField',
      {
        fieldName,
        'validation:errors': bankDetailsValues?.error[fieldName],
        'ajv:errors': fieldRelation[fieldName]?.errors,
        'schema:errorMessage': objValue.errorMessage,
        'fetchBankLookup:errorMessage':
          fieldRelation[fieldName]?.errors[bankDetailsValues?.error[fieldName]?.message],
        'finalDisplay:errorMessage': bankDetailsValues?.error[fieldName]
          ? fieldRelation[fieldName]?.errors[bankDetailsValues?.error[fieldName]?.message] ||
            objValue.errorMessage
          : '',
      },
      {
        routing_code_type_1: bankDetailsValues?.routing_code_type_1,
        routing_code_type_2: bankDetailsValues?.routing_code_type_2,
        routing_code_value_1: bankDetailsValues?.routing_code_value_1,
        routing_code_value_2: bankDetailsValues?.routing_code_value_2,
      }
    )
    /** If render type is label, We render only label*/
    if (fieldRelation[fieldName].renderType === 'label') return <></>

    /** If render type is dropDown, We render dropdown field*/
    if (fieldRelation[fieldName].renderType === 'dropDown')
      return (
        <FieldLayout key={`${fieldName}-${index}`}>
          <Dropdown
            id={`SME_contacts_form_${fieldName}`}
            label={capitalizeOnlyFirstLetter(fieldObj[fieldName].title)}
            errorMessage={bankDetailsValues?.error[fieldName]?.message ? objValue.errorMessage : ''}
            fullWidth
            placeholder={''}
            minHeight={48}
            value={
              bankDetailsValues[fieldName]
                ? {
                    label: bankDetailsValues[fieldName],
                    value: bankDetailsValues[fieldName],
                  }
                : null
            }
            options={fieldObj[fieldName].enum.map((t: any) => ({ label: t, value: t }))}
            onChange={(newValue: any): void => {
              ChangeBankDetails({
                ...bankDetailsValues,
                [fieldName]: newValue.value,
              })
            }}
          />
        </FieldLayout>
      )

    /**This decide which routing fields to disable if there are more than one routing field */
    const getFieldDisabled = (): boolean => {
      if (fieldName === 'routing_code_value_1' && destinationCurrency === 'CNY') {
        // check if the type exists else bankLookup will fail
        if (!bankDetailsValues.routing_code_type_1) {
          return true
        }

        // check if the other values entered then disable
        if (bankDetailsValues.routing_code_value_2) {
          return true
        }
      }

      if (fieldName === 'routing_code_value_2' && destinationCurrency === 'CNY') {
        // check if the type exists else bankLookup will fail
        if (!bankDetailsValues.routing_code_type_2) {
          return true
        }

        // check if the other values entered then disable
        if (bankDetailsValues.routing_code_value_1) {
          return true
        }
      }
      if (isAutoSweepEditFlow && fieldName === 'beneficiary_account_number') {
        return true
      }
      return false
    }
    const errorMessages = bankDetailsValues?.error[fieldName]
      ? fieldRelation[fieldName]?.errors[bankDetailsValues?.error[fieldName]?.message] ||
        //TODO: putting validation error fix for INDIA benes need to restructure the allOf schema handling later
        (bankDetailsValues.error[fieldName].code === 'DATA_ERROR' ||
        (destinationCountry === 'IN' && fieldName === 'beneficiary_account_number')
          ? bankDetailsValues.error[fieldName]?.message
          : objValue.errorMessage)
      : ''

    /** This is default way to render fields which renders the input text box with label */
    const inputLabel = getLabel
      ? getLabel
      : fieldRelation[fieldRelation[fieldName]?.relationship]?.title
    return (
      <FieldLayout
        getLabel={!!getLabel}
        fullWidth={fieldRelation[fieldRelation[fieldName]?.relationship]?.showLookup}
        key={index}
      >
        <BankDetailsInputWrapper
          getLabel={getLabel}
          lookup={fieldRelation[fieldRelation[fieldName]?.relationship]?.showLookup}
        >
          <InputWithIcon
            id={`SME_contacts_form_${fieldName}`}
            key={`${fieldName}-${index}`}
            value={bankDetailsValues[fieldName] || ''}
            css={`
              flex: 1;
              max-width: 16rem;
            `}
            maxLength={Number(fieldObj[fieldName].maxLength) || undefined}
            minLength={Number(fieldObj[fieldName].maxLength) || undefined}
            isError={errorMessages?.length || false}
            showIcon={errorMessages?.length || false}
            message={errorMessages}
            label={
              fieldRelation[fieldRelation[fieldName]?.relationship]?.showLookup ||
              fieldRelation[fieldName]?.relationship === 'beneficiary_identification_type'
                ? inputLabel
                : capitalizeOnlyFirstLetter(inputLabel)
            }
            disabled={getFieldDisabled()}
            onChange={(e: any): void => {
              ChangeBankDetails((currentBankDetails: any) => {
                if (
                  (fieldName === 'routing_code_value_1' ||
                    fieldName === 'routing_code_value_2' ||
                    fieldName === 'beneficiary_bank_code') &&
                  !usdSwiftEnabled
                ) {
                  return {
                    ...currentBankDetails,
                    [fieldName]: e.target.value,
                    beneficiary_bank_name: '',
                  }
                } else {
                  return {
                    ...currentBankDetails,
                    [fieldName]: e.target.value,
                  }
                }
              })
            }}
            onClick={(): void => {
              if (isEdit && !bankAccNumberEdit && fieldName === 'beneficiary_account_number') {
                setBankAccNumberEdit(true)
                ChangeBankDetails((currentBankDetails: any) => {
                  return {
                    ...currentBankDetails,
                    [fieldName]: '',
                  }
                })
              }
            }}
          />
          {fieldRelation[fieldRelation[fieldName]?.relationship]?.showLookup && (
            /** Rendering lookup Button based in  showLookup flag*/
            <ButtonWrapper>
              <Button
                id={automationIdSelectors.contactsButtons.lookupCta}
                css={`
                  margin-left: 22%;
                `}
                variant="secondary"
                size="large"
                title="lookup"
                onClick={(): Promise<void> => getBankName(fieldName)}
                disabled={getFieldDisabled()}
              />
            </ButtonWrapper>
          )}
        </BankDetailsInputWrapper>
      </FieldLayout>
    )
  }

  /** This checks id the lookup is available in the form
   * This is to decide if bank name field will be disabled
   */
  const lookupAvailable: boolean = (bankDetailsFormSchema as any[])
    .map((item) => ({
      ...item,
      objectKey: Object.keys(item)[0],
    }))
    .filter(
      (item) =>
        item.objectKey === 'routing_code_type_1' ||
        item.objectKey === 'routing_code_type_2' ||
        item.objectKey === 'beneficiary_bank_code'
    )
    .reduce((lookup, item) => {
      let lookupAvailable = lookup
      // if the item is not using lookup feature
      if (!item[item.objectKey]?.ignoreLookup) {
        lookupAvailable = true
      }
      Logger(FILE_NAMESPACE).silly('lookupAvailable:reduce', lookup, lookupAvailable, item)
      return lookupAvailable
    }, false)

  Logger(FILE_NAMESPACE).silly('render', {
    bankDetailsFormSchema,
    fieldRelation,
    bankDetailsValues,
    destinationCurrency,
  })

  const errorMessage = lookupAvailable
    ? ''
    : bankDetailsValues?.error?.beneficiary_bank_name
    ? 'Bank Name is required'
    : ''

  //removing identification tyoe and value fields from formSchema for all countries except Columbia and Peru
  const newFormSchema = currencyWithIdentificationType.includes(destinationCurrency)
    ? bankDetailsFormSchema
    : bankDetailsFormSchema.filter(
        (item: any) =>
          Object.keys(item)[0] !== 'beneficiary_identification_type' &&
          Object.keys(item)[0] !== 'beneficiary_identification_value'
      )

  return (
    <>
      <Container>
        <BankDetailsWrapper id={automationIdSelectors.contactsForms.bankDetailsForm}>
          <DynamicField>
            {isEdit && (
              <BankDetailsHeader
                type="h3"
                weight="semibold"
                id={automationIdSelectors.contactsTitles.accountDetails}
              >
                Account details
              </BankDetailsHeader>
            )}
            {newFormSchema
              /** Creating  dynamic field except bank name*/
              .filter((item: any) => Object.keys(item)[0] !== 'beneficiary_bank_name')
              .map((item: any, index: number) => {
                const objKey = Object.keys(item)[0]
                return createUIField(item, index, objKey)
              })}
          </DynamicField>
          <StaticField>
            <>
              <InputWithIcon
                /**Bank name field is rendered separately */
                id={automationIdSelectors.contactsFields.beneBankNameInput}
                label={'Bank name'}
                value={bankDetailsValues['beneficiary_bank_name']}
                onChange={(e): void => {
                  ChangeBankDetails({
                    ...bankDetailsValues,
                    beneficiary_bank_name: e.target.value,
                  })
                }}
                showMessage={errorMessage?.length > 0 || false}
                showIcon={errorMessage?.length > 0 || false}
                message={errorMessage}
                disabled={
                  // disabled only if lookup available
                  lookupAvailable
                }
              />
            </>
            {formError}
          </StaticField>
        </BankDetailsWrapper>
        {showBlueBox && (
          <BlueBox
            customIcon={
              <CustomIcon viewBox={'0 0 24 24'} width={24} height={24}>
                <InfoIconFilledV2 />
              </CustomIcon>
            }
            note={domesticAchText}
          />
        )}
      </Container>
    </>
  )
}

export default BankDetails
