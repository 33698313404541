// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState } from 'react'

import { Button } from 'V2/components/atoms/Button/Button'
import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { Tabs } from 'V2/components/atoms/Tabs/Tabs'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import { CloseIcon } from 'V2/components/iconPaths/Custom/BasicIcons/CloseIcon'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import { MoleculesModal } from 'V2/interfaces/components/Molecules.interface'
import {
  BodyWrapper,
  IconDivWrapper,
  ModalBody,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from './Styles'

export const Modal = ({
  title,
  bodyText,
  primaryFooterButtonLabel,
  secondaryFooterButtonLabel,
  onModalClose,
  onPrimaryAction,
  onSecondaryAction,
  requireTabs,
  children,
  tabHeader,
  sectionTextWrapper,
  bodySection,
  hideHeader = false,
  overFlow = true,
  ...props
}: MoleculesModal): JSX.Element => {
  const [activeTab, setActiveTab] = useState(1)
  return (
    <ModalContainer data-testid="modal">
      <ModalContent>
        {!hideHeader && (
          <ModalHeader>
            <>
              {title && (
                <Typography type="h2" weight="semibold" id={automationIdSelectors.modalTitle}>
                  {title}
                </Typography>
              )}
              {props.headerChild && props.headerChild}

              <IconDivWrapper
                id={automationIdSelectors.modalCloseButton}
                onClick={onModalClose}
                data-testid="close-button"
              >
                <CustomIcon children={<CloseIcon />} defaultSize={24} />
              </IconDivWrapper>
            </>
          </ModalHeader>
        )}
        <ModalBody overFlow={overFlow} fullsize={!bodyText}>
          {requireTabs && tabHeader && (
            <Tabs
              tabs={tabHeader}
              currentTab={activeTab}
              changeTabHandler={(selectedTab): void => setActiveTab(selectedTab)}
            />
          )}
          {bodyText ? (
            <BodyWrapper>
              <Typography type="body" weight="medium" id={automationIdSelectors.modalBodyText}>
                {bodyText}
              </Typography>
              {sectionTextWrapper && (
                <Typography type="body" weight="medium" id={automationIdSelectors.modalSectionText}>
                  {sectionTextWrapper}
                </Typography>
              )}
            </BodyWrapper>
          ) : (
            children
          )}
          {bodySection && bodySection}
        </ModalBody>
        {(secondaryFooterButtonLabel || primaryFooterButtonLabel) && (
          <ModalFooter>
            {secondaryFooterButtonLabel && (
              <Button
                id={automationIdSelectors.modalCancelButton}
                onClick={onSecondaryAction}
                title={secondaryFooterButtonLabel}
                variant="secondary"
                size="small"
              />
            )}
            {primaryFooterButtonLabel && (
              <Button
                id={automationIdSelectors.modalConfirmButton}
                onClick={onPrimaryAction}
                title={primaryFooterButtonLabel}
                variant="primary"
                size="small"
              />
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </ModalContainer>
  )
}
