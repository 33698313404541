import { ThunkWithApi } from 'react-redux'
import apm from 'apm'
import axios, { AxiosError } from 'axios'
import { ActionCreator, Dispatch } from 'redux'

import { apiRoutes } from 'constants/apiRoutes'
import {
  AccountData,
  CardSelectedStatus,
  CreditCardDetails,
  SelectSavedCard,
} from 'interfaces/redux/Dependents.interface'
import { CreditCardTypes, HeaderTypes, MultiPaymentTypes } from 'store/actionTypes'
import { defaultTimeout, snackBarAction } from 'V2/actions/snackbar'
import {
  citiCorporateCards,
  defaultBpspFeePricing,
  goodsPurchase,
  servicePurchase,
} from 'V2/constants/bpspCategoryConstants'
import { formErrorList } from 'V2/constants/formErrorList'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import { encryptData } from 'V2/helpers/encryptCreditCardDetails'
import { OrganismsTransactionInformation } from 'V2/interfaces/components/Organisms.interface'
import { CustomError } from 'V2/services/customError'
import { Logger } from 'V2/services/logger'
import { paymentErrorHandling } from 'V2/services/paymentErrorHandling'

const FILE_NAMESPACE = 'redux.actions.multiPayments'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNarrativeFromRedirectUrl(multiPayments: any, transactionInformation: any): string {
  if (transactionInformation.redirectionUrl.includes('individualsend')) {
    return `${multiPayments.transactions[0].beneficiary.beneficiary_group_name ?? ''}`
  } else if (
    transactionInformation.redirectionUrl.includes('groupsend') ||
    transactionInformation.redirectionUrl.includes('bulkpayment')
  ) {
    return 'multiplerecipients'
  } else if (transactionInformation.redirectionUrl.includes('addmoney')) {
    return 'topup'
  } else {
    return `INSTAREM ${transactionInformation.narrative ?? ''}`
  }
}

export const creditCardPayment: ActionCreator<ThunkWithApi<void>> = (
  transactionInformation: OrganismsTransactionInformation,
  nextAction?: ActionCreator<ThunkWithApi<void>>
) => {
  return async function (dispatch, getState): Promise<void> {
    try {
      const { userClientDetails, creditCard, multiPayments } = getState()
      const { creditCardDetails, cardSelectedStatus, saveCardDetails } = creditCard
      const activeClient = userClientDetails.activeClient
      const accountsData = userClientDetails.currentClientAccounts || []
      const getAccount = (currency: string): AccountData | undefined => {
        return accountsData.find((item: { currency: string }) => item.currency === currency)
      }
      const clientLegalEntity = clientLegalEntityMapper(activeClient?.client_legal_entity || 'US')

      // process card details
      const thisYear = new Date().getFullYear()
      const thisYearPrefix = parseInt(thisYear.toString().slice(0, 2) + '00')
      const creditCardObject = {
        client: activeClient?._id,
        accountNumber: getAccount(transactionInformation.currency)?.account_number,
        currency: getAccount(transactionInformation.currency)?.currency,
        card: {
          number: creditCardDetails.cardNumber?.replace(/\D+/g, ''),
          cvc: creditCardDetails.cvv,
          expMonth: Number(creditCardDetails.expiryDate?.slice(0, 2)),
          expYear: thisYearPrefix + Number(creditCardDetails.expiryDate?.slice(-2)),
        },
        amount: transactionInformation.amount,
        narrative: getNarrativeFromRedirectUrl(multiPayments, transactionInformation),
        redirectionUrl: transactionInformation.redirectionUrl,
        mcc_code: transactionInformation?.mccCode ?? '7399',
      }
      Logger(FILE_NAMESPACE).error('creditCardObject', creditCardObject)

      const encryptedData = encryptData(creditCardObject)

      // save credit card details
      if (cardSelectedStatus.isSaveCard) {
        try {
          dispatch({
            type: CreditCardTypes.LOADING_IN_PROGRESS,
          })
          const saveCard = await axios
            .post(apiRoutes.saveCard, {
              encryptedData,
            })
            .then((res) => {
              // Save Citibank+Regular Visa cards in SMEBE
              if (
                getAccount(transactionInformation.currency)?.currency === 'SGD' &&
                clientLegalEntity === 'SG' &&
                res.data.data.cardDetails.brand === 'visa'
              ) {
                const cardInfo = {
                  ippMapping: res.data.data.ippMappingId,
                }
                dispatch(saveVisaCreditCard(cardInfo))
              }

              return res.data
            })

          if (!saveCard.success) {
            throw new CustomError(saveCard.message, new Error('!saveCard.success'), saveCard)
          }
        } catch (err) {
          snackBarAction(
            (err as AxiosError)?.response?.data?.message ?? formErrorList.unExpectedError,
            'error',
            defaultTimeout,
            dispatch
          )

          // capture save card error
          apm.captureError(
            new CustomError(
              `paymentErrorHandling: ${
                (err as AxiosError)?.response?.data?.message ??
                (err as Error)?.message ??
                formErrorList.unExpectedError
              }`,
              err as Error,
              {
                errorMessage:
                  (err as AxiosError)?.response?.data?.message ?? (err as Error)?.message,
                maskedMessage: formErrorList.unExpectedError,
                response: JSON.stringify((err as AxiosError)?.response?.data ?? {}),
                errorDisplayed: true,
                inputDisabled: false,
                snackBarDisplayed: true,
                source: 'creditCardPayment',
              }
            )
          )

          return
        } finally {
          dispatch({
            type: CreditCardTypes.LOADING_DONE,
          })
        }
      }

      // charge card logic
      let response: any //eslint-disable-line @typescript-eslint/no-explicit-any
      if (cardSelectedStatus.isAvailableCardSelected) {
        dispatch({
          type: CreditCardTypes.LOADING_IN_PROGRESS,
        })

        response = await axios
          .post(apiRoutes.chargeOnCard, {
            amount: transactionInformation.amount,
            narrative: getNarrativeFromRedirectUrl(multiPayments, transactionInformation),
            accountNumber: getAccount(transactionInformation.currency)?.account_number,
            currency: transactionInformation.currency,
            ippMappingId: saveCardDetails?.selectedCard?.data.ippMappingId || '',
            customerId: saveCardDetails?.selectedCard?.data.customerId || '',
            client: activeClient?._id,
            redirectionUrl: transactionInformation.redirectionUrl,
            mcc_code: transactionInformation?.mccCode ?? '7399',
          })
          .then((res) => res.data)
        dispatch({
          type: MultiPaymentTypes.GET_STRIPE_ID,
          payload: response?.ippResponse?.partnerReferenceNumber,
        })
      } else {
        dispatch({
          type: CreditCardTypes.LOADING_IN_PROGRESS,
        })
        response = await axios
          .post(apiRoutes.cardPayment, {
            encryptedData,
          })
          .then((res) => res.data)
        dispatch({
          type: MultiPaymentTypes.GET_STRIPE_ID,
          payload: response?.ippResponse?.partnerReferenceNumber,
        })
      }
      dispatch({
        type: CreditCardTypes.LOADING_DONE,
      })

      Logger(FILE_NAMESPACE).error('chargeCardResponse', response)

      if (response.redirectUrl) {
        window.location.href = response.redirectUrl
      }
      if (response.requestId && nextAction) {
        dispatch(nextAction(response.requestId))
      }
    } catch (error) {
      paymentErrorHandling({ error: error as Error, dispatch, displaySnackBar: true })
      dispatch({
        type: CreditCardTypes.LOADING_DONE,
      })
    }
  }
}
export const getAllSavedCards: ActionCreator<ThunkWithApi<void>> = (currency: string) => {
  return async function (dispatch, getState): Promise<void> {
    try {
      dispatch({
        type: CreditCardTypes.GET_ALL_SAVED_CARDS_REQUEST,
      })
      dispatch({
        type: CreditCardTypes.LOADING_IN_PROGRESS,
      })
      const { userClientDetails } = getState()
      const res = await axios.get(apiRoutes.getAllSavedCard, {
        params: {
          currency,
          client: userClientDetails.activeClient?._id,
        },
      })
      if (res.data.length === 0) {
        // clearing the default value of currentCardStep, since there are no saved cards
        dispatch({
          type: CreditCardTypes.CURRENT_CARD_STEP,
          payload: '',
        })
      }
      dispatch({
        type: CreditCardTypes.GET_ALL_SAVED_CARDS_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      Logger(FILE_NAMESPACE).error('getAllSavedCards', err)
      dispatch({
        type: CreditCardTypes.GET_ALL_SAVED_CARDS_FAILURE,
      })

      // capture get all saved card error
      apm.captureError(
        new CustomError(`paymentErrorHandling: Get All Saved Cards`, err as Error, {
          errorMessage: (err as AxiosError)?.response?.data?.message ?? (err as Error)?.message,
          maskedMessage: formErrorList.unExpectedError,
          response: JSON.stringify((err as AxiosError)?.response?.data ?? {}),
          errorDisplayed: true,
          inputDisabled: false,
          snackBarDisplayed: true,
          source: 'getAllSavedCards',
        })
      )
    } finally {
      dispatch({
        type: CreditCardTypes.LOADING_DONE,
      })
    }
  }
}
export const updateCreditCardStatement: ActionCreator<void> = (
  statement: string,
  update: boolean
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CREDITCARD_STATEMENT_NARRATIVE,
      payload: update ? statement : statement.trim(),
    })
  }
}
export const updateSavedCardDetails: ActionCreator<void> = (selectedSavedCard: SelectSavedCard) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.SAVED_CARD_DETAILS,
      payload: selectedSavedCard,
    })
  }
}

export const updateCreditCardDetails: ActionCreator<void> = (
  creditCardDetails: CreditCardDetails
) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CREDIT_CARD_DETAILS,
      payload: creditCardDetails,
    })
  }
}

export const updateCardNumber: ActionCreator<void> = (cardNumber: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CREDIT_CARD_NUMBER,
      payload: cardNumber,
    })
  }
}

export const updateExpiryDate: ActionCreator<void> = (expiryDate: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.EXPIRY_DATE,
      payload: expiryDate,
    })
  }
}

export const updateCVVNumber: ActionCreator<void> = (cvv: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CVV_NUMBER,
      payload: cvv,
    })
  }
}

export const updateCreditCardType: ActionCreator<void> = (creditCardType: CardSelectedStatus) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CREDIT_CARD_TYPE,
      payload: creditCardType,
    })
  }
}

export const selectSavedCard: ActionCreator<void> = (isSaveCard: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.SELECT_SAVED_CARD,
      payload: isSaveCard,
    })
  }
}

export const selectAvailableCard: ActionCreator<void> = (isAvailableCardSelected: boolean) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.SELECT_AVAILABLE_CARD,
      payload: isAvailableCardSelected,
    })
  }
}

export const setCreditCardErrors: ActionCreator<void> = (creditCardErrors: CreditCardDetails) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CREDIT_CARD_ERRORS,
      payload: creditCardErrors,
    })
  }
}

export const setCardNumberError: ActionCreator<void> = (errorMessage: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CARD_NUMBER_ERROR,
      payload: errorMessage,
    })
  }
}

export const setExpiryDateError: ActionCreator<void> = (errorMessage: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.EXPIRY_DATE_ERROR,
      payload: errorMessage,
    })
  }
}

export const setCVVNumberError: ActionCreator<void> = (errorMessage: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CVV_NUMBER_ERROR,
      payload: errorMessage,
    })
  }
}

export const setCurrentCardStep: ActionCreator<void> = (currentCardStep: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: CreditCardTypes.CURRENT_CARD_STEP,
      payload: currentCardStep,
    })
  }
}

export const resetCreditCard: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch: Dispatch): Promise<void> {
    dispatch({
      type: CreditCardTypes.RESET_STATE,
    })
  }
}
export const getBpspPricing: ActionCreator<ThunkWithApi<void>> = (cardInfo: {
  mcc: string
  sendFlow: string
}) => {
  return async function (dispatch: Dispatch, getState): Promise<void> {
    let aq_type = ''
    const { userClientDetails, creditCard } = getState()
    const activeClient = userClientDetails.activeClient
    const { creditCardDetails, cardSelectedStatus, saveCardDetails } = creditCard
    // New Card entered in UI
    const mccApplicable = (): boolean => {
      if (
        (goodsPurchase.filter((item) => cardInfo.mcc === item.value).length > 0 ||
          servicePurchase.filter((item) => cardInfo.mcc === item.value).length > 0) &&
        cardInfo.mcc !== '7399'
      ) {
        return true
      }
      return false
    }
    if (!cardSelectedStatus.isAvailableCardSelected) {
      const ccNumber = creditCardDetails.cardNumber?.split(' ').join('')
      const citiCorporateCardBin = ccNumber?.slice(0, 8)
      const nonCitiVisaCheck = ccNumber?.slice(0, 1)
      const citiCorporateCheck = citiCorporateCards.filter(
        (item) => citiCorporateCardBin === item.label
      )

      // Citi Corporate
      if (citiCorporateCheck.length > 0) {
        aq_type = 'BANK'
      }
      // Visa starts with 4 + MCC category
      else if (
        nonCitiVisaCheck === '4' &&
        citiCorporateCheck.length === 0 &&
        mccApplicable() &&
        cardInfo.sendFlow === '/r/individualsend'
      ) {
        aq_type = 'NONBANK_SPECIALMCC'
      }
      // Non Visa and non MCC category
      else {
        aq_type = 'NONBANK'
      }
      dispatch({
        type: HeaderTypes.UPDATE_ACQUISITION_TYPE,
        payload: aq_type,
      })
    }
    // Saved Card selected
    else {
      const ippMappingId = saveCardDetails.selectedCard.data.ippMappingId
      const cardBrand = saveCardDetails.selectedCard.data?.cardDetails?.brand ?? ''
      try {
        dispatch({
          type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS,
        })
        // SMEBE is allowed to save only Visa Cards
        if (cardBrand === 'visa') {
          await axios
            .get(`${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getSavedCardDetails}`, {
              params: {
                ippMappingId,
              },
            })
            .then((res) => {
              if (res.data.acquisitionType === 'BANK') {
                aq_type = res.data.acquisitionType
              } else if (res.data.acquisitionType === 'NONBANK') {
                if (mccApplicable()) {
                  aq_type = 'NONBANK_SPECIALMCC'
                } else {
                  aq_type = res.data.acquisitionType
                }
              }
            })
        } else {
          aq_type = 'NONBANK'
        }
      } catch (err) {
        if ((err as AxiosError)?.response?.data?.errorCode === 'CARD_NOT_FOUND') {
          dispatch({
            type: HeaderTypes.CARD_DETAILS_NOT_AVAILABLE,
          })
        }
      } finally {
        dispatch({
          type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_DONE,
        })
      }
    }
    // update top_up_pricing in client settings
    try {
      dispatch({
        type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS,
      })
      await axios
        .get(`${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getClientPricing}`, {
          params: {
            acquisitionType: aq_type,
            clientId: activeClient?._id,
          },
        })
        .then(async (res) => {
          const params = {
            client_name: activeClient?.client_name,
            client_label: activeClient?.client_label,
            portal_type: activeClient?.portal_type || 'SME',
            utcOffset: activeClient?.utcOffset,
            is_active: activeClient?.is_active,
            auto_reversal_days: activeClient?.auto_reversal_days,
            default_bookfx_type: activeClient?.default_bookfx_type,
            supplier_payments_customer: activeClient?.supplier_payments_customer,
            institution_type: 'SME',
            identification_type: activeClient?.identification_type,
            identification_number: activeClient?.identification_number,
            engagement_type: activeClient?.engagement_type,
            id: activeClient?._id,
            is_api_payout_approval_required: activeClient?.is_api_payout_approval_required,
            topup_currency: activeClient?.topup_currency,
            acquisition_type: aq_type === 'BANK' ? 'BANK' : 'NON_BANK',
            topup_pricing: res.data.activeFee,
          }
          try {
            dispatch({
              type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS,
            })
            await axios.post(apiRoutes.clientUpdate, params).then(async (response) => {
              if (response.status === 200) {
                dispatch({
                  type: HeaderTypes.UPDATE_TOP_UP_PRICING,
                  payload: res.data.activeFee,
                })
              }
            })
          } catch (err) {
            console.debug('err', err)
          } finally {
            dispatch({
              type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_DONE,
            })
          }
        })
    } catch (err) {
      if ((err as AxiosError)?.response?.data?.errorCode === 'PRICING_NOT_FOUND') {
        const defaultFee = defaultBpspFeePricing.filter((item) => item.aq_type === aq_type)[0]?.fee
        dispatch({
          type: HeaderTypes.UPDATE_TOP_UP_PRICING,
          payload: defaultFee,
        })
      }
      // This has to be refactored later on BPSP enhancement.
      else {
        const defaultFee = defaultBpspFeePricing.filter((item) => item.aq_type === aq_type)[0]?.fee
        dispatch({
          type: HeaderTypes.UPDATE_TOP_UP_PRICING,
          payload: defaultFee,
        })
        const params = {
          client_name: activeClient?.client_name,
          client_label: activeClient?.client_label,
          portal_type: activeClient?.portal_type || 'SME',
          utcOffset: activeClient?.utcOffset,
          is_active: activeClient?.is_active,
          auto_reversal_days: activeClient?.auto_reversal_days,
          default_bookfx_type: activeClient?.default_bookfx_type,
          supplier_payments_customer: activeClient?.supplier_payments_customer,
          institution_type: 'SME',
          identification_type: activeClient?.identification_type,
          identification_number: activeClient?.identification_number,
          engagement_type: activeClient?.engagement_type,
          id: activeClient?._id,
          is_api_payout_approval_required: activeClient?.is_api_payout_approval_required,
          topup_currency: activeClient?.topup_currency,
          acquisition_type: aq_type === 'BANK' ? 'BANK' : 'NON_BANK',
          topup_pricing: defaultFee,
        }
        try {
          dispatch({
            type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS,
          })
          await axios.post(apiRoutes.clientUpdate, params).then(async (response) => {
            if (response.status === 200) {
              dispatch({
                type: HeaderTypes.UPDATE_TOP_UP_PRICING,
                payload: defaultFee,
              })
            }
          })
        } catch (err) {
          console.debug('err', err)
        }
      }
    } finally {
      dispatch({
        type: HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_DONE,
      })
    }
  }
}

export const saveVisaCreditCard: ActionCreator<ThunkWithApi<void>> = (cardInfo: {
  ippMapping: string
}) => {
  return async function (dispatch: Dispatch, getState): Promise<void> {
    const { userClientDetails } = getState()
    try {
      dispatch({
        type: CreditCardTypes.LOADING_IN_PROGRESS,
      })
      await axios.post(`${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.postSaveCard}`, {
        ippMappingId: cardInfo.ippMapping,
        acquisitionType: userClientDetails.aq_type === 'BANK' ? 'BANK' : 'NONBANK',
        isActive: 'true',
      })
    } catch (err) {
      console.debug(err)
    } finally {
      dispatch({
        type: CreditCardTypes.LOADING_DONE,
      })
    }
  }
}
