import styled from 'styled-components/macro'

import { pxToRem } from 'V2/helpers/pxToRem'

export const RadioWrapper = styled.div`
  display: flex;
  position: relative;
`

export const Label = styled.label`
  display: flex;
  line-height: ${pxToRem(24)};
  margin-left: 8px;
  cursor: pointer;
`

export const Mark = styled.span`
  z-index: 0;
  display: inline-block;
  position: absolute;
  width: ${pxToRem(21.5)};
  height: ${pxToRem(21.5)};
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  border: ${pxToRem(2)} solid ${({ theme }): string => theme.revamp.primary.blue.root};
  border-radius: 50%;
  margin-right: ${pxToRem(5)};
  &::after {
    position: absolute;
    content: '';
    display: block;
    border-radius: 50%;
  }
`

export const Input = styled.input`
  opacity: 0;
  margin: 0;
  z-index: 10;
  width: ${pxToRem(21.5)};
  height: ${pxToRem(21.5)};
  left: 0;
  top: 0;

  &:checked + ${Mark} {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.root};
    border: ${pxToRem(2)} solid ${({ theme }): string => theme.revamp.primary.blue.root};
    &::after {
      width: ${pxToRem(8)};
      height: ${pxToRem(8)};
      background-color: ${({ theme }): string => theme.revamp.staticColors.white};
      top: 27%;
      left: 27%;
    }
  }

  &:checked:disabled + ${Mark} {
    &::after {
      width: ${pxToRem(10)};
      height: ${pxToRem(10)};
      background-color: ${({ theme }): string => theme.revamp.primary.grey.dark};
      top: 21%;
      left: 21%;
    }
  }

  &:disabled + ${Mark} {
    cursor: not-allowed;
    background-color: ${({ theme }): string => theme.revamp.primary.grey.lighter};
    border: ${pxToRem(2)} solid ${({ theme }): string => theme.revamp.primary.grey.light};
  }

  &:disabled ~ ${Label}, &:disabled {
    cursor: not-allowed;
  }
`
