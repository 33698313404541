// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components/macro'

import { AtomsProgressBar } from 'V2/interfaces/components/Atoms.interface'
import { Container, Dot, ProgressIndicator } from './Styles'

export const ProgressBar: React.FC<AtomsProgressBar> = ({
  numberOfSteps = 6,
  activeStepIndex = 5,
  showDot = true,
  progressBarWidth,
  dotColor,
  progressBarColor,
  backgroundColorNotRequired,
}) => {
  const theme = useTheme()
  const isLastStep = useMemo(
    () => activeStepIndex === numberOfSteps,
    [activeStepIndex, numberOfSteps]
  )
  return (
    <Container backgroundColorNotRequired={backgroundColorNotRequired} isLastStep={isLastStep}>
      <ProgressIndicator
        progressBarWidth={progressBarWidth || (100 / numberOfSteps) * activeStepIndex}
        progressBarColor={progressBarColor || theme.revamp.primary.blue.dark}
      ></ProgressIndicator>
      {showDot && !isLastStep ? (
        <Dot fillColor={dotColor || theme.revamp.primary.grey.dark} />
      ) : (
        <></>
      )}
    </Container>
  )
}
