import theme2 from 'styles/V2/themeV2'

const palette = {
  primary: {
    niumBlueBasic: '#24BAD6',
    niumBlueL1: '#42C1D9',
    niumBlueL2: '#56C8DD',
    niumBlueL3: '#6ACFE1',
    niumBlueL4: '#7FD6E5',
    niumBlueL5: '#94DDE9',
    niumBlueL6: '#A9E3EE',
    niumBlueL7: '#BFEAF2',
    niumBlueL8: '#D4F1F6',
    niumBlueL9: '#EAF8FB',
    niumBlueL10: '#F4FBFD',
    niumBlueD1: '#20A7C1',
    niumBlueD2: '#1D95AB',
    niumBlueD3: '#198296',
    niumBlueD4: '#167080',
    niumBlueD5: '#125D6B',
    niumBlueD6: '#0E4A56',
    niumBlueD7: '#0B3840',
    niumBlueD8: '#07252B',
    niumBlueD9: '#041315',
    // Not part of SME-UI guidelines
    niumBlueD10: '#012852',
  },
  secondary: {
    niumGreen: '#D1D94E',
    niumYellow: '#FDBE2D',
    niumMagenta: '#FD97FD',
    niumBrightBlue: '#1D7CF6',
    niumMint: '#22CECB',
    niumPurple: '#8094FF',
    niumSubtleGreen: '#8FA145',
    niumSubtleOrange: '#FD8D31',
    niumSubtleMagenta: '#C27292',
    niumSubtleBlue: '#074486',
    nimSubtleMint: '#13898E',
    niumSubtlePurple: '#7C6BA0',
    // Not part of SME-UI guidelines
    nimSubtleBlue2: '#004187',
    nimSubtleBlue3: '#004288',
    niumLightRed: '#FBEDEC',
  },
  tertiary: {
    niumBlack: '#000000',
    niumBlackL1: '#212529', //  text
    niumBlackL2: '#495057',
    niumBlackL3: '#868E96', //  faded text
    niumBlackL4: '#ADB5BD', //  faded icons
    niumBlackL5: '#CED4DA',
    niumBlackL6: '#DEE2E6', //  dividers, lines, boxes,
    niumBlackL7: '#F1F2F6',
    niumBlackL8: '#F8F8FA',
    niumBlackL9: '#FCFBFC', //  Lightest
  },
}

const staticColors = {
  error: '#D9463D',
  success: '#27AE60',
  information: '#1D7CF6',
  warning: '#FDBE2D',
  // Not part of SME-UI guidelines
  white: '#FFFFFF',
  lightSlateGrey: '#868e96',
  lightgray: '#D3D3D3',
  offWhite: '#F9FDFE',
}

const fontSize = {
  xSmall: 12,
  small: 14,
  root: 16,
  medium: 18,
  large: 20,
  xLarge: 24,
  xxLarge: 28,
  xxxLarge: 36,
  largest: 48,
}

const theme = {
  palette,
  staticColors,
  fontSize,
  revamp: theme2,
}

export default theme
