/* eslint-disable @typescript-eslint/camelcase */
import { PayinBank } from 'interfaces/redux/Dependents.interface'
import { bizpayClientLegalEntities } from 'V2/constants/bizpayClientLegalEntity'
import { bizpaySendCurrencies } from 'V2/constants/bizpaySendCurrencies'
import { SharedLabelValueCountryOption } from 'V2/interfaces/components/Shared.interface'

export const currencyDropdownOptions = bizpaySendCurrencies
  .sort((a, b) => (a.label > b.label ? 1 : -1))
  .map((currency) => {
    return {
      value: currency.value,
      label: currency.label.split('(')[0].trim(),
      countryCode: currency.localForCountry,
    }
  })

export const currencyOptionsMapper = (
  clientLegalEntityText: string,
  payinBanks: PayinBank[]
): {
  defaultCurrency: SharedLabelValueCountryOption
  currencyOptions: SharedLabelValueCountryOption[]
} => {
  const { defaultCurrency, currencyOptions } = bizpayClientLegalEntities?.find(
    (legalEntity) =>
      legalEntity?.countryCode === clientLegalEntityText ||
      legalEntity?.countryName === clientLegalEntityText ||
      legalEntity?.masspayClientEntity === clientLegalEntityText
  ) || {
    defaultCurrency: 'USD',
    currencyOptions: ['USD'],
  }

  return {
    defaultCurrency: currencyDropdownOptions?.filter(
      (currency) => currency.value === defaultCurrency
    )[0],
    currencyOptions: currencyOptions.reduce((acc, currencyOption) => {
      return acc.concat(
        currencyDropdownOptions?.filter(
          (currency) =>
            currency.value === currencyOption &&
            payinBanks?.some((bank) => bank.bank_currency === currencyOption)
        )
      )
    }, [] as SharedLabelValueCountryOption[]),
  }
}
