import { createGlobalStyle } from 'styled-components/macro'

import { less } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'
import { Logger } from 'V2/services/logger'
import { commonFonts, resetCss } from './commonFonts'

import 'react-dates/lib/css/_datepicker.css'

const FILE_NAMESPACE = 'globalStyledV2'
// get custom font size set in browser settings
const browserRoot = Number(
  (window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/) ?? [16])[0]
)
Logger(FILE_NAMESPACE).silly('browserRoot', browserRoot)

const globalStyles = createGlobalStyle`
${commonFonts}
${resetCss}

html {
    font-family: Hellix, sans-serif;
    box-sizing: border-box;
    /**
     * when browser is in default settings: 1rem = 16px,
     * and portal default: 1rem = props.theme.revamp.desktopFont.root
     * when browser default font-size changes, then computed font-size will be:
     **/
    font-size:${(props): string => `${(props.theme.revamp.desktopFont.root / 16) * browserRoot}px`};
}

// scrollbar css for the entire application
* {
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-border-radius: ${pxToRem(10)};
    border-radius: ${pxToRem(10)};
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: ${pxToRem(4)};
    border-radius: ${pxToRem(4)};
    background: ${(props): string => props.theme.revamp.primary.blue.dark};
    @media ${less.lessThanTablet} {
      background: transparent;
    }
  }
  &:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: ${pxToRem(4)};
    border-radius: ${pxToRem(4)};
  }
}

body {
    width: 100%; /* standard min width for fixed displays */
    margin: 0;
    padding: 0;
    color: #ccc;
    font-size:${(props): string => pxToRem(props.theme.revamp.desktopFont.root)};
    color: ${(props): string => props.theme.revamp.staticColors.black};
    ::-webkit-scrollbar {
    width: 0px;
  }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 100vh;
}
`

export default globalStyles
