// flag names
export const ACH_FEATURE_FLAG = 'ACH'
export const CNY_FEATURE_FLAG = 'CNY'
export const FPX_FEATURE_FLAG = 'FPX'
export const NZD_BANNER_DISABLED = 'NZD_BANNER_DISABLED'
export const ACCOUNTING = 'ACCOUNTING'
export const ACCOUNTING_BANNER_DISABLED = 'ACCOUNTING_BANNER_DISABLED'
export const SWIFT_FEE_PAYER = 'SWIFT_FEE_PAYER'
export const USD_SWIFT_ENABLED = 'USD_SWIFT_ENABLED'
export const HK_RECEIVE_ENABLED = 'HK_RECEIVE_ENABLED'
export const PRICING_BANNER_ENABLED = 'PRICING_BANNER_ENABLED'
export const BATCH_BANNER_DISABLED = 'BATCH_BANNER_DISABLED'
export const ENABLE_BULK_UPLOADS = 'ENABLE_BULK_UPLOADS'
export const VFA_BANNER_DISABLED = 'VFA_BANNER_DISABLED'
export const VFA_DISCLAIMER_DISABLED = 'VFA_DISCLAIMER_DISABLED'
export const VFA_TRANSACTION_COUNT = 'vfa_transaction_count'
export const MY_BANNER_DISABLED = 'MY_BANNER_DISABLED'
export const SAR_BANNER_DISABLED = 'SAR_BANNER_DISABLED'
export const CSAT_RATING_DISABLED = 'CSAT_RATING_DISABLED'
export const BPSP_ENABLED = 'BPSP_ENABLED'
export const DISABLE_RECEIVE = 'DISABLE_RECEIVE'
export const HK_VFA_BANNER_DISABLED = 'HK_VFA_BANNER_DISABLED'
export const MAINTENANCE_BANNER = 'MAINTENANCE_BANNER'

// trait names
export const ENABLE_NZD_BANNER = 'enable_nzd_banner'
export const ENABLE_ACCOUNTING_BANNER = 'enable_accounting_banner'
export const ENABLE_PRICING_BANNER = 'enable_pricing_banner'
export const ENABLE_BATCH_BANNER = 'enable_batch_banner'
export const ENABLE_VFA_BANNER = 'enable_vfa_banner'
export const ENABLE_MY_BANNER = 'enable_my_banner'
export const ENABLE_SAR_BANNER = 'enable_sar_banner'
export const ENABLE_CSAT_RATING = 'enable_csat_rating'

// This constant to be updated whenever a new feature is needed as goToMarket Market Strategy
export const clientSpecificFeatureFlag = `${ACH_FEATURE_FLAG},${CNY_FEATURE_FLAG},${FPX_FEATURE_FLAG},${NZD_BANNER_DISABLED},${ACCOUNTING},${ACCOUNTING_BANNER_DISABLED},${SWIFT_FEE_PAYER},${USD_SWIFT_ENABLED},${HK_RECEIVE_ENABLED},${PRICING_BANNER_ENABLED},${BATCH_BANNER_DISABLED},${ENABLE_BULK_UPLOADS},${VFA_BANNER_DISABLED},${VFA_DISCLAIMER_DISABLED},${MY_BANNER_DISABLED},${SAR_BANNER_DISABLED},${CSAT_RATING_DISABLED},${BPSP_ENABLED},${DISABLE_RECEIVE},${HK_VFA_BANNER_DISABLED}`

// Beta Releases
export const betaFeatures = ['']

// Prod send Checker-id.  this id is always going to be same in masspay db
export const smeSendCheckerId = '616435cc169fcb0790220574'
