// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const NewTagIcon: React.FC<{}> = () => (
  <>
    <rect x="0.25" y="0.25" width="27.5" height="15.5" rx="1.75" fill="#FFFBD9" />
    <path
      d="M4.63965 3.99805H5.66016L8.9707 8.73926V3.99805H10.1523V11H9.1416L5.81152 6.25879V11H4.63965V3.99805ZM15.9971 8.33887C15.9971 8.52767 15.984 8.6888 15.958 8.82227H12.2373C12.2992 9.2194 12.457 9.53027 12.7109 9.75488C12.9681 9.97949 13.2936 10.0918 13.6875 10.0918C13.9642 10.0918 14.2181 10.0365 14.4492 9.92578C14.6803 9.81185 14.8529 9.66048 14.9668 9.47168L15.8555 10.0381C15.6732 10.3701 15.3883 10.6305 15.001 10.8193C14.6169 11.0049 14.1725 11.0977 13.668 11.0977C13.2025 11.0977 12.776 10.987 12.3887 10.7656C12.0013 10.541 11.6937 10.2318 11.4658 9.83789C11.2412 9.44401 11.1289 9.00781 11.1289 8.5293C11.1289 8.0638 11.2396 7.639 11.4609 7.25488C11.6855 6.86751 11.9899 6.56315 12.374 6.3418C12.7614 6.12044 13.1862 6.00977 13.6484 6.00977C14.0814 6.00977 14.4769 6.1123 14.835 6.31738C15.1963 6.52246 15.4795 6.80404 15.6846 7.16211C15.8929 7.51693 15.9971 7.90918 15.9971 8.33887ZM12.2764 7.96777H14.8252C14.7959 7.66178 14.6657 7.41276 14.4346 7.2207C14.2035 7.02539 13.9202 6.92773 13.585 6.92773C13.2562 6.92773 12.9779 7.02051 12.75 7.20605C12.5221 7.38835 12.3643 7.64225 12.2764 7.96777ZM17.999 11L16.2314 6.1123H17.418L18.4482 9.28125L19.5908 6.1123H20.4893L21.6416 9.28125L22.667 6.1123H23.8584L22.0908 11H21.1973L20.04 7.83105L18.8779 11H17.999Z"
      fill="#004288"
    />
    <rect
      x="0.25"
      y="0.25"
      width="27.5"
      height="15.5"
      rx="1.75"
      stroke="#ABA680"
      strokeWidth="0.5"
    />
  </>
)
