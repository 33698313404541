// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import { components, OptionProps, SingleValueProps, StylesConfig } from 'react-select'
import { useTheme } from 'styled-components/macro'

import Dropdown from 'V2/components/atoms/DropDown/Dropdown'
import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { floors } from 'V2/constants/floors'
import {
  ProfileDropDownProps,
  UserProfileDropDownOption,
} from 'V2/interfaces/components/Molecules.interface'
import { FlagCurrencyBoldName, FlagCurrencyOptionWrapper } from './Styles'

export const ProfileDropdown: React.FC<ProfileDropDownProps<UserProfileDropDownOption>> = ({
  ...dropdownCurrencyProp
}) => {
  const theme = useTheme()
  const customStyles: StylesConfig = {
    option: (base, state) => ({
      backgroundColor: theme.revamp.staticColors.white,
      '&:hover': {
        cursor: ` pointer`,
        backgroundColor: theme.revamp.primary.yellow.light,
      },
    }),
    menu: () => ({
      zIndex: floors.fifth,
    }),
  }
  return (
    <Dropdown
      components={{
        Option: FlagDropdownOption,
        SingleValue: IconSingleValue,
      }}
      styles={customStyles}
      {...dropdownCurrencyProp}
    />
  )
}

const FlagDropdownOption: React.FC<OptionProps<UserProfileDropDownOption>> = ({ ...props }) => {
  const { Option } = components
  return (
    <Option {...props}>
      <FlagOption value={''} {...props} />
    </Option>
  )
}
const FlagOption: React.FC<UserProfileDropDownOption> = ({ data }) => (
  <>
    <FlagCurrencyOptionWrapper onClick={data.onClick} id={data.id}>
      <CustomIcon defaultSize={24} viewBox={'0 0 24 24'}>
        {data.icon}
      </CustomIcon>
      <FlagCurrencyBoldName>{data.label}</FlagCurrencyBoldName>
    </FlagCurrencyOptionWrapper>
  </>
)

const IconSingleValue: React.FC<SingleValueProps<UserProfileDropDownOption>> = ({ ...props }) => {
  const { SingleValue } = components
  return (
    <SingleValue {...props}>
      <FlagOption icon={<></>} label={''} value={''} {...props} />
    </SingleValue>
  )
}
