import { ReceiveCurrencyDetails } from 'V2/interfaces/components/Shared.interface'
/** currencies which are out of scope for receive accounts */
const receiveDisabledCurrencies = [] as string[]
export const receiveDisabledBaseCurrencies = ['MYR', 'IDR']
export const partialSweepEnabledCurrencies = ['USD', 'MYR', 'HKD', 'IDR']
export const autoSweepEnabledCurrencies = ['MYR', 'HKD', 'IDR']
export const newTagEnabledLegalEntities = ['ID']
export const payindisabledCurrencies = ['JPY', 'NZD']

/** configurations for receive accounts  */
export const receiveCurrencyConfig: { [key: string]: ReceiveCurrencyDetails } = {
  SG: {
    disabledCurrency: receiveDisabledCurrencies,
    partialSweep: partialSweepEnabledCurrencies.includes('SGD'),
    autoSweep: autoSweepEnabledCurrencies.includes('SGD'),
    receiveEnabledforLocalCurrency: true,
  },
  US: {
    disabledCurrency: receiveDisabledCurrencies,
    partialSweep: partialSweepEnabledCurrencies.includes('USD'),
    autoSweep: autoSweepEnabledCurrencies.includes('USD'),
    receiveEnabledforLocalCurrency: true,
  },
  AU: {
    disabledCurrency: receiveDisabledCurrencies,
    partialSweep: partialSweepEnabledCurrencies.includes('AUD'),
    autoSweep: autoSweepEnabledCurrencies.includes('AUD'),
    receiveEnabledforLocalCurrency: true,
  },
  MY: {
    disabledCurrency: receiveDisabledCurrencies,
    partialSweep: partialSweepEnabledCurrencies.includes('MYR'),
    autoSweep: autoSweepEnabledCurrencies.includes('MYR'),
    receiveEnabledforLocalCurrency: false,
  },
  HK: {
    disabledCurrency: receiveDisabledCurrencies,
    partialSweep: partialSweepEnabledCurrencies.includes('HKD'),
    autoSweep: autoSweepEnabledCurrencies.includes('HKD'),
    receiveEnabledforLocalCurrency: false,
  },
  ID: {
    disabledCurrency: receiveDisabledCurrencies,
    partialSweep: partialSweepEnabledCurrencies.includes('IDR'),
    autoSweep: autoSweepEnabledCurrencies.includes('IDR'),
    receiveEnabledforLocalCurrency: false,
  },
}

/** success and error message on receive account creation */
export const recievedCurrencyMsg = {
  successMessage: 'Your currency accounts have been opened',
  errorMessagse: 'We are experiencing issues while creating the account. Please try again later',
}

/** VRA creation approach for receive accounts */
export const VraApproach = {
  PER_CURRENCY: 'PER_CURRENCY',
  PER_PAYER: 'PER_PAYER',
}
