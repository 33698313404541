// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const ProfileIcon: React.FC<{ stroke?: string }> = ({ stroke = 'white' }) => (
  <>
    <circle cx="32" cy="32" r="32" fill="#006D94" />
    <path
      d="M32.0016 4.17604e-08C25.6726 -0.000323222 19.4857 1.87613 14.2231 5.39206C8.96058 8.90799 4.85879 13.9055 2.43649 19.7526C0.0141881 25.5997 -0.619841 32.0338 0.614566 38.2413C1.84897 44.4487 4.89637 50.1507 9.37142 54.6262C13.8465 59.1017 19.5482 62.1497 25.7555 63.3848C31.9628 64.6198 38.397 63.9865 44.2443 61.5648C50.0917 59.1431 55.0896 55.0418 58.6061 49.7796C62.1226 44.5174 63.9997 38.3307 64 32.0017V32C64 27.7977 63.1723 23.6365 61.5642 19.7541C59.956 15.8717 57.5989 12.3441 54.6274 9.37258C51.656 6.4011 48.1283 4.04399 44.2459 2.43585C40.3635 0.827696 36.2023 -3.79119e-06 32 4.17604e-08H32.0016ZM32.0016 9.59913C33.9002 9.59913 35.7561 10.1621 37.3346 11.2169C38.9132 12.2716 40.1436 13.7708 40.8701 15.5248C41.5967 17.2788 41.7868 19.2089 41.4164 21.0709C41.046 22.933 40.1318 24.6434 38.7893 25.9859C37.4469 27.3283 35.7365 28.2426 33.8744 28.613C32.0124 28.9834 30.0823 28.7933 28.3283 28.0668C26.5743 27.3402 25.0751 26.1099 24.0203 24.5313C22.9655 22.9527 22.4025 21.0969 22.4025 19.1983C22.4025 16.6525 23.4139 14.2109 25.214 12.4107C27.0142 10.6105 29.4558 9.59915 32.0016 9.59913V9.59913ZM32.0016 55.8137C28.2092 55.7964 24.4784 54.8541 21.1326 53.0685C17.7868 51.2829 14.9271 48.7081 12.8016 45.5672C12.9558 39.1672 25.6016 35.6444 32.0016 35.6444C38.4016 35.6444 51.0475 39.1672 51.2016 45.5672C49.0738 48.7058 46.2137 51.2789 42.8684 53.0642C39.5231 54.8494 35.7934 55.7931 32.0016 55.8137Z"
      fill="#E6F8FF"
    />
  </>
)
