import { UtmParamsInterface } from 'V2/interfaces/Constants.interface'

export const UTM_DATA = 'utm_data'
export function constructQueryParams(string: string): Record<string, string> {
  if (!string) return {}
  const queries = string.replace('?', '').split('&')
  const object: Record<string, string> = {}
  queries.forEach((query) => {
    const [key, value] = query.split('=')
    object[key] = value
  })
  return object
}

export function getUTMQuery(query: string): UtmParamsInterface | null {
  const { utm_medium, utm_source, utm_campaign, utm_content, utm_term } =
    constructQueryParams(query)
  if (!utm_source) return null
  return {
    utm_medium,
    utm_source,
    utm_campaign,
    utm_content,
    utm_term,
  }
}

export function expiresAfter(days: number): Date {
  const newDate = new Date()
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export function setCookie(key: string, value: unknown, expires: Date): void {
  document.cookie = `${key}=${value}; ${expires ? 'expires=' + expires.toUTCString() : ''}; secure;`
}

export function getCookie(key: string): string | null {
  const cookies = document.cookie.replace(/\s/g, '').split(';')
  for (let i = 0, iL = cookies.length; i < iL; i++) {
    const [_key, value] = cookies[i].split('=')
    if (_key === key) return value
  }
  return null
}

export function deleteCookie(name: string): void {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export const getUTMQueryInString = (): string => {
  const utmCookieStr = getCookie(UTM_DATA)
  const clickrefCookie = getCookie('clickref')
  let appendUtmQueryString = '?'
  if (utmCookieStr) {
    const utmCookie = JSON.parse(utmCookieStr)
    for (const [key, value] of Object.entries(utmCookie)) {
      appendUtmQueryString += `&${key}=${value}`
    }
  } else {
    appendUtmQueryString = ''
  }

  if (clickrefCookie) {
    appendUtmQueryString += `${utmCookieStr ? '&' : '?'}clickref=${clickrefCookie}`
  }

  return appendUtmQueryString
}
