// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import AlertCircleIcon from 'V2/components/iconPaths/Custom/BasicIcons/AlertCircleIcon'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import CustomIcon from '../Icon/CustomIcon'
import { BoxWrapper, IconWrapper, Text, TextWrapper } from './Styles'

interface BlueBoxProps {
  note: string | JSX.Element
  subHeading?: string
  customIcon?: JSX.Element
}
export const BlueBox: React.FC<BlueBoxProps> = ({ note, subHeading, customIcon }) => {
  return (
    <BoxWrapper>
      <IconWrapper>
        {customIcon ? (
          customIcon
        ) : (
          <CustomIcon viewBox={'0 0 24 24'} width={24} height={24}>
            <AlertCircleIcon />
          </CustomIcon>
        )}
      </IconWrapper>
      <TextWrapper id={automationIdSelectors.vfa.blueBoxText}>
        {subHeading && (
          <Text type="body" weight="semibold">
            {`${subHeading} `}
          </Text>
        )}
        <Text type="body" weight="medium">
          {note}
        </Text>
      </TextWrapper>
    </BoxWrapper>
  )
}
