// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const AuthorizedSignatoryIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2716_44101)">
      <path
        d="M22.63 14.8695L15 22.5005L11.25 23.2505L12 19.5005L19.63 11.8695C20.0268 11.4728 20.5649 11.25 21.126 11.25C21.6871 11.25 22.2252 11.4728 22.622 11.8695L22.63 11.8785C23.0266 12.2752 23.2494 12.8131 23.2494 13.374C23.2494 13.9349 23.0266 14.4729 22.63 14.8695V14.8695Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3.75C3 4.54565 3.31607 5.30871 3.87868 5.87132C4.44129 6.43393 5.20435 6.75 6 6.75C6.79565 6.75 7.55871 6.43393 8.12132 5.87132C8.68393 5.30871 9 4.54565 9 3.75C9 2.95435 8.68393 2.19129 8.12132 1.62868C7.55871 1.06607 6.79565 0.75 6 0.75C5.20435 0.75 4.44129 1.06607 3.87868 1.62868C3.31607 2.19129 3 2.95435 3 3.75V3.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 13.5C11.25 12.1076 10.6969 10.7723 9.71231 9.78769C8.72774 8.80312 7.39239 8.25 6 8.25C4.60761 8.25 3.27226 8.80312 2.28769 9.78769C1.30312 10.7723 0.75 12.1076 0.75 13.5V15.75H3L3.75 23.25H8.25L9 15.75H11.25V13.5Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2716_44101">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
