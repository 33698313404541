export const appRoutes = Object.freeze({
  // Portal Routes
  authLogin: '/login',
  authForgetPassword: '/forgetPassword',
  authResetPassword: '/resetPassword/:resetToken',
  authSetPassword: '/r/setpassword',
  dashboard: '/r/dashboard',
  send: '/r/send',
  payBills: '/r/pay-bills',
  individualSend: '/r/individualsend',
  groupSend: '/r/groupsend',
  bulkPayment: '/r/bulkpayment',
  corridors: '/r/corridors',
  addMoney: '/r/addmoney',
  exchange: '/r/exchange',
  withdraw: '/r/withdraw',
  uipWithdraw: '/r/uip/withdraw',
  uipExchange: '/r/uip/exchange',
  accountStatement: '/r/account-statement',
  contacts: '/r/contacts',
  myAccounts: '/r/myaccounts',
  transactionList: '/r/transactions',
  settings: '/r/settings',
  migration: '/migration',
  migrationResetPassword: '/migration/:resetToken',
  help: '/r/help',
  transactionDescription: '/r/transactions/:transactionId',
  paymentRequestTransDescription: '/r/transactions/payer/:payerId/paymentrequest/:prId',
  vraTransactionDescription: '/r/vra/:vraId/transactions/:transactionId',
  exchangeDescription: '/r/exchange/:exchangeId',
  fundingDescription: '/r/funding/:fundingId',
  transactionApproval: '/r/transaction-approval',
  maintenanceDownTime: '/maintenance',
  callbackRedirection: '/r/callback',
  fpxSuccess: '/r/fpx/success',
  cards: '/r/cards',
  // Signup route is for ID form
  signUp: '/signup',
  // SME-Onboarding Routes
  startOnboarding: '/start',
  getStarted: '/welcome',
  register: '/register',
  lookup: '/lookup',
  onboardingForm: '/onboarding',
  success: '/success',
  reject: '/rejected',
  dropout: '/dropout',
  // SG-routes
  sgLookup: '/sg-lookup',
  sgOnboardingForm: '/sg-onboarding',
  sgDropoutForm: '/sg-dropout',
  // Myinfo Singpass Redirection
  onboardingSuccess: '/onboarding/redirect/success',
  onboardingError: '/onboarding/redirect/error',
  // UK-routes
  ukLookup: '/uk-lookup',
  ukOnboardingForm: '/uk-onboarding',
  // HK-routes
  hkOnboardingForm: '/hk-onboarding',
  // EU-routes
  euOnboardingForm: '/eu-onboarding',
  onboardingRFI: '/onboarding-rfi',
  onboardingRFIDocumentUpload: '/onboarding-rfi/document',
  onboardingRFISuccess: '/onboarding-rfi/success',
})

export const autoSweepBlockedUrls = [
  appRoutes.send,
  appRoutes.myAccounts,
  appRoutes.payBills,
  appRoutes.individualSend,
  appRoutes.groupSend,
  appRoutes.bulkPayment,
  appRoutes.addMoney,
  appRoutes.withdraw,
]
