//used by onboarding lookup page

import { Reducer } from 'react'

import { ScreenInfoActions } from 'store/actionTypes'

export interface ScreenInfoInitialState {
  currentScreen?: number
  currentStepName?: string
}

const initialState: ScreenInfoInitialState = {
  currentScreen: 0,
  currentStepName: '',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const screenInfoReducer: Reducer<any, any> = (state = initialState, action) => {
  switch (action.type) {
    case ScreenInfoActions.UPDATE_SCREEN_INFO_VALUES:
      return {
        ...state,
        currentScreen: action.payload?.currentScreen,
        currentStepName: action.payload?.currentStepName,
      }
    default:
      return state
  }
}
