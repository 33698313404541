export const paymentErrorMapper: {
  [a: string]: {
    message: string
    displayError: boolean
    disableInput: boolean
    displaySnackBar: boolean
    remitterIdentificationErrorMessage?: string
  }
} = {
  default: {
    message: 'Something went wrong. Please enter the amount again.',
    displayError: true,
    disableInput: false,
    displaySnackBar: false,
  },
  invalidDocument: {
    message: "We couldn't process your submitted documents.",
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidRecipient: {
    message: 'Your recipient details are in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidRemitterIdentification: {
    message: 'Your recipient details are in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
    remitterIdentificationErrorMessage: 'Please contact customer support.',
  },
  invalidIBAN: {
    message: 'The recipient IBAN is in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidName: {
    message: 'The recipient Name is in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidSwift: {
    message: 'The recipient SWIFT code is in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidRecipientAccount: {
    message: 'The recipient Account Details are in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidAccountNumber: {
    message: 'The recipient Account Number is in the incorrect format.',
    displayError: true,
    disableInput: true,
    displaySnackBar: false,
  },
  invalidAmount: {
    message: 'We cannot process this amount.',
    displayError: true,
    disableInput: false,
    displaySnackBar: false,
  },
  invalidFee: {
    message: 'Something went wrong. Please enter the amount again.',
    displayError: true,
    disableInput: false,
    displaySnackBar: false,
  },
  invalidCreditCard: {
    message: "We couldn't process your credit card.",
    displayError: false,
    disableInput: false,
    displaySnackBar: true,
  },
  invalidCorridor: {
    message: 'There is an issue with this payment corridor at the moment.',
    displayError: false,
    disableInput: true,
    displaySnackBar: true,
  },
}

// TODO: invalidFee needs little work to capture all possible netsettlement errors
export const errorSegment = {
  invalidRemitterIdentification: ['RemitterIdentificationValue'],
  invalidIBAN: ['Invalid IBAN', 'iban'],
  invalidName: ['Beneficiary Name', 'beneficiary_name'],
  invalidSwift: ['SWIFT is required in routing code for SGD payments in US', 'SWIFT'],
  invalidRecipientAccount: [
    'Routing Code Value',
    'Routing Code Type',
    'routing_code_type',
    'routing_code_value',
    'BSB CODE',
    'bsb_code',
    'routing code',
  ],
  invalidAccountNumber: [
    'Beneficiary Account Number',
    'beneficiary_account_number',
    'Invalid Beneficiary Account No',
  ],
  invalidRecipient: [
    'SenderRelationToReceiver',
    'Target Country should be 2 letter ISO country code',
    'beneficiary_account_type',
    'destination_country_code',
    'destination_country',
    'source_country',
    'supported_corridors',
    'beneficiary',
  ],
  invalidDocument: [
    'Supported document referenceId needed for this transaction',
    'document referenceId',
    'document_reference',
    'document',
    'upload',
  ],
  invalidAmount: [
    'Destination Amount',
    'Amount must be at least',
    'destination_amount can have upto 2 decimals for given currency',
    'equivalent_amount can have upto 2 decimals for given currency',
    'destination_amount cannot have any decimals for given currency',
    'destination_amount should be',
    'Transfer Amount should be Numeric',
    'should not contain more than 2 decimal digits',
    'Transfer amount should be less than',
    'amount',
    'decimal',
  ],
  invalidFee: ['fee_id', 'fee'],
  invalidCreditCard: ['Your card number is incorrect', 'rejected', 'card'],
  invalidCorridor: [
    'HoldFx id is invalid',
    'Exception occurred during execution on the exchange',
    'The source currency provided in payout object does not match currency in holdFx',
    'fx_hold_id can not be present when source currency and destination currency match',
    'fx',
    'holdfx',
    'fx_hold_id',
  ],
}

export const paymentWarningMsg = {
  accountName: 'Instarem only accepts funds from an account in your business name.',
}

export const vfaDetails = {
  note: 'Our bank account number has changed, so make sure to use the new account number for all future bank transfers. The reference number is no longer required.',
  subheading: 'Important update:',
}

export const FPXDetails = {
  note: 'Upon choosing this payment method you’ll be redirected to an external page to login with your banking credentials to trigger the FPX transfer.',
  title: 'Pay with FPX transfer',
}

export const INRBeneErrorText = `We need additional details for your recipient(s). Please add their address, city and postal code via ‘Contacts’ on the dashboard before proceeding.`
