// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const InfoBigIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <path
      d="M10.9997 6.33333C11.1838 6.33333 11.333 6.48258 11.333 6.66667C11.333 6.85076 11.1838 7 10.9997 7"
      stroke={stroke}
      strokeWidth="1.35417"
    />
    <path
      d="M11 6.33333C10.8159 6.33333 10.6667 6.48258 10.6667 6.66667C10.6667 6.85076 10.8159 7 11 7"
      stroke={stroke}
      strokeWidth="1.35417"
    />
    <path
      d="M11 9.66667V17"
      stroke={stroke}
      strokeWidth="1.35417"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11 1C5.47716 1 1 5.47716 1 11C1 16.5228 5.47716 21 11 21C16.5228 21 21 16.5228 21 11C21 5.47716 16.5228 1 11 1Z"
      stroke={stroke}
      strokeWidth="1.35417"
      strokeMiterlimit="10"
    />
  </>
)
