import { Reducer } from 'redux'

import { Withdraw } from 'interfaces/redux/Reducers.interface'
import { WithdrawTypes } from 'store/actionTypes'

const initialState = {
  selectedCurrency: '',
  withdrawResponse: {
    paymentId: '',
    withdrawalAmount: '',
    bankName: '',
    accountNumber: '',
  },
  isWithdrawComplete: false,
}

const withdrawReducer: Reducer<Withdraw> = (state = initialState, action) => {
  switch (action.type) {
    case WithdrawTypes.UPDATE_SELECTED_CURRENCY:
      return { ...state, selectedCurrency: action.payload }
    case WithdrawTypes.UPDATE_WITHDRAW_RESPONSE:
      return { ...state, ...action.payload }
    case WithdrawTypes.WITHDRAW_COMPLETED:
      return { ...state, isWithdrawComplete: action.payload }
    case WithdrawTypes.RESET_STATE:
      return { ...state, ...initialState }
    default:
      return state
  }
}

export { withdrawReducer as withdraw }
