import styled from 'styled-components/macro'

import { Button } from 'V2/components/atoms/Button/Button'
import { ModalBody } from 'V2/components/molecules/Modal/Styles'
import { less } from 'V2/helpers/mediaquery'

export const ModalWrapper = styled.div<{ openModal?: boolean }>`
  display: ${({ openModal }): string => (openModal ? 'block' : 'none')};
  ${ModalBody} {
    max-height: unset;
    min-height: unset;
  }
`

export const Screen = styled.div<{ height: string }>`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: overlay;
  @media ${less.lessThanMobile} {
    padding: 24px 16px;
    height: ${(props): string => props.height};
  }
`

export const CloseBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
`

export const WebImg = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  @media ${less.lessThanMobile} {
    margin-top: 8px;
  }
`

export const HeadingWrapper = styled.div`
  max-width: 100%;
  margin-top: 24px;
  text-align: center;
  @media ${less.lessThanMobile} {
    margin-top: 16px;
  }
`
export const SubTextWrapper = styled.div`
  text-align: center;
  padding: 0px 16px;
  margin: 16px 0px 48px 0px;
  @media ${less.lessThanMobile} {
    margin-top: 12px;
    margin-bottom: auto;
  }
`

export const LinkNowButton = styled(Button)`
  width: 167px;
  height: 36px;
  @media ${less.lessThanMobile} {
    width: 100%;
    height: 48px;
  }
`

export const Bold = styled.b`
  font-weight: 600;
`
export const BankBannerModalWrapper = styled.div`
  padding: 24px;
`
