import { breakPoint } from 'V2/constants/breakpoints'
import { MediaQueryDeviceTs } from 'V2/interfaces/components/Shared.interface'

const less = {
  lessThanMobile: `(max-width: ${breakPoint.mobile}px)`,
  lessThanTablet: `(max-width: ${breakPoint.tablet}px)`,
}

const great = {
  greaterThanMobile: `(min-width: ${breakPoint.mobile + 1}px)`,
  greaterThanTablet: `(min-width: ${breakPoint.tablet + 1}px)`,
}

const createBetween = (start: MediaQueryDeviceTs, stop: MediaQueryDeviceTs): string =>
  great[`greaterThan${start}`] + ' and ' + less[`lessThan${stop}`]

/* Example:
1.     @media ${query.lessThanLarge} {

    }

2.    @media ${query.betweenMediumLarge} {
         your css
    }
*/

export { createBetween, great, less }
