import styled from 'styled-components/macro'

import theme from 'styles/theme'
import { ButtonBase } from 'V2/components/atoms/Button/Styles'
import { Card } from 'V2/components/atoms/Card/Styles'
import { great } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'

export const CardWrapper = styled(Card)<{ buttonTitle?: boolean }>`
  min-height: ${({ buttonTitle }): string => (buttonTitle ? '370px' : '0px')};
  border: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light};
`
export const TitleWrapper = styled.div`
  margin: 16px;
  @media ${great.greaterThanMobile} {
    margin: 24px;
  }
`
export const Divider = styled.hr`
  background-color: ${({ theme }): string => theme.revamp.primary.grey.lighter};
  border-width: 0;
  height: 1px;
  margin: 0;
`
export const InnerWrapper = styled.div<{ loading?: boolean; verticalColumn?: boolean }>`
  display: flex;
  visibility: ${({ loading }): string => (loading ? 'hidden' : 'visible')};
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-height: 172px;
  flex-direction: ${({ verticalColumn }): string => (verticalColumn ? 'column' : 'row')};
  background: rgba(238, 246, 255, 0.4);
  @media ${great.greaterThanMobile} {
    flex-direction: column;
    margin: 24px;
  }
`
export const TextWrapper = styled.div<{ color?: string }>`
  color: ${({ color, theme }): string => color || theme.revamp.primary.blue.lighter};
  line-height: ${pxToRem(theme.revamp.mobileFont.root)};
`
export const ButtonWrapper = styled.div`
  margin: 24px 16px;
  display: flex;
  justify-content: space-between;
  & ${ButtonBase} {
    border-radius: 6px;
    padding: 4px 24px;
  }
  @media ${great.greaterThanMobile} {
    margin: 24px;
  }
`
export const RecipientCardWrapper = styled.div`
  @media ${great.greaterThanMobile} {
    display: flex;
    flex-wrap: wrap;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 172px;
  @media ${great.greaterThanMobile} {
    padding: 24px 8px 24px 24px;
  }
`
