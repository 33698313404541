/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { creditCard } from 'V2/constants/paymentMethodFilters'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import { apiKey } from '../constants/bulkPayment'
import { Logger } from './logger'

// TODO: remove any type and remove response logged since it will log promise only
const FILE_NAMESPACE = 'bulkPayment'
export const config = (clientID?: string, params?: any, contentType?: string): any => {
  if (process.env.REACT_APP_ENV?.toLowerCase() === 'dev') {
    return {
      params: params,
      headers: {
        'x-api-key': apiKey,
        'Content-Type': contentType,
      },
    }
  }
  return {
    params: params,
    withCredentials: true,
    headers: {
      'x-client-id': clientID,
      'Content-Type': contentType,
    },
  }
}
export const cancelBatch = (batchID: string, clientID: string): Promise<any> => {
  const response = axios.delete(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.cancelBatch(batchID)}`,
    config(clientID)
  )
  Logger(FILE_NAMESPACE).debug('Cancel Batch response', response)
  return response
}

export const getClientBatchDetails = (clientID: string): Promise<any> => {
  const params = { client_id: clientID }
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getBatchDetails}`,
    config(clientID, params)
  )
  Logger(FILE_NAMESPACE).debug('Get Batch Details response', response)
  return response
}
export const getBatchWithBatchId = (batchId: string, clientId: string): Promise<any> => {
  const params = { client_id: clientId }
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getBatchWithBatchId(batchId)}`,
    config(clientId, params)
  )
  Logger(FILE_NAMESPACE).debug('Get Batch with batch Id response', response)
  return response
}
export const getTransactionDetails = (clientID: string, transactionID: string): Promise<any> => {
  const params = { transaction_id: transactionID }
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getBatchDetails}`,
    config(clientID, params)
  )
  Logger(FILE_NAMESPACE).debug('Get Batch transaction Details response', response)
  return response
}

export const initiateHoldFx = (batchID: string, clientID: string): Promise<any> => {
  const response = axios.post(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.holdFx(batchID)}`,
    {
      batchId: batchID,
    },
    config(clientID)
  )
  Logger(FILE_NAMESPACE).debug('Initiate holdfx response', response)
  return response
}

export const downloadBulkTemplate = (
  client_legal_entity?: string,
  clientID?: string
): Promise<any> => {
  const params = {
    corridor: clientLegalEntityMapper(client_legal_entity || ''),
  }
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.downloadBulkTemplate}`,
    { ...config(clientID, params), responseType: 'blob' }
  )
  Logger(FILE_NAMESPACE).debug('Download template response', response)
  return response
}

export const batchUpload = (formData: FormData, clientID?: string): Promise<any> => {
  const response = axios.post(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.uploadBatch}`,
    formData,
    config(clientID, null, 'multipart/form-data')
  )
  Logger(FILE_NAMESPACE).debug('Batch upload response', response)
  return response
}
export const initiatePayout = (
  batchID: string,
  clientId: string,
  paymentMethod: string,
  loggedInUser: string,
  achDetails?: {
    achAccountNumber: string
    achRoutingValue: string
  },
  fpxPaymentId?: string,
  feePercentage?: number,
  stripe_id?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = axios.post(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.initiatePayout(batchID)}`,
    {
      batchId: batchID,
      clientId: clientId,
      depositMethod: paymentMethod,
      ...(achDetails ? achDetails : {}),
      fpxPaymentId: fpxPaymentId,
      supplierPaymentFeePercent: feePercentage,
      ...(paymentMethod === creditCard && { stripeId: stripe_id }),
      loggedInUser,
    },
    config(clientId)
  )
  Logger(FILE_NAMESPACE).debug('Get Batch Details response', response)
  return response
}

export const getBulkTransactions = (
  batchID: string,
  clientID?: string,
  params?: any
): Promise<any> => {
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getTransactions(batchID)}`,
    config(clientID, params)
  )
  Logger(FILE_NAMESPACE).debug('Get transactions response', response)
  return response
}

export const downloadErrorTransactios = (batchID: string, clientID?: string): Promise<any> => {
  const params = { status: 'validation_failed', clientId: clientID }
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.downloadErrorFile(batchID)}`,
    { ...config(clientID, params), responseType: 'blob' }
  )
  Logger(FILE_NAMESPACE).debug('Download error transaction response', response)
  return response
}
