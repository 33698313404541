// COLORS
const primary = {
  blue: {
    root: '#004288',
    dark: '#002D5C',
    light: '#326499',
    lighter: '#6B8199',
    lightest: '#EEF6FF',
  },
  yellow: {
    root: '#FFF9C7',
    dark: '#ABA680',
    light: '#FFFBD9',
    lighter: '#FFFDED',
    lightest: '#FFFEFA',
  },
  grey: {
    root: '#C6C6C6',
    dark: '#7D7D7D',
    light: '#E3E3E3',
    lighter: '#F5F5F5',
    lightest: '#FAFAFA',
  },
}

const secondary = {
  blue: {
    dark: '#006D94',
    root: '#00A5DF',
    light: '#53D0FC',
    lighter: '#B1E9FC',
    lightest: '#E5F8FF',
  },
  tangerine: {
    dark: '#A36200',
    root: '#F29100',
    light: '#FFBB54',
    lighter: '#FFE0B2',
    lightest: '#FFF5E5',
  },
}

const staticColors = {
  black: '#2C2C2C',
  white: '#ffffff',
  redPepperCorn: '#CA2B3B', // error
  success: '#157A3F', // success
  bahamaBlue: '#006D94', //information
  richGold: '#AE5409', // warning
  americanYellow: '#F2B202',
}

// FONT
const desktopFont = {
  xsm: 10, // tiny
  sm: 13, // caption
  root: 15, // body
  m: 18, // h3
  l: 20, // h2
  xl: 24, // h1
  lgt: 48, // amounts
}

const desktopLineHeight = {
  xsm: 16, // tiny
  sm: 20, // caption
  root: 24, // body
  m: 24, // h3
  l: 28, // h2
  xl: 32, // h1
  lgt: 64, // amounts
}

const mobileFont = {
  xsm: 10, // tiny
  sm: 13, // caption
  root: 15, // body
  m: 16, // h3
  l: 18, // h2
  xl: 20, // h1
  lgt: 24, // amounts
}

const mobileLineHeight = {
  xsm: 16, // tiny
  sm: 20, // caption
  root: 24, // body
  m: 22, // h3
  l: 24, // h2
  xl: 28, // h1
  lgt: 32, // amounts
}

const themeV2 = {
  primary,
  secondary,
  staticColors,
  desktopFont,
  mobileFont,
  desktopLineHeight,
  mobileLineHeight,
}

export default themeV2
