import styled from 'styled-components/macro'

import { pxToRem } from 'V2/helpers/pxToRem'

export const IconDiv = styled.span<{ disabled?: boolean }>`
  position: absolute;
  right: 14px;
  top: 50%;
  height: 24px;
  width: 24px;
  transform: translateY(-50%);
  padding-left: ${pxToRem(4)};
  background: ${({ disabled }): string => (disabled ? 'none' : 'white')};
`
