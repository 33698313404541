/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios'
import moment from 'moment'

import { apiRoutes } from 'constants/apiRoutes'
import { catchErrorHandler } from 'V2/helpers/catchErrorHandler'
import { decryptLocalData, encryptLocalData } from 'V2/helpers/encryptDecryptLocalData'
import { domesticCurrencyCountryPair } from 'V2/hooks/useAddRecipient'
import { Logger } from './logger'

const FILE_NAMESPACE = 'fetchValidationSchema'

// check if the string from sessionStorage is valid schema or not
const isValidSchema = (str: string, destinationCurrency: string): boolean => {
  const instanceOfStoredSchema = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any
  ): obj is {
    value: unknown
    createdAt: string
  } => {
    Logger(FILE_NAMESPACE).silly(
      'instanceOfStoredSchema',
      obj?.value?.title.indexOf(destinationCurrency) >= 0,
      Date.parse(obj?.createdAt)
    )
    return obj?.value?.title.indexOf(destinationCurrency) >= 0 && !isNaN(Date.parse(obj?.createdAt))
  }

  try {
    const testObj = JSON.parse(str)
    const returnFlag = instanceOfStoredSchema(testObj)
    Logger(FILE_NAMESPACE).silly('isValidSchema', returnFlag)
    return returnFlag
  } catch (err) {
    Logger(FILE_NAMESPACE).error(err)
    return false
  }
}

// check if the string from sessionStorage is fetched within 5minutes
const isExpiredSchema = (str: string): boolean => {
  try {
    const testObj = JSON.parse(str)
    const timeDiff = moment(new Date()).diff(testObj.createdAt, 'minutes')

    Logger(FILE_NAMESPACE).silly('isExpiredSchema', `${timeDiff} minute/s`, timeDiff > 5)

    return timeDiff > 5
  } catch (err) {
    Logger(FILE_NAMESPACE).error(err)
    return false
  }
}

// store schema in sessionStorage
const storeSchemaInSession = (
  destinationCurrency: string,
  payoutMethod: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any
): void => {
  try {
    sessionStorage.setItem(
      `validationSchema_${destinationCurrency}_${payoutMethod}`,
      encryptLocalData({
        value: schema,
        createdAt: new Date(),
      })
    )
  } catch (err) {
    Logger(FILE_NAMESPACE).error(err)
  }
}

// get schema from session storage
const getStoredSchemaInSession = (destinationCurrency: string, payoutMethod: string): string => {
  // session caching only for production environment
  if (process.env.NODE_ENV !== 'production') {
    return ''
  }

  const storedSchema = decryptLocalData(
    sessionStorage.getItem(`validationSchema_${destinationCurrency}_${payoutMethod}`)
  )

  if (
    !!storedSchema &&
    isValidSchema(storedSchema, destinationCurrency) &&
    !isExpiredSchema(storedSchema)
  ) {
    return storedSchema
  }

  return ''
}

export const fetchValidationSchema = async (
  destinationCurrency: string,
  destinationCountry: string,
  payoutMethod = 'LOCAL'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    // return schema found in sessionStorage if createdAt is <5min
    const storedSchema = getStoredSchemaInSession(destinationCurrency, payoutMethod)
    if (storedSchema) {
      Logger(FILE_NAMESPACE).silly('getStoredSchemaInSession', JSON.parse(storedSchema))
      return JSON.parse(storedSchema).value
    }

    // fetch only if no schema found in sessionStorage or schema createdAt is >5min
    const {
      data: {
        data: [schema],
      },
    } = await axios.get(apiRoutes.beneficiaryValidationSchema, {
      params: {
        destination_currency: destinationCurrency,
        payout_method: payoutMethod,
        destination_country: destinationCountry,
        remitter_account_type: 'corporate',
      },
    })

    storeSchemaInSession(destinationCurrency, payoutMethod, schema)

    return schema
  } catch (err) {
    return catchErrorHandler(err)
  }
}
export const fetchBeneCurrencies = async (
  destinationCountry: string,
  sourceCountry: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  try {
    // fetch only if no schema found in sessionStorage or schema createdAt is >5min
    const beneCurrencies = await axios
      .get(apiRoutes.metadata, {
        params: {
          entity: 'supported_corridors',
          destination_country: destinationCountry,
          source_country: sourceCountry,
        },
      })
      .then((response) => {
        const corridors = response.data.data
          .filter(
            (item: { payout_method: string; destination_currency: string }) =>
              (item.payout_method === 'LOCAL' || item.payout_method === 'SWIFT') &&
              !!domesticCurrencyCountryPair[item.destination_currency]
          )
          .map((item: { payout_method: string; destination_currency: string }) => ({
            payout_method: item.payout_method,
            destination_currency: item.destination_currency,
          }))

        return corridors
      })
    Logger(FILE_NAMESPACE).silly('corridors', beneCurrencies)

    return beneCurrencies
  } catch (err) {
    Logger(FILE_NAMESPACE).error('supportedCorridors', err)
    return catchErrorHandler(err)
  }
}
