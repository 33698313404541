import { Reducer } from 'react'

import { EditFlowActions } from 'store/actionTypes'

export interface EditFlowIntialState {
  isEditFlow?: boolean
}

const initialState: EditFlowIntialState = {
  isEditFlow: false,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editFlowReducer: Reducer<any, any> = (state = initialState, action) => {
  switch (action.type) {
    case EditFlowActions.EDIT_FLOW_VALUE:
      return {
        ...state,
        isEditFlow: action.payload,
      }
    default:
      return state
  }
}
