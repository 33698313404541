/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'

export const ShareholderIcon: React.FC = (): JSX.Element => {
  return (
    <>
      <path
        d="M21.75 11.25H12.75C11.9216 11.25 11.25 11.9216 11.25 12.75V21.75C11.25 22.5784 11.9216 23.25 12.75 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V12.75C23.25 11.9216 22.5784 11.25 21.75 11.25Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 14.25H20.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 17.25H20.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 20.25H16.5"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.75C7.65685 6.75 9 5.40685 9 3.75C9 2.09315 7.65685 0.75 6 0.75C4.34315 0.75 3 2.09315 3 3.75C3 5.40685 4.34315 6.75 6 6.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.492 9.57999C8.73592 8.9065 7.80122 8.46603 6.80052 8.31164C5.79982 8.15725 4.77583 8.29553 3.85192 8.70981C2.92802 9.1241 2.14363 9.79672 1.59326 10.6466C1.0429 11.4965 0.75005 12.4874 0.75 13.5V15.75H3L3.75 23.25H8.25L8.508 20.665"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export const ShareholderIconV2: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2690_55687)">
      <path
        d="M21.75 11.25H12.75C11.9216 11.25 11.25 11.9216 11.25 12.75V21.75C11.25 22.5784 11.9216 23.25 12.75 23.25H21.75C22.5784 23.25 23.25 22.5784 23.25 21.75V12.75C23.25 11.9216 22.5784 11.25 21.75 11.25Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 14.25H20.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 17.25H20.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 20.25H16.5"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.75C7.65685 6.75 9 5.40685 9 3.75C9 2.09315 7.65685 0.75 6 0.75C4.34315 0.75 3 2.09315 3 3.75C3 5.40685 4.34315 6.75 6 6.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.492 9.57974C8.73592 8.90626 7.80122 8.46579 6.80052 8.31139C5.79982 8.157 4.77583 8.29528 3.85192 8.70957C2.92802 9.12386 2.14363 9.79648 1.59326 10.6464C1.0429 11.4963 0.75005 12.4872 0.75 13.4997V15.7497H3L3.75 23.2497H8.25L8.508 20.6647"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_55687">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
