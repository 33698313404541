// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const LogoutIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <g clipPath="url(#clip0_62_2727)">
      <path
        d="M7.5 12.004H23.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 15.754L23.25 12.004L19.5 8.254"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 16.5V21C15.7672 21.3796 15.6333 21.7505 15.3777 22.0316C15.1221 22.3127 14.7655 22.4811 14.386 22.5H2.11304C1.73375 22.4808 1.37746 22.3123 1.12206 22.0312C0.86666 21.7501 0.73291 21.3794 0.750044 21V3C0.732643 2.62054 0.866302 2.24964 1.12176 1.96851C1.37721 1.68737 1.73365 1.51891 2.11304 1.5H14.386C14.7655 1.51891 15.1221 1.68734 15.3777 1.96845C15.6333 2.24955 15.7672 2.62044 15.75 3V7.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_62_2727">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
)
