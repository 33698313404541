import { Reducer } from 'react'

import { FlowType } from 'Onboarding/pages/corridors/au/AuForm'
import { CurrentFormDataValue } from 'store/actionTypes'

export interface CurrentFormDataIntialState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contactDetails: any
  businessRegistrationNumber?: string
  _flow: FlowType
  _registeredCountry?: string
  editFlow?: {
    isEditFlow?: boolean
  }
  currentLocation?: {
    currentStepName?: string
    currentScreen?: number
  }
  businessDetails?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
}
// The structure needs to be refactored
const currentFormDataInitialState = {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const currentFormDataReducer: Reducer<any, any> = (
  state = currentFormDataInitialState,
  action
) => {
  switch (action.type) {
    case CurrentFormDataValue.UPDATE_CURRENT_FORM_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case CurrentFormDataValue.RESET_CURRENT_FORM_DATA:
      return currentFormDataInitialState

    default:
      return state
  }
}
