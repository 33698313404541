import { ThunkWithApi } from 'react-redux'
import { replace } from 'connected-react-router'
import { ActionCreator } from 'redux'

import { AddMoneyTypes, CreditCardTypes } from 'store/actionTypes'
import { resetCreditCard } from 'V2/actions/creditCard'
import { appRoutes } from 'V2/constants/appRoutes'
import { creditCardStatus } from 'V2/services/creditCardStatus'
import { Logger } from 'V2/services/logger'
import { paymentErrorHandling } from 'V2/services/paymentErrorHandling'

const FILE_NAMESPACE = 'redux.actions.addMoney'

export const setReturnUrlSuccessCta: ActionCreator<ThunkWithApi<void>> = (
  returnUrl: string,
  successPageCta: string
) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: AddMoneyTypes.SET_RETURN_URL,
      payload: { returnUrl, successPageCta },
    })
  }
}

export const setFundingAmount: ActionCreator<ThunkWithApi<void>> = (amount) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: AddMoneyTypes.SET_FUNDING_AMOUNT,
      payload: amount,
    })
  }
}

export const setSelectedCurrency: ActionCreator<ThunkWithApi<void>> = (selectedCurrency) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: AddMoneyTypes.SET_SELECTED_CURRENCY,
      payload: selectedCurrency,
    })
  }
}

export const updatePaymentMethod: ActionCreator<ThunkWithApi<void>> = (paymentMethod) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: AddMoneyTypes.UPDATE_PAYMENT_TYPE,
      payload: paymentMethod,
    })
  }
}

export const setAddMoneyCompletedStatus: ActionCreator<ThunkWithApi<void>> = (
  addMoneyCompleted: boolean
) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: AddMoneyTypes.ADD_MONEY_COMPLETED,
      payload: addMoneyCompleted,
    })
  }
}
export const updateCreditCardPaymentInitiatedStatus: ActionCreator<ThunkWithApi<void>> = (
  creditCardPaymentInitiated: boolean
) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: AddMoneyTypes.CREDIT_CARD_PAYMENT_INITIATED,
      payload: creditCardPaymentInitiated,
    })
  }
}
export const goBackStepFromAddMoney: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch, getState): Promise<void> {
    const { addMoney } = getState()
    dispatch(replace(addMoney.returnUrl ?? appRoutes.myAccounts))
    dispatch({
      type: AddMoneyTypes.RESET_STATE,
    })
  }
}
export const goBackToAddMoney: ActionCreator<ThunkWithApi<void>> = (
  ignoreAmountCurrencyReturnUrl?: boolean
) => {
  return async function (dispatch, getState): Promise<void> {
    dispatch(replace(appRoutes.addMoney))
    dispatch({
      type: ignoreAmountCurrencyReturnUrl
        ? AddMoneyTypes.RESET_STATE_EXCEPT_AMOUNT_CURRENCY_RETURNURL
        : AddMoneyTypes.RESET_STATE_EXCEPT_SELECTED_CURRENCY,
    })
  }
}
export const resetAddMoneyStateExceptSelectedCurrency: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch, getState): Promise<void> {
    dispatch({
      type: AddMoneyTypes.RESET_STATE_EXCEPT_SELECTED_CURRENCY,
    })
  }
}
export const resetAddMoney: ActionCreator<ThunkWithApi<void>> = (redirect: boolean) => {
  return async function (dispatch, getState): Promise<void> {
    dispatch({
      type: AddMoneyTypes.RESET_STATE,
    })
    if (redirect) dispatch(replace(appRoutes.myAccounts))
  }
}
export const addMoneyCreditCardStatus: ActionCreator<ThunkWithApi<void>> = (requestId?: string) => {
  return async function (dispatch, getState): Promise<void> {
    try {
      await creditCardStatus(requestId)
      dispatch(updateCreditCardPaymentInitiatedStatus(true))
      dispatch(setAddMoneyCompletedStatus(true))
      dispatch(resetCreditCard())
    } catch (error) {
      Logger(FILE_NAMESPACE).error('addMoneyCreditCardStatus', error)
      paymentErrorHandling({ error: error as Error, dispatch, displaySnackBar: true })
    } finally {
      dispatch({
        type: CreditCardTypes.LOADING_DONE,
      })
    }
  }
}
