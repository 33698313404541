// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { FC, forwardRef, useEffect, useState } from 'react'
import ReactSelect, {
  components as reactSelectComponents,
  createFilter,
  IndicatorProps,
  OptionProps,
  Props,
  StylesConfig,
} from 'react-select'
import { useTheme } from 'styled-components/macro'

import { floors } from 'V2/constants/floors'
import { less } from 'V2/helpers/mediaquery'
import { DropdownOption } from 'V2/interfaces/components/Atoms.interface'
import { BlurDiv, DropdownWrapper, Error, Label } from './Style'

const Dropdown: FC<Props<DropdownOption>> = forwardRef(
  ({
    width,
    label,
    errorMessage,
    components = {},
    styles,
    isSearchable = false,
    isClearable = false,
    isMultiple = false,
    id,
    ...dropdownProps
  }) => {
    const theme = useTheme()
    const { IndicatorSeparator } = components
    const {
      menu: customStyleMenu,
      option: customOption,
      valueContainer: customValueContainer,
      control: customControl,
      container: customContainer,
      ...restStyle
    } = styles || {}

    /**This states are required for label animation */
    const [optionSelected, setOptionSelected] = useState(false)
    const [inputFocused, setInputFocused] = useState(false)
    const [active, setActive] = useState(false)

    const CustomIndicatorSeparator = (prop: IndicatorProps<DropdownOption>): JSX.Element => {
      useEffect(() => {
        setOptionSelected(prop.hasValue || (prop.isFocused && isSearchable))
        setInputFocused(prop.isFocused)
        setActive(prop.selectProps?.menuIsOpen || false)
      }, [prop.hasValue, prop.isFocused, prop.selectProps.menuIsOpen])
      return IndicatorSeparator ? <IndicatorSeparator {...prop} /> : <></>
    }
    const CustomOptions = (prop: OptionProps<DropdownOption>): JSX.Element => {
      prop.innerProps.id = prop.data.id ? prop.data.id : `${id}_option_${prop.data.value}`
      return reactSelectComponents.Option ? <reactSelectComponents.Option {...prop} /> : <></>
    }
    const mobileMediaQuery = `@media ${less.lessThanMobile}`
    const customStyles: StylesConfig = {
      container: (base, state) => ({
        ...base,
        padding: '0 0 2px 0',
        marginLeft: '0',
        border: 0,
        ...(customContainer ? customContainer(base, state) : {}),
      }),
      control: (base, state) => {
        return {
          ...base,
          height: '48px',
          boxShadow: 'none',
          minWidth: '130px',
          background: state.isDisabled
            ? theme.revamp.primary.grey.lightest
            : theme.revamp.staticColors.white,
          border: `1px solid ${
            errorMessage
              ? theme.revamp.staticColors.redPepperCorn
              : state.isFocused
              ? theme.revamp.primary.blue.root
              : theme.revamp.primary.grey.root
          }`,
          '&:hover': {
            borderColor: errorMessage
              ? theme.revamp.staticColors.redPepperCorn
              : state.isFocused
              ? theme.revamp.primary.blue.root
              : theme.revamp.primary.grey.root,
          },
          ...(customControl ? customControl(base, state) : {}),
        }
      },
      menu: (base, state) => ({
        ...base,
        margin: '0',
        padding: '0',
        background: theme.revamp.staticColors.white,
        border: `2px solid ${theme.revamp.primary.grey.lighter}`,
        minWidth: '130px',
        borderRadius: '3px',
        zIndex: 100,
        [mobileMediaQuery]: {
          borderRadius: '2px',
          position: 'fixed',
          top: 'unset',
          bottom: 0,
          right: 0,
          left: 0,
        },
        //reusing styles from props
        ...(customStyleMenu ? customStyleMenu(base, state) : {}),
      }),
      menuList: (base: object) => ({
        ...base,
        margin: '0',
        padding: '0',
        [mobileMediaQuery]: {
          maxHeight: '40vh',
          borderRadius: '2px',
        },
        '::-webkit-scrollbar': {
          width: '4px',
          height: '0',
        },
        '::-webkit-scrollbar-track': {
          background: theme.revamp.primary.grey.lightest,
        },
        '::-webkit-scrollbar-thumb': {
          background: theme.revamp.primary.blue.dark,
        },
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected
          ? theme.revamp.primary.yellow.light
          : theme.revamp.staticColors.white,
        color: theme.revamp.staticColors.black,
        height: '48px',
        padding: '12px 16px',
        fontSize: '15px',
        margin: '0',
        '&:active': {
          backgroundColor: theme.revamp.staticColors.white,
        },
        '&:hover': {
          cursor: ` pointer`,
          backgroundColor: theme.revamp.primary.yellow.light,
        },
        ...(customOption ? customOption(base, state) : {}),
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: errorMessage
          ? theme.revamp.staticColors.redPepperCorn
          : theme.revamp.staticColors.black,
        transform: (state.selectProps.menuIsOpen && 'rotate(180deg)') || '',
      }),
      placeholder: (base, state) => {
        return {
          ...base,
          display: state.isFocused || !label ? 'block' : 'none',
          margin: '0 4px',
          color: theme.revamp.primary.grey.root,
          fontSize: '15px',
        }
      },
      valueContainer: (base, state) => ({
        ...base,
        margin: '0',
        padding: '12px 11px',
        overflow: 'hidden',
        zIndex: state.hasValue ? floors.ground : floors.second,
        ...(customValueContainer ? customValueContainer(base, state) : {}),
      }),
      singleValue: (base: object) => ({
        ...base,
        margin: '0',
        padding: '0 4px',
        height: '24px',
        lineHeight: '24px',
        fontSize: '15px',
      }),
      input: (base: object) => ({
        ...base,
        margin: '0',
        padding: '0 4px',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '24px',
        color: theme.revamp.staticColors.black,
      }),
      noOptionsMessage: (base: object) => ({
        ...base,
        textAlign: 'left',
        color: theme.revamp.staticColors.black,
        background: theme.revamp.primary.yellow.root,
      }),
      multiValue: (base: object) => ({
        ...base,
        backgroundColor: theme.revamp.primary.yellow.light,
      }),
      ...restStyle,
    }

    return (
      <>
        <DropdownWrapper width={width}>
          {active && <BlurDiv />}
          {label && (
            <Label
              hasValue={optionSelected}
              error={(errorMessage && errorMessage?.length > 0) || false}
              isFocused={inputFocused}
            >
              {label}
            </Label>
          )}
          <ReactSelect
            id={id}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMultiple}
            defaultValue={
              (dropdownProps.defaultValue as DropdownOption)?.value
                ? dropdownProps.defaultValue
                : null
            }
            components={{
              ...components,
              Option: components.Option || CustomOptions,
              IndicatorSeparator: CustomIndicatorSeparator,
            }}
            styles={customStyles}
            menuIsOpen={(dropdownProps.options?.length ?? 0) > 0 ? undefined : false}
            {...dropdownProps}
            filterOption={createFilter({ matchFrom: 'start' })}
          />
          {errorMessage && <Error>{errorMessage}</Error>}
        </DropdownWrapper>
      </>
    )
  }
)
export default Dropdown
