import { Reducer } from 'redux'

import { SnackbarItem } from 'interfaces/redux/Reducers.interface'
import { AuthTypes, SnackbarTypes } from 'store/actionTypes'

const InitialState: SnackbarItem[] = []

const snackbarReducer: Reducer<SnackbarItem[]> = (state = InitialState, action) => {
  switch (action.type) {
    case SnackbarTypes.SNACKBAR_MESSAGE: {
      return [...state, action.payload]
    }
    case SnackbarTypes.SNACKBAR_DISMISS: {
      return [...state].filter((toast) => toast.id !== action.payload)
    }
    case AuthTypes.USER_LOGOUT:
    case SnackbarTypes.SNACKBAR_DISMISS_ALL: {
      return []
    }
    default:
      return state
  }
}

export { snackbarReducer as snackbar }
