// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import { CloseIcon } from 'V2/components/iconPaths/Custom/BasicIcons/CloseIcon'
import { LinkBankAccountIcon } from 'V2/components/iconPaths/Custom/IllustrationsIcons/LinkBankAccountIcon'
import { Modal } from 'V2/components/molecules/Modal/Modal'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import useDeviceWidth from 'V2/hooks/useDeviceWidth'
import { AddBankAccountBannerProps } from 'V2/interfaces/components/Shared.interface'
import {
  CloseBtnWrapper,
  HeadingWrapper,
  LinkNowButton,
  ModalWrapper,
  Screen,
  SubTextWrapper,
  WebImg,
} from './Styles'

const AddBankAccountBanner: React.FC<AddBankAccountBannerProps> = ({
  openModal,
  closeBanner,
  body,
  header,
  btnTitle,
  onSubmitButtonHandler,
}) => {
  const deviceSize = useDeviceWidth()
  return (
    <ModalWrapper openModal={openModal} data-testid="popup">
      <Modal
        hideHeader
        children={
          <Screen height={`${deviceSize.height}px`}>
            <CloseBtnWrapper
              id={automationIdSelectors.addBankBanner.closeButton}
              onClick={(): void => closeBanner()}
            >
              <CustomIcon children={<CloseIcon />} defaultSize={24} viewBox="0 0 24 24" />
            </CloseBtnWrapper>
            <WebImg>
              <CustomIcon
                children={<LinkBankAccountIcon />}
                defaultSize={120}
                viewBox={'0 0 120 120'}
              ></CustomIcon>
            </WebImg>
            <HeadingWrapper>
              <Typography
                type="h1"
                weight="semibold"
                id={automationIdSelectors.promoBanner.headerText}
              >
                {header}
              </Typography>
            </HeadingWrapper>
            <SubTextWrapper>
              <Typography
                type="body"
                weight="medium"
                id={automationIdSelectors.promoBanner.subtext}
              >
                {body}
              </Typography>
            </SubTextWrapper>

            <LinkNowButton
              id={automationIdSelectors.addBankBanner.linkNowButton}
              onClick={onSubmitButtonHandler}
              type="button"
              variant="primary"
              size={'large'}
              title={btnTitle}
            />
          </Screen>
        }
      />
    </ModalWrapper>
  )
}

export default AddBankAccountBanner
