// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const allowedLegalentitiesAutoSweep = ['MY', 'HK', 'ID', 'JP']
export const allowedLegalEntitiesBankAccounts = ['MY', 'HK', 'ID', 'SG', 'AU', 'US', 'JP']

export const accountDetailsSummary: { [countryCode: string]: string } = {
  MY: 'We will transfer your funds to your linked bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  ID: 'We will transfer your funds to your linked bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  HK: 'We will transfer your funds to your linked bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  AU: 'You can manually withdraw your funds to your linked bank account at any time.',
  US: 'You can manually withdraw your funds to your linked bank account at any time.',
  SG: 'You can manually withdraw your funds to your linked bank account at any time.',
  JP: 'We will transfer your funds to your linked bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
}

export const savedAccountDetailsSummary: { [countryCode: string]: string } = {
  MY: 'Any funds will be transferred to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  ID: 'Any funds will be transferred to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  HK: 'Any funds will be transferred to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  AU: 'You can manually withdraw your funds to your linked bank account at any time.',
  US: 'You can manually withdraw your funds to your linked bank account at any time.',
  SG: 'You can manually withdraw your funds to your linked bank account at any time.',
  JP: 'Any funds will be transferred to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
}

export const baseCurrencySummary: { [countryCode: string]: string } = {
  HK: 'Tailor your account settings by selecting your base currency below. Your selection will become the default currency for all outgoing transactions.',
  SG: 'Tailor your account settings by selecting your base currency below. Your selection will become the default currency for all outgoing transactions.',
}

export const withdrawMoneySelectBankAccountSummary: { [countryCode: string]: string } = {
  SG: 'Make sure the linked bank account belongs to you. You can manually withdraw your funds to your linked bank account at any time.',
  AU: 'Make sure the linked bank account belongs to you. You can manually withdraw your funds to your linked bank account at any time.',
  US: 'Make sure the linked bank account belongs to you. You can manually withdraw your funds to your linked bank account at any time.',
  MY: 'Make sure the linked bank account belongs to you. We will transfer your funds to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  ID: 'Make sure the linked bank account belongs to you. We will transfer your funds to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  HK: 'Make sure the linked bank account belongs to you. We will transfer your funds to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
  JP: 'Make sure the linked bank account belongs to you. We will transfer your funds to this bank account at the end of each day. You can also choose to manually withdraw your funds at any time.',
}

export const autoSweepBankAccountDetails = {
  BankDetailHeader: 'Select bank account',
  BankDetailDesc: 'Make sure the bank account belongs to your company.',
}

export const autoSweepBankAccountDesc = (
  <>
    Make sure the bank account belongs to your company.
    <br />
    We will transfer your funds to this bank account at the end of each day, or you can manually
    withdraw now.
  </>
)

export const invalidAccountMesssage = ['BSB', 'ACH', 'SWIFT', 'beneficiary_account_number']
