import { Reducer } from 'redux'

import { CreditCard } from 'interfaces/redux/Reducers.interface'
import { CreditCardTypes, MultiPaymentTypes } from 'store/actionTypes'

const initialState = {
  creditCardDetails: { cardNumber: '', cvv: '', expiryDate: '' },
  cardSelectedStatus: { isSaveCard: true, isAvailableCardSelected: false },
  saveCardDetails: { cardId: '', selectedCard: {}, useSavedCard: false, cvv: '' },
  creditCardErrors: { cardNumber: '', cvv: '', expiryDate: '' },
  currentCardStep: 'savedCard',
  loading: false,
  allSavedCards: [],
}

const creditCardReducer: Reducer<CreditCard> = (state = initialState, action) => {
  switch (action.type) {
    case CreditCardTypes.SAVED_CARD_DETAILS:
      return { ...state, saveCardDetails: action.payload }
    case CreditCardTypes.CREDIT_CARD_DETAILS:
      return { ...state, creditCardDetails: action.payload }
    case CreditCardTypes.CREDIT_CARD_NUMBER:
      return {
        ...state,
        creditCardDetails: { ...state.creditCardDetails, cardNumber: action.payload },
      }
    case CreditCardTypes.EXPIRY_DATE:
      return {
        ...state,
        creditCardDetails: { ...state.creditCardDetails, expiryDate: action.payload },
      }
    case CreditCardTypes.CVV_NUMBER:
      return {
        ...state,
        creditCardDetails: { ...state.creditCardDetails, cvv: action.payload },
      }
    case CreditCardTypes.SELECT_SAVED_CARD:
      return {
        ...state,
        cardSelectedStatus: { ...state.cardSelectedStatus, isSaveCard: action.payload },
      }
    case CreditCardTypes.SELECT_AVAILABLE_CARD:
      return {
        ...state,
        cardSelectedStatus: {
          ...state.cardSelectedStatus,
          isAvailableCardSelected: action.payload,
        },
      }
    case CreditCardTypes.CREDIT_CARD_ERRORS:
      return { ...state, creditCardErrors: action.payload }
    case CreditCardTypes.CARD_NUMBER_ERROR:
      return {
        ...state,
        creditCardErrors: {
          ...state.creditCardErrors,
          cardNumber: action.payload,
        },
      }
    case CreditCardTypes.CVV_NUMBER_ERROR:
      return {
        ...state,
        creditCardErrors: {
          ...state.creditCardErrors,
          cvv: action.payload,
        },
      }
    case CreditCardTypes.EXPIRY_DATE_ERROR:
      return {
        ...state,
        creditCardErrors: {
          ...state.creditCardErrors,
          expiryDate: action.payload,
        },
      }
    case CreditCardTypes.LOADING_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      }
    case CreditCardTypes.LOADING_DONE:
      return {
        ...state,
        loading: false,
      }
    case CreditCardTypes.CURRENT_CARD_STEP:
      return { ...state, currentCardStep: action.payload }
    case CreditCardTypes.GET_ALL_SAVED_CARDS_REQUEST:
      return {
        ...state,
        loading: true,
        allSavedCards: [],
      }
    case CreditCardTypes.GET_ALL_SAVED_CARDS_SUCCESS:
      return {
        ...state,
        allSavedCards: action.payload,
        loading: false,
      }
    case CreditCardTypes.GET_ALL_SAVED_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case CreditCardTypes.CREDITCARD_STATEMENT_NARRATIVE:
      return {
        ...state,
        creditCardStatementNarative: action.payload,
      }
    // reset credit card details on all these dispatch events
    case MultiPaymentTypes.CREATE_PAYOUT_REQUEST:
    case MultiPaymentTypes.CREATE_PAYOUT_FAILURE:
    case MultiPaymentTypes.UPDATE_PAYMENT_STEP:
    case MultiPaymentTypes.RESET_STATE:
    case CreditCardTypes.RESET_STATE:
      return initialState
    default:
      return state
  }
}

export { creditCardReducer as creditCard }
