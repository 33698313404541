/* eslint-disable @typescript-eslint/camelcase */

import { ThunkWithApi } from 'react-redux'
import axios from 'axios'
import { replace } from 'connected-react-router'
import moment from 'moment'
import { ActionCreator, Dispatch } from 'redux'

import { apiRoutes } from 'constants/apiRoutes'
import {
  AccountData,
  Beneficiary,
  ExchangeValue,
  FxRate,
  PayoutAdditionalInfo,
  Reason,
  UserTrait,
} from 'interfaces/redux/Dependents.interface'
import { MultiPaymentTypes } from 'store/actionTypes'
import { getCurrentClientAccountList } from 'V2/actions/accounts'
import { defaultTimeout, snackBarAction } from 'V2/actions/snackbar'
import { createPayoutDepositMethod, paymentMode } from 'V2/constants/feeWithOptions'
import { bankTransfer, creditCard, fpxTransfer } from 'V2/constants/paymentMethodFilters'
import { ENABLE_CSAT_RATING, VFA_TRANSACTION_COUNT } from 'V2/constants/userClientDetails'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import { currencyDropdownOptions } from 'V2/helpers/currencyOptionsMapper'
import GA from 'V2/helpers/gaTracker'
import { SharedLabelValueCountryOption } from 'V2/interfaces/components/Shared.interface'
import { calculateExchange } from 'V2/services/calculateExchange'
import { calculateNetSettlement } from 'V2/services/calculateNetSettlement'
import { creditCardStatus } from 'V2/services/creditCardStatus'
import { updateTransactionPaymentId } from 'V2/services/fpxTransfer'
import { Logger } from 'V2/services/logger'
import { paymentErrorHandling } from 'V2/services/paymentErrorHandling'
import { creditCardPayment } from './creditCard'
import { updateFlagTrait } from './userClientDetails'

const FILE_NAMESPACE = 'redux.actions.multiPayments'

export const addBeneToList: ActionCreator<ThunkWithApi<void>> = (
  beneficiary: Beneficiary,
  reason: Reason,
  transactionBeneficiaryId: string,
  additionalInfo: PayoutAdditionalInfo,
  documentUpload: { [key: string]: File }
) => {
  return async function (dispatch, getState): Promise<void> {
    const { multiPayments, userClientDetails } = getState()
    // to prevent multiple dispatch calls for same bene
    const beneficiaryInList = multiPayments.transactions.some(
      (transaction) => transaction.transactionBeneficiaryId === transactionBeneficiaryId
    )
    const currencyOptions = userClientDetails.currencyOptions ?? []
    const baseCurrency = currencyDropdownOptions?.filter(
      (currency) => currency.value === userClientDetails.activeClient?.client_base_currency
    )[0]
    const sourceCurrency = multiPayments.sourceCurrency || baseCurrency || currencyOptions[0]
    // check if source and destination account for transfer exists
    const getAccount = (currency: string): AccountData | undefined => {
      return userClientDetails.currentClientAccounts?.find(
        (item: { currency: string }) => item.currency === currency
      )
    }

    const destinationAccount = getAccount(beneficiary.payouts[0].destination_currency)
    const sourceAccount = getAccount(sourceCurrency?.value ?? '')

    Logger(FILE_NAMESPACE).info('addBeneToList', {
      beneficiary,
      destinationAccount,
      sourceAccount,
      additionalInfo,
      documentUpload,
    })

    if (!sourceAccount || !sourceAccount?.is_active) {
      snackBarAction(
        'We are experiencing some issues with initiating this payment. Please contact support.',
        'error',
        defaultTimeout,
        dispatch
      )
      return
    }

    if (destinationAccount && !destinationAccount?.is_active) {
      snackBarAction(
        'Destination currency account is not active. Please contact us for support.',
        'error',
        defaultTimeout,
        dispatch
      )
      return
    }

    if (!destinationAccount) {
      const accountDetails = {
        client: userClientDetails.activeClient?._id,
        currency: beneficiary.payouts[0].destination_currency,
        is_active: true,
        is_default_account: true,
        label: beneficiary.payouts[0].destination_currency,
        disable_auto_bookfx: false,
        auto_bookfx_sequence: 0,
      }

      try {
        dispatch({
          type: MultiPaymentTypes.LOADING_IN_PROGRESS,
        })

        await axios.post(apiRoutes.createAccount, accountDetails)

        // After account creation, update client account list
        dispatch(getCurrentClientAccountList())
      } catch (error) {
        snackBarAction(
          "You don't have this destination currency account. Please contact us for support.",
          'error',
          defaultTimeout,
          dispatch
        )
        return
      } finally {
        dispatch({
          type: MultiPaymentTypes.LOADING_DONE,
        })
      }
    }

    // first step: add bene to list
    if (!beneficiaryInList) {
      const createNewTransactionObject = {
        beneficiary,
        reason,
        additionalInfo,
        transactionBeneficiaryId,
        isSourceInput: false,
        isDestinationInput: false,
        paymentReference: additionalInfo?.paymentReference,
      }

      dispatch({
        type: MultiPaymentTypes.ADD_BENE_TO_LIST,
        payload: createNewTransactionObject,
      })

      // second step: add bene destination currency to list for fx fetch
      const destinationCurrencyExists = multiPayments.destinationCurrencies.some(
        (destinationCurrency) => destinationCurrency === beneficiary.payouts[0].destination_currency
      )

      if (!destinationCurrencyExists) {
        dispatch({
          type: MultiPaymentTypes.ADD_DESTINATION_CURRENCY_TO_LIST,
          payload: beneficiary?.payouts[0].destination_currency,
        })
      }
    }
  }
}

export const deleteBeneFromList: ActionCreator<ThunkWithApi<void>> = (
  transactionBeneficiaryId: string
) => {
  return async function (dispatch, getState): Promise<void> {
    try {
      const { multiPayments } = getState()

      const destinationCurrency = multiPayments.transactions.find(
        (transaction) => transaction.transactionBeneficiaryId === transactionBeneficiaryId
      )?.beneficiary.payouts[0].destination_currency

      const destinationCurrencyNeeded = multiPayments.transactions.some(
        (transaction) =>
          transaction.transactionBeneficiaryId !== transactionBeneficiaryId &&
          transaction.beneficiary.payouts[0].destination_currency === destinationCurrency
      )

      if (!destinationCurrencyNeeded) {
        dispatch({
          type: MultiPaymentTypes.REMOVE_DESTINATION_CURRENCY_FROM_LIST,
          payload: destinationCurrency,
        })
      }

      dispatch({
        type: MultiPaymentTypes.DELETE_BENE_FROM_LIST,
        payload: transactionBeneficiaryId,
      })
    } catch (error) {
      Logger(FILE_NAMESPACE).error('deleteBeneFromList', error)
    }
  }
}

export const fetchAndHoldFxRates: ActionCreator<ThunkWithApi<void>> = (forcedRefresh?: boolean) => {
  return async function (dispatch, getState): Promise<void> {
    const { multiPayments, userClientDetails } = getState()
    const endTransactionTimer = moment(multiPayments.endTransactionTimer)
    const defaultCurrency = userClientDetails.defaultCurrency ?? { value: 'USD' }

    const baseCurrency = currencyDropdownOptions?.filter(
      (currency) => currency.value === userClientDetails.activeClient?.client_base_currency
    )[0]

    const sourceCurrency = multiPayments.sourceCurrency || baseCurrency || defaultCurrency
    dispatch({
      type: MultiPaymentTypes.LOADING_IN_PROGRESS,
    })

    const computeFxRates = (currencies: string[], resetEndTimer: boolean): void => {
      const promises = currencies.map(async (destinationCurrency) => {
        // fetch fxRates
        return await axios
          .get(apiRoutes.fxRate, {
            params: {
              source_currency: sourceCurrency?.value,
              destination_currency: destinationCurrency,
            },
          })
          .then(async ({ data }) => {
            // lock fxrates
            const { data: lockFxData } = await axios.post(apiRoutes.lockfxRate, {
              rate_signature: data?.data?.rate_signature ?? null,
            })

            // check FxRates Interface in reducer to understand whats is done below
            return {
              ...data.data,
              ...lockFxData.data,
            }
          })
          .then((data: FxRate) => ({ success: true, data, error: undefined }))
          .catch((error) => ({
            success: false,
            data: {
              destination_currency: destinationCurrency,
            } as FxRate,
            error: paymentErrorHandling({ error, dispatch, displaySnackBar: true }),
          }))
      })

      Promise.all(promises).then((fxRates) => {
        dispatch({
          type: MultiPaymentTypes.FETCH_AND_LOCK_FXRATES,
          payload: {
            fxRates,
            resetEndTimer,
            endTransactionTimer: fxRates
              // find the hold_expiry_at which expires first
              .reduce((endDate, fxrate) => {
                if (
                  !!fxrate.success &&
                  !!fxrate?.data?.hold_expiry_at &&
                  moment(fxrate?.data?.hold_expiry_at).isBefore(endDate)
                ) {
                  return moment(fxrate?.data?.hold_expiry_at)
                }

                return endDate
              }, moment.utc().add(10, 'days')),
          },
        })
      })
    }

    // if new bene added to list after going back
    if (endTransactionTimer.isAfter(moment()) && !forcedRefresh) {
      const fxRateNeeded = multiPayments.transactions.reduce((acc, transaction) => {
        if (!transaction.fxRate && !transaction.error?.disableInput) {
          return [...acc, transaction.beneficiary.payouts[0].destination_currency]
        }
        return acc
      }, [] as string[])
      computeFxRates(fxRateNeeded, false)
      return
    }

    // fresh fxrates fetch
    computeFxRates(multiPayments.destinationCurrencies, true)
  }
}
export const disableInputByBeneficiaryId: ActionCreator<ThunkWithApi<void>> = (
  transactionBeneficiaryId: string,
  isSourceInput: boolean,
  isDestinationInput: boolean
) => {
  return async function (dispatch, getState): Promise<void> {
    const { multiPayments } = getState()
    const beneficiaryInList = multiPayments.transactions.find(
      (transaction) => transaction.transactionBeneficiaryId === transactionBeneficiaryId
    )

    if (beneficiaryInList) {
      Logger(FILE_NAMESPACE).silly('disableInputByBeneficiaryId', {
        transactionBeneficiaryId,
        isSourceInput,
        isDestinationInput,
      })

      dispatch({
        type: MultiPaymentTypes.INPUT_STATUS_BY_BENEFICIARYID,
        payload: {
          transactionBeneficiaryId,
          isSourceInput,
          isDestinationInput,
        },
      })
    }
  }
}
export const setAmountByBeneficiaryId: ActionCreator<ThunkWithApi<void>> = (
  transactionBeneficiaryId: string,
  isUpdating: boolean,
  {
    sourceAmount,
    destinationAmount,
  }: {
    sourceAmount?: {
      value: number
      display: string
    }
    destinationAmount?: {
      value: number
      display: string
    }
  }
) => {
  return async function (dispatch, getState): Promise<void> {
    try {
      const { multiPayments, userClientDetails } = getState()
      const currencyOptions = userClientDetails.currencyOptions ?? []
      const baseCurrency = currencyDropdownOptions?.filter(
        (currency) => currency.value === userClientDetails.activeClient?.client_base_currency
      )[0]
      const sourceCurrency = multiPayments.sourceCurrency || baseCurrency || currencyOptions[0]
      const beneficiaryInList = multiPayments.transactions.find(
        (transaction) => transaction.transactionBeneficiaryId === transactionBeneficiaryId
      )

      dispatch({
        type: MultiPaymentTypes.LOADING_FEE_IN_PROGRESS,
      })

      if (beneficiaryInList) {
        Logger(FILE_NAMESPACE).silly('setAmountByBeneficiaryId:object', {
          transactionBeneficiaryId,
          sourceAmount: sourceAmount?.value,
          destinationAmount: destinationAmount?.value,
          isUpdating,
          fxRate: beneficiaryInList.fxRate ?? {
            fx_rate: 1,
            base_fx_rate: 1,
            destination_currency: beneficiaryInList.beneficiary.payouts[0].destination_currency,
            source_currency: sourceCurrency?.value ?? '',
            margin: 0,
            rate_signature: '',
            lock_expiry_at: '',
          },
        })

        const { feeAmount, feeId } = await calculateNetSettlement({
          sourceAmount: sourceAmount?.value,
          destinationAmount: destinationAmount?.value,
          fxRate: beneficiaryInList.fxRate ?? {
            fx_rate: 1,
            base_fx_rate: 1,
            destination_currency: beneficiaryInList.beneficiary.payouts[0].destination_currency,
            source_currency: sourceCurrency?.value ?? '',
            margin: 0,
            rate_signature: '',
            lock_expiry_at: '',
          },
          scope: 'redux:setAmountByBeneficiaryId',
          customErrorFunc: (err) => Logger(FILE_NAMESPACE).silly('customErrorFunc', err),
        })

        const exchangeValue: ExchangeValue = {
          ...calculateExchange({
            sourceAmount: sourceAmount?.value,
            destinationAmount: destinationAmount?.value,
            fxRate: beneficiaryInList.fxRate ?? {
              fx_rate: 1,
              base_fx_rate: 1,
              destination_currency: beneficiaryInList.beneficiary.payouts[0].destination_currency,
              source_currency: sourceCurrency?.value ?? '',
              margin: 0,
              rate_signature: '',
              lock_expiry_at: '',
            },
            feeAmount,
            topupPricing: userClientDetails.topUpPricing,
            scope: 'redux:setAmountByBeneficiaryId',
            customErrorFunc: (err) => Logger(FILE_NAMESPACE).silly('customErrorFunc', err),
            swiftFee:
              beneficiaryInList?.beneficiary?.swiftFeeDetails?.fees?.find(
                (item) =>
                  item?.type === beneficiaryInList?.beneficiary.swift_fee_payer &&
                  item.sourceCurrency === sourceCurrency.value
              )?.convertedAmount || 0,
          }),
          // bypass calculated value if input is updating
          ...(isUpdating && sourceAmount
            ? {
                sourceAmount,
              }
            : {}),
          ...(isUpdating && destinationAmount
            ? {
                destinationAmount,
              }
            : {}),
        }

        dispatch({
          type: MultiPaymentTypes.SET_AMOUNT_BY_BENEFICIARYID,
          payload: {
            exchangeValue,
            transactionBeneficiaryId,
            isSourceInput: !!(isUpdating && sourceAmount),
            isDestinationInput: !!(isUpdating && destinationAmount),
            lastEntered: sourceAmount ? 'send' : 'receive',
            feeId,
          },
        })
      }
    } catch (error) {
      dispatch({
        type: MultiPaymentTypes.SET_AMOUNT_ERROR_BY_BENEFICIARYID,
        payload: {
          transactionBeneficiaryId,
          error: paymentErrorHandling({ error: error as Error, dispatch, displaySnackBar: true }),
        },
      })
    } finally {
      dispatch({
        type: MultiPaymentTypes.LOADING_FEE_DONE,
      })
    }
  }
}

export const validatePayouts: ActionCreator<ThunkWithApi<void>> = (
  exchangeValue?: ExchangeValue,
  fxRate?: FxRate,
  lastEntered?: string,
  flow?: string,
  creditCardPayload?: {
    amount: number
    currency: string
    narrative: string
    redirectionUrl: string
    mccCode?: string
  },
  onValidationSuccess?: () => void
) => {
  return async function (dispatch, getState): Promise<void> {
    const { multiPayments, userClientDetails, ach } = getState()
    const currencyOptions = userClientDetails.currencyOptions ?? []
    const baseCurrency = currencyDropdownOptions?.filter(
      (currency) => currency.value === userClientDetails.activeClient?.client_base_currency
    )[0]
    const sourceCurrency = multiPayments.sourceCurrency || baseCurrency || currencyOptions[0]

    const getAccount = (currency: string): AccountData | undefined => {
      return userClientDetails.currentClientAccounts?.find(
        (item: { currency: string }) => item.currency === currency
      )
    }

    const externalReferenceId =
      multiPayments.transactions.length > 1 ? `BIZM_${moment().valueOf()}` : undefined

    dispatch({
      type: MultiPaymentTypes.LOADING_IN_PROGRESS,
    })

    const failsafeTransactionNumber: string[] = []

    const generateTransactionNumber = (idPrefix: string): string => {
      let transaction_number = `SME_${idPrefix}_${new Date().getTime()}`

      while (failsafeTransactionNumber.includes(transaction_number)) {
        transaction_number = `SME_${idPrefix}_${new Date().getTime() + 1}`
      }

      failsafeTransactionNumber.push(transaction_number)

      return transaction_number
    }

    const promises = multiPayments.transactions.map(async (transaction) => {
      try {
        const source_account = getAccount(
          fxRate
            ? fxRate.source_currency
            : transaction.fxRate?.source_currency ?? sourceCurrency.value
        )?.account_number
        const inrLimitJpmcthreshold = (): boolean => {
          if (transaction.beneficiary?.payouts[0].destination_currency === 'INR') {
            const value = exchangeValue
              ? exchangeValue.destinationAmount.value ?? 0
              : transaction.exchangeValue?.destinationAmount.value ?? 0
            return value > 1500000
          } else {
            return false
          }
        }

        const payoutObject = {
          source: 'API',
          clientId: userClientDetails.activeClient?._id,
          transaction: {
            statement_narrative: transaction.additionalInfo?.paymentReference
              ? transaction.additionalInfo?.paymentReference
              : (userClientDetails.activeClient?.client_name || '').replace(/[^a-zA-Z\s]/g, ''),
            source_account,
            transaction_number: generateTransactionNumber(`${source_account || ''}`),
            source_currency: fxRate ? fxRate.source_currency : transaction.fxRate?.source_currency,
            destination_currency: fxRate
              ? fxRate.destination_currency
              : transaction.fxRate?.destination_currency,
            local_conversion_currency: fxRate
              ? fxRate.destination_currency
              : transaction.fxRate?.destination_currency,
            purpose_code: transaction.reason?.instarem_purpose_code ?? '',
            ...(transaction.lastEntered === 'send' || lastEntered === 'send'
              ? {
                  source_amount: exchangeValue
                    ? exchangeValue.sourceAmount.value
                    : transaction.exchangeValue?.sourceAmount.value,
                  fee_payer: 'BEN',
                }
              : {
                  destination_amount: exchangeValue
                    ? exchangeValue.destinationAmount.value
                    : transaction.exchangeValue?.destinationAmount.value,
                  fee_payer: 'OUR',
                }),
            destination_account: getAccount(
              fxRate ? fxRate.destination_currency : transaction.fxRate?.destination_currency ?? ''
            )?.account_number,
            payout_options: {
              fx_hold_id: fxRate ? fxRate?.fx_hold_id : transaction.fxRate?.fx_hold_id,
              deduct_amount: exchangeValue
                ? exchangeValue.totalFeeAmount.value
                : transaction.exchangeValue?.totalFeeAmount.value,
            },
            ...(transaction.beneficiary?.swift_fee_payer
              ? {
                  swift_fee_payer:
                    transaction.beneficiary?.swiftFeeDetails.swiftDisclaimer === 'BOTH' &&
                    !transaction.beneficiary?.swiftFeeDetails.swiftCorridor
                      ? 'SHA'
                      : transaction.beneficiary?.swift_fee_payer,
                }
              : { swift_fee_payer: 'ACO' }),
            remitter: {
              account_type: 'Company',
              beneficiary_relationship: 'SELF',
              source_of_income: 'Cross border remittence',
              given_name: true,
              source_of_funds: '',
              name: userClientDetails.activeClient?.client_name,
              bank_account_number: `${
                multiPayments.paymentMethod === 'ach'
                  ? ach.selectedBank.accountDetails.accountNumber
                  : getAccount(
                      fxRate ? fxRate.source_currency : transaction.fxRate?.source_currency ?? ''
                    )?.account_number
              }`,
              identification_type: userClientDetails.activeClient?.identification_type ?? '',
              identification_number: userClientDetails.activeClient?.identification_number ?? '',
              country_code: userClientDetails.activeClient?.client_country ?? '',
              address: userClientDetails.activeClient?.client_address ?? '',
              contact_number: userClientDetails.activeClient?.supplier_phone ?? '',
              city: userClientDetails.activeClient?.client_city ?? '',
              postcode: userClientDetails.activeClient?.client_pincode ?? '',
              state: userClientDetails.activeClient?.client_state ?? '',
              purpose_code: transaction.reason?.instarem_purpose_code ?? '',
              ...(transaction.beneficiary?.payouts[0].beneficiary_country_code === 'KR'
                ? {
                    nationality: userClientDetails.activeClient?.client_country ?? 'US',
                  }
                : {}),
            },
            beneficiary: {
              name: transaction.beneficiary?.beneficiary_group_name ?? '',
              address: transaction.beneficiary?.payouts[0].beneficiary_address ?? '',
              city: transaction.beneficiary?.payouts[0].beneficiary_city ?? '',
              country_code: transaction.beneficiary?.payouts[0].beneficiary_country_code ?? '',
              email:
                transaction.beneficiary?.beneficiary_email ??
                transaction.beneficiary?.payouts[0].beneficiary_email ??
                '',
              account_type: transaction.beneficiary?.beneficiary_account_type ?? 'Company',
              contact_number: transaction.beneficiary?.payouts[0].beneficiary_contact_number ?? '',
              contact_country_code:
                transaction.beneficiary?.payouts[0].beneficiary_contact_country_code ?? '',
              state: transaction.beneficiary?.payouts[0].beneficiary_state ?? '',
              postcode: transaction.beneficiary?.payouts[0].beneficiary_postcode ?? '',
              account_number: transaction.beneficiary?.payouts[0].beneficiary_account_number ?? '',
              bank_name: transaction.beneficiary?.payouts[0].beneficiary_bank_name ?? '',
              bank_code: transaction.beneficiary?.payouts[0].beneficiary_bank_code ?? '',
              relationship:
                transaction.beneficiary?.payouts[0].remitter_beneficiary_relationship ?? '',
              identification_type:
                transaction.beneficiary?.payouts[0].beneficiary_identification_type ?? null,
              identification_value:
                transaction.beneficiary?.payouts[0].beneficiary_identification_value ?? null,
              bank_account_type:
                transaction.beneficiary?.payouts[0].beneficiary_bank_account_type ?? '',
            },
            routing_code_type_1: transaction.beneficiary?.payouts[0].routing_code_type_1 ?? '',
            routing_code_value_1: transaction.beneficiary?.payouts[0].routing_code_value_1 ?? '',
            // if routing_code_type_2 is available
            ...(transaction.beneficiary?.payouts[0].routing_code_type_2
              ? {
                  routing_code_type_2: transaction.beneficiary?.payouts[0].routing_code_type_2,
                  routing_code_value_2: transaction.beneficiary?.payouts[0].routing_code_value_2,
                }
              : {}),
            // if routing_code_type_3 is available
            ...(transaction.beneficiary?.payouts[0].routing_code_type_3
              ? {
                  routing_code_type_3: transaction.beneficiary?.payouts[0].routing_code_type_3,
                  routing_code_value_3: transaction.beneficiary?.payouts[0].routing_code_value_3,
                }
              : {}),
            // INR bene thru JPMC for HDFC and limit check
            ...((transaction.beneficiary?.payouts[0].beneficiary_bank_name === 'HDFC BANK' &&
              ['IR01809', 'IR01801', 'IR01805'].includes(
                transaction.reason?.instarem_purpose_code
              )) ||
            inrLimitJpmcthreshold()
              ? {
                  client_preferences: {
                    clientType: null,
                    applicationFeeType: 'SHA',
                    exclusive_routing_partners: ['jpmcPartner'],
                    preferredPartnerType: 'any',
                    nextValueDate: 'no',
                  },
                }
              : {}),

            additional_info: {
              ...transaction.additionalInfo,
              remitter_bank_code: `${
                multiPayments.paymentMethod === 'ach'
                  ? ach.selectedBank?.accountDetails?.routingValue ?? ''
                  : ''
              }`,
            },
            deposit_method: createPayoutDepositMethod[multiPayments?.paymentMethod ?? 'creditCard'],
            external_reference_id: externalReferenceId,
          },
        }
        await axios.post(apiRoutes.validatePayout, payoutObject)

        dispatch({
          type: MultiPaymentTypes.VALIDATE_PAYOUT_REQUEST,
          payload: {
            validatePayout: payoutObject,
            transactionBeneficiaryId: transaction.transactionBeneficiaryId,
          },
        })

        return true
      } catch (error) {
        dispatch({
          type: MultiPaymentTypes.VALIDATE_PAYOUT_FAILURE,
          payload: {
            transactionBeneficiaryId: transaction.transactionBeneficiaryId,
            error: paymentErrorHandling({ error: error as Error, dispatch, displaySnackBar: true }),
          },
        })

        return false
      }
    })

    Promise.all(promises)
      .then((promisesReturn) => {
        const proceedToNextPage = promisesReturn.reduce((acc, flag) => (flag ? acc : false), true)
        if (proceedToNextPage) {
          dispatch({
            type: MultiPaymentTypes.VALIDATE_PAYOUT_BATCH_SUCCESS,
            payload: exchangeValue,
          })
          if (flow === 'individualSend') {
            if (multiPayments?.paymentMethod === 'creditCard') {
              dispatch(creditCardPayment(creditCardPayload, makePayouts))
            } else if (multiPayments?.paymentMethod !== fpxTransfer) {
              dispatch(makePayouts())
            }
          }
          onValidationSuccess && onValidationSuccess()
        } else
          dispatch({
            type: MultiPaymentTypes.VALIDATE_PAYOUT_BATCH_FAILURE,
          })
      })
      .catch((error) => {
        // extreme edge case handling
        // unlikely case as promises is under try catch block already
        Logger(FILE_NAMESPACE).error('validatePayouts', 'Promise.all', error)
      })
  }
}
export const makePayouts: ActionCreator<ThunkWithApi<void>> = (requestId?: string) => {
  return async function (dispatch, getState): Promise<void> {
    const { multiPayments, userClientDetails, ach } = getState()
    const isIndividual = window.location.href.includes('individualsend')
    let vfaTranCount =
      Number(
        userClientDetails.featureFlagConfiguration.userTraits.find(
          (item: UserTrait) => item.trait_key === VFA_TRANSACTION_COUNT
        )?.trait_value
      ) || 0
    const vfaCreatedList = userClientDetails.vfa.vfaEnabledList
    const sourceCurrency =
      multiPayments.transactions[0].validatePayout?.transaction.source_currency || ''

    const { paymentMethod, invoiceId } = multiPayments
    dispatch({
      type: MultiPaymentTypes.LOADING_IN_PROGRESS,
    })

    // main-try-catch
    try {
      if (paymentMethod === 'creditCard') {
        await creditCardStatus(requestId)
      }
      //to store transactionId's for fpx flow
      const transactionIdList: string[] = []
      const promises = multiPayments.transactions.map(async (transaction) => {
        try {
          if (transaction.validatePayout) {
            const payoutObject = transaction.validatePayout
            if (paymentMethod === 'ach') {
              payoutObject.transaction.ach_pull = true
              payoutObject.transaction.additional_info.remitter_bank_code =
                ach.selectedBank?.accountDetails?.routingValue ?? ''
            }
            payoutObject.transaction.payment_mode =
              paymentMethod === 'creditCard' ? 'INSTAREM' : paymentMode[paymentMethod]

            payoutObject.transaction.statement_narrative = transaction.additionalInfo
              ?.paymentReference
              ? transaction.additionalInfo?.paymentReference
              : (userClientDetails.activeClient?.client_name || '').replace(/[^a-zA-Z\s]/g, '')
            payoutObject.transaction.deposit_method =
              createPayoutDepositMethod[paymentMethod ?? 'creditCard']
            if (
              clientLegalEntityMapper(userClientDetails.activeClient?.client_legal_entity || '') ===
                'SG' &&
              paymentMethod === 'creditCard'
            ) {
              payoutObject.transaction.merchantCategoryCode =
                isIndividual && transaction.additionalInfo.subcategory
                  ? transaction.additionalInfo.subcategory.value
                  : '7399'
            }
            if (paymentMethod === 'creditCard') {
              payoutObject.transaction.stripe_id = transaction.stripe_id || ''
            }
            const createPayoutResponse = await axios
              .post(apiRoutes.createPayout, payoutObject)
              .then((res) => {
                transactionIdList.push(res.data.data.payment_id)
                dispatch(updateFlagTrait(ENABLE_CSAT_RATING, true))
                return res.data.data
              })
            const selectedInvoiceId = transaction.additionalInfo?.invoiceId || invoiceId
            if (selectedInvoiceId) {
              await axios.post(
                `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.invoiceMapping}`,
                null,
                {
                  params: {
                    clientId: userClientDetails.activeClient?._id || '',
                    pyId: createPayoutResponse?.payment_id,
                    invoiceId: selectedInvoiceId,
                  },
                }
              )
            }
            dispatch({
              type: MultiPaymentTypes.CREATE_PAYOUT_REQUEST,
              payload: {
                validatePayout: payoutObject,
                createPayoutResponse,
                transactionBeneficiaryId: transaction.transactionBeneficiaryId,
              },
            })
            GA.track('EVENT', {
              action: 'Transaction Initiated',
              category: 'Transaction Event',
              label: `Transaction Created ${createPayoutResponse?.payment_id}`,
            })
            if (paymentMethod === creditCard) {
              // update bpsp Transaction pricing in SMEBE
              await axios.post(
                `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.saveTransactionccPricing}`,
                {
                  transactionId: createPayoutResponse?.payment_id,
                  fee: userClientDetails.topUpPricing,
                  subCategory: transaction.additionalInfo?.subCategory?.label ?? '',
                }
              )
            }
          }
        } catch (error) {
          dispatch(loadingDone())
          dispatch({
            type: MultiPaymentTypes.CREATE_PAYOUT_FAILURE,
            payload: {
              transactionBeneficiaryId: transaction.transactionBeneficiaryId,
              error: paymentErrorHandling({
                error: error as Error,
                dispatch,
                displaySnackBar: true,
              }),
            },
          })
          throw error
        }
      })
      if (
        clientLegalEntityMapper(userClientDetails.activeClient?.client_legal_entity || '') ===
          'SG' &&
        paymentMethod === bankTransfer &&
        vfaCreatedList?.includes(sourceCurrency) &&
        vfaTranCount <= 4
      ) {
        dispatch(updateFlagTrait(VFA_TRANSACTION_COUNT, ++vfaTranCount))
      }
      await Promise.all(promises).then(() => {
        if (requestId && paymentMethod === fpxTransfer) {
          const clientId = userClientDetails.activeClient?._id || ''
          const data = {
            smeTransactionIDList: transactionIdList,
          }
          updateTransactionPaymentId(requestId, data, clientId)
        }
        dispatch(getCurrentClientAccountList())
        dispatch({
          type: MultiPaymentTypes.CREATE_PAYOUT_BATCH_COMPLETE,
        })
      })
    } catch (error) {
      Logger(FILE_NAMESPACE).error('makePayouts', 'main-try-catch', error)
      paymentErrorHandling({ error: error as Error, dispatch, displaySnackBar: true })
      dispatch(loadingDone())
      dispatch({
        type: MultiPaymentTypes.CREATE_PAYOUT_BATCH_FAILURE,
      })
    }
  }
}

export const loadingInProgress: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: MultiPaymentTypes.LOADING_IN_PROGRESS,
    })
  }
}

export const loadingDone: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: MultiPaymentTypes.LOADING_DONE,
    })
  }
}
export const updatePaymentStep: ActionCreator<ThunkWithApi<void>> = (step: number) => {
  return async function (dispatch: Dispatch): Promise<void> {
    dispatch({
      type: MultiPaymentTypes.UPDATE_PAYMENT_STEP,
      payload: step,
    })
  }
}
export const updateSourceCurrency: ActionCreator<ThunkWithApi<void>> = (
  option: SharedLabelValueCountryOption
) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: MultiPaymentTypes.UPDATE_SOURCE_CURRENCY,
      payload: option,
    })
  }
}
export const resetSendMoney: ActionCreator<void> = (redirectUrl: string) => {
  return (dispatch: Dispatch): void => {
    dispatch({
      type: MultiPaymentTypes.RESET_STATE,
    })
    if (redirectUrl) dispatch(replace(redirectUrl))
  }
}
export const updatePayoutMethod: ActionCreator<ThunkWithApi<void>> = (paymentMethod: string) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: MultiPaymentTypes.PAYMENT_METHOD,
      payload: paymentMethod,
    })
  }
}
export const updateReceiveCurrency: ActionCreator<ThunkWithApi<void>> = (
  option: SharedLabelValueCountryOption
) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: MultiPaymentTypes.UPDATE_RECEIVE_CURRENCY,
      payload: option,
    })
  }
}

export const updateAccountingData: ActionCreator<ThunkWithApi<void>> = (
  accountingData: {
    companyId: string
    billId: string
  } | null
) => {
  return (dispatch): void => {
    dispatch({
      type: MultiPaymentTypes.UPDATE_ACCOUNTING_DATA,
      payload: accountingData,
    })
  }
}

export const getInvoiceId: ActionCreator<ThunkWithApi<void>> = (invoiceId: string) => {
  return (dispatch): void => {
    dispatch({
      type: MultiPaymentTypes.GET_INVOICE_DATA,
      payload: invoiceId,
    })
  }
}
