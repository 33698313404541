import { History } from 'history'
import { Store } from 'redux'
import { DefaultTheme, GlobalStyleComponent, StyledComponent } from 'styled-components/macro'

import GlobalStylesV2 from 'styles/V2/globalStyledV2'
// SME V2
import { SpecialModalBackground as ModalBackGroundV2 } from 'V2/components/molecules/Modal/Styles'
import ErrorBoundaryV2 from 'V2/components/templates/ErrorBoundry/ErrorBoundary'
import { RoutesV2 } from 'V2/routes/routes'
import { axiosInstance as axiosInstanceV2 } from 'V2/services/axiosInstance'
import { BrowserLocation as BrowserLocationV2 } from 'V2/services/browserLocation'
import { Logger } from 'V2/services/logger'

const FILE_NAMESPACE = 'switchUiView'
// const V2_VERIONS_ENVIRONMENTS = ['qa', 'dev', 'development', 'preprod']
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const switchUiView = (): {
  GlobalStyles: GlobalStyleComponent<{}, DefaultTheme>
  Routes: React.FC<{}>
  SpecialModalBackground: StyledComponent<'div', DefaultTheme, {}, never>
  ErrorBoundary: typeof ErrorBoundaryV2
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  axiosInstance: (store: Store<any, any>) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  BrowserLocation: (history: History<unknown>, store: any) => void
} => {
  Logger(FILE_NAMESPACE).silly('REACT_APP_ENV', process.env.REACT_APP_ENV)
  //   NOTE: Enable following code to trigger old Ui
  //   const renderNewUI =
  //     sessionStorage.getItem('switch') === 'old'
  //       ? false
  //       : V2_VERIONS_ENVIRONMENTS.includes((process.env.REACT_APP_ENV || 'production').toLowerCase())

  //     if (renderNewUI) {
  return {
    GlobalStyles: GlobalStylesV2,
    Routes: RoutesV2,
    SpecialModalBackground: ModalBackGroundV2,
    ErrorBoundary: ErrorBoundaryV2,
    axiosInstance: axiosInstanceV2,
    BrowserLocation: BrowserLocationV2,
  }
}
