import { ThunkWithApi } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { ActionCreator } from 'redux'

import { apiRoutes } from 'constants/apiRoutes'
import { CurrentClientAccounts } from 'interfaces/redux/Dependents.interface'
import { AccountTypes } from 'store/actionTypes'

export const getCurrentClientAccountList: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch, getState): Promise<void> {
    try {
      dispatch({
        type: AccountTypes.LOADING,
        payload: true,
      })

      const { userClientDetails } = getState()
      const accountsData = await axios
        .get(apiRoutes.accountSummary, {
          params: {
            client: userClientDetails.activeClient?._id,
          },
        })
        .then((res) => res.data.accounts)
      dispatch({
        type: AccountTypes.ACCOUNT_ACCOUNT_LIST,
        payload: (accountsData as CurrentClientAccounts[]).filter((account) => !!account.is_active),
      })
    } catch (err) {
      dispatch({
        type: AccountTypes.ACCOUNT_ACCOUNT_LIST_ERROR,
        payload: {
          message: (err as AxiosError)?.response?.data?.message || null,
        },
      })
    } finally {
      dispatch({
        type: AccountTypes.LOADING,
        payload: false,
      })
    }
  }
}
