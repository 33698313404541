import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { CustomError } from './customError'
import { Logger } from './logger'

const FILE_NAMESPACE = 'services.creditCardStatus'
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const creditCardStatus = async (requestId?: string) => {
  if (!requestId) {
    throw new CustomError(
      'credit card transaction status not approved',
      new Error('credit card transaction status not approved')
    )
  }
  // send request to update requestId status on masspay-v2
  await axios.post(apiRoutes.postRequestId(requestId))
  // send request to fetch requestId status from masspay-v2
  const transactionStatus = await axios.get(apiRoutes.getRequestId(requestId))
  if (transactionStatus.data.status !== 'APPROVED') {
    Logger(FILE_NAMESPACE).error('makePayouts', `requestId=${requestId} is not approved`)
    throw new CustomError(
      'credit card transaction status not approved',
      new Error('credit card transaction status not approved'),
      transactionStatus
    )
  }
  return transactionStatus
}
