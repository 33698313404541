import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import {
  ServicesCalculateNetSettlement,
  ServicesNetSettlementAxiosRequestObj,
  ServicesNetSettlementAxiosResponseData,
} from 'V2/interfaces/Services.interface'
import { Logger } from './logger'

const FILE_NAMESPACE = 'calculateNetSettlement'

const fetchNetSettlementFees = (
  requestObj: ServicesNetSettlementAxiosRequestObj
): Promise<ServicesNetSettlementAxiosResponseData> =>
  axios.post(apiRoutes.netSettlementFees, requestObj).then((res) => res.data)

export const calculateNetSettlement = async ({
  sourceAmount = 0,
  destinationAmount = 0,
  fxRate,
  scope = 'default',
  customErrorFunc,
}: ServicesCalculateNetSettlement): Promise<{
  feeAmount: number
  feeCurrency: string
  feeId?: string
}> => {
  try {
    let netSettlementResponse

    Logger(FILE_NAMESPACE).silly(
      'debugger:input',
      {
        sourceAmount,
        destinationAmount,
        fxRate,
      },
      scope
    )

    if (sourceAmount > 0) {
      netSettlementResponse = await fetchNetSettlementFees({
        transactionType: 'funds_out',
        fundingType: 'third_party',
        sourceCurrency: fxRate.source_currency,
        destinationCurrency: fxRate.destination_currency,
        sourceAmount,
      })
    } else if (destinationAmount > 0) {
      netSettlementResponse = await fetchNetSettlementFees({
        transactionType: 'funds_out',
        fundingType: 'third_party',
        sourceCurrency: fxRate.source_currency,
        destinationCurrency: fxRate.destination_currency,
        holdFxId: fxRate.fx_hold_id,
        destinationAmount,
      })
    } else {
      return {
        feeAmount: 0,
        feeCurrency: fxRate.source_currency,
      }
    }

    Logger(FILE_NAMESPACE).silly(
      'debugger:return',
      {
        sourceAmount,
        destinationAmount,
        fxRate,
      },
      netSettlementResponse,
      scope
    )

    const { feeAmount, currency: feeCurrency, feeId } = netSettlementResponse

    return {
      feeAmount,
      feeCurrency,
      feeId,
    }
  } catch (error) {
    customErrorFunc(error as Error)
    throw error
  }
}
