// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const SettlorIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2716_76953)">
      <path
        d="M15.3086 19.5586C15.1015 19.5586 14.9336 19.3907 14.9336 19.1836C14.9336 18.9765 15.1015 18.8086 15.3086 18.8086"
        stroke="#F29100"
        strokeWidth="1.5"
      />
      <path
        d="M15.3086 19.5586C15.5157 19.5586 15.6836 19.3907 15.6836 19.1836C15.6836 18.9765 15.5157 18.8086 15.3086 18.8086"
        stroke="#F29100"
        strokeWidth="1.5"
      />
      <path
        d="M19.0864 17.7055L22.7744 14.0185C22.9254 13.8675 23.0452 13.6882 23.127 13.4909C23.2087 13.2936 23.2507 13.0821 23.2507 12.8685C23.2507 12.655 23.2087 12.4435 23.127 12.2462C23.0452 12.0488 22.9254 11.8696 22.7744 11.7185C22.6234 11.5675 22.4441 11.4477 22.2468 11.366C22.0495 11.2843 21.838 11.2422 21.6244 11.2422C21.4108 11.2422 21.1993 11.2843 21.002 11.366C20.8047 11.4477 20.6254 11.5675 20.4744 11.7185L16.7904 15.4095C15.9221 15.0695 14.9632 15.0387 14.0749 15.3224C13.1865 15.6061 12.4229 16.1869 11.9124 16.9673C11.4019 17.7477 11.1756 18.68 11.2715 19.6076C11.3674 20.5352 11.7797 21.4014 12.4391 22.0608C13.0985 22.7202 13.9648 23.1325 14.8924 23.2285C15.8199 23.3244 16.7523 23.0981 17.5326 22.5875C18.313 22.077 18.8938 21.3134 19.1775 20.4251C19.4612 19.5368 19.4304 18.5779 19.0904 17.7095L19.0864 17.7055Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.75C7.65685 6.75 9 5.40685 9 3.75C9 2.09315 7.65685 0.75 6 0.75C4.34315 0.75 3 2.09315 3 3.75C3 5.40685 4.34315 6.75 6 6.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.032 11.999C10.6722 10.7898 9.88909 9.75057 8.82587 9.07152C7.76266 8.39247 6.49047 8.11898 5.24212 8.3011C3.99377 8.48321 2.85274 9.10876 2.02784 10.0633C1.20293 11.0178 0.749317 12.2374 0.750001 13.499V15.749H3L3.75 23.249H8.25L8.775 17.999"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2716_76953">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
