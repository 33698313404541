import GA4 from 'react-ga4'

import { InteractionParamsType } from 'Onboarding/interfaces/Services.interface'

export default class Tracker {
  static userId = ''
  static isProduction = false

  static create = (trackingId: string, isProduction: boolean, debugMode = false): void => {
    Tracker.isProduction = isProduction
    trackingId &&
      GA4.initialize(trackingId, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        gaOptions: { siteSpeedSampleRate: 100, debug_mode: debugMode },
      })
  }

  static track = (
    interactionType: 'PAGEVIEW' | 'EVENT',
    interactionParams: InteractionParamsType
  ): void => {
    if (Tracker.isProduction) {
      const params: { userId?: string; page: string } = {
        page: window.location.href,
      }
      if (Tracker.userId) {
        params.userId = Tracker.userId
      }
      switch (interactionType.toUpperCase()) {
        case 'PAGEVIEW': {
          GA4.set(params)
          GA4.send({ hitType: 'pageview' })
          break
        }
        case 'EVENT': {
          GA4.set(params)
          const { action = '', category = '', label, ...rest } = interactionParams
          GA4.event(action, {
            event_category: category,
            event_label: label,
            ...rest,
          })
          break
        }
        default:
          break
      }
    }
  }
}
