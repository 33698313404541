// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import { automationIds } from 'Onboarding/utils/constants'
import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { CloseIcon } from 'V2/components/iconPaths/Custom/BasicIcons/CloseIcon'
import { SnackbarContainerProp } from 'V2/interfaces/components/Molecules.interface'
import {
  CenterAligned,
  IconWrapper,
  JustifiedSpaceSpan,
  Message,
  ToastChild,
  ToastContainer,
} from './Styles'

export const SnackbarContainer: React.FC<SnackbarContainerProp> = (props) => {
  return (
    <ToastContainer position={props.position} coordinates={props.coordinates || {}}>
      {props.snackList.map(({ variant, iconPath, message, id }, i) => (
        <ToastChild variant={variant} key={i} position={props.position}>
          <JustifiedSpaceSpan>
            <IconWrapper>
              <CustomIcon width={24} height={24} viewBox={'0 0 24 24'}>
                {iconPath}
              </CustomIcon>
            </IconWrapper>
            <Message>{message}</Message>
            <CenterAligned
              onClick={(): void => {
                props.dismissSnackbar(id)
              }}
            >
              <CustomIcon
                id={automationIds.snackBarCloseIcon}
                width={24}
                height={24}
                viewBox={'0 0 24 24'}
              >
                <CloseIcon stroke={'#FFFFFF'} />
              </CustomIcon>
            </CenterAligned>
          </JustifiedSpaceSpan>
        </ToastChild>
      ))}
    </ToastContainer>
  )
}
