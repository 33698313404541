import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { config } from './bulkPayment'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const triggerEmail = (params: any, clientId: string): Promise<any> => {
  const response = axios.post(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.triggerEmail}`,
    params,
    config(clientId)
  )
  return response
}
