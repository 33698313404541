import styled from 'styled-components/macro'

import { Button } from 'V2/components/atoms/Button/Button'
import { ButtonBase } from 'V2/components/atoms/Button/Styles'
import Dropdown from 'V2/components/atoms/DropDown/Dropdown'
import { DropdownWrapper } from 'V2/components/atoms/DropDown/Style'
import { Svg } from 'V2/components/atoms/Icon/CustomIcon'
import { BaseInput } from 'V2/components/atoms/Input/Styles'
import { Wrapper } from 'V2/components/atoms/Table/Styles'
import { Wrapper as TabWrapper } from 'V2/components/atoms/Tabs/Styles'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import {
  IconDivWrapper,
  ModalBody,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'V2/components/molecules/Modal/Styles'
import { floors } from 'V2/constants/floors'
import { great, less } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'
import { CardWrapper, InnerWrapper } from '../SendMoney/PaymentCard/Styles'

export const Container = styled.div`
  padding: 16px 56px 24px 56px;
  @media ${less.lessThanTablet} {
    padding: 32px 24px;
  }
  @media ${less.lessThanMobile} {
    padding: 16px;
  }
  ${ModalContainer} {
    z-index: ${floors.top};
  }
  ${ModalHeader} {
    padding: unset;
    height: unset;
  }
  ${IconDivWrapper} {
    padding-right: 24px;
    @media ${less.lessThanTablet} {
      padding-right: 16px;
    }
  }
  ${Wrapper} {
    border: unset;
  }
  table {
    background-color: ${({ theme }): string => theme.revamp.staticColors.white};
    border-radius: 0px 0px 4px 4px;
  }
`

export const TableWrapper = styled.div`
  border: 1px solid ${({ theme }): string => theme.revamp.primary.grey.lighter};
  border-radius: 4px;
  table thead tr {
    @media ${less.lessThanMobile} {
      display: none;
    }
  }
  table {
    table-layout: fixed;
    @media ${less.lessThanMobile} {
      border-top: 1px solid ${({ theme }): string => theme.revamp.primary.grey.lighter};
    }
    thead th:first-child {
      padding-left: 24px !important;
      @media ${less.lessThanMobile} {
        padding-left: 16px !important;
      }
    }
  }
  ${InnerWrapper} {
    @media ${less.lessThanMobile} {
      display: flex;
      flex-direction: column;
    }
    p {
      margin-top: 0;
    }
  }
`

export const ModalFormWrapper = styled.div<{ deleteModal: boolean }>`
  ${ModalContent} {
    ${({ deleteModal }): string =>
      !deleteModal
        ? ` height: calc(var(--vh, 1vh) * 100);
    @media ${great.greaterThanMobile} {
      width: 100vw;
      max-width: unset;
    }
    `
        : ''};
  }
  ${ModalBody} {
    ${({ deleteModal }): string =>
      !deleteModal
        ? `border-bottom: unset;
        min-height: calc((var(--vh, 1vh) * 100) - 75px);
        @media ${less.lessThanMobile} {
          height: calc((var(--vh, 1vh) * 100) - 55px);
          max-height: calc((var(--vh, 1vh) * 100) - 55px);
        }`
        : ''};
  }
  ${ModalHeader} {
    ${({ deleteModal }): string =>
      !deleteModal
        ? `@media ${less.lessThanMobile} {
          height: 55px;
        }`
        : ''};
  }
`
//  Add recipient

export const IconWrapper = styled.span`
  position: relative;
  top: ${pxToRem(2)};
  cursor: pointer;
`

export const FormContent = styled.div``
export const NotInMobile = styled.div`
  padding: 24px 32px 24px 24px;
  @media ${less.lessThanMobile} {
    display: none;
  }
`
export const EditFormHeader = styled.div`
  padding-bottom: 32px;
  @media ${less.lessThanMobile} {
    display: none;
  }
`
export const HeaderLeftElem = styled.div`
  display: flex;
  align-items: center;
`
export const OnlyInMobile = styled.div`
  display: flex;
  @media ${great.greaterThanMobile} {
    display: none;
  }
`
export const MobileBackButton = styled.div`
  padding: 16px;
  cursor: pointer;
  @media ${great.greaterThanMobile} {
    border-left: 1px solid ${({ theme }): string => theme.revamp.primary.grey.lighter};
    padding: 24px 8px;
  }
`
export const FormContentWrapper = styled.div`
  margin: auto;
  width: 550px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  @media ${great.greaterThanMobile} {
    padding-top: 40px;
  }
  @media ${less.lessThanMobile} {
    padding: 0 16px;
    width: unset;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 ${pxToRem(50)}
      ${({ theme }): string => theme.revamp.staticColors.white} inset !important;
  }
`
export const ContactFormHeader = styled(Typography).attrs({
  type: 'h1',
  weight: 'semibold',
})`
  padding-bottom: 24px;
  @media ${less.lessThanMobile} {
    display: none;
  }
`
export const ContactFormHeaderMobile = styled.div`
  @media ${great.greaterThanMobile} {
    display: none;
  }
`

//Pop Over
export const MenuItem = styled.div<{ show?: boolean; lastItem?: boolean }>`
  border-radius: 4px;
  display: flex;
  min-width: 166px;
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  flex-direction: column;
  box-shadow: 0px 3px 10px rgba(44, 44, 44, 0.08);
  width: fit-content;
  margin-right: 14px !important;
  display: ${({ show }): string => (show ? 'block' : 'none')};
  z-index: ${floors.first};
  padding: 8px 0px;

  & > :hover {
    background-color: ${({ theme }): string => theme.revamp.primary.yellow.light};
    cursor: pointer;
  }
`

export const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 48px;
  padding-left: 16px;
`
export const Item = styled.div`
  padding-left: 16px;
  color: ${({ theme }): string => theme.revamp.primary.blue.root};
  p {
    @media ${less.lessThanMobile} {
      font-weight: 600;
    }
  }
`

export const Footer = styled.div<{ editRecipient?: boolean }>`
  display: flex;
  padding-bottom: 35px;
  @media ${less.lessThanMobile} {
    flex-direction: column;
    margin-top: auto;
    ${ButtonBase} {
      width: 100%;
    }
  }
`
export const ContinueButtonWrapper = styled.div`
  padding-left: 16px;
  @media ${less.lessThanMobile} {
    padding-left: 0px;
    padding-top: 16px;
  }
`

export const StatusWrapper = styled.div`
  height: ${pxToRem(55)};
  max-width: 80%;
`
export const StatusMessage = styled.div<{ status: string }>`
  position: relative;
  left: ${pxToRem(38)};
  bottom: ${pxToRem(30)};
  color: ${({ theme, status }): string =>
    status === 'success' ? theme.staticColors.success : theme.staticColors.error};
`
export const NavigationIconDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 14px;
  &:hover {
    cursor: pointer;
  }
`
export const ErrorBlock = styled.div`
  flex: 1;
  color: ${({ theme }): string => theme.staticColors.error};
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 400;
`

export const IconContainer = styled.span`
  margin-right: 12.75px;
`
export const TableContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }): string => theme.revamp.primary.grey.lighter};
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  @media ${less.lessThanMobile} {
    border-bottom: unset;
  }
`
export const HeaderTitle = styled.div`
  padding: 24px;
  @media ${less.lessThanMobile} {
    padding: 16px;
  }
`
export const RoleInfoIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: 24px;
  right: 26px;
  @media ${great.greaterThanMobile} {
    display: none;
  }
`
export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
  padding: 20px 24px;
  @media ${less.lessThanMobile} {
    display: none;
  }
`
export const BeneDetailsHeaderContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
`
export const TableHeaderWrapper = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  & > p:last-child {
    margin-right: 83px;
  }
`
export const HeaderWrapper = styled.div<{ width?: string }>`
  text-align: left;
  width: ${({ width }): string => (width ? width : '')};
`
export const EmptyComponentWrapper = styled.div`
  padding: 8px;
  ${CardWrapper} {
    ${InnerWrapper} {
      margin: 0px !important;
      padding: 0px !important;
      height: 100%;
    }
    min-height: unset;
    border: unset;
  }
`
export const BeneDetailsHeaderTitle = styled.div`
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  @media ${less.lessThanMobile} {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
  }
`
export const BeneDetailsHeaderButtonContainer = styled.div`
  margin-left: auto;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 24px;
  color: ${({ theme }): string => theme.revamp.primary.blue.root};
  cursor: pointer;
  align-items: center;
  @media ${less.lessThanMobile} {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 16px;
  }
`
export const HeaderButton = styled(Button)`
  width: 140px;
  height: 36px;
`
export const MobileContactNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  @media ${great.greaterThanMobile} {
    display: none;
  }
`
export const BankNameWrapper = styled.div`
  display: flex;
`
export const TableElem = styled(Typography).attrs({
  type: 'body',
  weight: 'semibold',
})`
  @media ${great.greaterThanMobile} {
    font-weight: 500;
  }
`
export const RecipientName = styled(Typography).attrs({
  type: 'body',
  weight: 'semibold',
})`
  max-width: 311px;
  font-weight: 500;
  @media ${less.lessThanTablet} {
    max-width: 139px;
  }
  @media ${less.lessThanMobile} {
    font-weight: 600;
    max-width: 158px;
  }
`
export const BankNameText = styled(Typography).attrs({
  type: 'body',
  weight: 'semibold',
})`
  font-weight: 500;
  @media ${less.lessThanMobile} {
    font-weight: 600;
  }
`
export const AccountNumberText = styled(Typography).attrs({
  type: 'body',
  weight: 'semibold',
})`
  white-space: nowrap;
  @media ${great.greaterThanMobile} {
    font-weight: 500;
  }
  @media ${less.lessThanMobile} {
    color: ${({ theme }): string => theme.revamp.primary.grey.dark};
  }
`
export const TablePopElem = styled(Typography).attrs({
  type: 'body',
  weight: 'semibold',
})`
  @media ${great.greaterThanMobile} {
    font-weight: 400;
  }
  padding: 8px;
`
export const TableMobileElem = styled(Typography).attrs({
  type: 'caption',
  weight: 'regular',
})`
  color: ${({ theme }): string => theme.revamp.primary.grey.dark};
  @media ${great.greaterThanMobile} {
    display: none;
  }
  max-width: calc(100% - 40px);
`
export const CountryNameElem = styled(Typography).attrs({
  type: 'caption',
  weight: 'regular',
})`
  color: ${({ theme }): string => theme.revamp.primary.grey.dark};
  @media ${great.greaterThanMobile} {
    display: none;
  }
  max-width: 88px;
`
export const RoleInfoWrapper = styled.span`
  position: relative;
  left: 6px;
  top: 11px;
  cursor: pointer;
`

export const TabsWrapper = styled.div`
  ${TabWrapper} {
    display: flex;
    width: 300px;
    background: ${({ theme }): string => theme.revamp.staticColors.white};
    border-bottom: ${({ theme }): string => theme.revamp.staticColors.white};
  }
`

export const BodyContent = styled.div`
  padding: 24px;
  @media ${less.lessThanMobile} {
    padding: 16px;
  }
`
export const DeleteRecipientContainer = styled.div`
  ${ModalHeader} {
    padding: 24px;
    @media ${less.lessThanMobile} {
      padding: 16px;
    }
  }
  ${ModalFooter} {
    @media ${less.lessThanMobile} {
      justify-content: flex-end;
      button: last-child {
        margin-left: 16px;
      }
    }
  }
`
export const AddButtonWrapper = styled.div`
  dispay: flex;
  flex-direction: column;
  width: 360px;
  height: 80px;
  font-size: 15px;
  line-height: 24px;
  border-radius: 4px, 4px, 0px, 0px;
`

export const AddRecipientButton = styled.div`
  width: 150px;
  height: 24px;
  margin-bottom: 15px;
`
export const AddPayerButton = styled.div`
  width: 90px;
  height: 24px;
`

export const FilterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 24px;
  ${Svg} {
    width: 16px;
    height: 24px;
    margin-right: 8px;
    margin-top: 5px;
  }
  @media ${less.lessThanTablet} {
    display: none;
  }
`

export const FiltersSectionWrapper = styled.div`
  display: flex;
  padding: 30px 24px 0;
  justify-content: space-between;
  background: ${({ theme }): string => theme.revamp.staticColors.white};
  width: 100%;
  ${BaseInput} {
    height: 48px;
    width: 311px;
    &:focus + label {
      display: none;
    }
  }
  ${DropdownWrapper} {
    margin-left: 16px;
  }
`
export const CountryDropdown = styled(Dropdown)`
  width: 222px;
`

export const CurrencyDropdown = styled(Dropdown)`
  width: 140px;
`

export const ClearButton = styled.div``
export const FilterInnerWrapper = styled.div`
  width: 705px;
  display: flex;
  justify-content: space-between;
`

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0px 40px;
  @media ${less.lessThanMobile} {
    padding-left: 20px;
    justify-content: space-between;
    margin: 0px;
  }
`
export const RecipientCountWrapper = styled.div`
  padding: 16px 24px 0px 24px;
  gap: 10px;
  background: ${({ theme }): string => theme.revamp.staticColors.white};
  color: ${({ theme }): string => theme.revamp.primary.grey.dark};
`
export const FilterSection = styled.div`
  @media ${less.lessThanTablet} {
    display: none;
  }
`
