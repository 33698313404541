// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const HamburgerMenuIcon: React.FC<{ stroke?: string }> = ({ stroke = 'black' }) => (
  <>
    <rect width="24" height="24" fill="white" />
    <path
      d="M2.25 18.003H21.75"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 12.003H21.75"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 6.003H21.75"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
