// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const ArrowRightIcon: React.FC<{ fill?: string }> = ({ fill = '#2C2C2C' }) => (
  <>
    <path
      d="M15.1219 11.9975C15.1219 12.2892 14.9969 12.5392 14.7886 12.7058L10.1219 16.8308C9.78855 17.1225 9.28855 17.0808 9.03855 16.7475C8.78855 16.4142 8.78855 15.9558 9.12189 15.6642L13.2052 12.0808C13.2469 12.0392 13.2469 11.9975 13.2052 11.9142L9.12189 8.33082C8.78855 8.03915 8.78855 7.53915 9.08022 7.24749C9.37189 6.95582 9.83022 6.91415 10.1636 7.16415L14.8302 11.2475C14.9969 11.4558 15.1219 11.7058 15.1219 11.9975Z"
      fill={fill}
    />
  </>
)
