import styled, { css, FlattenSimpleInterpolation, keyframes } from 'styled-components/macro'

import { breakPoint } from 'V2/constants/breakpoints'
import { floors } from 'V2/constants/floors'
import { pxToRem } from 'V2/helpers/pxToRem'
import { Coordinates } from 'V2/interfaces/components/Molecules.interface'

const toastInRightKeyframe = keyframes`
  from {
	  transform: translateX(100%);
	}
	to {
	  transform: translateX(0);
	}
`

const toastInLeftKeyframe = keyframes`
  from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
`

export const ToastContainer = styled.div`
  position: fixed;
  z-index: ${floors.top};
  ${({
    position,
    coordinates,
  }: {
    position: string
    coordinates: Coordinates
  }): FlattenSimpleInterpolation => {
    switch (position) {
      case 'top-right':
        return css`
          top: ${coordinates.top ? coordinates.top : pxToRem(12)};
          right: ${coordinates.right ? coordinates.right : pxToRem(12)};
        `
      case 'bottom-right':
        return css`
          bottom: ${coordinates.bottom ? coordinates.bottom : pxToRem(12)};
          right: ${coordinates.right ? coordinates.right : pxToRem(12)};
        `
      case 'top-left':
        return css`
          top: ${coordinates.top ? coordinates.top : pxToRem(12)};
          left: ${coordinates.left ? coordinates.left : pxToRem(12)};
        `
      case 'bottom-left':
        return css`
          bottom: ${coordinates.bottom ? coordinates.bottom : pxToRem(12)};
          left: ${coordinates.left ? coordinates.left : pxToRem(12)};
        `
      default:
        return css``
    }
  }}
`

export const ToastChild = styled.div<{
  variant: string
  position: string
}>`
  position: relative;
  ${({ position }): FlattenSimpleInterpolation => {
    switch (position) {
      case 'top-right':
      case 'bottom-right':
        return css`
          transition: transform 0.6s ease-in-out;
          animation: ${css`
            ${toastInRightKeyframe} .7s;
          `};
        `
      case 'top-left':
      case 'bottom-left':
        return css`
          transition: transform 0.6s ease-in-out;
          animation: ${css`
            ${toastInLeftKeyframe} .7s;
          `};
        `
      default:
        return css``
    }
  }}
  pointer-events: auto;
  overflow: hidden;
  max-height: ${pxToRem(100)};
  border-radius: ${pxToRem(4)};
  margin: ${pxToRem(5)};
  box-shadow: 0 0 ${pxToRem(10)} ${({ theme }): string => theme.palette.tertiary.niumBlackL3};
  background-position: ${pxToRem(15)};
  ${({ variant, theme }): FlattenSimpleInterpolation => {
    switch (variant) {
      case 'success':
        return css`
          background-color: ${theme.revamp.staticColors.success};
        `
      case 'warn':
        return css`
          background-color: ${theme.revamp.secondary.tangerine.root};
        `
      case 'info':
        return css`
          background-color: ${theme.revamp.staticColors.bahamaBlue};
        `
      case 'error':
        return css`
          background-color: ${theme.revamp.staticColors.redPepperCorn};
        `
      default:
        return css``
    }
  }}
`

export const CenterAligned = styled.span`
  display: flex;
  align-items: center;
`

export const JustifiedSpaceSpan = styled(CenterAligned)`
  justify-content: space-between;
  padding: ${pxToRem(12)};
  min-width: ${pxToRem(500)};
  @media (max-width: ${breakPoint.mobile}px) {
    padding: ${pxToRem(8)};
    min-width: ${pxToRem(280)};
    word-break: break-word;
  }
`

export const Message = styled.div`
  font-size: ${pxToRem(15)};
  color: ${({ theme }): string => theme.staticColors.white};
  margin: 0 ${pxToRem(16)};
  flex-grow: 1;
  @media (max-width: ${breakPoint.mobile}px) {
    margin: ${pxToRem(8)};
  }
`

export const IconWrapper = styled(CenterAligned)`
  @media (max-width: ${breakPoint.mobile}px) {
    display: none;
  }
`
