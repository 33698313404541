/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'

export const PeopleIcon = (): JSX.Element => {
  return (
    <>
      <path
        d="M2.25 6C2.25 6.59674 2.48705 7.16903 2.90901 7.59099C3.33097 8.01295 3.90326 8.25 4.5 8.25C5.09674 8.25 5.66903 8.01295 6.09099 7.59099C6.51295 7.16903 6.75 6.59674 6.75 6C6.75 5.40326 6.51295 4.83097 6.09099 4.40901C5.66903 3.98705 5.09674 3.75 4.5 3.75C3.90326 3.75 3.33097 3.98705 2.90901 4.40901C2.48705 4.83097 2.25 5.40326 2.25 6V6Z"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 9.75C3.50544 9.75 2.55161 10.1451 1.84835 10.8483C1.14509 11.5516 0.75 12.5054 0.75 13.5V15.75H2.25L3 21.75H6"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 6C17.25 6.59674 17.4871 7.16903 17.909 7.59099C18.331 8.01295 18.9033 8.25 19.5 8.25C20.0967 8.25 20.669 8.01295 21.091 7.59099C21.5129 7.16903 21.75 6.59674 21.75 6C21.75 5.40326 21.5129 4.83097 21.091 4.40901C20.669 3.98705 20.0967 3.75 19.5 3.75C18.9033 3.75 18.331 3.98705 17.909 4.40901C17.4871 4.83097 17.25 5.40326 17.25 6Z"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 9.75C20.4946 9.75 21.4484 10.1451 22.1517 10.8483C22.8549 11.5516 23.25 12.5054 23.25 13.5V15.75H21.75L21 21.75H18"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.75C9 4.54565 9.31607 5.30871 9.87868 5.87132C10.4413 6.43393 11.2044 6.75 12 6.75C12.7956 6.75 13.5587 6.43393 14.1213 5.87132C14.6839 5.30871 15 4.54565 15 3.75C15 2.95435 14.6839 2.19129 14.1213 1.62868C13.5587 1.06607 12.7956 0.75 12 0.75C11.2044 0.75 10.4413 1.06607 9.87868 1.62868C9.31607 2.19129 9 2.95435 9 3.75V3.75Z"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 13.5C17.25 12.1076 16.6969 10.7723 15.7123 9.78769C14.7277 8.80312 13.3924 8.25 12 8.25C10.6076 8.25 9.27226 8.80312 8.28769 9.78769C7.30312 10.7723 6.75 12.1076 6.75 13.5V15.75H9L9.75 23.25H14.25L15 15.75H17.25V13.5Z"
        stroke="#2C2C2C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export const PeopleIconV2: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2690_27714)">
      <path
        d="M2.25 6C2.25 6.59674 2.48705 7.16903 2.90901 7.59099C3.33097 8.01295 3.90326 8.25 4.5 8.25C5.09674 8.25 5.66903 8.01295 6.09099 7.59099C6.51295 7.16903 6.75 6.59674 6.75 6C6.75 5.40326 6.51295 4.83097 6.09099 4.40901C5.66903 3.98705 5.09674 3.75 4.5 3.75C3.90326 3.75 3.33097 3.98705 2.90901 4.40901C2.48705 4.83097 2.25 5.40326 2.25 6Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 9.75C3.50544 9.75 2.55161 10.1451 1.84835 10.8483C1.14509 11.5516 0.75 12.5054 0.75 13.5V15.75H2.25L3 21.75H6"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 6C17.25 6.59674 17.4871 7.16903 17.909 7.59099C18.331 8.01295 18.9033 8.25 19.5 8.25C20.0967 8.25 20.669 8.01295 21.091 7.59099C21.5129 7.16903 21.75 6.59674 21.75 6C21.75 5.40326 21.5129 4.83097 21.091 4.40901C20.669 3.98705 20.0967 3.75 19.5 3.75C18.9033 3.75 18.331 3.98705 17.909 4.40901C17.4871 4.83097 17.25 5.40326 17.25 6Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 9.75C20.4946 9.75 21.4484 10.1451 22.1517 10.8483C22.8549 11.5516 23.25 12.5054 23.25 13.5V15.75H21.75L21 21.75H18"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.75C9 4.54565 9.31607 5.30871 9.87868 5.87132C10.4413 6.43393 11.2044 6.75 12 6.75C12.7956 6.75 13.5587 6.43393 14.1213 5.87132C14.6839 5.30871 15 4.54565 15 3.75C15 2.95435 14.6839 2.19129 14.1213 1.62868C13.5587 1.06607 12.7956 0.75 12 0.75C11.2044 0.75 10.4413 1.06607 9.87868 1.62868C9.31607 2.19129 9 2.95435 9 3.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 13.5C17.25 12.1076 16.6969 10.7723 15.7123 9.78769C14.7277 8.80312 13.3924 8.25 12 8.25C10.6076 8.25 9.27226 8.80312 8.28769 9.78769C7.30312 10.7723 6.75 12.1076 6.75 13.5V15.75H9L9.75 23.25H14.25L15 15.75H17.25V13.5Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_27714">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
