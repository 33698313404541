/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'

export const DirectorIcon: React.FC = (): JSX.Element => {
  return (
    <>
      <path
        d="M5.25 6.75C6.90685 6.75 8.25 5.40685 8.25 3.75C8.25 2.09315 6.90685 0.75 5.25 0.75C3.59315 0.75 2.25 2.09315 2.25 3.75C2.25 5.40685 3.59315 6.75 5.25 6.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.19 10.57C8.70757 9.69568 7.94803 9.00666 7.03097 8.61145C6.11392 8.21624 5.09147 8.13728 4.12461 8.38701C3.15776 8.63675 2.30143 9.20098 1.69049 9.99087C1.07954 10.7808 0.748677 11.7514 0.750004 12.75V15.75H2.25L3 23.25H7.5L8 18.15"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 13.75H12C11.5858 13.75 11.25 14.0858 11.25 14.5V22.5C11.25 22.9142 11.5858 23.25 12 23.25H22.5C22.9142 23.25 23.25 22.9142 23.25 22.5V14.5C23.25 14.0858 22.9142 13.75 22.5 13.75Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 17.25H23.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 17.25V19.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 10.75H16C15.6022 10.75 15.2206 10.908 14.9393 11.1893C14.658 11.4706 14.5 11.8522 14.5 12.25V13.75H20V12.25C20 11.8522 19.842 11.4706 19.5607 11.1893C19.2794 10.908 18.8978 10.75 18.5 10.75Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export const DirectorIconV2: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2690_55670)">
      <path
        d="M5.25 6.75C6.90685 6.75 8.25 5.40685 8.25 3.75C8.25 2.09315 6.90685 0.75 5.25 0.75C3.59315 0.75 2.25 2.09315 2.25 3.75C2.25 5.40685 3.59315 6.75 5.25 6.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.19 10.5682C8.70757 9.69385 7.94803 9.00483 7.03097 8.60962C6.11392 8.21441 5.09147 8.13545 4.12461 8.38518C3.15776 8.63492 2.30143 9.19915 1.69049 9.98904C1.07954 10.7789 0.748677 11.7496 0.750004 12.7482V15.7482H2.25L3 23.2482H7.5L8 18.1482"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 13.75H12C11.5858 13.75 11.25 14.0858 11.25 14.5V22.5C11.25 22.9142 11.5858 23.25 12 23.25H22.5C22.9142 23.25 23.25 22.9142 23.25 22.5V14.5C23.25 14.0858 22.9142 13.75 22.5 13.75Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 17.25H23.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 17.25V19.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 10.75H16C15.6022 10.75 15.2206 10.908 14.9393 11.1893C14.658 11.4706 14.5 11.8522 14.5 12.25V13.75H20V12.25C20 11.8522 19.842 11.4706 19.5607 11.1893C19.2794 10.908 18.8978 10.75 18.5 10.75Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2690_55670">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
