import * as localForage from 'localforage'
import { encryptTransform } from 'redux-persist-transform-encrypt'

const persistConfig = Object.freeze({
  key: 'sme',
  storage: localForage,
  whitelist: [
    'userClientDetails',
    'transactionHistory',
    'multiPayments',
    'creditCard',
    'addMoney',
    'ach',
    'formData',
  ],
  // blacklist: ['user'],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_LOCALSTORAGE_RSA_PUBLIC_KEY ?? 'T5JiXb@r&@4g',
    }),
  ],
})
export { localForage, persistConfig }
