// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const CheckboxCircleIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <path
      d="M6 13.223L8.45 16.7C8.54293 16.8388 8.66768 16.9534 8.81385 17.0343C8.96002 17.1152 9.12339 17.16 9.29037 17.165C9.45735 17.17 9.6231 17.135 9.77384 17.063C9.92458 16.991 10.0559 16.884 10.157 16.751L18 6.828"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 11.999C0.75 14.9827 1.93526 17.8442 4.04505 19.954C6.15483 22.0637 9.01631 23.249 12 23.249C14.9837 23.249 17.8452 22.0637 19.955 19.954C22.0647 17.8442 23.25 14.9827 23.25 11.999C23.25 9.01532 22.0647 6.15383 19.955 4.04405C17.8452 1.93426 14.9837 0.749001 12 0.749001C9.01631 0.749001 6.15483 1.93426 4.04505 4.04405C1.93526 6.15383 0.75 9.01532 0.75 11.999V11.999Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
