export enum AuthTypes {
  USER_LOGIN_AUTH = 'USER_LOGIN_AUTH',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_LOGIN_ERROR = 'USER_LOGIN_ERROR',
  REMOVE_PERSISTS = 'REMOVE_PERSISTS',
}

// TODO: Remove these actionTypes
export enum AuthErrorTypes {
  LOADING = 'AUTH_LOADING',
  AUTH_GENCODE_ERROR = 'AUTH_GENCODE_ERROR',
  FORGOT_PASSWORD_ERROR = 'AUTH_FORGOT_PASSWORD_ERROR',
  RESET_ERROR = 'AUTH_RESET_ERROR',
}

export enum SetPasswordType {
  SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE',
}

export enum HeaderTypes {
  SET_USER_CLIENT_DATA = 'SET_USER_CLIENT_DATA',
  USER_DETAILS = 'USER_DETAILS',
  UPDATE_DEFAULT_CURRENCY = 'UPDATE_DEFAULT_CURRENCY',
  SET_RECEIVE_OFFERED_CURRENIES = 'SET_RECEIVE_OFFERED_CURRENIES',
  SET_FEATURE_FLAG_CLIENT_CONFIGURATION = 'SET_FEATURE_FLAG_CLIENT_CONFIGURATION',
  UPDATE_MAKER_CHECKER_FEATURE = 'UPDATE_MAKER_CHECKER_FEATURE',
  UPDATE_AUTO_SWEEP_BANK_DETAILS = 'UPDATE_AUTO_SWEEP_BANK_DETAILS',
  UPDATE_CLIENT_BASE_CURRENCY = 'UPDATE_CLIENT_BASE_CURRENCY',
  UPDATE_TOP_UP_PRICING = 'UPDATE_TOP_UP_PRICING',
  UPDATE_ACQUISITION_TYPE = 'UPDATE_ACQUISITION_TYPE',
  UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS = 'UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS',
  UPDATE_TOP_UP_PRICING_LOADING_DONE = 'UPDATE_TOP_UP_PRICING_LOADING_DONE',
  CARD_DETAILS_NOT_AVAILABLE = 'CARD_DETAILS_NOT_AVAILABLE',
  CARD_DETAILS_AVAILABLE = 'CARD_DETAILS_AVAILABLE',
}

export enum DefaultTypes {
  CLEAR_ERROR = 'CLEAR_ERROR',
}

export enum SnackbarTypes {
  SNACKBAR_DISMISS = 'SNACKBAR_DISMISS',
  SNACKBAR_DISMISS_ALL = 'SNACKBAR_DISMISS_ALL',
  SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE',
}

export enum TransactionHistoryActionType {
  TRANSACTION_HISTORY_DATA_REQUEST = 'TRANSACTION_HISTORY_DATA_REQUEST',
  TRANSACTION_HISTORY_DATA_SUCCESS = 'TRANSACTION_HISTORY_DATA_SUCCESS',
  TRANSACTION_HISTORY_DATA_FAILURE = 'TRANSACTION_HISTORY_DATA_FAILURE',
  TRANSACTION_HISTORY_UPDATE_SUCCESS = 'TRANSACTION_HISTORY_UPDATE_SUCCESS',
  TRANSACTION_HISTORY_UPDATE_REQUEST = 'TRANSACTION_HISTORY_UPDATE_REQUEST',
  // TODO: Remove this with V1
  AWAITING_FUNDS_TXN_FETCH_SUCCESS = 'AWAITING_FUNDS_TXN_FETCH_SUCCESS',
}

export enum CurrencyCalculatorTypes {
  FX_LOADING = 'CURRENCY_CALCULATOR_FX_LOADING',
  FEE_LOADING = 'CURRENCY_CALCULATOR_FEE_LOADING',
  GET_FX_RATE_REQUEST = 'CURRENCY_CALCULATOR_GET_FX_RATE_REQUEST',
  GET_FX_RATE_ERROR = 'CURRENCY_CALCULATOR_GET_FX_RATE_ERROR',
  GET_FEE_ERROR = 'CURRENCY_CALCULATOR_FEE_ERROR',
  EXCHANGE_VALUE_SET = 'CURRENCY_CALCULATOR_EXCHANGE_VALUE_SET',
  SEND_CURRENCY_UPDATE = 'CURRENCY_CALCULATOR_SEND_CURRENCY_UPDATE',
  RECEIVE_CURRENCY_UPDATE = 'CURRENCY_CALCULATOR_RECEIVE_CURRENCY_UPDATE',
}

export enum AccountTypes {
  LOADING = 'ACCOUNT_LOADING',
  ACCOUNT_ACCOUNT_LIST = 'ACCOUNT_ACCOUNT_LIST',
  ACCOUNT_ACCOUNT_LIST_ERROR = 'ACCOUNT_ACCOUNT_LIST_ERROR',
}

export enum CreditCardTypes {
  SAVED_CARD_DETAILS = 'CREDIT_CARD_SAVED_CARD_DETAILS',
  CREDIT_CARD_DETAILS = 'CREDIT_CARD_DETAILS',
  CREDIT_CARD_NUMBER = 'CREDIT_CARD_NUMBER',
  EXPIRY_DATE = 'CREDIT_CARD_EXPIRY_DATE',
  CVV_NUMBER = 'CREDIT_CARD_CVV_NUMBER',
  CREDIT_CARD_TYPE = 'CREDIT_CARD_TYPE',
  SELECT_AVAILABLE_CARD = 'CREDIT_CARD_SELECT_AVAILABLE_CARD',
  SELECT_SAVED_CARD = 'CREDIT_CARD_SELECT_SAVED_CARD',
  CREDIT_CARD_ERRORS = 'CREDIT_CARD_ERRORS',
  CARD_NUMBER_ERROR = 'CREDIT_CARD_CARD_NUMBER_ERROR',
  CVV_NUMBER_ERROR = 'CREDIT_CARD_CVV_NUMBER_ERROR',
  EXPIRY_DATE_ERROR = 'CREDIT_CARD_EXPIRY_DATE_ERROR',
  CURRENT_CARD_STEP = 'CREDIT_CARD_CURRENT_CARD_STEP',
  LOADING_IN_PROGRESS = 'CREDIT_CARD_LOADING_IN_PROGRESS',
  LOADING_DONE = 'CREDIT_CARD_LOADING_DONE',
  GET_ALL_SAVED_CARDS_REQUEST = 'CREDIT_CARD_GET_ALL_SAVED_CARDS_REQUEST',
  GET_ALL_SAVED_CARDS_SUCCESS = 'CREDIT_CARD_GET_ALL_SAVED_CARDS_SUCCESS',
  GET_ALL_SAVED_CARDS_FAILURE = 'CREDIT_CARD_GET_ALL_SAVED_CARDS_FAILURE',
  CREDITCARD_STATEMENT_NARRATIVE = 'CREDIT_CARD_STATEMENT_NARRATIVE',
  RESET_STATE = 'CREDIT_CARD_RESET_STATE',
}

export enum MultiPaymentTypes {
  ADD_BENE_TO_LIST = 'MULTIPAYMENT_ADD_BENE_TO_LIST',
  ADD_DESTINATION_CURRENCY_TO_LIST = 'MULTIPAYMENT_ADD_DESTINATION_CURRENCY_TO_LIST',
  REMOVE_DESTINATION_CURRENCY_FROM_LIST = 'MULTIPAYMENT_REMOVE_DESTINATION_CURRENCY_FROM_LIST',
  DELETE_BENE_FROM_LIST = 'MULTIPAYMENT_DELETE_BENE_FROM_LIST',
  UPDATE_RECEIVE_CURRENCY = 'UPDATE_RECEIVE_CURRENCY',
  LOADING_IN_PROGRESS = 'MULTIPAYMENT_LOADING_IN_PROGRESS',
  LOADING_DONE = 'MULTIPAYMENT_LOADING_DONE',
  LOADING_FEE_IN_PROGRESS = 'MULTIPAYMENT_LOADING_FEE_IN_PROGRESS',
  LOADING_FEE_DONE = 'MULTIPAYMENT_LOADING_FEE_DONE',

  FETCH_AND_LOCK_FXRATES = 'MULTIPAYMENT_FETCH_AND_LOCK_FXRATES',
  SET_AMOUNT_BY_BENEFICIARYID = 'MULTIPAYMENT_SET_AMOUNT_BY_BENEFICIARYID',
  SET_AMOUNT_ERROR_BY_BENEFICIARYID = 'MULTIPAYMENT_SET_AMOUNT_ERROR_BY_BENEFICIARYID',
  INPUT_STATUS_BY_BENEFICIARYID = 'MULTIPAYMENT_INPUT_STATUS_BY_BENEFICIARYID',
  VALIDATE_PAYOUT_REQUEST = 'MULTIPAYMENT_VALIDATE_PAYOUT_REQUEST',
  VALIDATE_PAYOUT_FAILURE = 'MULTIPAYMENT_VALIDATE_PAYOUT_FAILURE',
  VALIDATE_PAYOUT_BATCH_SUCCESS = 'MULTIPAYMENT_VALIDATE_PAYOUT_BATCH_SUCCESS',
  VALIDATE_PAYOUT_BATCH_FAILURE = 'MULTIPAYMENT_VALIDATE_PAYOUT_BATCH_FAILURE',

  CREATE_PAYOUT_REQUEST = 'MULTIPAYMENT_CREATE_PAYOUT_REQUEST',
  CREATE_PAYOUT_FAILURE = 'MULTIPAYMENT_CREATE_PAYOUT_FAILURE',
  CREATE_PAYOUT_BATCH_COMPLETE = 'MULTIPAYMENT_CREATE_PAYOUT_BATCH_COMPLETE',
  CREATE_PAYOUT_BATCH_FAILURE = 'MULTIPAYMENT_CREATE_PAYOUT_BATCH_FAILURE',
  GET_ALL_SAVED_CARDS_REQUEST = 'MULTIPAYMENT_GET_ALL_SAVED_CARDS_REQUEST',
  GET_ALL_SAVED_CARDS_SUCCESS = 'MULTIPAYMENT_GET_ALL_SAVED_CARDS_SUCCESS',
  GET_ALL_SAVED_CARDS_FAILURE = 'MULTIPAYMENT__FAILURE',
  UPDATE_PAYMENT_STEP = 'MULTIPAYMENT_UPDATE_PAYMENT_STEP',
  PAYMENT_METHOD = 'MULTIPAYMENT_PAYMENT_METHOD',
  UPDATE_SOURCE_CURRENCY = 'MULTIPAYMENT_UPDATE_SOURCE_CURRENCY',
  RESET_STATE = 'MULTIPAYMENT_RESET_STATE',

  UPDATE_ACCOUNTING_DATA = 'UPDATE_ACCOUNTING_DATA',
  GET_INVOICE_DATA = 'GET_INVOICE_DATA',
  GET_STRIPE_ID = 'GET_STRIPE_ID',
}

export enum BankNameTypes {
  GET_BANK_NAME_REQUEST = 'GET_BANK_NAME_REQUEST',
  GET_BANK_NAME_SUCCESS = 'GET_BANK_NAME_SUCCESS',
}

export enum RoleNameTypes {
  GET_ROLE_NAME_REQUEST = 'GET_ROLE_NAME_REQUEST',
  GET_ROLE_NAME_LIST = 'GET_ROLE_NAME_LIST',
}

export enum AddMoneyTypes {
  SET_FUNDING_AMOUNT = 'ADD_MONEY_SET_FUNDING_AMOUNT',
  SET_SELECTED_CURRENCY = 'ADD_MONEY_SET_SELECTED_CURRENCY',
  UPDATE_PAYMENT_TYPE = 'ADD_MONEY_UPDATE_PAYMENT_TYPE',
  RESET_STATE = 'ADD_MONEY_RESET_STATE',
  RESET_STATE_EXCEPT_SELECTED_CURRENCY = 'ADD_MONEY_RESET_STATE_EXCEPT_SELECTED_CURRENCY',
  RESET_STATE_EXCEPT_AMOUNT_CURRENCY_RETURNURL = 'ADD_MONEY_RESET_STATE_EXCEPT_AMOUNT_CURRENCY_RETURNURL',
  ADD_MONEY_COMPLETED = 'ADD_MONEY_ADD_MONEY_COMPLETED',
  CREDIT_CARD_PAYMENT_INITIATED = 'ADD_MONEY_CREDIT_CARD_PAYMENT_INITIATED',
  SET_RETURN_URL = 'ADD_MONEY_RETURN_URL',
}
export enum CurrencyExchangeTypes {
  BOOK_FX_LOADING = 'CURRENCY_EXCHANGE_BOOK_FX_LOADING',
  GET_BOOK_FX_RATE_REQUEST = 'CURRENCY_EXCHANGE_GET_BOOK_FX_RATE_REQUEST',
  GET_BOOK_FX_RATE_ERROR = 'CURRENCY_EXCHANGE_GET_BOOK_FX_RATE_ERROR',
  EXCHANGE_VALUE_SET = 'CURRENCY_EXCHANGE_EXCHANGE_VALUE_SET',
  SEND_CURRENCY_UPDATE = 'CURRENCY_EXCHANGE_SEND_CURRENCY_UPDATE',
  RECEIVE_CURRENCY_UPDATE = 'CURRENCY_EXCHANGE_RECEIVE_CURRENCY_UPDATE',
  TO_AMOUNT_SPECIFIED = 'TO_AMOUNT_SPECIFIED',
  RESET_STATE = 'CURRENCY_EXCHANGE_RESET_STATE',
  UPDATE_EXCHANGE_RESPONSE = 'CURRENCY_EXCHANGE_UPDATE_RESPONSE',
  EXCHANGE_COMPLETED = 'CURRENCY_EXCHANGE_COMPLETED',
  UPDATE_SELECTED_CURRENCY = 'CURRENCY_EXCHANGE_UPDATE_SELECTED_CURRENCY',
}
export enum WithdrawTypes {
  UPDATE_SELECTED_CURRENCY = 'WITHDRAW_UPDATE_SELECTED_CURRENCY',
  UPDATE_WITHDRAW_RESPONSE = 'WITHDRAW_SET_RESPONSE',
  WITHDRAW_COMPLETED = 'WITHDRAW_COMPLETED',
  RESET_STATE = 'WITHDRAW_RESET_STATE',
}
export enum ACHTypes {
  ROLLING_LIMIT = 'SET_ROLLING_LIMIT',
  ADD_NEW_BANK_ACCOUNT_PLAID = 'ADDING_A_NEW_BANK_ACCOUNT_PLAID',
  ADD_LIST_OF_LINKED_BANK_ACCOUNTS = 'ADD_LIST_OF_LINKED_BANK_ACCOUNTS',
  DELETE_SELECTED_BANK_ACCOUNT = 'DELETE_SELECTED_BANK_ACCOUNT',
  UPDATE_SELECTED_BANK_ACCOUNT = 'UPDATE_SELECTED_BANK_ACCOUNT',
  RESET_SELECTED_BANK_ACCOUNT = 'RESET_SELECTED_BANK_ACCOUNT',
  LOADING_IN_PROGRESS = 'ACH_LOADING_IN_PROGRESS',
  LOADING_DONE = 'ACH_LOADING_DONE',
}
//Onboarding Action types
export enum LookUpActions {
  UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES',
}

export enum FetchActions {
  UPDATE_FETCH_VALUES = 'UPDATE_FETCH_VALUES',
}

export enum FormdataActions {
  UPDATE_FORMDATA_VALUES = 'UPDATE_FORMDATA_VALUES',
  RESET_FORM_DATA = 'RESET_FORM_DATA',
  UPDATE_KYC_MODE = 'UPDATE_KYC_MODE',
}

export enum RiskAssessmentInfoActions {
  UPDATE_RISK_ASSESSMENT_INFO_VALUES = 'UPDATE_RISK_ASSESSMENT_INFO_VALUES',
}

export enum ScreenInfoActions {
  UPDATE_SCREEN_INFO_VALUES = 'UPDATE_SCREEN_INFO_VALUES',
}

export enum EditFlowActions {
  EDIT_FLOW_VALUE = 'EDIT_FLOW_VALUE',
}

export enum UpdateSimplifiedValue {
  UPDATE_SIMPLIFIED_VALUE = 'UPDATE_SIMPLIFIED_VALUE',
}

export enum UpdateInputValue {
  UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE',
}

export enum CurrentFormDataValue {
  UPDATE_CURRENT_FORM_DATA = 'UPDATE_CURRENT_FORM_DATA',
  RESET_CURRENT_FORM_DATA = 'RESET_CURRENT_FORM_DATA',
}

export enum VFAActionTypes {
  SET_VFA_BANK_DETAILS = 'SET_VFA_BANK_DETAILS',
  SET_VFA_ENABLED_LIST = 'SET_VFA_ENABLED_LIST',
}
export enum SaveURLTypes {
  SAVE_LAST_VISITED_URL = 'SAVE_LAST_VISITED_URL',
  RESET_LAST_VISITED_URL = 'RESET_LAST_VISITED_URL',
}
export enum BlockerBannerClosed {
  IS_BLOCKER_BANNER_CLOSED = 'IS_BLOCKER_BANNER_CLOSED',
}
