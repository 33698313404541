// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useMemo } from 'react'

import { AtomsTabs } from 'V2/interfaces/components/Atoms.interface'
import { Tab, Wrapper } from './Styles'

export const Tabs: React.FC<AtomsTabs> = ({ tabs, currentTab, changeTabHandler }): JSX.Element => {
  const widthOfEachTab = useMemo(() => 100 / tabs.length, [tabs])
  return (
    <Wrapper>
      {tabs.map((tabObj, index) => (
        <Tab
          key={`i-${index}`}
          onClick={(): void => {
            changeTabHandler && changeTabHandler(tabObj.tab)
          }}
          isActive={tabObj.tab === currentTab}
          width={widthOfEachTab}
          id={tabObj.id}
        >
          {tabObj.title}
        </Tab>
      ))}
    </Wrapper>
  )
}
