// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const DashboardNavigationIcon: React.FC<{ stroke?: string }> = ({ stroke = 'white' }) => (
  <>
    <path
      d="M10.5 12.52C10.5005 12.7792 10.3981 13.028 10.2153 13.2118C10.0325 13.3955 9.78421 13.4992 9.525 13.5H1.725C1.59662 13.4997 1.46954 13.4742 1.35104 13.4248C1.23254 13.3754 1.12494 13.3031 1.03439 13.2121C0.943842 13.1211 0.872118 13.0131 0.823321 12.8943C0.774524 12.7756 0.74961 12.6484 0.750005 12.52V1.73C0.749742 1.60157 0.774778 1.47434 0.823685 1.35558C0.872591 1.23683 0.944409 1.12886 1.03504 1.03786C1.12567 0.946862 1.23334 0.874602 1.35189 0.825211C1.47045 0.775819 1.59757 0.750262 1.726 0.75L9.526 0.763C9.78504 0.764059 10.0331 0.86784 10.2157 1.05157C10.3983 1.2353 10.5005 1.48396 10.5 1.743V12.52Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.246 6.53803C23.2442 6.79485 23.1404 7.04043 22.9576 7.22081C22.7748 7.4012 22.5278 7.50162 22.271 7.50003H14.471C14.3438 7.50095 14.2177 7.47679 14.0998 7.42893C13.9819 7.38107 13.8747 7.31045 13.7841 7.22111C13.6936 7.13177 13.6215 7.02545 13.5721 6.90824C13.5226 6.79103 13.4968 6.66523 13.496 6.53803V1.72503C13.4976 1.46847 13.601 1.22304 13.7834 1.04265C13.9659 0.862271 14.2124 0.761695 14.469 0.763026L22.269 0.750026C22.5261 0.748162 22.7734 0.848438 22.9566 1.02882C23.1398 1.2092 23.2439 1.45494 23.246 1.71203V6.53803Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 22.285C10.4992 22.4125 10.4733 22.5386 10.4238 22.6562C10.3743 22.7737 10.3021 22.8803 10.2114 22.9699C10.1206 23.0595 10.0132 23.1303 9.89505 23.1784C9.77694 23.2265 9.65052 23.2508 9.523 23.25L1.723 23.237C1.46618 23.2381 1.21944 23.1371 1.037 22.9564C0.854551 22.7756 0.751327 22.5298 0.750004 22.273V17.464C0.750658 17.3367 0.776404 17.2107 0.82577 17.0933C0.875135 16.976 0.947152 16.8695 1.0377 16.7799C1.12825 16.6904 1.23556 16.6196 1.35349 16.5716C1.47143 16.5235 1.59767 16.4992 1.725 16.5H9.525C9.78209 16.4987 10.0292 16.5995 10.212 16.7802C10.3948 16.961 10.4984 17.2069 10.5 17.464V22.285Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.472 23.25C14.3435 23.2497 14.2163 23.2241 14.0977 23.1747C13.979 23.1252 13.8713 23.0529 13.7807 22.9618C13.69 22.8707 13.6182 22.7626 13.5694 22.6437C13.5206 22.5249 13.4956 22.3975 13.496 22.269V11.481C13.4956 11.3526 13.5205 11.2253 13.5693 11.1065C13.6181 10.9877 13.6898 10.8797 13.7803 10.7886C13.8709 10.6975 13.9785 10.6251 14.097 10.5756C14.2155 10.5261 14.3426 10.5004 14.471 10.5H22.271C22.3994 10.5004 22.5265 10.5261 22.645 10.5756C22.7635 10.6251 22.8711 10.6975 22.9617 10.7886C23.0522 10.8797 23.1239 10.9877 23.1727 11.1065C23.2215 11.2253 23.2464 11.3526 23.246 11.481V22.256C23.2465 22.5151 23.1443 22.7639 22.9617 22.9478C22.7792 23.1317 22.5311 23.2357 22.272 23.237L14.472 23.25Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
