/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import pick from 'lodash/pick'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'

import {
  ServicesContactSchema,
  ServicesContactSchemaScreen,
  ServicesContactSchemaState,
} from 'V2/interfaces/Services.interface'
import { Logger } from './logger'

const FILE_NAMESPACE = 'contactsCommon'

export default class ContactSchemaService {
  /**This filters all required properties from anyof and return a array of all routing types and routing values **/
  static anyOfFieldsFromSchema = (flattenedSchema: any, anyOf: { [x: string]: any }[]): any =>
    (Object.prototype.hasOwnProperty.call(flattenedSchema, 'anyOf') &&
      anyOf
        .map((anyOfElem: { [x: string]: any }, c: any) => {
          const anyOfPropertiesArray: any[] = []
          Object.keys(anyOfElem).forEach((itm, i) => {
            return anyOfPropertiesArray.push(anyOfElem[itm])
          })
          return anyOfPropertiesArray.flat()
        })
        .flat()) ||
    []

  /**
   * This returns a object of bankRoutingDetails and routingCodeType
   * bankRoutingDetails contains all bank routing property object
   * routingCodeType contains routing type value for title
   * @param bankRoutingCodeFields is a constant which contains all possible bank routing relation
   * @param flattenedSchema is a schema to filter out the bank routing properties
   **/
  static bankRoutingFields({ bankRoutingCodeFields, flattenedSchema, state }: any): any {
    return bankRoutingCodeFields?.reduce(
      (
        acc: any,
        routingField: {
          type: string
          value: string
        }
      ) => {
        if (flattenedSchema?.required?.includes(routingField?.type)) {
          if (
            flattenedSchema?.properties?.[routingField.type] &&
            flattenedSchema?.properties?.[routingField.type]?.const
          ) {
            return {
              ...acc,
              ...(!state?.[routingField.type] && {
                routingCodeTypeState: {
                  ...acc?.routingCodeTypeState,
                  [routingField.type]: flattenedSchema?.properties?.[routingField.type]?.const,
                },
              }),
              bankRoutingDetails: {
                ...(acc?.bankRoutingDetails ?? {}),
                ...(flattenedSchema?.properties?.[routingField.type] && {
                  [routingField.value]: {
                    ...flattenedSchema?.properties?.[routingField.value],
                    title: flattenedSchema?.properties?.[routingField.type]?.const,
                    fieldName: routingField.value,
                  },
                }),
              },
            }
          }
          return {
            ...acc,
            bankRoutingDetails: {
              ...(acc?.bankRoutingDetails ?? {}),
              [routingField.type]: {
                ...flattenedSchema?.properties?.[routingField.type],
                fieldName: routingField.type,
              },
              [routingField.value]: {
                ...flattenedSchema?.properties?.[routingField.value],
                fieldName: routingField.value,
              },
            },
          }
        }
        return acc
      },
      {}
    )
  }
  /**
   * This function gives a object of properties and required fields which is based on condition If and then in schema response
   * @param schema it is if and then fraction of response schema
   * @state it holds client basic details like client_legal_entity, beneficiary_country_code, destination_country etc
   **/
  static conditionalSchema = ({
    key,
    schema,
    state,
  }: {
    key: string | number
    schema?: any
    state?: any
  }): any => {
    // if const or enum value in condition matches
    // then return schema.then required and properties
    if (
      schema?.if?.properties?.[key]?.const === state?.[key] ||
      schema?.if?.properties?.[key]?.enum?.includes(state?.[key])
    ) {
      const conditionalObject = {
        ...(schema?.then?.properties ? { properties: schema?.then?.properties } : {}),
        ...(schema?.then?.required ? { required: schema?.then?.required } : {}),
      }
      Logger(FILE_NAMESPACE).silly('conditionalSchema:then:', key, conditionalObject)
      return conditionalObject
    }
    // if const or enum value in condition doesnt match and there is schema.else
    // then return schema.else required and properties
    else if (
      (schema?.if?.properties?.[key]?.const !== state?.[key] ||
        !schema?.if?.properties?.[key]?.enum?.includes(state?.[key])) &&
      schema?.else
    ) {
      const conditionalObject = {
        ...(schema?.else?.properties ? { properties: schema?.else?.properties } : {}),
        ...(schema?.else?.required ? { required: schema?.else?.required } : {}),
      }
      Logger(FILE_NAMESPACE).silly('conditionalSchema:else', conditionalObject)
      return conditionalObject
    }

    return {}
  }

  /** This function creates the parameter to find conditional rendering properties from schema **/
  static formatConditionalSchema: any = ({ extraSchemaProperties, state }: any) => {
    return Object.keys(extraSchemaProperties).reduce((acc: any, esp: any) => {
      if (esp === 'then') {
        Logger(FILE_NAMESPACE).silly('formatConditionalSchema:then', acc)
        return acc
      }
      if (!Array.isArray(extraSchemaProperties[esp])) {
        const key =
          esp === 'if'
            ? Object.keys(extraSchemaProperties[esp]?.properties).toString()
            : Object.keys(extraSchemaProperties[esp]?.if?.properties).toString()
        const currentSchema = esp === 'if' ? extraSchemaProperties : extraSchemaProperties[esp]

        const formatedData: any = ContactSchemaService.conditionalSchema({
          key,
          schema: currentSchema,
          state,
        })
        Logger(FILE_NAMESPACE).silly('formatConditionalSchema:if', key, currentSchema, formatedData)

        let mergedObj = mergeWith(acc, formatedData, (objValue, srcValue) => {
          if (isArray(objValue)) {
            return objValue.concat(srcValue)
          }
        })

        return mergedObj
      } else {
        Logger(FILE_NAMESPACE).silly('formatConditionalSchema:allOf', esp)
        return ContactSchemaService.formatConditionalSchema({
          extraSchemaProperties: extraSchemaProperties[esp],
          state,
        })
      }
    }, {})
  }
  /**
   * This function takes response schema and  restructure all required properties
   * based on 'allOf', 'if', 'else', 'then' properties of response schema
   **/
  static flatternSchema = (
    schema: ServicesContactSchema,
    state: ServicesContactSchemaState
  ): any => {
    const extraSchemaPropertiesKeys: string[] = ['allOf', 'if', 'else', 'then']

    const { anyOf = [] } = schema

    const extraSchemaProperties: any = pick(schema, extraSchemaPropertiesKeys)

    Logger(FILE_NAMESPACE).silly('flatternSchema:props', schema, state)

    // execution starts here
    const filterFields = ContactSchemaService.formatConditionalSchema({
      extraSchemaProperties,
      state,
    })

    Logger(FILE_NAMESPACE).silly('flatternSchema:required', schema.required, filterFields?.required)
    Logger(FILE_NAMESPACE).silly(
      'flatternSchema:properties',
      schema.properties,
      filterFields?.properties
    )

    const getAnyOfFields = ContactSchemaService.anyOfFieldsFromSchema(schema, anyOf)

    Logger(FILE_NAMESPACE).silly('getAnyOfFields', getAnyOfFields)
    const flattenedSchema = {
      ...(schema?.anyOf && { anyOf: schema?.anyOf }),
      properties: {
        ...schema.properties,
        ...(filterFields?.properties ?? {}),
      },
      required: [
        ...schema.required,
        ...(filterFields?.required ?? []),
        //Added Email address field to all country
        'beneficiary_email',
        ...getAnyOfFields,
      ],
    }

    Logger(FILE_NAMESPACE).silly('flattenedSchema', filterFields, flattenedSchema)

    return flattenedSchema
  }
  /**
   * This function takes modified schema and divide it into no of screens
   * @param schemaAndScreensObj contains flattenedSchema and contactModalScreens
   * flattenedSchema contains modified schema which have all fields properties
   * contactModalScreens is a constant that contains screen wise schema, steps and fields
   **/
  static screenFields = (schemaAndScreensObj: any): any => {
    const { flattenedSchema, contactModalScreens } = schemaAndScreensObj

    const finalScreens = contactModalScreens?.map((screen: ServicesContactSchemaScreen) => {
      return {
        ...screen,
        ...(screen.step !== 1 && {
          schema: {
            ...screen.schema,
            properties: screen?.fields?.reduce((acc, field) => {
              if (field.includes('routing_code_value')) {
                return {
                  ...acc,
                  ...(flattenedSchema?.properties?.[field] && {
                    [field]: {
                      ...flattenedSchema?.properties?.[field],
                      title:
                        flattenedSchema?.properties?.[field?.replace('value_', 'type_')]?.const,
                      fieldName: field?.replace('value', 'type'),
                    },
                  }),
                }
              }
              return {
                ...acc,
                ...(flattenedSchema?.properties?.[field] && {
                  [field]: {
                    ...flattenedSchema?.properties?.[field],
                  },
                }),
              }
            }, {}),
            required:
              screen?.fields?.filter((requiredField) =>
                flattenedSchema?.required?.includes(requiredField)
              ) ?? flattenedSchema?.required,
          },
        }),
      }
    })
    return {
      contactModalScreens: finalScreens,
      ...(flattenedSchema?.required?.includes('remitter_beneficiary_relationship')
        ? { remitter_beneficiary_relationship: 'Supplier' }
        : {}),
    }
  }
}
