// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import { AtomsRadio } from 'V2/interfaces/components/Atoms.interface'
import { Input, Label, Mark, RadioWrapper } from './Styles'

export const Radio: React.FC<AtomsRadio> = ({ id, label, ...props }) => {
  return (
    <RadioWrapper id={`${id}_wrapper`}>
      <Input type="radio" id={id} {...props} />
      <Mark />
      <Label htmlFor={id}>{label}</Label>
    </RadioWrapper>
  )
}
