export const addRecipientSteps = [
  {
    step: 1,
    title: 'Country/Region Details',
  },
  {
    step: 2,
    title: 'Recipient Details',
  },
  {
    step: 3,
    title: 'Account Details',
  },
]

export const editRecipientTabs = [
  {
    tab: 1,
    title: 'Recipient Details',
  },
  {
    tab: 2,
    title: 'Account Details',
  },
]

export const bankDetailsScreen = [
  'beneficiary_bank_name',
  'beneficiary_account_number',
  'beneficiary_bank_account_type',
  'beneficiary_bank_code',
  'routing_code_type_1',
  'routing_code_value_1',
  'routing_code_type_2',
  'routing_code_value_2',
  'routing_code_type_3',
  'beneficiary_identification_type',
  'beneficiary_identification_value',
]

export const addRecipientScreen = [
  'beneficiary_name',
  'beneficiary_email',
  'beneficiary_contact_country_code',
  'beneficiary_contact_number',
  'beneficiary_address',
  'beneficiary_city',
  'beneficiary_state',
  'beneficiary_postcode',
  'remitter_beneficiary_relationship',
  'beneficiary_contact_name',
  'beneficiary_entity_type',
]

export const recipientCountryScreen = [
  'beneficiary_country_code',
  'destination_currency',
  'destination_country',
  'client_legal_entity',
  'beneficiary_account_type',
]

/* eslint-disable @typescript-eslint/camelcase */
export const contactModalScreens = [
  {
    step: 1,
    showStep: 1,
    schema: {
      type: 'object',
      properties: {
        beneficiary_country_code: {
          type: 'string',
          messages: {
            required: 'Please select Country',
          },
        },
        destination_currency: {
          type: 'string',
          messages: {
            required: 'Please select Default Currency',
          },
        },
      },
      required: ['beneficiary_country_code', 'destination_currency'],
    },
    fields: recipientCountryScreen,
  },
  {
    step: 2,

    showStep: 2,
    schema: {
      type: 'object',
      properties: {},
      required: [],
    },
    fields: addRecipientScreen,
  },

  {
    step: 3,

    showStep: 3,
    schema: {
      type: 'object',
      properties: {},
      required: [],
    },
    // NOTE: this priority is important
    fields: bankDetailsScreen,
  },
  {
    step: 4,

    showStep: 3,
    schema: {
      type: 'object',
      properties: {},
      required: [],
      fields: [],
    },
  },
]

export const bankRoutingCodeFields = [
  {
    type: 'routing_code_type_1',
    value: 'routing_code_value_1',
  },
  {
    type: 'routing_code_type_2',
    value: 'routing_code_value_2',
  },
  {
    type: 'routing_code_type_3',
    value: 'routing_code_value_3',
  },
  {
    type: 'routing_code_type_4',
    value: 'routing_code_value_4',
  },
  {
    type: 'beneficiary_identification_type',
    value: 'beneficiary_identification_value',
  },
]

export const AccountTypeOptions = [
  { label: 'Saving Account', value: 'Saving' },
  { label: 'Current Account', value: 'Current' },
  { label: 'Checking Account', value: 'Checking' },
  { label: 'Maestra Account', value: 'Maestra' },
]

export const requiredFieldBankDetails = [
  'beneficiary_bank_name',
  'beneficiary_account_number',
  'beneficiary_bank_account_type',
  'beneficiary_bank_code',
  'routing_code_type_1',
  'routing_code_value_1',
  'routing_code_type_2',
  'routing_code_value_2',
  'routing_code_type_3',
  'beneficiary_identification_type',
  'beneficiary_identification_value',
]

export const fieldsPriority: { [key: string]: number } = {
  beneficiary_name: 1,
  beneficiary_email: 2,
  beneficiary_contact_country_code: 3,
  beneficiary_address: 4,
  beneficiary_city: 5,
  beneficiary_state: 6,
  beneficiary_postcode: 7,
}

export const INVOICE_REQUIRED_REASON_CODES: { [key: string]: string[] } = {
  INR: [
    'IR01801',
    'IR01802',
    'IR01803',
    'IR01804',
    'IR01805',
    'IR01806',
    'IR01807',
    'IR01808',
    'IR01809',
    'IR01810',
    'IR01811',
  ],
}

export const domesticAchText = `Your domestic ACH code can be found on your online banking platform, on your cheque book (typically located next to your account number) or by searching online for “ACH routing number” and your bank's name.`

export const currencyWithIdentificationType = ['COP', 'PEN', 'BRL', 'CLP', 'UYU', 'ARS']
