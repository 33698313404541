import styled, { css } from 'styled-components/macro'

import { pxToRem } from 'V2/helpers/pxToRem'
import styledInterpolation from 'V2/helpers/styledInterpolation'

export const LinkStyle = css`
  background-color: transparent;
  color: ${({ theme }): string => theme.revamp.primary.blue.root};
  border: none;
  min-width: max-content;
  width: max-content;
  padding: 0;
  &:first-child path {
    stroke: ${({ theme }): string => theme.revamp.primary.blue.root};
  }
  &:first-child:hover path {
    stroke: ${({ theme }): string => theme.revamp.primary.blue.light};
  }

  &:first-child[disabled] path {
    stroke: ${({ theme }): string => theme.revamp.primary.grey.root};
  }
  &:hover {
    background-color: transparent;
    color: ${({ theme }): string => theme.revamp.primary.blue.light};
  }
  &:focus {
    background-color: transparent;
    color: ${({ theme }): string => theme.revamp.primary.blue.dark};
  }
  &[disabled] {
    background-color: transparent;
    cursor: not-allowed;
    color: ${({ theme }): string => theme.revamp.primary.grey.root};
  }
`
export const SecondaryStyle = css`
  &:first-child path {
    stroke: ${({ theme }): string => theme.revamp.primary.blue.root};
  }
  border: 1px solid ${({ theme }): string => theme.revamp.primary.blue.root};
  background-color: transparent;
  color: ${({ theme }): string => theme.revamp.primary.blue.root};
  &:first-child:hover path {
    stroke: ${({ theme }): string => theme.revamp.primary.blue.light};
  }
  &:first-child:focus path {
    stroke: ${({ theme }): string => theme.revamp.primary.blue.root};
  }
  &:first-child[disabled] path {
    stroke: ${({ theme }): string => theme.revamp.primary.grey.root};
  }
  &:hover {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.lightest};
    color: ${({ theme }): string => theme.revamp.primary.blue.light};
  }

  &:focus {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.lightest};
    color: ${({ theme }): string => theme.revamp.primary.blue.root};
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }): string => theme.revamp.staticColors.white};
    color: ${({ theme }): string => theme.revamp.primary.grey.root};
    border-color: ${({ theme }): string => theme.revamp.primary.grey.root};
  }
`
export const ButtonBase = styled.button<{
  variant?: string
  size?: string
}>`
  border: 0;
  border-radius: ${pxToRem(4)};
  cursor: pointer;
  outline: none;
  background-color: ${({ theme }): string => theme.revamp.primary.blue.root};
  color: ${({ theme }): string => theme.revamp.staticColors.white};
  height: ${pxToRem(48)};
  min-width: ${pxToRem(160)};
  width: max-content;
  font-size: ${pxToRem(15)};
  font-weight: 600;
  line-height: ${pxToRem(21.6)};
  padding: ${pxToRem(12)} ${pxToRem(24)};
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child path {
    stroke: ${({ theme }): string => theme.revamp.staticColors.white};
  }
  &:hover {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.light};
  }

  &:focus {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.dark};
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }): string => theme.revamp.primary.grey.root};
  }

  ${({ size }): string =>
    styledInterpolation(
      size === 'small',
      `
      height: ${pxToRem(36)};
    min-width: ${pxToRem(99)};
    font-size: ${pxToRem(14)};
    font-weight: 600;
    padding: ${pxToRem(6)} ${pxToRem(24)};

    `
    )}

  ${({ size }): string =>
    styledInterpolation(
      size === 'xl',
      `
     
    min-width: 100%;

    `
    )}
    

  ${({ variant }): string => styledInterpolation(variant === 'link', LinkStyle)}
  ${({ variant }): string => styledInterpolation(variant === 'secondary', SecondaryStyle)}
`

export const IconWrapper = styled.div<{ iconPosition?: string }>`
  ${({ iconPosition }): string =>
    styledInterpolation(
      iconPosition === 'right',
      `
      margin-top:${pxToRem(2)};
      margin-right:${pxToRem(7)};

    `
    )}
  ${({ iconPosition }): string =>
    styledInterpolation(
      iconPosition === 'linkRight',
      `
      margin-top:${pxToRem(6)};
      margin-right:${pxToRem(5)};
    `
    )}
    ${({ iconPosition }): string =>
    styledInterpolation(
      iconPosition === 'linkLeft',
      `
      margin-top:${pxToRem(6)};
      order:3;
    `
    )}
`

export const Title = styled.div``
