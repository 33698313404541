import { Reducer } from 'redux'

import { UserClientDetails } from 'interfaces/redux/Reducers.interface'
import {
  AccountTypes,
  AuthTypes,
  BankNameTypes,
  BlockerBannerClosed,
  HeaderTypes,
  RoleNameTypes,
  SaveURLTypes,
  VFAActionTypes,
} from 'store/actionTypes'

const InitialState = {
  loading: false,
  error: null,
  authenticated: false,
  generatedPasswordChanged: false,
  activeClient: undefined,
  userDetails: undefined,
  menus: undefined,
  currentClientAccounts: undefined,
  vfa: {
    vfaBankDetails: [],
    vfaEnabledList: [],
  },
  payinBank: undefined,
  receiveOfferedCurrencyList: [],
  featureFlagConfiguration: {
    featureFlagInfo: [{ enabled: false, feature: 'ACH' }],
    userTraits: [],
  },
  rolesLists: [],
  isApiPayoutApprovalRequired: false,
  topUpPricing: 0,
  topUpPricingLoading: false,
  aq_type: '',
  delete_card: false,
  autosweep_bank_account: {
    account_number: '',
    bank_name: '',
    routing_code_value: '',
    routing_code_type: '',
  },
  lastVisitedUrl: null,
  isBlockerBannerClosed: false,
}

const userClientDetailsReducer: Reducer<UserClientDetails> = (state = InitialState, action) => {
  switch (action.type) {
    // TODO: remove on V2 integration completion
    case AuthTypes.USER_LOGIN_AUTH:
      return {
        ...state,
        authenticated: true,
        url: action.payload.redirectUrl,
      }
    case AccountTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case AuthTypes.USER_LOGIN_ERROR:
    case AccountTypes.ACCOUNT_ACCOUNT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      }
    case AccountTypes.ACCOUNT_ACCOUNT_LIST:
      return { ...state, loading: false, currentClientAccounts: action.payload }

    case BankNameTypes.GET_BANK_NAME_REQUEST: {
      return { ...state, loading: true }
    }
    case BankNameTypes.GET_BANK_NAME_SUCCESS: {
      return { ...state, loading: false, payinBank: action.payload }
    }
    case RoleNameTypes.GET_ROLE_NAME_REQUEST: {
      return { ...state, loading: true }
    }
    case RoleNameTypes.GET_ROLE_NAME_LIST: {
      return { ...state, loading: false, rolesLists: action.payload }
    }
    case HeaderTypes.SET_USER_CLIENT_DATA: {
      const {
        defaultCurrency,
        currencyOptions,
        activeClient,
        menus,
        userDetails,
        generatedPasswordChanged,
      } = action.payload
      return {
        ...state,
        loading: false,
        error: null,
        authenticated: true,
        activeClient,
        defaultCurrency,
        currencyOptions,
        menus,
        userDetails,
        generatedPasswordChanged,
      }
    }
    case HeaderTypes.UPDATE_MAKER_CHECKER_FEATURE:
      return {
        ...state,
        isApiPayoutApprovalRequired: action.payload,
      }
    case HeaderTypes.UPDATE_TOP_UP_PRICING:
      return {
        ...state,
        topUpPricing: action.payload,
      }
    case HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_IN_PROGRESS:
      return {
        ...state,
        topUpPricingLoading: true,
      }
    case HeaderTypes.UPDATE_TOP_UP_PRICING_LOADING_DONE:
      return {
        ...state,
        topUpPricingLoading: false,
      }
    case HeaderTypes.UPDATE_ACQUISITION_TYPE:
      return {
        ...state,
        aq_type: action.payload,
      }
    case HeaderTypes.CARD_DETAILS_NOT_AVAILABLE:
      return {
        ...state,
        delete_card: true,
      }
    case HeaderTypes.CARD_DETAILS_AVAILABLE:
      return {
        ...state,
        delete_card: false,
      }
    case HeaderTypes.UPDATE_DEFAULT_CURRENCY:
      return {
        ...state,
        defaultCurrency: action.payload,
      }
    case HeaderTypes.USER_DETAILS:
      return { ...state, userDetails: action.payload }
    case HeaderTypes.SET_RECEIVE_OFFERED_CURRENIES:
      return { ...state, receiveOfferedCurrencyList: action.payload || [] }
    case HeaderTypes.SET_FEATURE_FLAG_CLIENT_CONFIGURATION: {
      return {
        ...state,
        featureFlagConfiguration: {
          ...state.featureFlagConfiguration,
          featureFlagInfo: action.payload.featureFlagInfo,
          userTraits: action.payload.userTraits,
        },
      }
    }
    case HeaderTypes.UPDATE_AUTO_SWEEP_BANK_DETAILS: {
      return {
        ...state,
        autosweep_bank_account: {
          ...state?.autosweep_bank_account,
          account_number: action.payload?.account_number,
          bank_name: action.payload?.bank_name,
          routing_code_value: action.payload?.routing_code_value,
          routing_code_type: action.payload?.routing_code_type,
        },
      }
    }
    case HeaderTypes.UPDATE_CLIENT_BASE_CURRENCY: {
      if (!state.activeClient) {
        return state
      }
      return {
        ...state,
        activeClient: {
          ...state.activeClient,
          client_base_currency: action.payload,
        },
      }
    }
    case VFAActionTypes.SET_VFA_BANK_DETAILS: {
      return {
        ...state,
        vfa: {
          ...state.vfa,
          vfaBankDetails: action.payload,
        },
      }
    }
    case VFAActionTypes.SET_VFA_ENABLED_LIST:
      return {
        ...state,
        vfa: {
          ...state.vfa,
          vfaEnabledList: action.payload,
        },
      }
    case SaveURLTypes.SAVE_LAST_VISITED_URL:
      return {
        ...state,
        lastVisitedUrl: action.payload,
      }
    case SaveURLTypes.RESET_LAST_VISITED_URL:
      return {
        ...state,
        lastVisitedUrl: null,
      }
    case BlockerBannerClosed.IS_BLOCKER_BANNER_CLOSED:
      return {
        ...state,
        isBlockerBannerClosed: true,
      }
    default:
      return state
  }
}

export { userClientDetailsReducer as userClientDetails }
