import { EUCountriesAndStates } from 'Onboarding/pages/corridors/eu/Form/Constants'

export const AUTH_CONSTANTS = Object.freeze({
  SIGNUP_FORM: 'https://www.instarem.com/signup?accountType=CORPORATE',
  LOGIN_FORM: `${process.env.REACT_APP_API_URL}/register`,
  SNACKBAR_TIMEOUT: 5000,
  REDIRECTION_TIMEOUT: 3000,
  TEXTS: {
    // LOGIN_PROMPT and LOGIN_OTP
    LOGIN_CARD_HEADER: 'Log in',
    LOGIN_BUTTON: 'Login',
    LOGIN_FORGET_PASSWORD_LINK: 'Forgot password?',
    LOGIN_FOOTER_TEXT: "Don't have an account?",
    LOGIN_SIGNUP_LINK: 'Sign up now.',
    LOGIN_EMAIL_PLACEHOLDER: 'Email address',
    LOGIN_PASSWORD_PLACEHOLDER: 'Password',
    LOGIN_OTP_CONTENT_1: 'We sent a 6-digit code to',
    LOGIN_OTP_CONTENT_EMAIL_FALLBACK: 'your email',
    LOGIN_OTP_CONTENT_2: "Didn't get a login code? ",
    LOGIN_TRY_AGAIN_LINK: 'Try again',
    LOGIN_OTP_PLACEHOLDER: 'Login code',
    //SIGN_UP
    REGISTERED_BUSINESS_NAME: 'Registered business name',
    CREATE_PASSWORD: 'Create Password',
    COUNTRY_OF_RESIDENCE: 'Country of residence',
    MOBILE_NUMBER: 'Mobile number',
    COUNTRY_OF_RESIDENCE_VALUES: [
      { label: 'Australia', value: 'AU' },
      { label: 'Great Britain', value: 'UK' },
      { label: 'Singapore', value: 'SG' },
    ],
    // FORGET_PASSWORD_PROMPT
    FORGET_PASSWORD_PROMPT_CARD_HEADER: 'Forgot password?',
    FORGET_PASSWORD_PROMPT_BUTTON: 'Send link',
    FORGET_PASSWORD_PROMPT_CONTENT: 'Enter your registered email to receive a password reset link.',
    FORGET_PASSWORD_PROMPT_EMAIL_PLACEHOLDER: 'Email address',
    // FORGET_PASSWORD_SUCCESS
    FORGET_PASSWORD_SUCCESS_CARD_HEADER: 'Email sent!',
    FORGET_PASSWORD_SUCCESS_BUTTON: 'Back to login',
    FORGET_PASSWORD_SUCCESS_CONTENT_1: 'We sent a reset password link to',
    FORGET_PASSWORD_SUCCESS_CONTENT_EMAIL_FALLBACK: 'your email',
    FORGET_PASSWORD_SUCCESS_CONTENT_2: ', so you can reset your password.',
    FORGET_PASSWORD_SUCCESS_CONTENT_3: "Didn't get a reset password link? ",
    FORGET_PASSWORD_SUCCESS_TRY_AGAIN_LINK: 'Try again',
    // RESET_PASSWORD
    RESET_PASSWORD_CARD_HEADER: 'Reset password',
    RESET_PASSWORD_BUTTON: 'Reset password',
    RESET_PASSWORD_CONTENT:
      'New password must be minimum 8 characters, and have at least 1 uppercase letter, 1 lowercase letter, and 1 number.',
    RESET_PASSWORD_NEW_PASSWORD_PLACEHOLDER: 'Enter new password',
    RESET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm new password',
    // SET_PASSWORD
    SET_PASSWORD_CARD_HEADER: 'Set password',
    SET_PASSWORD_BUTTON: 'Confirm',
    SET_PASSWORD_CONTENT:
      'New password must be minimum 8 characters, and have at least 1 uppercase letter, 1 lowercase letter, and 1 number.',
    SET_PASSWORD_OLD_PASSWORD_PLACEHOLDER: 'Old password',
    SET_PASSWORD_NEW_PASSWORD_PLACEHOLDER: 'Enter new password',
    SET_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm new password',
    // SIGN_UP
    SIGN_UP: 'Sign up',
    SIGN_UP_BUTTON: 'Signup',
    SIGN_UP_NEXT: 'Next',
    // MIGRATION
    MIGRATION_CARD_HEADER: 'Experience your new business account',
    MIGRATION_BUTTON: 'Get started',
    MIGRATION_CONTENT:
      'Access new and improved features, and experience fast and seamless global business transfers',
    MIGRATION_EMAIL_PLACEHOLDER: 'Registered email address',
    // MIGRATION_SUCCESS
    MIGRATION_SUCCESS_CARD_HEADER: 'We’ve sent you an email!',
    MIGRATION_SUCCESS_CONTENT_1: 'Please check your inbox at  ',
    MIGRATION_SUCCESS_CONTENT_EMAIL_FALLBACK: 'your email ',
    MIGRATION_SUCCESS_CONTENT_2: ' and use the link provided to log into your account.',
    MIGRATION_SUCCESS_CONTENT_3: 'Didn’t get the email? ',
    MIGRATION_SUCCESS_TRY_AGAIN_LINK: 'Try again',
    // MIGRATION_RESET_TOKEN
    MIGRATION_RESET_TOKEN_CARD_HEADER: 'New account, new password',
    MIGRATION_RESET_TOKEN_CONTENT_1:
      'Before you log into your improved experience, please create a new password  ',
    MIGRATION_RESET_TOKEN_CONTENT_2:
      'Password must be minimum 8 characters, and have at least 1 uppercase letter, 1 lowercase letter, and 1 number. ',
    MIGRATION_RESET_TOKEN_NEW_PASSWORD_PLACEHOLDER: 'Enter new password',
    MIGRATION_RESET_TOKEN_CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm new password',
    MIGRATION_RESET_TOKEN_BUTTON: 'Confirm',
    // MIGRATION_RESET_TOKEN_SUCCESS
    MIGRATION_RESET_TOKEN_SUCCESS_CARD_HEADER: 'You’re all set!',
    MIGRATION_RESET_TOKEN_SUCCESS_CONTENT:
      'Click ‘Proceed to login’ to start enjoying your new account.  ',
    MIGRATION_RESET_TOKEN_SUCCESS_BUTTON: 'Proceed to login',
  },
  UI_ERROR_MESSAGES: {
    REQUIRED: 'Required',
    INVALID_EMAIL: 'Invalid email',
    OTP_MIN_LENGTH: 'OTP should be at least 6 digits',
    OTP_MAX_LENGTH: 'OTP should not be more than 6 digits',
    PASSWORD_INVALID_FORMAT: "Password doesn't meet requirements",
    PASSWORD_NO_MATCH: 'Passwords must match',
    MIGRATION_INVALID_EMAIL: 'Enter a valid email address',
    MIGRATION_INVALID_PASSWORD: 'Enter a valid password',
    MIGRATION_PASSWORD_NO_MATCH: `Passwords don't match`,
  },
  SERVER_ERROR_MESSAGES: {
    UNKNOWN_ERROR: 'Something went wrong! Please try again.',
    INVALID_RESET_TOKEN: 'URL contains an invalid ResetToken.',
  },
  SERVER_SUCCESS_MESSAGES: {
    PASSWORD_UPDATE_SUCCESS: 'Password updated successfully.',
  },
  TEMPLATES: {
    LOGIN_PROMPT: 'login:prompt',
    LOGIN_OTP: 'login:otp',
    FORGET_PASSWORD_PROMPT: 'forgetPassword:prompt',
    FORGET_PASSWORD_SUCCESS: 'forgetPassword:success',
    RESET_PASSWORD: 'resetPassword',
    SET_PASSWORD: 'setPassword',
    SIGN_UP: 'signup',
    GETTING_STARTED: 'gettingStarted',
    MIGRATION: 'migration',
    MIGRATION_SUCCESS: 'migration:success',
    MIGRATION_TOKEN: 'migration/:resetToken',
    MIGRATION_RESET_PASSWORD_SUCCESS: 'migration/:resetToken/success',
  },
  REGEX: {
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,32}$/,
    UUID: /^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}$/gi,
  },
})

export const envArr = ['development', 'qa', 'preprod']

// These are the list of equivalent masspay status keeping this constant here for reference.
// const listOfmasspayStatus = {
//   REGISTERED: 'REGISTERED',
//   COMPLIANCE_INITIATED: 'COMPLIANCE_INITIATED',
//   COMPLIANCE_ACTION_REQUIRED: 'COMPLIANCE_ACTION_REQUIRED',
//   COMPLIANCE_APPROVED: 'COMPLIANCE_APPROVED',
//   COMPLIANCE_REJECTED: 'COMPLIANCE_REJECTED',
//   COMPLIANCE_ERROR: 'COMPLIANCE_ERROR',
//   COMPLIANCE_EXPIRED: 'COMPLIANCE_EXPIRED',
//   COMPLIANCE_IN_PROGRESS: 'COMPLIANCE_IN_PROGRESS',
// }
export const notAllowedStatusesToForm = ['COMPLIANCE_APPROVED']
export const statusArr = ['REGISTERED', 'COMPLIANCE_REJECTED']

const supportedRegions = {
  EU: EUCountriesAndStates,
}
export const getRegion = (country: string): string => {
  for (const reg in supportedRegions) {
    if (country in supportedRegions[reg as keyof typeof supportedRegions]) {
      return reg
    }
  }
  return ''
}
