const sendMoneyIds = Object.freeze({
  sendMoneyLandingPage: {
    newPaymentCta: 'SME_sendmoney_new_payment_cta',
    groupPaymentCta: 'SME_sendmoney_group_payment_cta',
    bulkPaymentCta: 'SME_sendmoney_bulk_payment_cta',
    downloadTemplateCta: 'SME_sendmoney_download_template_cta',
    chooseRecipientTitle: 'SME_sendmoney_chooserecipient_title',
    sendmoneyUpTo50RecipientText: 'SME_sendmoney_upto50recipients_text',
    bulkPaymentText: 'SME_sendmoney_bulk_payment_text',
    downloadTemplateText: 'SME_sendmoney_download_template_text',
    sendMoneyToIndividualRecipient: 'SME_sendmoney_individualrecipient_text',
    individualPaymentTitle: 'SME_sendmoney_individual_payment_title',
    groupPaymentTitle: 'SME_sendmoney_group_payment_title',
    bulkPaymentTitle: 'SME_sendmoney_bulk_payment_title',
    downloadTemplateTitle: 'SME_sendmoney_download_template_title',
  },
  individualFlow: {
    confirmCta: 'SME_sendmoney_individual_payment_confirm_CTA',
    changeCta: 'SME_sendmoney_individual_payment_change_CTA',
    additionalDetailsTitle: 'SME_sendmoney_individual_additional_details_title',
  },
  groupFlow: {
    addToListCta: 'SME_sendmoney_recipient-addtolist-cta',
    recipientDetailsNextCta: 'SME_sendmoney_recipient-next-cta',
    closeRecipientCard: 'SME_sendmoney_group_close_recipientcard',
    setAmountSendDropdown: 'SME_sendmoney_setamount-send-dropdown',
    setAmountNextCta: 'SME_sendmoney_setamount-next-cta',
    reviewDetailsNextCta: 'SME_sendmoney_review-next-cta',
    paymentMethodSubmitCta: 'SME_sendmoney_payment-submit-cta',
    recipientListTitle: 'SME_sendmoney_recipientlist_title',
    youCanSelectUpto50: 'SME_sendmoney_canselectupto50_text',
    bankAccountField: 'SME_sendmoney_bankaccount_field',
    youSendInputField: 'SME_sendmoney_setamount_yousend_input',
    theyReceiveInputField: 'SME_sendmoney_setamount_theyreceive_input',
    fxRateField: 'SME_sendmoney_setamount_fxrate',
    payoutFeeField: 'SME_sendmoney_setamount_payout_fee',
    viewDetailsButton: 'SME_sendmoney_review_viewdetails_button',
    youSendFieldReview: 'SME_sendmoney_review_yousend_value',
    theyReceiveFieldReview: 'SME_sendmoney_review_theyreceive_value',
    youSendFlag: 'SME_sendmoney_review_yousend_flag',
    theyReceiveFlag: 'SME_sendmoney_review_theyreceive_flag',
    setAmountInfoIcon: 'SME_setamount_info_icon',
  },
  sendMoneyCommon: {
    reasonForTransferDropDown: 'SME_sendmoney_reason-select-dropdown',
    recipientDetailsTitle: 'SME_sendmoney_recipient_details_title',
    paymentMethodTitle: 'SME_sendmoney_payment_method_title',
    addNewRecipientCta: 'SME_sendmoney_addrecipient_cta',
    recipientDropDown: 'SME_sendmoney_selectrecipient_dropdown',
    invoiceInputField: 'SME_sendmoney_invoice_input',
    invoicedateInputField: 'SME_sendmoney_invoice_date_input',
    setAmountTitle: 'SME_sendmoney_setamount_title',
    reviewDetails: 'SME_sendmoney_review_details_title',
    recipientName: 'SME_sendmoney_recipient_name',
    recipientBankName: 'SME_sendmoney_recipient_bank_name',
    recipientAccountNumber: 'SME_sendmoney_recipient_accountnum_currency',
    reasonForTransfer: 'SME_sendmoney_recipient_reason_for_transfer',
    subCategory: 'SME_sendmoney_subcategory',
    paymentReference: 'SME_sendmoney_payment_reference',
    swiftFeeInfoDescription: `SME_sendmoney_swift_fee_info_description`,
    swiftFeeInfoAmount: `SME_sendmoney_swift_fee_info_amount`,
  },
  paymentMethods: {
    currencyAccount: {
      currencyAccountBalance: 'SME_sendmoney_currencyaccount_balance',
      currencyAccountTitle: 'SME_sendmoney_currencyaccount',
      currencyAccountLabel: 'SME_sendmoney_currencyaccount_label',
      currencyAccountFlag: 'SME_sendmoney_currencyaccount_flag',
    },
    creditCard: {
      creditCardTitle: 'SME_sendmoney_creditcard',
      addNewCardCta: 'SME_sendmoney_savedcard-newcard-cta',
      newCardNumber: 'SME_sendmoney_newcard-input-cardnumber',
      backToSavedCardsCta: 'SME_sendmoney_backtosavedcards_cta',
      saveCardCheckbox: 'SME_sendmoney_savecard-checkbox',
      savedCardCvvInput: 'SME_sendmoney_savedcard-cvv-input',
      savedCardExpiryInput: 'SME_sendmoney_savedcard-expiry-input',
      newCardCvvInput: 'SME_sendmoney_newcard-input-cvv',
      newCardExpiryInput: 'SME_sendmoney_newcard-input-expiry',
    },
    payNow: {
      payNowTitle: 'SME_sendmoney_paynow',
    },
    payID: { payIdTitle: 'SME_sendmoney_payid' },
    bankTransfer: { bankTransferTitle: 'SME_sendmoney_banktransfer' },
    ach: { achTitle: 'SME_sendmoney_ach' },
    fpxTransfer: { fpxTransferTitle: 'SME_sendmoney_fpxTransfer' },
  },
  tooltip: {
    youSend: 'SME_sendmoney_tooltip_yousend',
    theyReceive: 'SME_sendmoney_tooltip_theyreceive',
    convertedAmount: 'SME_sendmoney_tooltip_converted_amount',
    payoutFee: 'SME_sendmoney_tooltip_payoutfee',
    swiftFee: 'SME_sendmoney_tooltip_swiftfee',
    tooltipKey: 'SME_sendmoney_tooltip_key',
    tooltipValue: 'SME_sendmoney_tooltip_value',
  },
  viewDetails: {
    reasonForTransfer: 'SME_review_reason_for_transfer',
    otherDetails: 'SME_review_other_details',
    invoiceNumber: 'SME_review_invoice_number',
    invoiceDetails: 'SME_review_invoice_details',
    theyReceive: 'SME_review_they_receive',
    fxrate: 'SME_review_fxrate',
    convertedAmount: 'SME_review_converted_amount',
    payoutFee: 'SME_review_payout_fee',
    swiftFee: 'SME_review_swift_fee',
    youPay: 'SME_review_you_pay',
    paymentDetails: 'SME_review_payment_details',
    accountNumber: 'SME_review_account_number',
    bankName: 'SME_review_bank_name',
    recipientName: 'SME_review_recipient_name',
    recipientDetails: 'SME_review_recipient_details',
    viewDetailsKey: 'SME_review_viewdetails_key',
    viewDetailsValue: 'SME_review_viewdetails_value',
  },
  bulkReviewDetails: {
    reasonForTransfer: 'SME_bulk_review_reason_for_transfer',
    otherDetails: 'SME_bulk_review_other_details',
    invoiceNumber: 'SME_bulk_review_invoice_number',
    invoiceDetails: 'SME_bulk_review_invoice_details',
    theyReceive: 'SME_bulk_review_they_receive',
    fxrate: 'SME_bulk_review_fxrate',
    convertedAmount: 'SME_bulk_review_converted_amount',
    payoutFee: 'SME_bulk_review_payout_fee',
    swiftFee: 'SME_bulk_review_swift_fee',
    youPay: 'SME_bulk_review_you_pay',
    paymentDetails: 'SME_bulk_review_payment_details',
    accountNumber: 'SME_bulk_review_account_number',
    bankName: 'SME_bulk_review_bank_name',
    recipientName: 'SME_bulk_review_recipient_name',
    recipientDetails: 'SME_bulk_review_recipient_details',
    viewDetailsKey: 'SME_bulk_review_viewdetails_key',
    viewDetailsValue: 'SME_bulk_review_viewdetails_value',
    youSendFlag: 'SME_bulk_sendmoney_review_yousend_flag',
    viewDetailsCTA: 'SME_sendmoney_review_viewdetails_button',
    totalConvertedAmount: 'SME_bulk_review_total_converted_amount',
    totalPayoutFees: 'SME_bulk_review_total_payout_fee',
    reviewDetailsNextCta: 'SME_bulk_review_next_btn_cta',
    transactionSummary: 'SME_bulk_summary_transactionsummary_title',
    paymentReviewDetailsTitle: 'SME_payment_review_details_title',
    fxTimerDescription: 'SME_bulk_fx_timer_description',
    fxTimer: 'SME_bulk_fx_timer',
    paymentMethodInfoNote: 'SME_bulk_payment_method_info_note',
    paymentSummarywarningText: 'SME_payment_summary_warning_text',
    bulkReviewTitle: 'SME_bulk_review_title',
    bulkReviewTransactionsCount: 'SME_bulk_review_transactions_count',
    bulkReviewSuccessBar: 'SME_bulk_review_success_bar',
    bulkReviewFailedBar: 'SME_bulk_review_failed_bar',
    bulkReviewSuccessTransCount: 'SME_bulk_review_success_trans_count',
    bulkReviewFailedTransCount: 'SME_bulk_review_failed_trans_count',
    bulkReviewErrorFileText: 'SME_bulk_review_error_file_text',
    bulkReviewDownloadErrorFileBtn: 'SME_bulk_review_download_error_file_btn',
    bulkReviewTableHeader: 'SME_bulk_review_table_header',
    bulkReviewContinueTxn: 'SME_bulk_review_continue_transaction',
    bulkReviewCancelTxn: 'SME_bulk_review_continue_transaction',
  },
  bulkPayment: {
    bulkPaymentHeader: 'SME_bulk_payment_header',
    bulkPaymentTitle: 'SME_bulk_payment_title',
    bulkPaymentSubTitle: 'SME_bulk_payment_sub_title',
    bulkPaymentInfoStep1: 'SME_bulk_payment_info_step_1',
    bulkPaymentInfoStep2: 'SME_bulk_payment_info_step_2',
    bulkPaymentInfoStep3: 'SME_bulk_payment_info_step_3',
    bulkPaymentUploadInput: 'SME_bulk_payment_upload_input',
    bulkPaymentProgressBarDescription: 'SME_bulk_payment_progress_bar_description',
    bulkPaymentProgressBarSubDescription: 'SME_bulk_payment_progress_bar_sub_desciption',
    bulkPaymentProgressBar: 'SME_bulk_payment_progress_bar',
  },
})

const myaccountsIds = Object.freeze({
  myAccountTitles: {
    myAccountTitle: 'SME_myaccounts_title',
    openCurrencyAccountsTitle: 'SME_myaccount_opencurr_title',
  },
  myAccountsButtons: {
    addMoneyButton: 'SME_myaccounts_addmoney_button',
    exchangeButton: 'SME_myaccounts_exchange_button',
    withdrawButton: 'SME_myaccounts_withdraw_button',
    shareButton: 'SME_myaccounts_share_button',
    ellipseButton: 'SME_myaccounts_ellipse_button',
  },

  myAccountsWalletFields: {
    currencyFlag: 'SME_myaccounts_currency_flag',
    currencyBalanceWrapper: 'SME_myaccount_wallet_balance_wrapper',
    balanceAmount: 'SME_myaccounts_balance_amount',
    balanceCurrency: 'SME_myaccounts_balance_currency',
  },
  addMoney: {
    addMoneyTitle: 'SME_addmoney_title',
    selectPaymentTitle: 'SME_addmoney_selectpayment_title',
    currencyInput: 'SME_addmoney_currency_input',
    currencyLabel: 'SME_addmoney_currency',
  },
  exchangeMoney: {
    exchangeMoneyTitle: 'SME_exchange_money_title',
    fxRate: 'SME_exchange_fxrate',
    exchangeCta: 'SME_exchange_cta',
    fromCurrencyBalance: 'SME_exchange_from_currency_balance_text',
    toCurrencyBalance: 'SME_exchange_to_currency_balance_text',
    exchangeIcon: 'SME_exchange_icon',
    fromDropdwon: 'SME_exchange_from_dropdown',
    toDropdown: 'SME_exchange_to_dropdown',
    fromCurrencyInput: 'SME_exchange_from_input',
    toCurrencyInput: 'SME_exchange_to_input',
  },
  withdrawMoney: {
    withdrawMoneyTitle: 'SME_withdraw_money_title',
    currencyInput: 'SME_withdraw_currency_input',
    currencyLabel: 'SME_withdraw_from_currency',
    balance: 'SME_withdraw_currency_balance',
    selectBankTitle: 'SME_withdraw_select_bank',
    addNewBankAccount: 'SME_withdraw_add_new_bank_acc_button',
    newBankAccountText: 'SME_withdraw_new_bankaccount_text',
    infoIcon: 'SME_withdraw_info_icon',
    //className
    withdrawFormInput: 'SME_withdraw_form_input',
  },
  shareDetails: {
    shareModalTitle: 'SME_share_details_title',
    descriptionText: 'SME_share_details_description',
    localTab: 'SME_share_details_local_tab',
    globalTab: 'SME_share_details_global_tab',
    contentKey: 'SME_share_details_content_key',
    contentValue: 'SME_share_details_content_value',
    retryButton: 'SME_share_details_retry_button',
    errorMessage: 'SME_share_details_error_message',
    optionalMessage: 'SME_share_details_optional_message',
  },
  openCurrencyAccounts: {
    cardWrapper: 'SME_myaccs_open_accs_card_wrapper',
    subtext: 'SME_myaccs_open_accs_modal_subtext',
    currLabel: 'SME_myaccs_open_accs_modal_currency_label',
    currFlag: 'SME_myaccs_open_accs_modal_currency_flag',
    openAccsText: 'SME_myaccs_open_accs_text',
  },
})

const promoBannerIds = Object.freeze({
  promoBanner: {
    closeButton: 'SME_promo_banner_close_button',
    gotitButton: 'SME_promo_banner_gotit_button',
    headerText: 'SME_promo_banner_header_text',
    subtext: 'SME_promo_banner_subtext',
  },
})

const addNewBankAccountBannerIds = Object.freeze({
  addBankBanner: {
    closeButton: 'SME_add_new_bank_banner_close_button',
    linkNowButton: 'SME_add_new_bank_banner_link_now_button',
    headerText: 'SME_add_new_bank_banner_header_text',
    subtext: 'SME_add_new_bank_banner_subtext',
  },
})

const radioAccordionIds = Object.freeze({
  accordionRadio: 'SME_radio_accordion_radio_button',
  accordionMainWrapper: 'SME_radio_accordion_main_wrapper',
  accordionTitle: 'SME_radio_accordion_title',
  accordionDescription: 'SME_radio_accordion_description',
})

const summaryIds = Object.freeze({
  summaryTitles: {
    transactionSummary: 'SME_summary_transactionsummary_title',
    withdrawSummary: 'SME_summary_withdrawsummary_title',
  },
  summaryFields: {
    youAreAdding: 'SME_summary_you_adding',
    totalFees: 'SME_summary_total_fees',
    creditCardFee: 'SME_summary_creditcard_fee',
    totalPayableAmount: 'SME_summary_total_payable_amount',
    totalPayableAmountLabel: 'SME_summary_total_payable_amount_label',
    contentKey: 'SME_summary_content_key',
    contentValue: 'SME_summary_content_value',
    totalConvertedAmount: 'SME_summary_total_converted_amount',
    payoutFee: 'SME_summary_payout_fee',
    swiftFee: 'SME_summary_swift_fee',
    thirdSection: 'SME_summary_creditcard_or_currencybal',
    numberOfRecipients: 'SME_summary_numberofrecipients',
    youAreWithdrawing: 'SME_summary_you_withdrawing',
    requestedAmount: 'SME_summary_requested_amount',
    transactionFee: 'SME_summary_transaction_fee',
  },
  summaryCTAs: {
    addMoneyCta: 'SME_summary_addmoney_cta',
    withdrawCta: 'SME_summary_withdraw_cta',
  },
})

const successScreenIds = Object.freeze({
  successScreen: {
    statusTitle: 'SME_success_screen_statustitle',
    description: 'SME_success_screen_description',
    moreInfo: 'SME_success_screen_moreinfo',
    contentKey: 'SME_success_screen_content_key',
    contentValue: 'SME_success_screen_content_value',
    payNowText: 'SME_success_screen_paynow_text',
    payNowImg: 'SME_success_screen_paynow_img',
    newPaymentCta: 'SME_success_screen_newpayment_cta',
    exchangeCta: 'SME_success_screen_exchange_cta',
    trackStatusCta: 'SME_success_screen_trackstatus_cta',
    goToDashboardCta: 'SME_success_screen_gotodashboard_cta',
    sendmoneyCta: 'SME_success_screen_sendmoney_cta',
    newExchangeCta: 'SME_success_screen_newexchange_cta',
    trackRequestCta: 'SME_success_screen_trackrequest_cta',
    goToMyAccountsCta: 'SME_success_screen_gotomyaccounts_cta',
    newRequestCta: 'SME_success_screen_new_request_cta',
    contentTable: {
      paymentRequestId: 'SME_success_table_payment_request_id',
      payerName: 'SME_success_table_payer_name',
      requestedAmount: 'SME_success_table_requested_amount',
      invoiceNumber: 'SME_success_table_invoice_number',
      sortCode: 'SME_success_table_sort_code',
      amountRequired: 'SME_success_table_amount_required',
      totalPayableAmount: 'SME_success_total_payable_amount',
      status: 'SME_success_table_status',
      companyName: 'SME_success_table_company_name',
      bankName: 'SME_success_table_bank_name',
      accountNumber: 'SME_success_table_account_number',
      bankAddress: 'SME_success_table_bank_address',
      accountType: 'SME_success_table_account_type',
      swiftCode: 'SME_success_table_swift_code',
      bankCode: 'SME_success_table_bank_code',
      branchCode: 'SME_success_table_branch_code',
      bsbCode: 'SME_success_table_bsb_code',
      referenceNumber: 'SME_success_table_reference_number',
      remarks: 'SME_success_table_remarks',
      accountName: 'SME_success_table_account_name',
      UEN: 'SME_success_table_UEN',
      payId: 'SME_success_table_payid',
      paymentMethod: 'SME_success_table_payment_method',
      totalPaidAmount: 'SME_success_table_totalpaid_amount',
      noOfRecipients: 'SME_success_table_noofrecipients',
      transactionId: 'SME_success_table_transaction_id',
      recipientName: 'SME_success_table_recipient_name',
      theyReceiveAmount: 'SME_success_table_theyreceive_amount',
      exchangedAmount: 'SME_success_table_exchanged_amount',
      fxRate: 'SME_success_table_fxrate',
      withdrawAmount: 'SME_success_table_amount',
      youAddedAmount: 'SME_success_table_you_added',
      totalFees: 'SME_success_table_total_fees',
    },
  },
})

const currencyCalculatorIds = Object.freeze({
  currencyCalculator: {
    currencyCalculatorTitle: 'SME_dashboard_currency_calc_title',
    youSendDropDown: 'SME_dashboard_currency-calc-send-dropdown',
    theyReceiveDropDown: 'SME_dashboard_currency-calc-receive-dropdown',
    fxRateTitle: 'SME_currency_calc_fxrate_title',
    fxRateValue: 'SME_currency_calc_fxrate_value',
    youSendInputField: 'SME_currency_calc_yousend_input_field',
    theyReceiveInputField: 'SME_currency_calc_theyreceive_input_field',
    currencyBalanceText: 'SME_currency_calc_account_balance_text',
    payoutFeeTitle: 'SME_currency_calc_payout_fee',
    swiftFeeTitle: 'SME_currency_calc_swift_fee',
    payoutFeeValue: 'SME_currency_calc_payout_fee_value',
    swiftFeeValue: 'SME_currency_calc_swift_fee_value',
    currencyFlag: 'SME_currency_flag',
    currencyLabel: 'SME_currency_label',
  },
})

const pageHeaderIds = Object.freeze({
  closeButton: 'SME_pageheader_close_button',
  brand: 'SME_pageheader_brand',
})

const settingsIds = Object.freeze({
  settingsLandingPage: {
    settingsBackToMoreCta: 'SME_settings_back_to_more_cta',
    profileCardCta: 'SME_profile_card_cta',
    securityCardCta: 'SME_security_card_cta',
  },
  profileSettingsPage: {
    profileBackDesktopCta: 'SME_profile_settings_back_desktop_cta',
    profileBackMobileCta: 'SME_profile_settings_back_mobile_cta',
    profileUpdateCta: 'SME_profile_update_cta',
    profileUpdateForm: 'update-account-form',
    nameInputField: 'SME_name_input_field',
    emailInputField: 'SME_email_input_field',
  },
  securitySettingsPage: {
    securityBackDesktopCta: 'SME_security_settings_back_desktop_cta',
    securityBackMobileCta: 'SME_security_settings_back_mobile_cta',
    passwordUpdateCta: 'SME_password_update_cta',
    passwordUpdateForm: 'update-password-form',
    currentPasswordInputField: 'SME_current_password_input_field',
    newPasswordInputField: 'SME_new_password_input_field',
    confirmPasswordInputField: 'SME_confirm_password_input_field',
  },
  userSettingsPage: {
    deactivateButton: 'SME_usersettings_deactivate_button',
    editButton: 'SME_usersettings_edit_button',
    approverCheckBox: 'SME_usersettings_approverCheckBox',
    deactivateModalDropdown: 'SME_usersettings_deactivateModalDropdown',
  },
  autoSweepBankDetailsPage: {
    bankNameInputField: 'SME_bankName_input_field',
    bankAccountNumberInputField: 'SME_bankAccountNumber_input_field',
    swiftCodeInputField: 'SME_swiftCode_input_field',
    bankDetailsSaveCta: 'SME_bankDetails_save_cta',
    bankDetailsForm: 'update-bankDetails-form',
    bankAccountBackCTA: 'SME_bankAccount_back_cta',
    deleteBankDetailsTitle: 'SME_bankAccount_settings_delete_title',
    deleteBankDetailsText: 'SME_bankAccount_settings_delete_content_text',
    addBankAccountCta: 'SME_auto_sweep_add_account_cta',
  },
})

const dashboardIds = Object.freeze({
  sideBar: {
    dashboardButton: 'SME_sidebar_dashboard_button',
    myAccountsButton: 'SME_sidebar_myaccounts_button',
    sendMoneyButton: 'SME_sidebar_sendmoney_button',
    billsButton: 'SME_sidebar_bills_button',
    contactsButton: 'SME_sidebar_contacts_button',
    transactionsButton: 'SME_sidebar_transactions_button',
    approvalsButton: 'SME_sidebar_approvals_button',
    helpButton: 'SME_sidebar_help_button',
    moreButton: 'SME_sidebar_more_button',
    collapsibleMenuButton: 'SME_sidebar_collapsible_menu_button',
    poweredByNIUM: 'SME_sidebar_poweredbyNIUM',
  },
  quickActions: {
    quickActionTitle: 'SME_dashboard_quickactions_title',
    sendMoney: 'SME_dashboard_quickactions_sendmoney',
    addAContact: 'SME_dashboard_quickactions_addAContact',
    leaveFeedback: 'SME_dashboard_quickactions_leavefeedback',
    actionText: 'SME_dashboard_quickactions_action_text',
    actionSubText: 'SME_dashboard_quickactions_action_subtext',
  },
  userProfileDropdown: {
    profileDropdown: 'SME_userprofile_dropdown',
    settingsButton: 'SME_profiledropdown_settings_button',
    logoutButton: 'SME_profiledropdown_logout_button',
  },
  accountBalance: {
    accountBalanceTitle: 'SME_accountbal_title',
    primaryAccountBalance: 'SME_accountbal_primary_account',
    secondaryAccountBalance: 'SME_accountbal_secondary_account',
  },
})

const modalIds = Object.freeze({
  modalTitle: 'SME_modal_title',
  modalCloseButton: 'SME_modal_close_button',
  modalBodyText: 'SME_modal_body_text',
  modalSectionText: 'SME_modal_section_text',
  modalCancelButton: 'SME_modal_cancel_button',
  modalConfirmButton: 'SME_modal_confirm_button',
})

const approvalsIds = Object.freeze({
  approvalsLandingPage: {
    pageTitle: 'SME_pending_approvals_title',
    emptyStateText: 'SME_approvals_empty_state_text',
    selectAllCheckbox: 'SME_approve-transaction-checkbox-all',
    selectAllLabel: 'SME_approvals_select_all_label',
  },
  approvalsFooter: {
    selectedTransCount: 'SME_approvals_footer_selected_trans_count',
    footerApproveButton: 'SME_approvals_footer_approve_button',
    footerRejectButton: 'SME_approvals_footer_reject_button',
    deselectButton: 'SME_approvals_footer_deselect_button',
  },
  approvalsModal: {
    multiSelectedTrans: 'SME_approvals_modal_multi_selected',
    transName: 'SME_approvals_modal_trans_name',
    transAmount: 'SME_approvals_modal_trans_amount',
  },
  approvalLineItem: {
    transName: 'SME_approvals_lineitem_trans_name',
    transId: 'SME_approvals_lineitem_trans_id',
    sourceAmount: 'SME_approvals_lineitem_source_amount',
    destinationAmount: 'SME_approvals_lineitem_destination_amount',
    rejectButton: 'SME_approvals_reject_button',
    approveButton: 'SME_approvals_approve_button',
  },
})

const paginationIds = Object.freeze({
  pagination: {
    leftArrow: 'SME_pagination_left_arrow',
    rightArrow: 'SME_pagination_left_arrow',
    paginationContainer: 'SME_pagination_container',
  },
})

const helpCorridorsIds = Object.freeze({
  corridorAccordion: {
    accordionLabel: 'SME_help_corridor_accordion_label',
    curency: 'SME_help_corridor_currency',
    backButton: 'SME_help_corridors_back_button',
    sendMoneyTitle: 'SME_help_corridor_send_money_title',
    descText: 'SME_help_send_money_desc_text',
  },
  faqCard: {
    title: 'SME_help_faq_card_title',
    icon: 'SME_help_faq_card_icon',
    desc: 'SME_help_faq_card_desc',
  },
  helpLandingPage: {
    desc: 'SME_help_description',
    title: 'SME_help_title',
    firstText: 'SME_help_footer_first_text',
    secondText: 'SME_help_footer_second_text',
    lastText: 'SME_help_footer_last_text',
    instaremEmail: 'SME_help_footer_instarem_email',
  },
  individualFaqPageContent: {
    text: 'SME_help_faq_content_text',
    list: 'SME_help_faq_content_list',
    subText: 'SME_help_faq_content_subtext',
    additionalText: 'SME_help_faq_content_additional_text',
    clickHereCta: 'SME_help_faq_click_here_cta',
    backButton: 'SME_help_faq_back_button',
    faqTitle: 'SME_help_faq_page_title',
  },
})

const dropdownAccordionIds = Object.freeze({
  dropdownAccordion: {
    accordionHeader: 'SME_dropdown_accordion_header',
    accordionTitle: 'SME_dropdown_accordion_title',
  },
})

const contactsIds = Object.freeze({
  contactsTitles: {
    contacts: 'SME_contacts_title',
    addRecipient: 'SME_contacts_add_recipient_title',
    editContact: 'SME_contacts_edit_contact_title',
    accountType: 'SME_contacts_account_type_title',
    accountDetails: 'SME_contacts_account_details_title',
    recipientDetails: 'SME_contacts_recipient_details_title',
    viewDetails: 'SME_contacts_view_details_title',
    recipientType: 'SME_contacts_recipient_type_title',
    deleteContact: 'SME_contacts_delete_contact_title',
  },
  contactsFilters: {
    searchQuery: 'SME_contacts_search_query',
    currencyField: 'SME_contacts_currency_field',
    countryField: 'SME_contacts_country_field',
    clearFilterButton: 'SME_contacts_clear_filter_button',
    filterButton: 'SME_contacts_filter_button',
  },
  contactsButtons: {
    lookupCta: 'SME_contacts_form_lookup-cta',
    saveChangesCta: 'SME_contacts_save_changes_cta',
    continueCta: 'SME_contacts_edit_continue_cta',
    addRecipientContinueCta: 'SME_contacts_add-recipient-cta',
    sendMoneyOption: 'SME_contacts_menu_sendmoney_button',
    editOption: 'SME_contacts_menu_edit_button',
    deleteOption: 'SME_contacts_menu_delete_button',
    viewDetailsOption: 'SME_contacts_viewdetails_button',
    menuButton: 'SME_contacts_menu_button',
    addARecipientCta: 'SME_contacts_add_recipient_cta',
    addPayerCta: 'SME_contacts_add_payer_cta',
    editButton: 'SME_contacts_viewdetails_edit_button',
  },
  contactsFields: {
    accountNumber: 'SME_contacts_bene_account_num',
    currencyAccountType: 'SME_contacts_bene_currency_account_type',
    relationship: 'SME_contacts_bene_relationship',
    postCode: 'SME_contacts_bene_postcode',
    state: 'SME_contacts_bene_state',
    city: 'SME_contacts_bene_city',
    address: 'SME_contacts_bene_address',
    mobileNumber: 'SME_contacts_bene_mobile_num',
    email: 'SME_contacts_bene_email',
    beneName: 'SME_contacts_bene_name',
    currency: 'SME_contacts_bene_currency',
    country: 'SME_contacts_bene_country',
    accountType: 'SME_contacts_bene_account_type',
    bankName: 'SME_contacts_bene_bank_name',
    bankCode: 'SME_contacts_bene_bank_code',
    countryDropdown: 'SME_contacts_form_country-dropdown',
    currencyDropdown: 'SME_contacts_form_currency-dropdown',
    beneAddressInput: 'SME_contacts_form_beneficiary_address',
    beneBankNameInput: 'SME_contacts_form_beneficiary_bank_name',
    banDetailsWrapper: 'SME_contacts_bank_details_wrapper',
    recipientBankName: 'SME_contacts_recipient_bank_name',
    recipientAccountNumber: 'SME_contacts_recipient_account_number',
    recipientName: 'SME_contacts_recipient_name',
    recipientCountry: 'SME_contacts_recipient_country',
    recipientCurrency: 'SME_contacts_recipient_currency',
    menuIcon: 'SME_contacts_menu_icon',
    emptyStateText: 'SME_contacts_empty_state_text',
    viewDetailsKey: 'SME_contacts_viewdetails_key',
    viewDetailsValue: 'SME_contacts_viewdetails_value',
    deleteContactText: 'SME_contacts_delete_content_text',
  },
  contactsForms: {
    recepientDetailsForm: 'SME_contacts_recipient_details_form',
    bankDetailsForm: 'SME_contacts_bank_details_form',
  },
})

export const reasonForTransferIds = Object.freeze({
  reasonForTransfer: {
    intraCompany: 'SME_reason_for_transfer_intra_company',
    utilityPayment: 'SME_reason_for_transfer_utility_payment',
    loanPayment: 'SME_reason_for_transfer_loan_payment',
    taxPayment: 'SME_reason_for_transfer_tax_payment',
    rentalPayment: 'SME_reason_for_transfer_rental_payment',
    insurancePayment: 'SME_reason_for_transfer_insurance_payment',
    investments: 'SME_reason_for_transfer_investments',
    servicePurchase: 'SME_reason_for_transfer_service_purchase',
    salary: 'SME_reason_for_transfer_salary',
    goodsPurchase: 'SME_reason_for_transfer_goods_purchase',
  },
})

export const calendarIds = Object.freeze({
  calendarMonths: {
    january: 'SME_calendar_month_january',
    february: 'SME_calendar_month_february',
    march: 'SME_calendar_month_march',
    april: 'SME_calendar_month_april',
    may: 'SME_calendar_month_may',
    june: 'SME_calendar_month_june',
    july: 'SME_calendar_month_july',
    august: 'SME_calendar_month_august',
    september: 'SME_calendar_month_september',
    october: 'SME_calendar_month_october',
    november: 'SME_calendar_month_november',
    december: 'SME_calendar_month_december',
  },
  calendarDropdowns: {
    monthsDropdown: 'SME_calendar_months_dropdown',
    yearsDropdown: 'SME_calendar_years_dropdown',
  },
  calendarDatePicker: {
    datePicker: 'SME_calendar_date_range_picker',
    calendarIcon: 'SME_calendar_icon',
  },
})

export const transactionsIds = Object.freeze({
  transactionsLandingPage: {
    recentActivity: 'SME_transactions_recent_activity_title',
    viewAllButton: 'SME_transactions_viewall_button',
    filterButton: 'SME_transactions_filter_button',
    downloadCsvButton: 'SME_transactions_download_csv_button',
    transactionsTypeDropdown: 'SME_transactions_trans_type_dropdown',
    calendar: 'SME_transactions_calendar',
    emptyStateText: 'SME_transactions_empty_state_text',
    footerText: 'SME_transactions_footer_text',
  },
  transactionTableLineItem: {
    description: 'SME_transaction_lineitem_description',
    id: 'SME_transaction_lineitem_id',
    amount: 'SME_transaction_lineitem_amount',
    subAmount: 'SME_transaction_lineitem_sub_amount',
    status: 'SME_transaction_lineitem_status',
    dueDate: 'SME_transaction_lineitem_duedate',
  },
  banner: {
    transactionsCount: 'SME_banner_item_transactions_count',
    closestDueDate: 'SME_banner_item_closest_duedate',
    title: 'SME_banner_item_title',
    arrowIcon: 'SME_banner_item_icon',
    pendingApproval: 'SME_banner_pending_approvals',
    awaitingFunds: 'SME_banner_awaiting_funds',
    rfiTransactions: 'SME_banner_rfi_transactions',
    headerText: 'SME_banner_header_text',
    addBankIcon: 'SME_banner_item_bank_icon',
    linkBankAccount: 'SME_banner_link_back_account',
    swiftInfoBanner: 'SME_banner_swift_info_banner',
    swiftInfoBannerText: 'SME_banner_swift_info_banner_text',
    swiftFeeConstantAco: `SME_swift_fee_payer_grey_note_info_aco_amount`,
    swiftFeeConstantSha: `SME_swift_fee_payer_grey_note_info_sha_amount`,
    swiftFeePayerSectionGrayNoteDescription: `SME_swift_fee_payer_grey_note_info_description`,
  },
  transactionTypes: {
    allTypes: 'SME_transactions_type_all',
    moneyAdded: 'SME_transactions_type_money_added',
    moneySent: 'SME_transactions_type_money_sent',
    moneyWithdrawn: 'SME_transactions_type_money_withdrawn',
    moneyExchanged: 'SME_transactions_type_money_exchanged',
    moneyReceived: 'SME_transactions_type_money_received',
  },
  rfiFormFields: {
    noteTitle: 'SME_transaction_rfi_form_note_title',
    noteDesc: 'SME_transaction_rfi_form_note_description',
    finalNote: 'SME_transaction_rfi_form_final_note',
    fileSizeFormatNote: 'SME_transaction_rfi_form_file_size_format_note',
    formTitle: 'SME_transaction_rfi_form_title',
    submitButton: 'SME_transaction_rfi_form_submit_button',
    requestedOnDate: 'SME_transaction_rfi_requested_on_date',
    dueByDate: 'SME_transaction_rfi_due_by_date',
    closebutton: 'SME_transaction_rfi_modal_close_button',
    modalTitle: 'SME_transaction_rfi_modal_title',
    infoRequestedText: 'SME_transaction_rfi_form_info_requested_text',
  },
  transactionDesc: {
    submitPaymentRequestCta: 'SME_transaction_desc_submit_cta',
    newRequestCta: 'SME_transaction_desc_new_request_cta',
    requestedAmount: 'SME_transaction_desc_requested_amount',
    youReceiveAmount: 'SME_transaction_desc_youreceive_amount',
    payerName: 'SME_transaction_desc_payer_name',
    requestDesc: 'SME_transaction_desc_payment_req_desc',
    paymentReqDetailsTitle: 'SME_transaction_desc_payment_req_details_title',
    amountAdded: 'SME_transaction_desc_amount_added',
    totalPaidAmount: 'SME_transaction_desc_total_paid_amount',
    totalFees: 'SME_transaction_desc_total_fees',
    bankAccountName: 'SME_transaction_desc_bank_account_name',
    withdrawalAmount: 'SME_transaction_desc_withdrawal_amount',
    creditCardFees: 'SME_transaction_desc_credit_card_fees',
    senderDetails: 'SME_transaction_desc_sender_details',
    creditedAmount: 'SME_transaction_desc_credited_amount',
    appliedFees: 'SME_transaction_desc_applied_fees',
    receivedAmount: 'SME_transaction_desc_received_amount',
    senderName: 'SME_transaction_desc_sender_name',
    sendersBankName: 'SME_transaction_desc_senders_bank_name',
    sendersAccountNumber: 'SME_transaction_desc_senders_account_number',
    referenceNumber: 'SME_transaction_desc_reference_number',
    heading: 'SME_transaction_desc_heading',
    status: 'SME_transaction_desc_status',
    dueDate: 'SME_transaction_desc_duedate',
    completedDate: 'SME_transaction_desc_trans_completed_date',
    transId: 'SME_transaction_desc_trans_id',
    cancelButton: 'SME_transaction_desc_cancel_button',
    downloadButton: 'SME_transaction_desc_download_button',
    backButton: 'SME_transaction_desc_back_button',
    exchangedFrom: 'SME_transaction_desc_exchanged_from',
    exchangedTo: 'SME_transaction_desc_exchanged_to',
    fxRate: 'SME_transaction_desc_fxrate',
    transactionTitle: 'SME_transaction_desc_title',
    contentLabel: 'SME_transaction_desc_content_label',
    contentValue: 'SME_transaction_desc_content_value',
    exchangeSummary: 'SME_transaction_desc_exchange_summary',
    paymentSummary: 'SME_transaction_desc_payment_summary',
    requestSummaryTitle: 'SME_transaction_desc_request_summary_title',
    withdrawalSummary: 'SME_transaction_desc_withdrawal_summary',
    recipientDetails: 'SME_transaction_desc_recipient_details',
    otherDetails: 'SME_transaction_desc_other_details',
    invoiceNumber: 'SME_transaction_desc_invoice_number',
    invoice: 'SME_transaction_desc_invoice',
    reasonForTransfer: 'SME_transaction_desc_reason_for_transfer',
    batchId: 'SME_transaction_desc_batch_id',
    paymentMethod: 'SME_transaction_desc_payment_method',
    totalAmount: 'SME_transaction_desc_total_amount',
    payoutFees: 'SME_transaction_desc_payout_fees',
    supplierFees: 'SME_transaction_desc_supplier_fee',
    convertedAmount: 'SME_transaction_desc_converted_amount',
    theyReceive: 'SME_transaction_desc_they_receive',
    recipientName: 'SME_transaction_desc_recipient_name',
    destinationCurrency: 'SME_transaction_desc_destination_currency',
    bankName: 'SME_transaction_desc_bank_name',
    accountNumber: 'SME_transaction_desc_account_number',
  },
  statusTracker: {
    approvalButton: 'SME_transaction_status_tracker_approve_button',
    rejectButton: 'SME_transaction_status_tracker_reject_button',
    title: 'SME_transaction_status_tracker_title',
    vraPaymentCta: 'SME_transactions_vraPayment-cta',
    description: 'SME_transaction_status_tracker_description',
    descriptionWrapper: 'SME_transaction_status_tracker_description_wrapper',
    subText: 'SME_transaction_status_tracker_subtext',
    note: 'SME_transaction_status_tracker_note',
    awaitingFundsnote: 'SME_transaction_status_tracker_awaiting_funds_note',
    failureDescription: 'SME_transaction_status_tracker_failure_description',
    paymentMethodDropdown: 'SME_transaction_payment_method_dropdown',
    rfiListWrapper: 'SME_transaction_status_tracker_rfi_list_wrapper',
    tableContent: {
      remarks: 'SME_transaction_table_content_remarks',
      referenceNumber: 'SME_transaction_table_content_reference_number',
      bsbCode: 'SME_transaction_table_content_bsb_code',
      branchCode: 'SME_transaction_table_content_branch_code',
      bankCode: 'SME_transaction_table_content_bank_code',
      swiftCode: 'SME_transaction_table_content_swift_code',
      accountType: 'SME_transaction_table_content_account_type',
      bankAddress: 'SME_transaction_table_content_bank_address',
      accountNumber: 'SME_transaction_table_content_account_number',
      bankName: 'SME_transaction_table_content_bank_name',
      companyName: 'SME_transaction_table_content_company_name',
      totalPayableAmount: 'SME_transaction_table_content_total_payable_amount',
      contentLabel: 'SME_transaction_table_content_label',
      contentValue: 'SME_transaction_table_content_value',
      payID: 'SME_transaction_table_content_payid',
      accountName: 'SME_transaction_content_account_name',
      UEN: 'SME_transaction_table_content_UEN',
      payNow: 'SME_transaction_table_content_paynow',
      payNowText: 'SME_transaction_table_content_paynow_text',
      payNowImg: 'SME_transaction_table_content_paynow_img',
      amountRequired: 'SME_transaction_table_content_amount_required',
      routingCode: 'SME_transaction_table_content_routing_code',
      routingCode2: 'SME_transaction_table_content_routing_code2',
    },
  },
  approvalModal: {
    bodyText: 'SME_transaction_approval_modal_body_text',
    subtext: 'SME_transaction_approval_modal_body_subtext',
    beneName: 'SME_transaction_approval_modal_bene_name',
    currencyAmount: 'SME_transaction_approval_modal_currency_amount',
  },
  transactionSteps: {
    stepTitle: 'SME_transaction_status_tracker_step_title',
    stepDate: 'SME_transaction_status_tracker_step_date',
    transactionInitiated: 'SME_transaction_step_transaction_initiated',
    withdrawalInitiated: 'SME_transaction_step_withdrawal_initiated',
    paymentComplete: 'SME_transaction_step_payment_complete',
    withdrawalComplete: 'SME_transaction_step_withdrawal_complete',
    sentToBank: 'SME_transaction_step_sent_to_bank',
    sentToRecipient: 'SME_transaction_step_sent_to_recipient',
    processing: 'SME_transaction_step_processing_payment',
    couldntProcess: 'SME_transaction_step_couldnt_process_trans',
    processTransaction: 'SME_transaction_step_process_trans',
    transferBefore: 'SME_transaction_step_transfer_before',
    exchangeBefore: 'SME_transaction_step_exchange_before',
    approveBefore: 'SME_transaction_step_approve_before',
    transactionApproved: 'SME_transaction_step_trans_approved',
    withdrawalReturned: 'SME_transaction_step_withdrawal_returned',
    paymentReturned: 'SME_transaction_step_payment_returned',
    sendingToBank: 'SME_transaction_step_sending_to_bank',
    sendingToRecipient: 'SME_transaction_step_sending_to_recipient',
    transactionCancelled: 'SME_transaction_step_trans_cancelled',
    reviewingInfo: 'SME_transaction_step_reviewing_info',
    submitInfoBefore: 'SME_transaction_step_submit_info_before',
    debitingFunds: 'SME_transaction_step_debiting_funds',
    withdrawalCancelled: 'SME_transaction_step_withdrawal_cancelled',
    paymentReceived: 'SME_transaction_step_payment_received',
    creditedTo: 'SME_transaction_step_payment_credited_to',
    submitMoreInfo: 'SME_transaction_step_submit_more_info',
    creditTo: 'SME_transaction_step_credit_payment_to',
    paymentProcessed: 'SME_transaction_step_payment_processed',
    reconciled: 'SME_transaction_step_payment_reconciled',
    reconciliationRfiRaised: 'SME_transaction_step_payment_reconcilation_rfi_raised',
    reconciliationRfiResponded: 'SME_transaction_step_payment_reconcilation_rfi_responded',
    paymentRequestSent: 'SME_status_tracker_request_sent',
    weReceiveFromPayer: 'SME_status_tracker_receive_from_payer',
    paymentWillReconcile: 'SME_status_tracker_will_reconcile',
    paymentWillCredit: 'SME_status_tracker_will_credit',
    paymentRequestCancelled: 'SME_status_tracker_request_cancelled',
    autosweepInitiated: 'SME_transaction_step_autosweep_initiated',
    autosweepCancelled: 'SME_transaction_step_autosweep_cancelled',
    autosweepReturned: 'SME_transaction_step_autosweep_returned',
    autosweepComplete: 'SME_transaction_step_autosweep_complete',
  },
  transactionsForms: {
    VraRfiForm: 'SME_transaction_vra_rfi_form',
    SendRfiForm: 'SME_transaction_send_rfi_form',
  },
})

const awaitingFundsPopupIds = Object.freeze({
  popupLineItem: {
    transactionAmount: 'SME_awaiting_funds_lineitem_trans_amount',
    transId: 'SME_awaiting_funds_lineitem_trans_id',
    beneName: 'SME_awaiting_funds_lineitem_bene_name',
    dueDate: 'SME_awaiting_funds_accordion_due_date',
  },
  popupContent: {
    accordionTitle: 'SME_awaiting_funds_accordion_title',
    subText: 'SME_awaiting_funds_popup_sub_text',
    totalTransAmount: 'SME_awaiting_funds_total_transaction_amount',
    amountDeducted: 'SME_awaiting_funds_amount_deducted',
    contentValue: 'SME_awaiting_funds_content_value',
    contentLabel: 'SME_awaiting_funds_content_label',
    totalPayableAmount: 'SME_awaiting_funds_total_payable_amount',
    paymentDesc: 'SME_awaiting_funds_payment_method_desc',
    exchangeButton: 'SME_awaiting_funds_exchange_button',
    footerText: 'SME_awaiting_funds_footer_text',
    infoIcon: 'SME_awaiting_funds_info_icon',
  },
})

const payBillsIds = Object.freeze({
  payBillsLandingPage: {
    connectAccountingSoftwareButton: 'SME_paybills_connect_accounting_software_btn',
    heading: 'SME_paybills_heading',
    subHeading: 'SME_paybills_subheading',
    description: 'SME_paybills_description',
    accountsToConnect: 'SME_paybills_accounts_to_connect',
    howToConnect: 'SME_paybills_how_to_connect',
    accountingIcon: 'SME_paybills_accounting_software_icon',
    softwareName: 'SME_paybills_accounting_software_name',
    updatedOn: 'SME_paybills_updated_on',
    disconnectAccountButton: 'SME_paybills_disconnect_account_btn',
    tableHeading: 'SME_paybills_table_heading',
    emptyStateText: 'SME_paybills_table_empty_text',
    downloadBillButton: 'SME_paybills_download_bill_btn',
    payButton: 'SME_paybills_pay_btn',
    amount: 'SME_paybills_bill_amount',
    name: 'SME_paybills_name',
    invoiceNumber: 'SME_paybills_invoice_number',
    disconnectModalText: 'SME_paybills_disconnect_modal_text',
  },
  accountingPromoBanner: {
    container: 'SME_accounting_promo_banner_container',
    contentWrapper: 'SME_accounting_promo_banner_content_wrapper',
    header: 'SME_accounting_promo_banner_header',
    content: 'SME_accounting_promo_banner_content',
    buttonWrapper: 'SME_accounting_promo_banner_button_wrapper',
    buttonText: 'SME_accounting_promo_banner_button_text',
  },
  findRecipient: {
    heading: 'SME_paybills_find_recipient_heading',
    proceedBtn: 'SME_paybills_proceed_button',
    addContactBtn: 'SME_paybills_add_contact_button',
    singleBeneFound: 'SME_paybills_recipient',
    noBeneMatchedInList: 'SME_paybills_no_bene_matched_in_list',
    subText: 'SME_paybills_subtext',
    noBeneFound: 'SME_paybills_no_bene_found',
  },
})

const accountStatementIds = Object.freeze({
  accountStatementSection: {
    title: 'SME_account_statement_title',
    backButton: 'SME_account_statement_back_button',
    downloadCSVButton: 'SME_account_statement_download_csv_title',
    downloadPDFButton: 'SME_account_statement_download_pdf_button',
    accountStatementButton: 'SME_account_statement_button',
  },
  accountFilterFields: {
    accountDropdown: 'SME_account_statement_accounts_dropdown',
    dateRange: 'SME_account_statement_date_range',
    ledgerDropdown: 'SME_account_statement_ledger_dropdown',
    reportingCurrencyDropdown: 'SME_account_statement_reporting_curr_dropdown',
    typeDropdown: 'SME_account_statement_type_dropdown',
    filterButton: 'SME_account_statement_filter_button',
    clearButton: 'SME_account_statement_clear_button',
  },
  statementLineItem: {
    date: 'SME_account_statement_date',
    transactionId: 'SME_account_statement_transaction_id',
    amount: 'SME_account_statement_amount',
    lederType: 'SME_account_statement_ledger_type',
    reference: 'SME_account_statement_reference',
    balance: 'SME_account_statement_curr_balance',
  },
})

const signupIds = {
  signupText: 'SME_signup_text',
  sgukSignup: 'SME_sg_uk_signup',
  auSignup: 'SME_au_signup',
  othersSignup: 'SME-others_signup',
}
const vfaIds = Object.freeze({
  vfa: {
    blueBoxText: 'SME_vfa_blueBox_text',
    newTag: 'SME_vfa_new_tag',
  },
})

const paymentRequestIds = Object.freeze({
  paymentRequest: {
    currencyInput: 'SME_payment_request_currency_input',
    currencyLabel: 'SME_payment_request_currency_label',
    reasonDropdown: 'SME_payment_request_reason_dropdown',
    descriptionInput: 'SME_payment_request_description_input',
    invoiceNumberInput: 'SME_payment_request_invoice_number_input',
    dueDateInput: 'SME_payment_request_due_date_input',
    invoiceFileInput: 'SME_payment_request_invoice_file_input',
    requestPaymentCta: 'SME_payment_request_cta',
    supportedFilesText: 'SME_payment_request_supported_files_text',
    supportedFilesWrapper: 'SME_payment_request_supported_files_text_wrapper',
    invoiceTitle: 'SME_payment_request_invoice_upload_title',
    additionalDetailsTitle: 'SME_payment_request_additional_details_title',
    setAmountTitle: 'SME_payment_request_set_amount_title',
    selectPayerDropdown: 'SME_payment_request_select_payer_dropdown',
    addNewPayerButton: 'SME_payment_request_add_new_payer_button',
    emailText: 'SME_payment_request_email_text',
    emailTextWrapper: 'SME_payment_request_email_text_wrapper',
    requestPaymentTitle: 'SME_payment_request_title',
  },
})
const payerIds = Object.freeze({
  addPayer: {
    nameInput: 'SME_add_payer_name_input',
    emailInput: 'SME_add_payer_email_input',
    countryDropdown: 'SME_add_payer_country-dropdown',
    saveButton: 'SME_add_payer_save_button_cta',
    payerTitle: 'SME_add_payer_title',
    payerType: 'SME_add_payer_type_title',
  },
  editPayer: {
    nameInput: 'SME_edit_payer_name_input',
    emailInput: 'SME_edit_payer_email_input',
    countryDropdown: 'SME_edit_payer_country-dropdown',
    saveButton: 'SME_edit_payer_save_button_cta',
    payerTitle: 'SME_edit_payer_title',
    payerType: 'SME_edit_payer_type_title',
  },
  benePayer: {
    nameInput: 'SME_bene_payer_name_input',
    emailInput: 'SME_bene_payer_email_input',
    countryDropdown: 'SME_bene_payer_country-dropdown',
    payerTitle: 'SME_bene_payer_title',
    payerType: 'SME_bene_payer_type_title',
  },
  deletePayer: {
    payerTitle: 'SME_delete_payer_title',
    deleteContactText: 'SME_delete_payer_content_text',
  },
})
const csatIds = {
  submitFeedbackCta: 'SME_csat_submit_Feedback_cta',
  feedbackInputField: 'SME_csat_feedback_input_field',
  title: 'SME-csat_title',
  description: 'SME_csat_description',
  closeButton: 'SME_csat_clsoe_button',
  thanksNote: 'SME_csat_thanks_note',
  lessRatingNote: 'SME_csat_less_rating_note',
  thanksNoteText: 'SME_csat_thanks_note_text',
  lessRatingNoteTitle: 'SME_csat_less_rating_note_title',
  lessRatingNoteDesc: 'SME_csat_less_rating_note_desc',
}

export const automationIdSelectors = Object.freeze({
  ...myaccountsIds,
  ...sendMoneyIds,
  ...settingsIds,
  ...dashboardIds,
  ...currencyCalculatorIds,
  ...pageHeaderIds,
  ...successScreenIds,
  ...modalIds,
  ...contactsIds,
  ...summaryIds,
  ...radioAccordionIds,
  ...approvalsIds,
  ...paginationIds,
  ...helpCorridorsIds,
  ...dropdownAccordionIds,
  ...reasonForTransferIds,
  ...calendarIds,
  ...transactionsIds,
  ...awaitingFundsPopupIds,
  ...promoBannerIds,
  ...addNewBankAccountBannerIds,
  ...payBillsIds,
  ...accountStatementIds,
  ...signupIds,
  ...vfaIds,
  ...paymentRequestIds,
  ...payerIds,
  ...csatIds,
})
