// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const bulkPayment = {
  instruction: {
    title: 'Import and make multiple payments on the go',
    subTitle: 'Create up to 1,000 transfers in a single batch payment in 3 quick steps:',
    step1: '1. Download the template and enter transfer details using the guide in the file',
    step2: '2. Upload the file',
    step3: '3. Review transfers, then make a single payment for the entire batch',
  },
}

export const batchStatuses = {
  VALIDATION_IN_PROGRESS: 'validation_in_progress',
  VALIDATED: 'validated',
  FX_IN_PROGRESS: 'fx_in_progress',
  FX_COMPLETED: 'fx_completed',
  PAYOUT_IN_PROGRESS: 'payout_in_progress',
  UPLOADED: 'uploaded',
  CANCELED: 'cancelled',
  PAID: 'paid',
  PAYOUT_FAILED: 'payout_failed',
}
export const fileValidationError: { [key: string]: string } = {
  FILE_MUST_NOT_BE_EMPTY: 'Incorrect template',
  FILE_TOO_BIG: 'File size too big',
  UNSUPPORTED_FILE_FORMAT: 'Incorrect file format',
  MANDATORY_HEADER_NOT_FOUND: 'File missing headers',
  MULTIPLE_SOURCE_CURRENCIES: 'Cannot process multiple source currencies in one file',
  MAX_BATCH_LIMIT_REACHED:
    'Our system is processing a lot of batches right now. Please try again later.',
}
export const processingDescription: { [key: number]: string } = {
  2: 'Validating transactions',
  4: 'Locking in FX rates',
  7: 'Creating transactions',
}
export const processingSubDescription: { [key: number]: JSX.Element } = {
  2: (
    <>
      We’re validating your transactions right now. <br /> We're processing them as quickly as
      possible. Rest assured, it won't take too long!
    </>
  ),
  4: (
    <>
      We’re locking in FX rates now. <br />
      Rest assured, it won't take too long!
    </>
  ),
  7: (
    <>
      We’re creating your transactions right now. <br />
      You can upload more files once they’re completed.
    </>
  ),
}
export const NotToCancelBatch = [batchStatuses.CANCELED, batchStatuses.PAYOUT_IN_PROGRESS]
export const apiKey = '8625f633-5870-4747-8340-5a912cd98665'
