import styled, { keyframes } from 'styled-components/macro'

import { floors } from 'V2/constants/floors'

const spin = keyframes`
  0% {
    transform: rotate(0deg)
  }100% {
    transform: rotate(360deg)
  }
`

export const StyledSpinner = styled.div<{
  size: string
  borderWidth: string
  color?: string
}>`
  position: fixed;
  display: inline-block;
  width: ${({ size }): string => size};
  height: ${({ size }): string => size};
  animation: ${spin} 1s linear infinite;
  border: ${({ borderWidth }): string => borderWidth} solid
    ${({ color, theme }): string => color || theme.revamp.primary.blue.root};
  border-bottom-color: transparent;
  background: transparent;
  border-radius: 50%;
  left: calc(
    50% - (${({ size }): string => size} / 2) + (${({ borderWidth }): string => borderWidth}*2)
  );

  bottom: calc(
    50% - (${({ size }): string => size} / 2) + (${({ borderWidth }): string => borderWidth}*2)
  );
`

export const SpinnerContainer = styled.div<{ show: boolean }>`
  display: ${({ show }): string => (show ? 'block' : 'none')};
  position: fixed;
  background: rgba(255, 255, 255, 0.3);
  z-index: ${floors.first};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
`
