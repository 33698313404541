// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const ApprovalIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <g clipPath="url(#clip0_929_178509)">
      <path
        d="M21.75 21.761C21.75 22.1588 21.592 22.5404 21.3107 22.8217C21.0294 23.103 20.6478 23.261 20.25 23.261H3.75C3.35218 23.261 2.97064 23.103 2.68934 22.8217C2.40804 22.5404 2.25 22.1588 2.25 21.761V2.261C2.25 1.86318 2.40804 1.48165 2.68934 1.20034C2.97064 0.919037 3.35218 0.761002 3.75 0.761002H14.379C14.7765 0.761086 15.1578 0.918983 15.439 1.2L21.311 7.072C21.592 7.3532 21.7499 7.73445 21.75 8.132V21.761Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 8.261H15.75C15.3522 8.261 14.9706 8.10297 14.6893 7.82166C14.408 7.54036 14.25 7.15883 14.25 6.761V0.761002"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.669 9.619L7.537 12.461C7.4725 12.5469 7.39031 12.6179 7.29598 12.6693C7.20165 12.7207 7.09739 12.7512 6.99025 12.7588C6.88311 12.7663 6.77559 12.7508 6.67496 12.7133C6.57434 12.6757 6.48296 12.6169 6.407 12.541L5.25 11.385"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.669 16.369L7.537 19.211C7.4725 19.2969 7.39031 19.3679 7.29598 19.4193C7.20165 19.4707 7.09739 19.5012 6.99025 19.5088C6.88311 19.5163 6.77559 19.5008 6.67496 19.4633C6.57434 19.4257 6.48296 19.3669 6.407 19.291L5.25 18.134"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 12.761H17.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 18.761H17.25"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_929_178509">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </>
)
