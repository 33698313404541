import apm from 'apm'

import { CustomError } from 'V2/services/customError'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const catchErrorHandler = (err: any): { status: string; errors: any[] } => {
  const errors = err?.response?.data?.errors
  if (!errors) {
    return {
      status: 'error',
      errors: [
        {
          code: 'UNEXPECTED_ERROR',
          message: 'Unexpected error occured',
        },
      ],
    }
  }

  apm.captureError(
    new CustomError(`beneficiaryErrorHandling: ${err?.message}`, err, {
      source: 'catchErrorHandler',
    })
  )

  return { status: 'error', errors }
}
