// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components/macro'
import { ModalProvider } from 'styled-react-modal'

import { history, storeConfig } from 'store/store'
import theme from 'styles/theme'
import { switchUiView } from './switchUi'
import {
  constructQueryParams,
  expiresAfter,
  getUTMQuery,
  setCookie,
  UTM_DATA,
} from './V2/helpers/utmHelpers'

const { store, persistor } = storeConfig()

const {
  GlobalStyles,
  Routes,
  SpecialModalBackground,
  ErrorBoundary,
  axiosInstance,
  BrowserLocation,
} = switchUiView()

axiosInstance(store)
BrowserLocation(history, store)

function App(): JSX.Element {
  useEffect(() => {
    if (window?.location?.search) {
      initCookies()
    }
  }, [])

  function initCookies(): void {
    const utmData = getUTMQuery(window.location.search)
    const { clickref } = constructQueryParams(window.location.search)
    utmData && setCookie(UTM_DATA, JSON.stringify(utmData), expiresAfter(30))
    clickref && setCookie('clickref', clickref, expiresAfter(30))
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        <PersistGate loading={<div>Loading ...</div>} persistor={persistor}>
          <ErrorBoundary>
            <ModalProvider backgroundComponent={SpecialModalBackground}>
              <ConnectedRouter history={history}>
                <Routes />
              </ConnectedRouter>
            </ModalProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}
interface LinkProps {
  className?: string
  href: string
}
export const Link: React.FC<LinkProps> = ({ className, href, children }) => {
  return (
    <a href={href} className={className}>
      {children}
    </a>
  )
}

export default App
