import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Typography } from 'V2/components/atoms/Typography/Typography'
import { floors } from 'V2/constants/floors'
import { great, less } from 'V2/helpers/mediaquery'

export const CompanyNameWrapper = styled.div`
  margin-left: 16px;
`

export const Footer = styled.footer`
  margin: 8px;
  padding-top: 32px;
  padding-left: 24px;
  border-top: 1px solid ${({ theme }): string => theme.revamp.primary.blue.root};
`

export const LogoContainer = styled.div`
  padding-top: 32px;
  padding-left: 24px;
  padding-bottom: 50px;
`

export const MoreMenuArrow = styled.div`
  margin-left: auto;
`

export const MoreMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  padding: 16px;
  margin-bottom: 8px;
  width: 100%;
  border: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
`

export const MoreMenuContainer = styled.div<{ moremenuopen?: boolean | number }>`
  display: flex;
  align-items: center;
  padding: 16px;
  flex-direction: column;
  background-color: ${({ theme }): string => theme.revamp.primary.grey.lightest};
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: ${floors.second};
  transform: ${({ moremenuopen }): string =>
    moremenuopen ? 'translateX(0%)' : 'translateX(-100%)'};
  transition: transform 0.5s ease-in-out;
  & ${MoreMenuWrapper}:last-child {
    margin-top: auto;
  }
  @media ${great.greaterThanMobile} {
    display: none;
  }
  > div:last-child {
    color: ${({ theme }): string => theme.revamp.staticColors.redPepperCorn};
    ${MoreMenuArrow} {
      path:first-child {
        fill: ${({ theme }): string => theme.revamp.staticColors.redPepperCorn};
      }
    }
  }
`

export const MoreMenuHeaderWrapper = styled.div`
  display: flex;
  margin: 40px 0;
  width: 100%;
  align-items: center;
`

export const MoreMenuNavLink = styled(NavLink)`
  border: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light};
  box-sizing: border-box;
  border-radius: 4px;
  text-decoration: none;
  color: ${({ theme }): string => theme.revamp.staticColors.black};
  display: flex;
  align-items: center;
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  padding: 16px;
  margin-bottom: 8px;
  width: 100%;
`

export const MoreMenuTitle = styled.div`
  padding: 0 16px;
`

export const NavContainer = styled.nav`
  display: flex;
  @media ${great.greaterThanMobile} {
    flex-direction: column;
  }
`

export const StyledNavText = styled(Typography)`
  color: ${({ theme }): string => theme.revamp.primary.blue.root};
  font-weight: 400;
  @media ${great.greaterThanMobile} {
    padding-left: 16px;
    font-size: 15px;
  }
  @media ${less.lessThanMobile} {
    font-size: 10px;
    margin-top: 4px;
  }
`

export const NavLinks = styled.div`
  flex: 1;
`

export const SideBarContainer = styled.nav<{ open: boolean; onboardingTemplate: boolean }>`
  background-color: ${({ onboardingTemplate }): string =>
    onboardingTemplate ? '#004288' : 'white'};
  width: 16.3vw;
  width: 236px;
  display: flex;
  flex-direction: column;
  @media ${less.lessThanTablet} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: ${floors.second};
    transform: ${({ open }): string => (open ? 'translateX(0%)' : 'translateX(-100%)')};
    transition: transform 0.5s ease-in-out;
  }
  @media ${less.lessThanMobile} {
    display: none;
  }
  @media ${great.greaterThanMobile} {
    z-index: ${floors.second};
  }
`

export const NavWrapper = styled.div<{
  $mobileMenu: boolean
  moremenuopen?: boolean | number
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 16px 6px 16px 26px;
  align-items: center;
  text-decoration: none;
  background-color: ${({ theme, moremenuopen }): string =>
    moremenuopen ? theme.revamp.primary.blue.lightest : 'unset'};
  &:hover {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.lightest};
  }
  @media ${less.lessThanMobile} {
    padding: unset;
    justify-content: center;
    flex-direction: column;
    display: ${({ $mobileMenu }): string => ($mobileMenu ? 'flex' : 'none')};
  }
  @media ${great.greaterThanMobile} {
    display: none;
  }
`

const activeClassName = 'active'
export const NavBarLink = styled(NavLink).attrs({ activeClassName })<{
  $mobileMenu: boolean
  moremenuopen: boolean | number
}>`
  display: flex;
  flex-direction: row;
  padding: 16px 6px 16px 26px;
  height: 68px;
  align-items: center;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }): string => theme.revamp.primary.blue.lightest};
  }
  @media ${less.lessThanMobile} {
    align-items: center;
    padding: unset;
    justify-content: center;
    flex-direction: column;
    display: ${({ $mobileMenu }): string => ($mobileMenu ? 'flex' : 'none')};
    &:hover {
      background-color: unset;
    }
  }
  flex: 1;

  &.${activeClassName} {
    & > ${StyledNavText} {
      font-weight: 600;
    }
    background-color: ${({ theme }): string => theme.revamp.primary.blue.lightest};
    @media ${less.lessThanMobile} {
      background-color: ${({ theme, moremenuopen }): string =>
        !moremenuopen ? theme.revamp.primary.blue.lightest : 'unset'};
    }
  }
`
export const BetaIconWrapper = styled.span`
  padding-left: 8px;
`
