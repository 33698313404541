//used by onboarding lookup page

import { Reducer } from 'react'

import { RiskAssessmentInfoActions } from 'store/actionTypes'

export interface RiskAssessmentIntialState {
  annualTurnover?: string
  totalEmployees?: string
  industrySector?: string
  intendUseOfAccount?: string
  countryOfOperation?: []
}

const initialState: RiskAssessmentIntialState = {
  annualTurnover: '',
  totalEmployees: '',
  industrySector: '',
  intendUseOfAccount: '',
  countryOfOperation: [],
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const riskAssessmentInfoReducer: Reducer<any, any> = (state = initialState, action) => {
  switch (action.type) {
    case RiskAssessmentInfoActions.UPDATE_RISK_ASSESSMENT_INFO_VALUES:
      return {
        ...state,
        annualTurnover: action.payload?.annualTurnover,
        industrySector: action.payload?.industrySector,
        totalEmployees: action.payload?.totalEmployees,
        intendUseOfAccount: action.payload?.intendUseOfAccount,
        countryOfOperation: action.payload?.countryOfOperation,
      }
    default:
      return state
  }
}
