const styledInterpolation = (
  condition: boolean | undefined,
  styledTemplate: unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): string | any => {
  if (condition) return styledTemplate
  return ''
}

export default styledInterpolation
