export const capitalizeFirstLetter = (wordToBeFormatted?: string): string => {
  if (wordToBeFormatted) {
    const parts = wordToBeFormatted.split(' ')
    const result: string[] = []
    parts.forEach((part, index) => {
      if (index === 0) {
        result.push(part.charAt(0).toUpperCase() + part.slice(1))
      } else {
        result.push(part.charAt(0).toLowerCase() + part.slice(1))
      }
    })
    return result.join(' ')
  }
  return ''
}
export const capitalizeOnlyFirstLetter = (wordToBeFormatted: string): string => {
  return wordToBeFormatted[0].toUpperCase() + wordToBeFormatted.slice(1).toLocaleLowerCase()
}
