// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router'
import { push } from 'connected-react-router'
import { useTheme } from 'styled-components/macro'

import { updateFlagTrait } from 'V2/actions/userClientDetails'
import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import { ArrowRightIcon } from 'V2/components/iconPaths/Custom/BasicIcons/ArrowRightIcon'
import { LogoutIcon } from 'V2/components/iconPaths/Custom/BasicIcons/LogoutIcon'
import { MoreIcon } from 'V2/components/iconPaths/Custom/BasicIcons/MoreIcon'
import { ProfileIcon } from 'V2/components/iconPaths/Custom/BasicIcons/ProfileIcon'
import { SettingsIcon } from 'V2/components/iconPaths/Custom/BasicIcons/SettingsIcon'
import { BetaIcon } from 'V2/components/iconPaths/Custom/IllustrationsIcons/BetaIcon'
import { NewTagIcon } from 'V2/components/iconPaths/Custom/IllustrationsIcons/NewTagIcon'
import { FooterIcon } from 'V2/components/iconPaths/Custom/NavIcons/Footer'
import { LogoIcon } from 'V2/components/iconPaths/Custom/NavIcons/LogoIcon'
import { appRoutes } from 'V2/constants/appRoutes'
import { automationIdSelectors } from 'V2/constants/automationIdSelectors'
import { breakPoint } from 'V2/constants/breakpoints'
import { newTagEnabledLegalEntities, receiveCurrencyConfig } from 'V2/constants/receiveAccount'
import { sidebarNavMapping } from 'V2/constants/sidebarNavMapping'
import {
  ACCOUNTING_BANNER_DISABLED,
  betaFeatures,
  ENABLE_ACCOUNTING_BANNER,
} from 'V2/constants/userClientDetails'
import { labelMapper, trackEvents } from 'V2/helpers/amplitudeEvents'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import GA from 'V2/helpers/gaTracker'
import {
  Menu,
  MoreMenuProp,
  NavProp,
  SidebarProp,
} from 'V2/interfaces/components/Molecules.interface'
import {
  BetaIconWrapper,
  CompanyNameWrapper,
  Footer,
  LogoContainer,
  MoreMenuArrow,
  MoreMenuContainer,
  MoreMenuHeaderWrapper,
  MoreMenuNavLink,
  MoreMenuTitle,
  MoreMenuWrapper,
  NavBarLink,
  NavContainer,
  NavLinks,
  NavWrapper,
  SideBarContainer,
  StyledNavText,
} from './Styles'

const GetMoreMobileMenus = ({
  moremenuopen = 0,
  MoreMenuLinks,
  setMoreOpen,
  companyName,
  onLogoutClick,
  onSettingsClick,
}: MoreMenuProp): JSX.Element => {
  const theme = useTheme()
  const moreMenuList = [
    {
      icon: <SettingsIcon />,
      title: 'Account settings',
      onClick: onSettingsClick,
    },
    {
      icon: <LogoutIcon stroke={theme.revamp.staticColors.redPepperCorn} />,
      title: 'Logout',
      onClick: onLogoutClick,
    },
  ]
  return (
    <MoreMenuContainer moremenuopen={moremenuopen ? 1 : 0}>
      <MoreMenuHeaderWrapper>
        <CustomIcon width={64} height={64} viewBox="0 0 64 64">
          <ProfileIcon />
        </CustomIcon>
        <CompanyNameWrapper>
          <Typography type="h2" weight="semibold">
            {companyName}
          </Typography>
        </CompanyNameWrapper>
      </MoreMenuHeaderWrapper>
      {MoreMenuLinks.map(({ _id, key, label, url }: Menu) => {
        const { IconPath, children = [] } = sidebarNavMapping[key]
        return (
          <MoreMenuNavLink
            onClick={(): void => setMoreOpen((prevState) => !prevState)}
            to={url}
            key={_id}
            isActive={(match, location): boolean =>
              location.pathname === url || children.includes(location.pathname)
            }
          >
            <CustomIcon defaultSize={24} viewBox={'0 0 24 24'}>
              <IconPath />
            </CustomIcon>
            <MoreMenuTitle>
              <Typography type="body" weight="medium">
                {label}
                {betaFeatures.includes(label) && (
                  <BetaIconWrapper>
                    <CustomIcon width={72}>
                      <BetaIcon />
                    </CustomIcon>
                  </BetaIconWrapper>
                )}
              </Typography>
            </MoreMenuTitle>
            <MoreMenuArrow>
              <CustomIcon defaultSize={24} viewBox={'0 0 24 24'}>
                <ArrowRightIcon />
              </CustomIcon>
            </MoreMenuArrow>
          </MoreMenuNavLink>
        )
      })}
      {moreMenuList.map((item) => {
        return (
          <MoreMenuWrapper onClick={item.onClick} key={item.title}>
            <CustomIcon defaultSize={24} viewBox={'0 0 24 24'}>
              {item.icon}
            </CustomIcon>
            <MoreMenuTitle>
              <Typography type="body" weight="medium">
                {item.title}
              </Typography>
            </MoreMenuTitle>
            <MoreMenuArrow>
              <CustomIcon defaultSize={24} viewBox={'0 0 24 24'}>
                <ArrowRightIcon />
              </CustomIcon>
            </MoreMenuArrow>
          </MoreMenuWrapper>
        )
      })}
    </MoreMenuContainer>
  )
}

// MOBILE: This function creates the navigation menus for mobile, tablet and desktop
const SmeNavBar = ({
  setMoreOpen,
  navLinks,
  setNavOpen,
  moremenuopen = 0,
}: NavProp): JSX.Element => {
  const theme = useTheme()
  const location = useLocation()
  const dispatch = useDispatch()

  const accountingBannerEnabled =
    !useSelector((state) => state.userClientDetails.featureFlagConfiguration.featureFlagInfo).find(
      (flag: { feature: string }) => flag.feature === ACCOUNTING_BANNER_DISABLED
    )?.enabled || false
  const clientLegalEntity = useSelector((state) =>
    clientLegalEntityMapper(state.userClientDetails.activeClient?.client_legal_entity || '')
  )
  const isAutoSweep = useMemo(
    () => receiveCurrencyConfig[clientLegalEntityMapper(clientLegalEntity)]?.autoSweep || false,
    [clientLegalEntity]
  )
  return (
    <NavContainer>
      {navLinks.map(({ _id, key, label, url, automationId }: Menu) => {
        const { IconPath, children = [], isMobileMenu } = sidebarNavMapping[key]
        return (
          <NavBarLink
            id={automationId}
            onClick={(): void => {
              if (label === 'Pay Bills' && accountingBannerEnabled)
                dispatch(updateFlagTrait(ENABLE_ACCOUNTING_BANNER, false))
              setNavOpen((prevState) => !prevState)
              setMoreOpen(false)
              trackEvents(labelMapper[label])
              //GA Event
              GA.track('EVENT', {
                action: labelMapper[label],
                category: 'Portal',
                label: labelMapper[label],
              })
            }}
            to={url}
            key={_id}
            // problem -> https://styled-system.com/guides/removing-props-from-html/#styled-components
            // solution -> https://github.com/styled-components/styled-components/releases/tag/v5.1.0
            $mobileMenu={isMobileMenu}
            moremenuopen={moremenuopen}
            isActive={(match, location): boolean =>
              location.pathname === url ||
              children.some(
                (elem) =>
                  matchPath(location.pathname, {
                    path: elem,
                  })?.isExact
              )
            }
          >
            <CustomIcon
              defaultSize={24}
              children={<IconPath stroke={theme.revamp.primary.blue.root} />}
              viewBox={'0 0 24 24'}
            />
            <StyledNavText type="body">
              {isAutoSweep && label === 'My Accounts'
                ? 'Receive money'
                : label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()}
              {/* below block is kept separate because eventually newtag will be removed */}
              {((newTagEnabledLegalEntities.includes(clientLegalEntityMapper(clientLegalEntity)) &&
                label === 'My Accounts') ||
                label === 'Send Money') &&
                window.innerWidth > breakPoint.tablet && (
                  <BetaIconWrapper>
                    <CustomIcon width={32}>
                      <NewTagIcon />
                    </CustomIcon>
                  </BetaIconWrapper>
                )}
            </StyledNavText>
          </NavBarLink>
        )
      })}
      <NavWrapper
        id={automationIdSelectors.sideBar.moreButton}
        $mobileMenu={true}
        moremenuopen={
          moremenuopen ||
          (sidebarNavMapping?.['SME_navBar_more-navlink']?.children &&
            sidebarNavMapping?.['SME_navBar_more-navlink']?.children.some(
              (elem) =>
                matchPath(location.pathname, {
                  path: elem,
                })?.isExact || false
            ))
        }
        onClick={(): void => setMoreOpen((prevState) => !prevState)}
      >
        <CustomIcon
          defaultSize={24}
          children={<MoreIcon stroke={theme.revamp.primary.blue.root} />}
          viewBox={'0 0 24 24'}
        />
        <StyledNavText type="body">More</StyledNavText>
      </NavWrapper>
    </NavContainer>
  )
}

// SIDEBAR ( tab n mobile):  This function gives sidebar UI
const SideBar = ({
  open = true,
  setMoreOpen,
  navLinks,
  setNavOpen,
  onboardingTemplate = false,
}: SidebarProp): JSX.Element => {
  const dispatch = useDispatch()
  const logoClickEvent = (event: React.MouseEvent<HTMLDivElement>): void => {
    dispatch(push(appRoutes.dashboard))
  }
  const onNavigateHandler = (): void => {
    window.open('https://www.nium.com/')
  }
  return (
    <SideBarContainer onboardingTemplate={onboardingTemplate} open={open}>
      <LogoContainer
        onClick={(event: React.MouseEvent<HTMLDivElement>): void => {
          logoClickEvent(event)
          setNavOpen(false)
        }}
        id={automationIdSelectors.brand}
      >
        <CustomIcon width={120} height={21} viewBox="0 0 120 21">
          <LogoIcon fill={`${onboardingTemplate ? 'white' : 'black'}`} />
        </CustomIcon>
      </LogoContainer>

      <NavLinks>
        <SmeNavBar setMoreOpen={setMoreOpen} navLinks={navLinks} setNavOpen={setNavOpen} />
      </NavLinks>
      <Footer onClick={onNavigateHandler}>
        <CustomIcon
          width={152}
          height={17}
          viewBox="0 0 152 17"
          id={automationIdSelectors.sideBar.poweredByNIUM}
        >
          <FooterIcon />
        </CustomIcon>
      </Footer>
    </SideBarContainer>
  )
}

export { GetMoreMobileMenus, SideBar, SmeNavBar }
