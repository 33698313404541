// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { ErrorInfo } from 'react'

import { CustomError } from 'V2/services/customError'
import { Logger } from 'V2/services/logger'
import apm from '../../../../apm'

const FILE_NAMESPACE = 'ErrorBoundry'

const ErrorFallback = (): JSX.Element => <div>Something Went Wrong</div>

class ErrorBoundary extends React.Component<
  {},
  {
    hasError: boolean
  }
> {
  constructor(props: {}) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    Logger(FILE_NAMESPACE).error(error.name, info)
    apm.captureError(
      new CustomError(`ErrorBoundary: ${error.name}`, error, {
        ...info,
        source: 'componentDidCatch',
      })
    )
    if (error.name === 'ChunkLoadError') {
      // Redirect to home page to reload main chunk first
      window.location.href = '/'
    }
  }

  render(): React.ReactNode {
    const rightUrl = window.location.href === '/r/oops'
    if (this.state.hasError || rightUrl) {
      return <ErrorFallback />
    }
    return this.props.children
  }
}

export default ErrorBoundary
