import { track } from '@amplitude/analytics-browser'
import * as amplitude from '@amplitude/analytics-browser'

export const trackEvents = (eventName: string, eventProperties?: {}): void => {
  track(eventName, eventProperties)
}

export const identifyEvent = new amplitude.Identify()

export const labelMapper: {
  [key: string]: string
} = {
  Dashboard: 'dashboard_landing_page',
  'My Accounts': 'my_accounts_landing_page',
  'Send Money': 'send_money_landing_page',
  Contacts: 'contacts_landing_page',
  Transactions: 'transactions_landing_page',
  Approvals: 'approvals_landing_page',
  Help: 'help_landing_page',
  'Pay Bills': 'pay_bills_landing_page',
}
