export const formErrorList = {
  somethingWentWrongError: 'Something went wrong! Please try again.',
  unExpectedError: 'Unexpected error ocurred',
  otpCodeRequired: 'Dynamic Access Code is required',
  emailIsRequired: 'Email is required',
  passwordIsRequired: 'Password is required',
  invalidEmailAddress: 'Invalid Email ID',
  uppercaseEmailAddress: 'Please enter email address in lowercase letters',
  otpAuthError: 'Incorrect code',
  requiredName: "should have required property 'beneficiary_name'",
  requiredEmail: "should have required property 'beneficiary_email'",
  requiredAddress: "should have required property 'beneficiary_address'",
  requiredPostCode: "should have required property 'beneficiary_postcode'",
  requiredCity: "should have required property 'beneficiary_city'",
  requiredState: "should have required property 'beneficiary_state'",
  requiredBeneficiary: "should have required property 'beneficiary_account_number'",
  requiredRoutingValue1: "should have required property 'routing_code_value_1'",
  requiredAccountNumber: "should have required property 'beneficiary_account_number'",
  requiredBankCode: "should have required property 'beneficiary_bank_code'",
  requiredBankAccountType: "should have required property 'beneficiary_bank_account_type'",
  requiredIdentificationValue: "should have required property 'beneficiary_identification_value'",
  requiredIdentificationType: "should have required property 'beneficiary_identification_type'",
  requiredContactCode: "should have required property 'beneficiary_contact_country_code'",
  requiredRoutingValue2: "should have required property 'routing_code_value_1'",
  recipientCustomErrorMessage: {
    name: 'Name cannot be empty',
    email: 'Email address cannot be empty',
    address: 'Address field cannot be empty',
    postalCode: 'Postal Code cannot be empty',
    city: 'City cannot be empty',
    state: 'State cannot be empty',
    bankName: 'Bank Name is required',
    accountNumber: 'Account Number is required',
    bankAccountType: 'This is a required filed',
    bankCode: 'This is a required field',
    routingValue1: 'This is a required field',
    identificationType: 'This is a required field',
    identificationValue: 'This is a required field',
    beneficiaryContactCountryCode: 'Country code cannot be empty',
    routingValue2: 'This is a required field',
  },
}

export const snackbarErrorMessages = {
  apiFailError: 'Sorry, something went wrong on our end. Please try again later',
  invalidSwiftCode: 'Invalid SWIFT code',
  invalidBsbCode: 'Invalid BSB code',
  invalidAchCode: 'Invalid ACH code',
  fpxPaymentFailed: 'We were unable to process your payment.',
  requestPaymentFailed: 'Couldn`t create payment request. Please try again.',
  withdrawFundsErrorMessage: 'Invalid bank account details. Please delete and add again.',
}
