/* eslint-disable @typescript-eslint/camelcase */
import { Reducer } from 'redux'

import { CurrencyExchange } from 'interfaces/redux/Reducers.interface'
import { CurrencyExchangeTypes } from 'store/actionTypes'

const initialState = {
  bookFxRate: undefined,
  sendCurrency: undefined,
  receiveCurrency: undefined,
  loading: {
    bookFxRateLoading: false,
  },
  inputUpdating: false,
  inputTouched: false,
  exchangeValue: {
    sourceAmount: {
      value: 0,
      display: '0',
    },
    destinationAmount: {
      value: 0,
      display: '0',
    },
  },
  isToAmountSpecified: false,
  selectedCurrency: null,
  exchangeResponse: undefined,
  isExchangeComplete: false,
}

const currencyExchangeReducer: Reducer<CurrencyExchange> = (state = initialState, action) => {
  switch (action.type) {
    case CurrencyExchangeTypes.BOOK_FX_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          bookFxRateLoading: action.payload,
        },
      }
    case CurrencyExchangeTypes.GET_BOOK_FX_RATE_REQUEST:
      return {
        ...state,
        bookFxRate: action.payload,
        inputUpdating: false,
        inputTouched: false,
        exchangeValue: initialState.exchangeValue,
      }
    case CurrencyExchangeTypes.EXCHANGE_VALUE_SET:
      return {
        ...state,
        exchangeValue: action.payload.exchangeValue,
        inputUpdating: action.payload.isUpdating,
        inputTouched: action.payload.inputTouched,
      }
    case CurrencyExchangeTypes.SEND_CURRENCY_UPDATE:
      return {
        ...state,
        sendCurrency: action.payload,
      }
    case CurrencyExchangeTypes.RECEIVE_CURRENCY_UPDATE:
      return {
        ...state,
        receiveCurrency: action.payload,
      }
    case CurrencyExchangeTypes.TO_AMOUNT_SPECIFIED:
      return {
        ...state,
        isToAmountSpecified: action.payload,
      }
    case CurrencyExchangeTypes.RESET_STATE:
      return {
        ...initialState,
      }
    case CurrencyExchangeTypes.UPDATE_SELECTED_CURRENCY:
      return { ...state, selectedCurrency: action.payload }
    case CurrencyExchangeTypes.UPDATE_EXCHANGE_RESPONSE:
      return { ...state, ...action.payload }
    case CurrencyExchangeTypes.EXCHANGE_COMPLETED:
      return { ...state, isExchangeComplete: action.payload }
    default:
      return state
  }
}

export { currencyExchangeReducer as currencyExchange }
