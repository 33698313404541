/* eslint-disable @typescript-eslint/camelcase */
import { Reducer } from 'redux'

import { AddMoney } from 'interfaces/redux/Reducers.interface'
import { AddMoneyTypes } from 'store/actionTypes'

const initialState = {
  fundingAmount: 0,
  selectedCurrency: '',
  paymentMethod: 'creditCard',
  addMoneyCompleted: false,
  creditCardPaymentInitiated: false,
}

const addMoneyReducer: Reducer<AddMoney> = (state = initialState, action) => {
  switch (action.type) {
    case AddMoneyTypes.SET_RETURN_URL:
      return {
        ...state,
        ...action.payload,
      }
    case AddMoneyTypes.SET_FUNDING_AMOUNT:
      return {
        ...state,
        fundingAmount: action.payload,
      }
    case AddMoneyTypes.SET_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.payload,
      }
    case AddMoneyTypes.UPDATE_PAYMENT_TYPE:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case AddMoneyTypes.ADD_MONEY_COMPLETED:
      return {
        ...state,
        addMoneyCompleted: action.payload,
      }
    case AddMoneyTypes.CREDIT_CARD_PAYMENT_INITIATED:
      return {
        ...state,
        creditCardPaymentInitiated: action.payload,
      }
    case AddMoneyTypes.RESET_STATE_EXCEPT_AMOUNT_CURRENCY_RETURNURL:
      return {
        ...initialState,
        ...{
          selectedCurrency: state.selectedCurrency,
          fundingAmount: state.fundingAmount,
          returnUrl: state.returnUrl,
          successPageCta: state.successPageCta,
        },
      }
    case AddMoneyTypes.RESET_STATE_EXCEPT_SELECTED_CURRENCY:
      return { ...initialState, ...{ selectedCurrency: state.selectedCurrency } }
    case AddMoneyTypes.RESET_STATE:
      return initialState
    default:
      return state
  }
}

export { addMoneyReducer as addMoney }
