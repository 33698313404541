const authApis = Object.freeze({
  resetPassword: '/r/api/resetPassword',
  logout: '/logout',
  generateOtp: '/r/api/authentication/generateOTP',
  login: '/r/api/login',
  requestPassword: 'r/api/requestPassword',
  setPassword: '/r/api/settings/userProfile/changePassword',
  getOnboardingStatus: (clientId: string) =>
    `/smebackend/ach/api/v1/getOnboardingStatus/${clientId}`,
  setFeatureConfiguration: '/smebackend/api/v1/configuration',
})

const allFxRateApis = Object.freeze({
  fxRate: '/r/api/lockFx/getFxRate',
  lockfxRate: '/r/api/lockFx/lockFxRate',
  getHoldFx: '/r/api/lockFx/getHoldFx',
})

const transactionHistoryApis = Object.freeze({
  userDetails: (userId: string) => `/r/api/settings/user/${userId}`,
  transactionHistory: '/r/api/reports/transactions/pagination',
  transactionHistoryDetails: '/r/api/reports/transactions',
  accountStatement: '/r/api/reports/accountStatement/pagination',
  downloadAccountStatementCSV: '/r/api/reports/accountStatement/download',
  exchangeStatement: '/r/api/reports/bookfx/pagination',
  exchangeDetails: (exchangeId: string): string => `/r/api/bookfx/${exchangeId}`,
  bookFx: '/r/api/bookfx',
})

const contactApis = Object.freeze({
  beneficiaryValidationSchema: '/r/api/beneficiary/validations',
  bankSearchByRoutingCode: '/r/api/bankNameLookup',
  metadata: '/r/api/beneficiary/metadata',
  createBeneficiaries: (clientId: string): string => `/r/api/clients/${clientId}/beneficiaries`,
  beneficiaryList: (clientId: string) => `/r/api/clients/${clientId}/beneficiaries`,
  payersList: (clientId: string) => `/r/api/client/${clientId}/payers`,
  addPayer: (clientId: string) => `/r/api/client/${clientId}/payer`,
  editPayer: (clientId: string, payerId: string) => `/r/api/client/${clientId}/payer/${payerId}`,
  deletePayer: (clientId: string, payerId: string) =>
    `/r/api/client/${clientId}/payer/${payerId}/deactivate`,
  payerDetail: (clientId: string, payerId: string) => `/r/api/client/${clientId}/payers/${payerId}`,
  updateBeneficiaryPayout: (
    clientId: string | undefined,
    beneficiaryId: string,
    payoutId: string
  ): string => `/r/api/clients/${clientId}/beneficiaries/${beneficiaryId}/payouts/${payoutId}`,
  updateBeneficiary: (clientId: string, beneficiaryId: string): string =>
    `/r/api/clients/${clientId}/beneficiaries/${beneficiaryId}`,
  deleteBeneficiary: (clientId: string, beneficiaryId: string): string =>
    `/r/api/clients/${clientId}/beneficiaries/${beneficiaryId}`,
  triggerEmail: '/smebackend/api/v1/email-trigger',
})

const rfiDocumentsApi = Object.freeze({
  rfiPagination: '/r/api/transactions/clientrfi/pagination',
  rfiDocsName: '/r/api/transactions/clientrfi/getRfiDocNames',
  rfiDocsDetails: '/r/api/transactions/getrfidocsdetails',
  rfiSubmit: '/r/api/transactions/clientrfi/respondToComplianceRequest',
})

const sendMoneyApis = Object.freeze({
  purposeCodes: '/r/api/common/purposeCodes',
  validatePayout: '/r/api/validatePayout',
  createPayout: '/r/api/createPayout',
  netSettlementFees: '/r/api/transactionFees',
  transactionDocuments: '/r/api/transaction/document',
  getSwiftFee: '/smebackend/api/v1/swift-fees',
})
const bulkPaymentApis = Object.freeze({
  getBatchDetails: '/smebackend/api/v1/batch',
  cancelBatch: (batchID: string): string => `/smebackend/api/v1/batch/${batchID}`,
  getBatchWithBatchId: (batchID: string): string => `/smebackend/api/v1/batch/${batchID}`,
  downloadBulkTemplate: '/smebackend/api/v1/batch/file',
  uploadBatch: '/smebackend/api/v1/batch/upload',
  initiatePayout: (batchID: string): string => `/smebackend/api/v1/batch/${batchID}/payout`,
  batchProgress: (clientId: string): string => `/client/${clientId}/topic/progress`,
  getTransactions: (batchId: string): string => `/smebackend/api/v1/batch/${batchId}/transactions`,
  downloadErrorFile: (batchId: string): string => `/smebackend/api/v1/batch/${batchId}/file`,
  holdFx: (batchId: string): string => `/smebackend/api/v1/batch/${batchId}/hold-fx`,
})

const cardApis = Object.freeze({
  validBinNumbers: '/r/api/transactions/cardPaymentBins',
  cardPayment: '/r/api/transactions/cardPayment',
  getAllSavedCard: `/r/api/bpsp/getAllSavedCards`,
  saveCard: `/r/api/bpsp/saveCard`,
  deleteCard: (cardId: string) => `/r/api/bpsp/deleteCard/${cardId}`,
  chargeOnCard: `/r/api/bpsp/chargeSavedCard`,
  postRequestId: (requestId: string) => `/r/api/transactions/cardPayment/updateStatus/${requestId}`,
  getRequestId: (requestId: string) => `/r/api/transactions/cardPayment/${requestId}`,
})

const userSettingApis = Object.freeze({
  userUpdate: (userId: string | number) => `/r/api/settings/user/${userId}/update`,
  createUser: '/r/api/settings/user/create',
  accountSummary: '/r/api/dashboard/client/getAccountSummary',
  createVfa: '/r/api/settings/virtualFundingAccount/create',
  createAccount: '/r/api/account',
  payinBank: '/r/api/banks/payinBanks',
  getlistOfUsers: '/r/api/settings/user/pagination',
  roles: '/r/api/settings/role/bulk',
  clientUpdate: '/r/api/settings/client/update',
  getClientDetails: (clientId: string) => `/r/api/client/${clientId}`,
})

const recieveApis = Object.freeze({
  receiveOfferedCurrencies: (clientId: string) =>
    `r/api/client/${clientId}/receiveOfferedCurrencies`,
  getVirtualReceivingAccounts: (clientId: string) =>
    `/r/api/client/${clientId}/virtualReceivingAccount`,
  getVirtualReceivingAccountStatement: (clientId: string, virtualReceivingAccountId: string) =>
    `/r/api/client/${clientId}/virtual-receiving-account-statement/${virtualReceivingAccountId}`,
  allocateVRA: (clientId: string) =>
    `/r/api/client/${clientId}/virtualReceivingAccount/autoAllocation`,
  getPaymentRequestedList: `/r/api/payment-request/track-payment-request`,
  createPaymentRequest: (clientId: string, payerId: string) =>
    `/r/api/client/${clientId}/payer/${payerId}/paymentRequest`,
  paymentRequestByPrId: (clientId: string, payerId: string) =>
    `/r/api/client/${clientId}/payer/${payerId}/paymentRequest`,
  cancelPaymentRequestedTrans: (clientId: string, payerId: string, paymentRequestId: string) =>
    `/r/api/client/${clientId}/payer/${payerId}/paymentRequest/${paymentRequestId}/markAsCancel`,
  reconcilePrId: (transactionId: string) => `/r/api/transaction/${transactionId}/reconcile`,
})

const recieveRfiApis = Object.freeze({
  complianceListForVRA: (transactionId: string) => `/r/api/rfi/${transactionId}`,
  submitRfiForVRA: (clientId: string) => `/r/api/client/${clientId}/rfi/submit`,
})

const exchangeCurrencyApis = Object.freeze({
  calculateBookFx: '/r/api/transactions/bookFx/calculate-amounts',
  currencyExchange: '/r/api/transactions/bookFx',
})
const withdrawApis = Object.freeze({
  addAndGetBankDetails: (clientId: string) => `/r/api/client/${clientId}/bankAccounts`,
  getBankDetailsFormSchema: '/r/api/receive/settings',
  EditDeleteBank: (clientId: string, bankId: string) =>
    `/r/api/client/${clientId}/bankAccounts/${bankId}`,
  withdrawFund: '/r/api/withdrawFund',
})

const transactionApproveApis = Object.freeze({
  approveRejectTransactionsForChecker: '/r/api/transactions/payouts/action',
})
const cancelTransactionApis = Object.freeze({
  cancelOnAwaitingFunds: '/r/api/settings/transactions/setCancelStatus',
  cancelOnRfiRequest: '/r/api/transactions/clientrfi/cancel-transaction',
})
const achRelatedApis = Object.freeze({
  rollOutLimit: `/r/api/transaction/ach/checkEligibility`,
  listoflinkedBankAccounts: (clientId: string) => `/smebackend/ach/api/v1/accounts/${clientId}`,
  initiateLinkAccount: `/smebackend/pp/api/v1/initiateLinkAccount`,
  getStatusofLinkedAccount: (referenceNumber: string, requestId: string) =>
    `/smebackend/pp/api/v1/accounts/link/status?referenceNumber=${referenceNumber}&requestId=${requestId}`,
  addBankAccount: `/smebackend/ach/api/v1/accounts`,
  getBalanceofAccount: (accountId: string) => `/smebackend/pp/api/v1/balance/${accountId}`,
  delinkBankAccount: `/smebackend/ach/api/v1/accounts`,
})

const accountingApis = Object.freeze({
  getCompanyDetails: '/smebackend/accounting/api/v1/companies',
  disconnectAccount: (companyId: string, connectionId: string) =>
    `/smebackend/accounting/api/v1/companies/${companyId}/connections/${connectionId}`,
  downloadBill: (companyId: string, connectionId: string, billId: string, attachmentId: string) =>
    `/smebackend/accounting/api/v1/companies/${companyId}/connections/${connectionId}/data/bills/${billId}/attachments/${attachmentId}/download`,
  getBills: (companyId: string) => `smebackend/accounting/api/v1/companies/${companyId}/data/bills`,
  updateBill: (companyId: string) => `/smebackend/accounting/api/v1/companies/${companyId}/bills`,
  syncBills: (companyId: string) =>
    `smebackend/accounting/api/v1/companies/${companyId}/data/queue/refresh`,
  turnOffXero: '/smebackend/accounting/api/v1/integrations/Xero',
})

const fpxTransferApis = Object.freeze({
  initiateFPXPayout: '/smebackend/fpx/api/v1/payout',
  getTransactionStatus: 'smebackend/fpx/api/v1/fpx-payments',
  updateTransactionPaymentId: (paymentId: string) =>
    `/smebackend/fpx/api/v1/fpx-payments/${paymentId}`,
})
const bpspDynamicCreditCardFee = Object.freeze({
  getClientPricing: '/smebackend/api/v1/bpsp/bpsp-pricing',
  getSavedCardDetails: '/smebackend/api/v1/bpsp/cards',
  postSaveCard: '/smebackend/api/v1/bpsp/cards',
  saveTransactionccPricing: '/smebackend/api/v1/bpsp/transaction-pricing',
  getCCPricing: (txnId: string) => `/smebackend/api/v1/bpsp/transaction-pricing/${txnId}`,
})

const csatApis = Object.freeze({
  csatRating: '/smebackend/api/v1/csat/rating',
})

const invoiceApis = Object.freeze({
  uploadInvoice: '/smebackend/api/v1/bpsp/invoice/upload',
  getInvoice: (pyId: string) => `/smebackend/api/v1/bpsp/invoice/${pyId}/file`,
  getFileName: (pyId: string) => `/smebackend/api/v1/bpsp/invoice/${pyId}`,
  invoiceMapping: '/smebackend/api/v1/bpsp/invoice/mapping',
})

export const apiRoutes = Object.freeze({
  ...authApis,
  ...transactionHistoryApis,
  ...allFxRateApis,
  ...contactApis,
  ...rfiDocumentsApi,
  ...cardApis,
  ...userSettingApis,
  ...sendMoneyApis,
  ...recieveApis,
  ...recieveRfiApis,
  ...exchangeCurrencyApis,
  ...withdrawApis,
  ...transactionApproveApis,
  ...cancelTransactionApis,
  ...achRelatedApis,
  ...accountingApis,
  ...bulkPaymentApis,
  ...fpxTransferApis,
  ...bpspDynamicCreditCardFee,
  ...csatApis,
  ...invoiceApis,
  // HACK: Use this URL for future file upload testing
  // testMulterUpload: '/r/api/test/multer/upload',
})
