import styled from 'styled-components/macro'

import { floors } from 'V2/constants/floors'
import { createBetween, great, less } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'

export const ModalContainer = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${floors.third};
`

export const ModalContent = styled.div`
  flex: 1;
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: unset;
  @media ${great.greaterThanMobile} {
    position: initial;
    width: ${pxToRem(328)};
    max-width: ${pxToRem(598)};
  }
  @media ${great.greaterThanTablet} {
    position: initial;
    width: ${pxToRem(328)};
    max-width: ${pxToRem(768)};
  }
  background: ${({ theme }): string => theme.revamp.staticColors.white};
  border: 1px solid ${({ theme }): string => theme.revamp.primary.grey.light};
  box-sizing: border-box;
  border-radius: 4px;
`

export const ModalHeader = styled.div`
  width: 100%;
  height: ${pxToRem(55)};
  @media ${great.greaterThanMobile} {
    height: ${pxToRem(75)};
  }
  padding: ${pxToRem(16)};
  box-sizing: border-box;
  border-bottom: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ModalBody = styled.div<{ overFlow: boolean; fullsize: boolean }>`
  min-height: ${pxToRem(72)};
  @media ${great.greaterThanMobile} {
    min-height: ${pxToRem(96)};
  }
  max-height: calc((var(--vh, 1vh) * 100) - 150px);
  overflow: ${({ overFlow }): string => (overFlow ? 'overlay' : '')};
  border-bottom: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light};
`
export const ModalFooter = styled.div`
  margin-left: 65%;
  padding: ${pxToRem(16)};
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: ${pxToRem(67)};
  @media ${great.greaterThanMobile} {
    min-height: ${pxToRem(83)};
  }
  @media ${createBetween('Mobile', 'Tablet')} {
    margin-left: 55%;
  }
  @media ${less.lessThanMobile} {
    margin: 0 10% 0;
  }
`
export const BodyWrapper = styled.div`
  padding: ${pxToRem(16)};
`
export const IconDivWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`

export const SpecialModalBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 30;
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.3);
`
