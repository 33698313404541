import styled from 'styled-components/macro'

import { BoxWrapper } from 'V2/components/atoms/BlueBox/Styles'
import { Wrapper } from 'V2/components/atoms/Input/Styles'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import { great, less } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'

export const Container = styled.div`
  ${BoxWrapper} {
    margin-bottom: 24px;
    @media ${great.greaterThanMobile} {
      margin-bottom: 32px;
      margin-top: 8px;
    }
  }
`

export const BankDetailsWrapper = styled.div`
  padding-top: 0px;
  @media ${less.lessThanMobile} {
    padding-top: 16px;
  }
`

export const DynamicField = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const BankDetailsHeader = styled(Typography).attrs({ type: 'h3', weight: 'semibold' })`
  margin: 0;
  padding-bottom: 24px;
`

export const StaticField = styled.span`
  margin-right: ${pxToRem(40)};
  input {
    padding: 12px 16px;
    font-size: 15px;
    line-height: 22px;
  }
`

export const FieldLayout = styled.div<{ fullWidth?: boolean; getLabel?: boolean }>`
  width: 100%;
  margin-bottom: ${pxToRem(24)};
`

export const ButtonWrapper = styled.div`
  margin-left: 24px;
  @media ${less.lessThanMobile} {
    width: 50%;
    margin-left: 16px;
    button {
      width: 100%;
    }
  }
`

export const BankDetailsInputWrapper = styled.div<{ getLabel?: boolean; lookup?: boolean }>`
  ${Wrapper} {
    width: ${({ lookup }): string => (lookup ? '263px' : '100%')};
    @media ${less.lessThanMobile} {
      width: ${({ lookup }): string => (lookup ? '50%' : '100%')};
    }
  }
  display: flex;
  flex: 1;
  input {
    padding: 12px 16px;
    font-size: 15px;
    line-height: 22px;
  }
`
export const ToolTipStyle = styled.span`
  display: flex;
  gap: 5px;
`
export const TooltipText = styled.div`
  padding: 16px;
  font-size: ${pxToRem(14)};
  line-height: 20px;
`
export const LineSeparator = styled.hr`
  border: none;
  background: ${({ theme }): string => theme.palette.tertiary.niumBlackL6};
  height: 1px;
  margin: ${pxToRem(16)} 0;
`
export const ContentHeader = styled.p`
  font-weight: bolder;
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(-8)};
  margin-top: ${pxToRem(8)};
  color: ${({ theme }): string => theme.palette.tertiary.niumBlackL1};
`
export const ContentText = styled.p`
  font-size: ${pxToRem(14)};
  color: ${({ theme }): string => theme.palette.tertiary.niumBlackL1};
`
export const StyledUL = styled.ul`
  margin-top: -5px;
  li {
    margin-left: ${pxToRem(-16)};
    color: ${({ theme }): string => theme.palette.tertiary.niumBlackL1};
  }
`
