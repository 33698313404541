import { FunctionComponent } from 'react'

import { MoreIcon } from 'V2/components/iconPaths/Custom/BasicIcons/MoreIcon'
import { ApprovalIcon } from 'V2/components/iconPaths/Custom/MoneyIcons/ApprovalIcon'
import { ContactsIcon } from 'V2/components/iconPaths/Custom/NavIcons/ContactsIcon'
import { CurrencyAccountsIcon } from 'V2/components/iconPaths/Custom/NavIcons/CurrencyAccountsIcon'
import { DashboardNavigationIcon } from 'V2/components/iconPaths/Custom/NavIcons/DashboardNavigationIcon'
import { HelpIcon } from 'V2/components/iconPaths/Custom/NavIcons/HelpIcon'
import { PayBillsIcon } from 'V2/components/iconPaths/Custom/NavIcons/PayBillsIcon'
import { SendMoneyIcon } from 'V2/components/iconPaths/Custom/NavIcons/SendMoneyIcon'
import { TransactionNavigationIcon } from 'V2/components/iconPaths/Custom/NavIcons/TransactionNavigationIcon'
import { appRoutes } from './appRoutes'
import { automationIdSelectors } from './automationIdSelectors'
import { WithdrawIcon } from 'V2/components/iconPaths/Custom/NavIcons/WithdrawIcon'

export const sidebarNavMapping: {
  [a: string]: {
    IconPath: FunctionComponent<{ stroke?: string }>
    children?: Array<string>
    isMobileMenu: boolean
    automationId?: string
  }
} = {
  'SME_navBar_dashboard-navlink': {
    IconPath: DashboardNavigationIcon,
    isMobileMenu: true,
    automationId: automationIdSelectors.sideBar.dashboardButton,
  },
  'SME_navbar_myactivity-navlink': {
    IconPath: CurrencyAccountsIcon,
    children: [appRoutes.addMoney, appRoutes.accountStatement],
    isMobileMenu: true,
    automationId: automationIdSelectors.sideBar.myAccountsButton,
  },
  'SME_navBar_sendmoney-navlink': {
    IconPath: SendMoneyIcon,
    isMobileMenu: true,
    automationId: automationIdSelectors.sideBar.sendMoneyButton,
  },
  'SME_navBar_bills-navlink': {
    IconPath: PayBillsIcon,
    isMobileMenu: false,
    automationId: automationIdSelectors.sideBar.billsButton,
  },
  'SME_navBar_contacts-navlink': {
    IconPath: ContactsIcon,
    isMobileMenu: false,
    automationId: automationIdSelectors.sideBar.contactsButton,
  },
  'SME_navBar_transactions-navlink': {
    IconPath: TransactionNavigationIcon,
    isMobileMenu: true,
    children: [
      appRoutes.vraTransactionDescription,
      appRoutes.exchangeDescription,
      appRoutes.fundingDescription,
      appRoutes.transactionDescription,
    ],
    automationId: automationIdSelectors.sideBar.transactionsButton,
  },
  'SME_navBar_approvals-navlink': {
    IconPath: ApprovalIcon,
    isMobileMenu: false,
    automationId: automationIdSelectors.sideBar.approvalsButton,
  },
  'SME_navBar_help-navlink': {
    IconPath: HelpIcon,
    children: [appRoutes.corridors],
    isMobileMenu: false,
    automationId: automationIdSelectors.sideBar.helpButton,
  },
  'SME_navBar_cards-navlink': {
    IconPath: WithdrawIcon,
    children: [appRoutes.cards],
    isMobileMenu: false,
    automationId: automationIdSelectors.sideBar.helpButton,
  },
  'SME_navBar_more-navlink': {
    IconPath: MoreIcon,
    children: [
      appRoutes.contacts,
      appRoutes.help,
      appRoutes.settings,
      appRoutes.transactionApproval,
      appRoutes.payBills,
    ],
    isMobileMenu: true,
    automationId: automationIdSelectors.sideBar.moreButton,
  },
}
