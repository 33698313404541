export const bpspSupportedLegalEntities = ['AU', 'SG', 'HK', 'MY']
export const bpspSupportedCurrencies = ['SGD', 'HKD', 'AUD', 'MYR']
export const directDebitSupportedLegalEntities = ['US']
export const SGD_TRANSFER_LIMIT_FOR_PAY_NOW = 200000
export const PaymentStatusType = {
  awaitingfunds: 'awaitingfunds',
  success: 'success',
  pendingApproval: 'pendingApproval',
}
export const legalEntityMappings: Record<string, string> = {
  SG: 'Singapore',
  AU: 'Australia',
  MY: 'Malaysia',
  HK: 'Hong Kong',
  JP: 'Japan',
}
export const HKD = 'HKD'
export const SGD = 'SGD'
export const AUD = 'AUD'
export const GBP = 'GBP'
export const EUR = 'EUR'
export const USD = 'USD'
export const NZD = 'NZD'
export const JPY = 'JPY'
export const MYR = 'MYR'
export const GB = 'GB'
export const US = 'US'
export const HK = 'HK'
export const MY = 'MY'
export const INR = 'INR'
export const IN = 'IN'
export const JP = 'JP'

export const creditCard = 'creditCard'
export const bankTransfer = 'bankTransfer'
export const account = 'account'
export const payId = 'payId'
export const payNow = 'payNow'
export const ach = 'ach'
export const fpxTransfer = 'fpxTransfer'

export const indirectPaymentMethods = [bankTransfer, payId, payNow]

//legal entities with additional payment methods like paynow, payid etc in awaiting funds credit card, currency account trnx in vfa flow
//once vfa is enabled for SG add it to the below list
export const additionalPaymentMethodsEnabledEntities = ['AU', 'US']

//Legal entities for popup when clicked on receive button for INR
export const sgHkLegalEntities = ['SG', 'HK', 'US', 'ID', 'MY']
export const auLegalEntities = ['AU']

//show timeline for status
export const timeLineStatus = ['CANCELLED', 'PAID', 'RFI_REJECTED', 'RETURNED', 'USER_REJECTED']

export const extraFeeCreditCardText =
  'When using a card issued outside of {country}, total fees of up to 3% may be applied after the transaction.'

//invoice upload title
export const reasonForTransferArr = ['Intra-company transfer', 'Investments', 'Salary & Wages']

export const JapanType1License = 'TYPE_1'
