import { ThunkResult } from 'react-redux'
import { ActionCreator, Dispatch } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import { SnackbarTypes } from 'store/actionTypes'
import { snackBarPaths } from 'V2/helpers/snackBarPaths'
import { Logger } from 'V2/services/logger'

const FILE_NAMESPACE = 'redux.actions.snackbar'
export const defaultTimeout = 5000

export const snackBarAction = (
  message: string,
  variant: string,
  timeout: number,
  dispatch: Dispatch
): string => {
  try {
    const messageId = uuidv4()
    dispatch({
      type: SnackbarTypes.SNACKBAR_MESSAGE,
      payload: {
        message,
        variant,
        id: messageId,
        iconPath: snackBarPaths[variant],
      },
    })

    setTimeout(() => {
      dispatch({
        type: SnackbarTypes.SNACKBAR_DISMISS,
        payload: messageId,
      })
    }, timeout)

    return messageId
  } catch (err) {
    Logger(FILE_NAMESPACE).error(`${variant} catch`, err)

    return `failed to create snackbar with message: ${message}`
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const success: ActionCreator<ThunkResult<any>> = (
  message: string,
  timeout = defaultTimeout
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function (dispatch): Promise<string> {
    return snackBarAction(message, 'success', timeout, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const warn: ActionCreator<ThunkResult<any>> = (
  message: string,
  timeout = defaultTimeout
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function (dispatch): Promise<string> {
    return snackBarAction(message, 'warn', timeout, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const info: ActionCreator<ThunkResult<any>> = (
  message: string,
  timeout = defaultTimeout
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function (dispatch): Promise<string> {
    return snackBarAction(message, 'info', timeout, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const error: ActionCreator<ThunkResult<any>> = (
  message: string,
  timeout = defaultTimeout
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function (dispatch): Promise<string> {
    return snackBarAction(message, 'error', timeout, dispatch)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dismiss: ActionCreator<ThunkResult<any>> = (messageId: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function (dispatch): Promise<void> {
    dispatch({
      type: SnackbarTypes.SNACKBAR_DISMISS,
      payload: messageId,
    })
  }
}
