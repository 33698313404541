import { bizpayClientLegalEntities } from 'V2/constants/bizpayClientLegalEntity'
import { migrationAdminUserId } from 'V2/constants/instaremMigration'

export const clientLegalEntityMapper = (clientLegalEntityText: string): string => {
  const result = bizpayClientLegalEntities.find(
    (legalEntity) =>
      legalEntity?.countryCode === clientLegalEntityText ||
      legalEntity?.countryName === clientLegalEntityText ||
      legalEntity?.masspayClientEntity === clientLegalEntityText
  )?.countryCode

  return result ?? clientLegalEntityText
}

export const showCurrencyAccountForLegalEntity = (clientLegalEntity: string): boolean => {
  const showCurrencyAccount = bizpayClientLegalEntities.find(
    (legalEntity) =>
      legalEntity.countryCode === clientLegalEntity ||
      legalEntity.countryName === clientLegalEntity ||
      legalEntity.masspayClientEntity === clientLegalEntity
  )?.showCurrencyAccount
  return showCurrencyAccount ?? true
}

export const showNewAccountTagForClient = (
  clientLegalEntity: string,
  createdBy: string
): boolean => {
  if (createdBy === migrationAdminUserId)
    return (
      bizpayClientLegalEntities.find(
        (legalEntity) =>
          legalEntity.countryCode === clientLegalEntity ||
          legalEntity.countryName === clientLegalEntity ||
          legalEntity.masspayClientEntity === clientLegalEntity
      )?.showNewAccountTagMigratedCustomer || false
    )

  return false
}

export const getDefaultCurrencyForClient = (clientLegalEntity: string): string => {
  return (
    bizpayClientLegalEntities.find(
      (legalEntity) =>
        legalEntity.countryCode === clientLegalEntity ||
        legalEntity.countryName === clientLegalEntity ||
        legalEntity.masspayClientEntity === clientLegalEntity
    )?.defaultCurrency || clientLegalEntity
  )
}
