export const feeWithOptions = [
  { value: 'account', label: 'Currency Account' },
  { value: 'creditCard', label: 'Credit Card' },
  { value: 'payNow', label: 'PayNow' },
  { value: 'payId', label: 'PayId' },
  { value: 'bankTransfer', label: 'Bank Transfer' },
  { value: 'ach', label: 'ACH' },
  { value: 'fpxTransfer', label: 'FPX Transfer' },
]

export const transactionDepositMethod: { [key: string]: string } = {
  CARD: 'Credit Card',
  WALLET: 'Currency Account',
  REFUNDWALLET: 'Amount',
  BANK: 'Bank Transfer',
  PROXYSGD: 'PayNow',
  PROXYAUD: 'PayID',
  ACH: 'ACH',
  PROXYMYR: 'FPX Transfer',
}

export const createPayoutDepositMethod: { [key: string]: string } = {
  creditCard: 'CARD',
  account: 'WALLET',
  bankTransfer: 'BANK',
  payId: 'PROXY',
  payNow: 'PROXY',
  ach: 'PROXY',
  fpxTransfer: 'PROXY',
}

export const paymentMode: { [key: string]: string } = {
  account: 'Funded by Account',
  bankTransfer: 'Funded by Bank',
  payId: 'Funded by PayId',
  payNow: 'Funded by PayNow',
  ach: 'Funded by ACH',
  fpxTransfer: 'Funded by FPX',
}

export const paymentMethodLabel: { [key: string]: string } = {
  bankTransfer: 'bank',
  payId: 'PayID',
  payNow: 'PayNow',
  ach: 'ACH',
}
