// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

// TODO: update with new sidemenu integration
import { SmeTemplate } from 'V2/components/templates/SmeTemplate/SmeTemplate'
import { appRoutes } from 'V2/constants/appRoutes'
import { PrivateRouteProps } from 'V2/interfaces/Routes.interface'
import { Logger } from 'V2/services/logger'

const FILE_NAMESPACE = 'PrivateRouteComponent'

export const PrivateRouteComponent: React.FC<PrivateRouteProps> = ({
  hideSidePanel = false,
  checkPrivileges = false,
  subMenuOf = '',
  component: Component,
  ...rest
}) => {
  const loggedIn = useSelector((state) => state.userClientDetails?.authenticated || false)
  const menus = useSelector((state) => state.userClientDetails?.menus || [])
  if (!loggedIn) {
    sessionStorage.setItem('redirectionUrl', window.location.href)
  }

  // logger
  Logger(FILE_NAMESPACE).silly('props', {
    hideSidePanel,
    checkPrivileges,
    subMenuOf,
    loggedIn,
    menus,
    ...rest,
  })

  // redirect to login page
  const goToLogin = (): JSX.Element => <Redirect to={{ pathname: appRoutes.authLogin }} />

  // redirect to dashboard page
  const goToDashboard = (): JSX.Element => <Redirect to={{ pathname: appRoutes.dashboard }} />

  // display component with sidebar or without sidebar
  const renderView = (): JSX.Element => {
    return hideSidePanel ? (
      <Component {...rest} />
    ) : (
      <SmeTemplate>
        <Component {...rest} />
      </SmeTemplate>
    )
  }

  // check user privilege before displaying component
  const renderByPrivileges = (): JSX.Element => {
    if (checkPrivileges) {
      const { path } = rest
      const pathToCheck = subMenuOf ? subMenuOf : path

      return menus.find((menu) => menu.url === pathToCheck) ? renderView() : goToDashboard()
    }
    return renderView()
  }

  useEffect(() => {
    const adjustWindowSize = (): number => {
      let windowHeight = Math.max(window.innerHeight, document?.documentElement?.clientHeight || 0)
      const iDevices = ['iphone', 'ipad', 'ipod']

      if (iDevices.some((iDevice) => navigator.userAgent.toLowerCase().includes(iDevice))) {
        let bodyRect = new DOMRect()
        if (document?.documentElement?.getBoundingClientRect) {
          bodyRect = document.documentElement.getBoundingClientRect()
        } else if (document?.body?.getBoundingClientRect) {
          bodyRect = document.body.getBoundingClientRect()
        }
        if (bodyRect?.top !== 0 && bodyRect?.bottom !== 0) {
          windowHeight = bodyRect.bottom - bodyRect.top
        }
      }
      return windowHeight
    }
    // The resize event to resolve the 100vh issue for the mobile
    window.addEventListener('resize', () => {
      const vh = adjustWindowSize() * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })
    window.dispatchEvent(new Event('resize'))
  }, [])

  return (
    <Route {...rest} render={(): JSX.Element => (loggedIn ? renderByPrivileges() : goToLogin())} />
  )
}

export const PrivateRoute = React.memo(PrivateRouteComponent)
