export const bizpayClientLegalEntities = [
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    masspayClientEntity: 'Singapore',
    defaultCurrency: 'SGD',
    currencyOptions: ['SGD', 'USD'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    masspayClientEntity: 'HongKong',
    defaultCurrency: 'HKD',
    currencyOptions: ['HKD', 'USD'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: true,
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    masspayClientEntity: 'Aust',
    defaultCurrency: 'AUD',
    currencyOptions: ['AUD', 'USD'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: true,
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    masspayClientEntity: 'Ind',
    defaultCurrency: 'INR',
    currencyOptions: ['INR'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'US',
    countryName: 'United States',
    masspayClientEntity: 'USA',
    defaultCurrency: 'USD',
    currencyOptions: ['USD'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: true,
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    masspayClientEntity: 'UK',
    defaultCurrency: 'GBP',
    currencyOptions: ['GBP', 'EUR'],
    showCurrencyAccount: false,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'CA',
    countryName: 'Canada',
    masspayClientEntity: 'Canada',
    defaultCurrency: 'CAD',
    currencyOptions: ['CAD', 'USD'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'MY',
    countryName: 'Malaysia',
    masspayClientEntity: 'Malaysia',
    defaultCurrency: 'MYR',
    currencyOptions: ['MYR'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    masspayClientEntity: 'Indonesia',
    defaultCurrency: 'IDR',
    currencyOptions: ['IDR', 'USD'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    masspayClientEntity: 'Lithuania',
    defaultCurrency: 'EUR',
    currencyOptions: ['EUR', 'USD'],
    showCurrencyAccount: false,
    showNewAccountTagMigratedCustomer: false,
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    masspayClientEntity: 'Japan',
    defaultCurrency: 'JPY',
    currencyOptions: ['JPY'],
    showCurrencyAccount: true,
    showNewAccountTagMigratedCustomer: false,
  },
]
