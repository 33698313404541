/* eslint-disable @typescript-eslint/camelcase */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import {
  bizpaySendCountries,
  disabledCountriesForClientLegalEntities,
  invalidCountriesForJapanType1,
} from 'V2/constants/bizpaySendCountries'
import { bizpaySendCurrencies } from 'V2/constants/bizpaySendCurrencies'
import { JP, US, USD } from 'V2/constants/paymentMethodFilters'
import { CNY_FEATURE_FLAG } from 'V2/constants/userClientDetails'
import {
  accountTypeDisabledSendCurrency,
  disabledCurrency,
} from 'V2/helpers/calculateTotalAmountWithFees'
import { clientLegalEntityMapper } from 'V2/helpers/clientLegalEntityMapper'
import { DestinationCurrency } from 'V2/interfaces/components/Organisms.interface'
import {
  HooksFieldOptions,
  HooksStateCountryDetails,
  HooksToggleBeneAccountType,
} from 'V2/interfaces/Hooks.interface'
import { getUsers } from 'V2/services/beneficiary'
import { Logger } from 'V2/services/logger'

const FILE_NAMESPACE = 'useAddRecipient'

export const domesticCurrencyCountryPair = bizpaySendCurrencies.reduce((acc, currency) => {
  const newAcc = { ...acc }
  newAcc[currency.value] = currency.localForCountry
  return newAcc
}, {} as Record<string, string>)

const domesticCountryCurrencyPair = bizpaySendCurrencies.reduce((acc, currency) => {
  const newAcc = { ...acc }
  newAcc[currency.localForCountry] = currency.value
  return newAcc
}, {} as Record<string, string>)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type useAddRecipientReturn = (id: string, value: any) => void

const useAddRecipient = (
  isDomesticUser: boolean, // engagement type
  source_country: string, //legal entity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beneficiaryData?: any,
  billCurrency?: DestinationCurrency,
  payerCountryCode?: string
): [
  HooksStateCountryDetails,
  HooksFieldOptions,
  useAddRecipientReturn,
  string,
  boolean,
  string,
  HooksToggleBeneAccountType,
  string,
  string[]
] => {
  const { beneficiary_account_type, payouts } = beneficiaryData ?? {}
  const clientLegalEntity = useSelector((state) =>
    clientLegalEntityMapper(state.userClientDetails?.activeClient?.client_legal_entity || '')
  )
  const clientId = useSelector((state) =>
    clientLegalEntityMapper(state.userClientDetails?.activeClient?._id || '')
  )

  // compute initial value for country
  const countryCodeInitialState = { label: '', value: '' }

  // compute initial value for currency
  const destinationCurrency = {
    label: '',
    value: '',
    localForCountry: '',
  }
  const licenseType = useSelector(
    (state) => state.userClientDetails?.activeClient?.license_type ?? ''
  )
  const isJapanType1 = clientLegalEntity === JP && licenseType === 'TYPE_1'
  // compute options for country drop down
  const countryList = [
    ...(isDomesticUser
      ? bizpaySendCountries.filter((bizpayCountry) => bizpayCountry?.value === source_country)
      : bizpaySendCountries
    )
      .filter(
        (country) =>
          !disabledCountriesForClientLegalEntities[clientLegalEntity]?.includes(country.value)
      )
      .filter((country) => {
        if (isJapanType1) {
          return !invalidCountriesForJapanType1.includes(country.value)
        } else {
          return true
        }
      }),
  ].sort((a, b) => (a.label > b.label ? 1 : -1))

  const getPayerCountryOption = countryList.find((item) => item.value === payerCountryCode)

  // compute options for currency drop down
  const currencyList = isDomesticUser
    ? bizpaySendCurrencies?.filter((currency) => currency?.localForCountry === source_country)
    : bizpaySendCurrencies

  // declare state for country and currency
  const [stateCountryDetails, changeCountryDetails] = useState<HooksStateCountryDetails>({
    beneficiaryCountryCode: getPayerCountryOption || countryCodeInitialState, //  Country
    destinationCurrency: destinationCurrency, // Currency
  })
  // declare state for country and currency options
  const [fieldOptions, changeFieldOptions] = useState<HooksFieldOptions>({
    countryList,
    currencyList,
  })

  // beneficiary_account_type selection
  const [beneAccountType, changeBeneAccountType] = useState<string>(
    beneficiary_account_type ?? 'Company'
  )
  const [destinationsCurrencyError, changeDestinationCurrencyError] = useState<string>('')
  const [userEmail, setUsersEmail] = useState<string[]>([])

  useEffect(() => {
    beneficiary_account_type && changeBeneAccountType(beneficiary_account_type)
  }, [beneficiary_account_type])

  useEffect(() => {
    if (clientLegalEntityMapper(clientLegalEntity) === 'JP') {
      getUsers(clientId).then((res) => {
        setUsersEmail(
          res?.data?.data?.map((item: { email: string }): string => {
            return item.email
          })
        )
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleBeneAccountType = useCallback((newValue: string) => {
    changeBeneAccountType(newValue)
  }, [])

  const supportedCorridors = useRef<
    { payout_method: string; destination_currency: string }[] | null
  >(null)
  const [payoutMethod, setPayoutMethod] = useState<string>('LOCAL')
  const [loading, setLoading] = useState<boolean>(false)

  const cnyEnabled =
    useSelector((state) => state.userClientDetails.featureFlagConfiguration.featureFlagInfo).filter(
      (item: { feature: string; enabled: boolean }) => item.feature === CNY_FEATURE_FLAG
    )[0]?.enabled || false

  //handles country and currency field changes
  const onFieldChange = useCallback(
    async (id, newValues) => {
      setLoading(true)
      if (id === 'beneficiaryCountryCode') {
        //executes when country field is changed

        supportedCorridors.current = newValues.value
          ? await axios
              .get(apiRoutes.metadata, {
                params: {
                  entity: 'supported_corridors',
                  destination_country: newValues.value,
                  source_country,
                },
              })
              .then((response) => {
                const corridors = response.data.data
                  .filter(
                    (item: { payout_method: string; destination_currency: string }) =>
                      (item.payout_method === 'LOCAL' || item.payout_method === 'SWIFT') &&
                      !!domesticCurrencyCountryPair[item.destination_currency]
                  )
                  .map((item: { payout_method: string; destination_currency: string }) => ({
                    payout_method: item.payout_method,
                    destination_currency: item.destination_currency,
                  }))

                Logger(FILE_NAMESPACE).silly('corridors', corridors)
                return corridors
              })
              .catch((err) => {
                Logger(FILE_NAMESPACE).error('supportedCorridors', err)
                return null
              })
          : await Promise.resolve([])

        const localCurrency = domesticCountryCurrencyPair[newValues.value]
        //If "country" changes, compute "currency" drop downs options
        const newCurrencyList = beneficiary_account_type
          ? // Edit Bene: beneficiary data exists show beneficiary currency only
            [
              bizpaySendCurrencies.find((currency) => {
                Logger(FILE_NAMESPACE).debug(
                  'newCurrencyList:editBene',
                  Array.isArray(payouts) ? payouts[0]?.destination_currency : '',
                  currency.value,
                  (Array.isArray(payouts) ? payouts[0]?.destination_currency : '') ===
                    currency.value
                )
                return (
                  (Array.isArray(payouts) ? payouts[0]?.destination_currency : '') ===
                  currency.value
                )
              }) ?? bizpaySendCurrencies[0],
            ]
          : // Add Bene: show supported corridors list currencies from entire bizpaySend list
          // Check if the dropdown is reset due to account_type change then return empty string object
          newValues.value
          ? bizpaySendCurrencies
              .filter(
                (currency1) =>
                  (supportedCorridors.current ?? []).some(
                    (corridor: { destination_currency: string }) => {
                      return corridor.destination_currency === currency1.value
                    }
                  ) &&
                  // hide disabled currencies
                  !disabledCurrency(currency1.value, cnyEnabled) &&
                  !accountTypeDisabledSendCurrency(currency1.value, beneAccountType)
              )
              .sort((currency1, _currency2) => {
                if (currency1.value === localCurrency) {
                  return -1
                }
                return 1
              })
          : currencyList

        //stores all supported payout method if newCurrencyList[0] is in supportedCorridors
        const supportedPayoutmethods = supportedCorridors.current?.filter(
          (item) =>
            item.destination_currency ===
            (billCurrency ? billCurrency.value : newCurrencyList[0].value)
        )

        //stores payout_method -> LOCAL is preferred if present then the first match else LOCAL
        let updatedPayoutMethod =
          supportedPayoutmethods?.find((item) => item.payout_method === 'LOCAL')?.payout_method ||
          supportedPayoutmethods?.find((item) => item)?.payout_method ||
          'LOCAL'

        if (newValues.value === US && newCurrencyList[0].value === USD) {
          updatedPayoutMethod = 'LOCAL'
        }

        Logger(FILE_NAMESPACE).debug('onFieldChange:beneficiaryCountryCode', {
          'supportedCorridors.current': supportedCorridors.current,
          newValues,
          newCurrencyList,
          updatedPayoutMethod,
          'supportedCorridors.current?.find': supportedCorridors.current?.find(
            (item) => item.payout_method === 'LOCAL'
          )?.destination_currency,
        })

        if (payoutMethod !== updatedPayoutMethod) setPayoutMethod(updatedPayoutMethod)

        // Update drop down options for currency field
        changeFieldOptions({
          ...fieldOptions,
          currencyList: newCurrencyList,
        })

        let newDestinationCurrency: DestinationCurrency
        const billCurrencyAvailable =
          billCurrency && newCurrencyList.find((curency) => curency.value === billCurrency.value)

        if (billCurrency) {
          newDestinationCurrency = billCurrencyAvailable
            ? billCurrency
            : {
                value: '',
                label: '',
                localForCountry: '',
              }

          changeDestinationCurrencyError(
            billCurrencyAvailable
              ? ''
              : `We do not support ${billCurrency?.value} payments to ${newValues.label}`
          )
        } else {
          newDestinationCurrency = newCurrencyList[0]
        }

        // update country and currency fields
        changeCountryDetails({
          ...stateCountryDetails,
          beneficiaryCountryCode: newValues,
          destinationCurrency: newValues.value ? newDestinationCurrency : destinationCurrency,
        })
      }
      if (id === 'destinationCurrency') {
        //executes when currency field is changed

        //stores all supported payout method if selected currency is in supportedCorridors
        const supportedPayoutmethods = supportedCorridors.current?.filter(
          (item) => item.destination_currency === newValues.value
        )

        //stores payout_method -> LOCAL is preferred if present then the first match else LOCAL
        let updatedPayoutMethod =
          supportedPayoutmethods?.find((item) => item.payout_method === 'LOCAL')?.payout_method ||
          supportedPayoutmethods?.find((item) => item)?.payout_method ||
          'LOCAL'
        Logger(FILE_NAMESPACE).debug('onFieldChange:destinationCurrency', newValues)

        if (newValues.value === USD && stateCountryDetails?.beneficiaryCountryCode.value === US) {
          updatedPayoutMethod = 'LOCAL'
        }
        if (payoutMethod !== updatedPayoutMethod) setPayoutMethod(updatedPayoutMethod)
        //update currency field
        changeCountryDetails((prevState) => ({
          ...prevState,
          destinationCurrency: newValues,
        }))
      }
      setLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      beneAccountType,
      beneficiary_account_type,
      currencyList,
      destinationCurrency,
      fieldOptions,
      payoutMethod,
      payouts,
      source_country,
      stateCountryDetails,
    ]
  )

  return [
    stateCountryDetails, // state to maintain bene country and currency details
    fieldOptions, // state to maintain country and currency drop down list
    onFieldChange, // handles country and currency field changes
    payoutMethod, // to store payout method ('local' or 'swift')
    loading,
    beneAccountType, // to store bene account type ('company' or 'individual')
    toggleBeneAccountType,
    destinationsCurrencyError,
    userEmail,
  ]
}

export { useAddRecipient }
