import { Reducer } from 'redux'

import { TransactionHistory } from 'interfaces/redux/Reducers.interface'
import { TransactionHistoryActionType } from 'store/actionTypes'

const InitialState = {
  loading: false,
  timeoutId: [],
  transactionData: {
    items: [],
    total: 0,
  },
  awaitingFundsTxnData: {
    items: [],
    updatedAt: '',
  },
}

const transactionHistory: Reducer<TransactionHistory> = (state = InitialState, action) => {
  switch (action.type) {
    case TransactionHistoryActionType.TRANSACTION_HISTORY_DATA_REQUEST:
      return { ...state, loading: true }
    case TransactionHistoryActionType.TRANSACTION_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: {
          items: action.payload.data ?? [],
          total: action.payload.recordsFiltered ?? 0,
        },
      }
    case TransactionHistoryActionType.TRANSACTION_HISTORY_UPDATE_REQUEST:
      return {
        ...state,
        timeoutId: [...state.timeoutId, action.payload],
      }
    case TransactionHistoryActionType.TRANSACTION_HISTORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        transactionData: {
          items: [...(action.payload ?? []), ...state.transactionData.items],
          total: action.payload?.recordsFiltered
            ? state.transactionData.total + action.payload.recordsFiltered
            : state.transactionData.total,
        },
      }
    case TransactionHistoryActionType.TRANSACTION_HISTORY_DATA_FAILURE:
      return { ...state, loading: false }
    // TODO: Remove this with V1 code
    case TransactionHistoryActionType.AWAITING_FUNDS_TXN_FETCH_SUCCESS:
      return { ...state, awaitingFundsTxnData: action.payload }
    default:
      return state
  }
}

export { transactionHistory }
