// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const PartnerIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2716_77138)">
      <path
        d="M3 3.75C3 4.54565 3.31607 5.30871 3.87868 5.87132C4.44129 6.43393 5.20435 6.75 6 6.75C6.79565 6.75 7.55871 6.43393 8.12132 5.87132C8.68393 5.30871 9 4.54565 9 3.75C9 2.95435 8.68393 2.19129 8.12132 1.62868C7.55871 1.06607 6.79565 0.75 6 0.75C5.20435 0.75 4.44129 1.06607 3.87868 1.62868C3.31607 2.19129 3 2.95435 3 3.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 13.5C11.25 12.1076 10.6969 10.7723 9.71231 9.78769C8.72774 8.80312 7.39239 8.25 6 8.25C4.60761 8.25 3.27226 8.80312 2.28769 9.78769C1.30312 10.7723 0.75 12.1076 0.75 13.5V15.75H3L3.75 23.25H8.25L9 15.75H11.25V13.5Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 3.75C15 4.54565 15.3161 5.30871 15.8787 5.87132C16.4413 6.43393 17.2044 6.75 18 6.75C18.7956 6.75 19.5587 6.43393 20.1213 5.87132C20.6839 5.30871 21 4.54565 21 3.75C21 2.95435 20.6839 2.19129 20.1213 1.62868C19.5587 1.06607 18.7956 0.75 18 0.75C17.2044 0.75 16.4413 1.06607 15.8787 1.62868C15.3161 2.19129 15 2.95435 15 3.75Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15.7492H15L15.75 23.2492H20.25L21 15.7492H23.25V13.4992C23.2496 12.3516 22.8733 11.2358 22.1786 10.3223C21.4839 9.40887 20.5091 8.74816 19.4033 8.44126C18.2975 8.13435 17.1216 8.19816 16.0555 8.62292C14.9894 9.04767 14.0918 9.80998 13.5 10.7932"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8.25V14.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2716_77138">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
