// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router'

import { Spinner } from 'V2/components/atoms/Spinner/Spinner'
import { appRoutes } from 'V2/constants/appRoutes'
import { AUTH_CONSTANTS } from 'V2/constants/auth'
import { ENABLE_BULK_UPLOADS } from 'V2/constants/userClientDetails'
import GA from 'V2/helpers/gaTracker'
import { useDefaultReload } from 'V2/hooks/useDefaultReload'
import { DefaultRoute } from 'V2/routes/DefaultRoute'
import { PrivateRoute } from 'V2/routes/PrivateRoute'

const SuspenseFallback = <Spinner show={true} />
// SME-Portal Routes
const AuthPage = React.lazy(() => import('V2/pages/Auth/AuthPage'))
const Dashboard = React.lazy(() => import('V2/pages/Dashboard/DashboardPage'))
const Send = React.lazy(() => import('V2/pages/SendMoney/SendMoneyLandingPage'))
const Bills = React.lazy(() => import('V2/pages/PayBills/PayBills'))
const IndividualSend = React.lazy(
  () => import('V2/pages/SendMoney/IndividualSend/IndividualSendPage')
)
const GroupSend = React.lazy(() => import('V2/pages/SendMoney/GroupSend/GroupSendPage'))
const AddMoney = React.lazy(() => import('V2/pages/AddMoney/AddMoney'))
const Exchange = React.lazy(() => import('V2/pages/Exchange/Exchange'))
const Withdraw = React.lazy(() => import('V2/pages/Withdraw/Withdraw'))
const MyAccounts = React.lazy(() => import('V2/pages/MyAccounts/MyAccounts'))
const Contacts = React.lazy(() => import('V2/pages/Contacts/Contacts'))
const CallbackRedirection = React.lazy(
  () => import('V2/pages/CallbackRedirection/CallbackRedirection')
)
const Settings = React.lazy(() => import('V2/pages/Settings/Settings'))
const BulkPayment = React.lazy(() => import('V2/pages/BulkPayment/BulkPayment'))
const Faq = React.lazy(() => import('V2/pages/Faq/Faq'))
const Corridors = React.lazy(() => import('V2/pages/Corridors/Corridors'))
const Approvals = React.lazy(() => import('V2/pages/Approvals/Approvals'))
const AccountStatement = React.lazy(() => import('V2/pages/AccountStatement/AccountStatement'))
const Cards = React.lazy(() => import('V2/pages/Cards/Cards'))
const WithdrawMoney = React.lazy(() => import('V2/pages/Cards/WithdrawMoney'))
const ExchangeMoney = React.lazy(() => import('V2/pages/Cards/ExchangeMoney'))
// SME-Onboarding Routes
const SGLookup = React.lazy(() => import('Onboarding/pages/corridors/sg/SG-Lookup/SGLookup'))
const SGOnboardingForm = React.lazy(() => import('Onboarding/pages/corridors/sg/Form/SgForm'))
const Dropout = React.lazy(() => import('Onboarding/pages/dropout/Dropout'))
const SGDropoutForm = React.lazy(
  () => import('Onboarding/pages/corridors/sg/SG-Dropout/SGDropoutForm')
)
const UKLookup = React.lazy(() => import('Onboarding/pages/corridors/uk/UK-Lookup/UKLookup'))
const UKOnboardingForm = React.lazy(() => import('Onboarding/pages/corridors/uk/Form/UkForm'))
const HKOnboardingForm = React.lazy(() => import('Onboarding/pages/corridors/hk/Form/HkForm'))
const EUOnboardingForm = React.lazy(() => import('Onboarding/pages/corridors/eu/Form/EUForm'))
const FpxStatusScreen = React.lazy(
  () => import('V2/components/molecules/FpxStatusScreen/FpxStatusScreen')
)
const GettingStarted = React.lazy(() => import('Onboarding/pages/GettingStarted/GettingStarted'))
const AuForm = React.lazy(() => import('Onboarding/pages/corridors/au/AuForm'))
const Lookup = React.lazy(() => import('Onboarding/pages/lookup/Lookup'))
const Error = React.lazy(() => import('Onboarding/pages/reject/Error'))
const Reject = React.lazy(() => import('Onboarding/pages/reject/Reject'))
const SignUp = React.lazy(() => import('Onboarding/pages/signup/SignUp'))
const Success = React.lazy(() => import('Onboarding/pages/success/Success'))
const RfiDashboard = React.lazy(() => import('Onboarding/pages/rfi/rfiDashboard'))
const RfiDocumentUpload = React.lazy(() => import('Onboarding/pages/rfi/rfiDocumentUpload'))
const RfiSuccess = React.lazy(() => import('Onboarding/pages/rfi/rfiSuccess'))
const IDSignUpNewTab = React.lazy(() => import('V2/pages/Auth/IdSignupMobile'))
const Maintenance = React.lazy(() => import('V2/pages/Maintenance/Maintenance'))

const Transactions = React.lazy(() => import('V2/pages/Transactions/Transactions'))

const TransactionDescription = React.lazy(
  () => import('V2/pages/TransactionDescription/TransactionDescription')
)

const PaymentReqTransDescription = React.lazy(
  () => import('V2/pages/PaymentReqTransDescription/PaymentReqTransDescription')
)
const FundingDescription = React.lazy(
  () => import('V2/pages/FundingDescription/FundingDescription')
)

const VraTransactionDescription = React.lazy(
  () => import('V2/pages/VraTransactionDescription/VraTransactionDescription')
)
const ExchangeDescription = React.lazy(
  () => import('V2/pages/ExchangeDescription/ExchangeDescription')
)

export const RoutesV2: React.FC<{}> = () => {
  const history = useHistory()
  const bulkPaymentEnabled =
    useSelector(
      (state) => state.userClientDetails?.featureFlagConfiguration?.featureFlagInfo
    )?.find((flag: { feature: string }) => flag.feature === ENABLE_BULK_UPLOADS)?.enabled || false

  useEffect(() => {
    history.listen((): void => GA.track('PAGEVIEW', {}))
  }, [history])

  useDefaultReload()
  return (
    <Suspense fallback={SuspenseFallback}>
      <Switch>
        {/* SME Portal- Public Routes */}
        <Route exact path={appRoutes.authLogin}>
          <AuthPage screen={AUTH_CONSTANTS.TEMPLATES.LOGIN_PROMPT} />
        </Route>
        <Route exact path={appRoutes.authForgetPassword}>
          <AuthPage screen={AUTH_CONSTANTS.TEMPLATES.FORGET_PASSWORD_PROMPT} />
        </Route>
        <Route exact path={appRoutes.authResetPassword}>
          <AuthPage screen={AUTH_CONSTANTS.TEMPLATES.RESET_PASSWORD} />
        </Route>
        <Route exact path={appRoutes.migration}>
          <AuthPage screen={AUTH_CONSTANTS.TEMPLATES.MIGRATION} />
        </Route>
        <Route exact path={appRoutes.migrationResetPassword}>
          <AuthPage screen={AUTH_CONSTANTS.TEMPLATES.MIGRATION_TOKEN} />
        </Route>
        <Route exact path={appRoutes.onboardingSuccess}>
          <Success />
        </Route>
        <Route exact path={appRoutes.onboardingError}>
          <Error />
        </Route>
        <Route exact path={appRoutes.signUp}>
          <IDSignUpNewTab />
        </Route>
        {/* Onboarding Public Routes */}
        {/* Logic to be written for handling routes disabled in preprod */}
        <Route exact path={appRoutes.register}>
          <SignUp />
        </Route>
        <Route exact path={appRoutes.startOnboarding}>
          <GettingStarted />
        </Route>
        <Route exact path={appRoutes.lookup}>
          <Lookup />
        </Route>
        <Route exact path={appRoutes.onboardingForm}>
          <AuForm />
        </Route>
        <Route exact path={appRoutes.sgOnboardingForm}>
          <SGOnboardingForm />
        </Route>
        <Route exact path={appRoutes.sgLookup}>
          <SGLookup />
        </Route>
        <Route exact path={appRoutes.dropout}>
          <Dropout />
        </Route>
        <Route exact path={appRoutes.sgDropoutForm}>
          <SGDropoutForm />
        </Route>
        <Route exact path={appRoutes.success}>
          <Success />
        </Route>
        <Route exact path={appRoutes.reject}>
          <Reject />
        </Route>
        <Route exact path={appRoutes.maintenanceDownTime}>
          <Maintenance />
        </Route>
        <Route exact path={appRoutes.ukOnboardingForm}>
          <UKOnboardingForm />
        </Route>
        <Route exact path={appRoutes.hkOnboardingForm}>
          <HKOnboardingForm />
        </Route>
        <Route exact path={appRoutes.euOnboardingForm}>
          <EUOnboardingForm />
        </Route>
        <Route exact path={appRoutes.ukLookup}>
          <UKLookup />
        </Route>
        <Route exact path={appRoutes.onboardingRFI}>
          <RfiDashboard />
        </Route>
        <Route exact path={appRoutes.onboardingRFIDocumentUpload}>
          <RfiDocumentUpload />
        </Route>
        <Route exact path={appRoutes.onboardingRFISuccess}>
          <RfiSuccess />
        </Route>
        {/* SME-Private Routes */}
        <PrivateRoute
          exact
          hideSidePanel
          path={appRoutes.authSetPassword}
          component={AuthPage}
          screen={AUTH_CONSTANTS.TEMPLATES.SET_PASSWORD}
        />
        <PrivateRoute exact path={appRoutes.dashboard} component={Dashboard} />
        <PrivateRoute exact path={appRoutes.contacts} component={Contacts} />
        <PrivateRoute exact path={appRoutes.callbackRedirection} component={CallbackRedirection} />
        <PrivateRoute exact checkPrivileges path={appRoutes.send} component={Send} />
        <PrivateRoute exact checkPrivileges path={appRoutes.payBills} component={Bills} />
        <PrivateRoute
          exact
          hideSidePanel
          path={appRoutes.individualSend}
          component={IndividualSend}
        />
        <PrivateRoute hideSidePanel exact path={appRoutes.groupSend} component={GroupSend} />
        <PrivateRoute
          exact
          path={appRoutes.bulkPayment}
          checkPrivileges
          component={BulkPayment}
          subMenuOf={bulkPaymentEnabled ? appRoutes.send : ''}
        />
        <PrivateRoute exact path={appRoutes.transactionList} component={Transactions} />
        <PrivateRoute exact path={appRoutes.help} component={Faq} />
        <PrivateRoute exact path={appRoutes.corridors} component={Corridors} />
        <PrivateRoute
          exact
          checkPrivileges
          path={appRoutes.transactionApproval}
          component={Approvals}
        />
        <PrivateRoute exact path={appRoutes.settings} component={Settings} />
        {/* fpx */}
        <PrivateRoute exact hideSidePanel path={appRoutes.fpxSuccess} component={FpxStatusScreen} />
        {/* Private Routes - Need Access */}
        <PrivateRoute
          exact
          checkPrivileges
          path={appRoutes.myAccounts}
          subMenuOf={appRoutes.myAccounts}
          component={MyAccounts}
        />
        <PrivateRoute
          exact
          checkPrivileges
          hideSidePanel
          path={appRoutes.exchange}
          subMenuOf={appRoutes.myAccounts}
          component={Exchange}
        />
        <PrivateRoute
          exact
          checkPrivileges
          hideSidePanel
          path={appRoutes.withdraw}
          subMenuOf={appRoutes.myAccounts}
          component={Withdraw}
        />
        <PrivateRoute
          exact
          checkPrivileges
          hideSidePanel
          path={appRoutes.addMoney}
          subMenuOf={appRoutes.myAccounts}
          component={AddMoney}
        />
        <PrivateRoute
          exact
          checkPrivileges
          path={appRoutes.accountStatement}
          subMenuOf={appRoutes.myAccounts}
          component={AccountStatement}
        />
        <PrivateRoute
          exact
          checkPrivileges
          path={appRoutes.transactionDescription}
          subMenuOf={appRoutes.transactionList}
          component={TransactionDescription}
        />
        <PrivateRoute
          exact
          checkPrivileges
          path={appRoutes.paymentRequestTransDescription}
          subMenuOf={appRoutes.transactionList}
          component={PaymentReqTransDescription}
        />
        <PrivateRoute exact path={appRoutes.fundingDescription} component={FundingDescription} />
        <PrivateRoute exact path={appRoutes.exchangeDescription} component={ExchangeDescription} />
        <PrivateRoute
          exact
          path={appRoutes.vraTransactionDescription}
          component={VraTransactionDescription}
        />
        <PrivateRoute exact path={appRoutes.cards} component={Cards} />
        <PrivateRoute exact hideSidePanel path={appRoutes.uipWithdraw} component={WithdrawMoney} />
        <PrivateRoute exact hideSidePanel path={appRoutes.uipExchange} component={ExchangeMoney} />
        {/* Private onboarding routes */}
        <DefaultRoute />
      </Switch>
    </Suspense>
  )
}
