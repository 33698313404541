// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import { StakeholderRoleOption } from 'Onboarding/interfaces/Onboarding.interfaces'
import { automationIds } from 'Onboarding/utils/constants'
import { AuthorizedSignatoryIcon } from 'V2/components/iconPaths/Custom/BasicIcons/AuthorizedSignatoryIcon'
import { DirectorIconV2 } from 'V2/components/iconPaths/Custom/BasicIcons/DirectorIcon'
import { OneOfManyPersonIconV2 } from 'V2/components/iconPaths/Custom/BasicIcons/OneOfManyPersonIcon'
import { PartnerIcon } from 'V2/components/iconPaths/Custom/BasicIcons/PartnerIcon'
import { PeopleIconV2 } from 'V2/components/iconPaths/Custom/BasicIcons/PeopleIcon'
import { SettlorIcon } from 'V2/components/iconPaths/Custom/BasicIcons/SettlorIcon'
import { ShareholderIconV2 } from 'V2/components/iconPaths/Custom/BasicIcons/ShareholderIcon'
import { SinglePersonIconV2 } from 'V2/components/iconPaths/Custom/BasicIcons/SinglePersonIcon'
import { TrusteeIcon } from 'V2/components/iconPaths/Custom/BasicIcons/TrusteeIcon'
import { DocumentTypeAndTitle } from './Interfaces'

export enum businessTypes {
  PRIVATE_COMPANY = 'PRIVATE_COMPANY',
  PUBLIC_COMPANY = 'PUBLIC_COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  ASSOCIATION = 'ASSOCIATION',
  GOVERNMENT_ENTITY = 'GOVERNMENT_ENTITY',
  TRUST = 'TRUST',
  LIMITED_LIABILITY_PARTNERSHIP = 'LIMITED_LIABILITY_PARTNERSHIP',
}

export const brdTypes: {
  [key: string]: {
    label: string
    value: string
  }[]
} = {
  FOREIGN_COMPANY_OFFICE: [
    {
      label: 'NAR1',
      value: 'NAR1',
    },
    {
      label: 'NNC1',
      value: 'NNC1',
    },
    {
      label: 'NN3',
      value: 'NN3',
    },
  ],
  GENERAL_PARTNERSHIP: [
    {
      label: 'BUSINESS_REGISTRATION_DOC',
      value: 'BUSINESS_REGISTRATION_DOC',
    },
    {
      label: 'PARTNERSHIP_DEED',
      value: 'PARTNERSHIP_DEED',
    },
  ],
  LIMITED_PARTNERSHIP: [
    {
      label: 'BUSINESS_REGISTRATION_DOC',
      value: 'BUSINESS_REGISTRATION_DOC',
    },
    {
      label: 'PARTNERSHIP_DEED',
      value: 'PARTNERSHIP_DEED',
    },
  ],
  OTHER: [
    {
      label: 'NAR1',
      value: 'NAR1',
    },
    {
      label: 'NNC1',
      value: 'NNC1',
    },
  ],
  PRIVATE_COMPANY: [
    {
      label: 'NAR1',
      value: 'NAR1',
    },
    {
      label: 'NNC1',
      value: 'NNC1',
    },
  ],
  PUBLIC_COMPANY: [
    {
      label: 'NAR1',
      value: 'NAR1',
    },
    {
      label: 'NNC1',
      value: 'NNC1',
    },
  ],
  SOLE_TRADER: [
    {
      label: 'BUSINESS_REGISTRATION_DOC',
      value: 'BUSINESS_REGISTRATION_DOC',
    },
  ],
}

export const stakeholderRoleOptionList: StakeholderRoleOption[] = [
  {
    value: 'DIRECTOR',
    title: 'Director',
    details: 'Person in charge of making decisions about how the business is run',
    titleId: automationIds.stakeHolderDirector,
    detailsId: automationIds.stakeHolderDirectorDetails,
    icon: <DirectorIconV2 />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.ASSOCIATION,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'SIGNATORY',
    title: 'Authorised Signatory',
    details: 'Person who is authorized to sign documents on behalf of the company',
    titleId: automationIds.stakeHolderSignatory,
    detailsId: automationIds.stakeHolderSignatoryDetails,
    icon: <AuthorizedSignatoryIcon />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.ASSOCIATION,
      businessTypes.LIMITED_LIABILITY_PARTNERSHIP,
      businessTypes.TRUST,
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.GOVERNMENT_ENTITY,
      businessTypes.SOLE_TRADER,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'REPRESENTATIVE',
    title: 'Authorised Representative',
    details: 'Person who is authorized to act on behalf of the company',
    titleId: automationIds.stakeHolderRepresentative,
    detailsId: automationIds.stakeHolderRepresentativeDetails,
    icon: <AuthorizedSignatoryIcon />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.ASSOCIATION,
      businessTypes.LIMITED_LIABILITY_PARTNERSHIP,
      businessTypes.TRUST,
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.GOVERNMENT_ENTITY,
      businessTypes.SOLE_TRADER,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'SETTLOR',
    title: 'Settlor',
    details: 'Person who creates a trust, and who transfers assets to the trust',
    titleId: automationIds.stakeHolderSettlor,
    detailsId: automationIds.stakeHolderSettlorDetails,
    icon: <SettlorIcon />,
    endAdornment: false,
    eligibleEntities: [businessTypes.TRUST],
    eligibleEntitiesForSimplifiedFlow: ['REGULATED_TRUST', 'UNREGULATED_TRUST'],
  },
  {
    value: 'SHAREHOLDER',
    title: 'Significant shareholder',
    details: 'Person who owns or manages a company, entitled to 25% or more of the capital',
    titleId: automationIds.stakeHolderShareHolder,
    detailsId: automationIds.stakeHolderShareHolderDetails,
    icon: <ShareholderIconV2 />,
    endAdornment: false,
    eligibleEntities: [businessTypes.ASSOCIATION],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'UBO',
    title: 'Significant shareholder',
    details: 'Person who owns or manages a company, entitled to 25% or more of the capital',
    titleId: automationIds.stakeHolderUBO,
    detailsId: automationIds.stakeHolderUBODetails,
    icon: <ShareholderIconV2 />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.ASSOCIATION,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'PARTNER',
    title: 'Partner',
    details:
      'Person who is a member of a partnership, and who shares in the profits and losses of the partnership',
    titleId: automationIds.stakeHolderPartner,
    detailsId: automationIds.stakeHolderPartnerDetails,
    icon: <PartnerIcon />,
    endAdornment: false,
    eligibleEntities: [businessTypes.LIMITED_LIABILITY_PARTNERSHIP],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'TRUSTEE',
    title: 'Trustee',
    details: 'Person who holds property for the benefit of another person or persons',
    titleId: automationIds.stakeHolderTrustee,
    detailsId: automationIds.stakeHolderTrusteeDetails,
    icon: <TrusteeIcon />,
    endAdornment: false,
    eligibleEntities: [businessTypes.TRUST],
    eligibleEntitiesForSimplifiedFlow: [],
  },
]

export const stakeholderTypeOptions: StakeholderRoleOption[] = [
  {
    value: 'director',
    title: 'I am the only stakeholder',
    details: 'I am the only director or significant shareholder',
    titleId: automationIds.onlyStakeholderTitle,
    detailsId: automationIds.onlyStakeholderDetails,
    icon: <SinglePersonIconV2 />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.SOLE_TRADER,
      businessTypes.TRUST,
      businessTypes.ASSOCIATION,
      businessTypes.GOVERNMENT_ENTITY,
      businessTypes.LIMITED_LIABILITY_PARTNERSHIP,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'significantShareholder',
    title: 'I am one of the stakeholders',
    details: 'I am one of the directors or significant shareholders',
    titleId: automationIds.oneOfStakeholderTitle,
    detailsId: automationIds.oneOfStakeholderDetails,
    icon: <OneOfManyPersonIconV2 />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.TRUST,
      businessTypes.ASSOCIATION,
      businessTypes.GOVERNMENT_ENTITY,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
  {
    value: 'oneOfManyStakeholders',
    title: 'I am representing the stakeholders',
    details: 'I am not a director or significant shareholder',
    titleId: automationIds.representingStakeholderTitle,
    detailsId: automationIds.representingStakeholderDetails,
    icon: <PeopleIconV2 />,
    endAdornment: false,
    eligibleEntities: [
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.SOLE_TRADER,
      businessTypes.TRUST,
      businessTypes.ASSOCIATION,
      businessTypes.GOVERNMENT_ENTITY,
      businessTypes.LIMITED_LIABILITY_PARTNERSHIP,
    ],
    eligibleEntitiesForSimplifiedFlow: [],
  },
]

export const documentTypeAndTitle: DocumentTypeAndTitle[] = [
  {
    title: 'Partnership Deed',
    value: 'PARTNERSHIP_DEED',
    eligibleEntities: [businessTypes.LIMITED_LIABILITY_PARTNERSHIP],
  },
  {
    title: 'Association Deed',
    value: 'ASSOCIATION_DEED',
    eligibleEntities: [businessTypes.ASSOCIATION],
  },
  {
    title: 'Trust Deed',
    value: 'TRUST_DEED',
    eligibleEntities: [businessTypes.TRUST],
  },
  {
    title: 'Business Registration document',
    value: 'BUSINESS_REGISTRATION_DOC',
    eligibleEntities: [
      businessTypes.PRIVATE_COMPANY,
      businessTypes.PUBLIC_COMPANY,
      businessTypes.GOVERNMENT_ENTITY,
      businessTypes.SOLE_TRADER,
    ],
  },
]

export const eligibleEntitiesForMoreBusinessDetails: businessTypes[] = []

export const eligibleEntitiesForDocumentUploadInSimplifiedFlow: businessTypes[] = []

export const eligibleEntitiesForDocumentUpload: businessTypes[] = [
  businessTypes.PRIVATE_COMPANY,
  businessTypes.PUBLIC_COMPANY,
  businessTypes.SOLE_TRADER,
  businessTypes.TRUST,
  businessTypes.ASSOCIATION,
  businessTypes.GOVERNMENT_ENTITY,
  businessTypes.LIMITED_LIABILITY_PARTNERSHIP,
]

export const eligibleEntitiesForSimplifiedFlow: businessTypes[] = []

export const EUCountriesAndStates = {
  AT: {
    value: 'AT',
    label: 'Austria',
    statesOrCounty: [
      {
        value: 'AT',
        label: 'Austria',
      },
    ],
  },
  BE: {
    value: 'BE',
    label: 'Belgium',
    statesOrCounty: [
      {
        value: 'BE',
        label: 'Belgium',
      },
    ],
  },
  BG: { label: 'Bulgaria', value: 'BG' },
  HR: { label: 'Croatia', value: 'HR' },
  CY: {
    value: 'CY',
    label: 'Cyprus',
    statesOrCounty: [
      {
        value: 'CY',
        label: 'Cyprus',
      },
    ],
  },
  CZ: { label: 'Czech Republic', value: 'CZ' },
  DE: {
    value: 'DE',
    label: 'Germany',
    statesOrCounty: [
      {
        value: 'BE',
        label: 'Berlin',
      },
      {
        value: 'BR',
        label: 'Brandenburg',
      },
      {
        value: 'BRE',
        label: 'Bremen',
      },
      {
        value: 'BV',
        label: 'Bavaria',
      },
      {
        value: 'BW',
        label: 'Baden-Württemberg',
      },
      {
        value: 'DE',
        label: 'Germany',
      },
      {
        value: 'HA',
        label: 'Hamburg',
      },
      {
        value: 'HE',
        label: 'Hesse',
      },
      {
        value: 'LS',
        label: 'Lower Saxony',
      },
      {
        value: 'MV',
        label: 'Mecklenburg-Vorpommern',
      },
      {
        value: 'NRW',
        label: 'North Rhine-Westphalia',
      },
      {
        value: 'RP',
        label: 'Rhineland-Palatinate',
      },
      {
        value: 'SAX',
        label: 'Saxony',
      },
      {
        value: 'SCH',
        label: 'Schleswig-Holstein',
      },
      {
        value: 'SLD',
        label: 'Saarland',
      },
      {
        value: 'SXA',
        label: 'Saxony-Anhalt',
      },
      {
        value: 'TH',
        label: 'Thuringia',
      },
    ],
  },
  DK: {
    value: 'DK',
    label: 'Denmark',
    statesOrCounty: [
      {
        value: 'DK',
        label: 'Denmark',
      },
    ],
  },
  EE: {
    value: 'EE',
    label: 'Estonia',
    statesOrCounty: [
      {
        value: 'EE',
        label: 'Estonia',
      },
    ],
  },
  ES: {
    value: 'ES',
    label: 'Spain',
    statesOrCounty: [
      {
        value: 'ANL',
        label: 'Andalusia',
      },
      {
        value: 'ARO',
        label: 'Aragon',
      },
      {
        value: 'ASR',
        label: 'Asturias',
      },
      {
        value: 'BAC',
        label: 'Basque Country',
      },
      {
        value: 'BAI',
        label: 'Balearic Islands',
      },
      {
        value: 'CAA',
        label: 'Cantabria',
      },
      {
        value: 'CAI',
        label: 'Castilla-La Mancha',
      },
      {
        value: 'CAL',
        label: 'Catalonia',
      },
      {
        value: 'CEA',
        label: 'Ceuta',
      },
      {
        value: 'CIS',
        label: 'Canary Islands',
      },
      {
        value: 'COM',
        label: 'Community of Madrid',
      },
      {
        value: 'CSL',
        label: 'Castile and León',
      },
      {
        value: 'ES',
        label: 'Spain',
      },
      {
        value: 'EXE',
        label: 'Extremadura',
      },
      {
        value: 'GAC',
        label: 'Galicia',
      },
      {
        value: 'LAR',
        label: 'La Rioja',
      },
      {
        value: 'MEL',
        label: 'Melilla',
      },
      {
        value: 'NAR',
        label: 'Navarre',
      },
      {
        value: 'ROM',
        label: 'Region of Murcia',
      },
      {
        value: 'VAC',
        label: 'Valencian Community',
      },
    ],
  },
  FI: {
    value: 'FI',
    label: 'Finland',
    statesOrCounty: [
      {
        value: 'FI',
        label: 'Finland',
      },
    ],
  },
  FR: {
    value: 'FR',
    label: 'France',
    statesOrCounty: [
      {
        value: 'FR',
        label: 'France',
      },
    ],
  },
  GR: {
    value: 'GR',
    label: 'Greece',
    statesOrCounty: [
      {
        value: 'AT',
        label: 'Attica',
      },
      {
        value: 'CG',
        label: 'Central Greece',
      },
      {
        value: 'CM',
        label: 'Central Macedonia',
      },
      {
        value: 'CR',
        label: 'Crete',
      },
      {
        value: 'EMT',
        label: 'East Macedonia and Thrace',
      },
      {
        value: 'EP',
        label: 'Epirus',
      },
      {
        value: 'GR',
        label: 'Greece',
      },
      {
        value: 'II',
        label: 'Ionian Islands',
      },
      {
        value: 'NA',
        label: 'North Aegean',
      },
      {
        value: 'PLP',
        label: 'Peloponnese',
      },
      {
        value: 'SA',
        label: 'South Aegean',
      },
      {
        value: 'THY',
        label: 'Thessaly',
      },
      {
        value: 'WG',
        label: 'West Greece',
      },
      {
        value: 'WM',
        label: 'West Macedonia',
      },
    ],
  },
  HU: {
    value: 'HU',
    label: 'Hungary',
    statesOrCounty: [
      {
        value: 'BA',
        label: 'Baranya',
      },
      {
        value: 'BAZ',
        label: 'Borsod-Abaúj-Zemplén',
      },
      {
        value: 'BK',
        label: 'Bács-Kiskun',
      },
      {
        value: 'BKS',
        label: 'Békés',
      },
      {
        value: 'BU',
        label: 'Budapest',
      },
      {
        value: 'CSG',
        label: 'Csongrád',
      },
      {
        value: 'FJ',
        label: 'Fejér',
      },
      {
        value: 'GMS',
        label: 'Győr-Moson-Sopron',
      },
      {
        value: 'HB',
        label: 'Hajdú-Bihar',
      },
      {
        value: 'HV',
        label: 'Heves',
      },
      {
        value: 'JNS',
        label: 'Jász-Nagykun-Szolnok',
      },
      {
        value: 'KE',
        label: 'Komárom-Esztergom',
      },
      {
        value: 'PT',
        label: 'Pest',
      },
      {
        value: 'SO',
        label: 'Somogy',
      },
      {
        value: 'SZB',
        label: 'Szabolcs-Szatmár-Bereg',
      },
      {
        value: 'TL',
        label: 'Tolna',
      },
      {
        value: 'VAS',
        label: 'Vas',
      },
      {
        value: 'VZP',
        label: 'Veszprém',
      },
      {
        value: 'ZL',
        label: 'Zala',
      },
    ],
  },
  IE: {
    value: 'IE',
    label: 'Ireland',
    statesOrCounty: [
      {
        value: 'AM',
        label: 'Armagh',
      },
      {
        value: 'AN',
        label: 'Antrim',
      },
      {
        value: 'CA',
        label: 'Carlow',
      },
      {
        value: 'CK',
        label: 'Cork',
      },
      {
        value: 'CL',
        label: 'Clare',
      },
      {
        value: 'CV',
        label: 'Cavan',
      },
      {
        value: 'DLR',
        label: 'Dún Laoghaire–Rathdown',
      },
      {
        value: 'DNG',
        label: 'Donegal',
      },
      {
        value: 'DWN',
        label: 'Down',
      },
      {
        value: 'FE',
        label: 'Fermanagh',
      },
      {
        value: 'FG',
        label: 'Fingal',
      },
      {
        value: 'GL',
        label: 'Galway',
      },
      {
        value: 'IE',
        label: 'Ireland',
      },
      {
        value: 'KLD',
        label: 'Kildare',
      },
      {
        value: 'KLK',
        label: 'Kilkenny',
      },
      {
        value: 'KRY',
        label: 'Kerry',
      },
      {
        value: 'LDN',
        label: 'Londonderry',
      },
      {
        value: 'LFD',
        label: 'Longford',
      },
      {
        value: 'LMK',
        label: 'Limerick',
      },
      {
        value: 'LS',
        label: 'Laois',
      },
      {
        value: 'LTR',
        label: 'Leitrim',
      },
      {
        value: 'LUT',
        label: 'Louth',
      },
      {
        value: 'MGH',
        label: 'Monaghan',
      },
      {
        value: 'MTH',
        label: 'Meath',
      },
      {
        value: 'MY',
        label: 'Mayo',
      },
      {
        value: 'OFL',
        label: 'Offaly',
      },
      {
        value: 'RMN',
        label: 'Roscommon',
      },
      {
        value: 'SD',
        label: 'South Dublin',
      },
      {
        value: 'SLG',
        label: 'Sligo',
      },
      {
        value: 'TP',
        label: 'Tipperary',
      },
      {
        value: 'TY',
        label: 'Tyrone',
      },
      {
        value: 'WFD',
        label: 'Waterford',
      },
      {
        value: 'WKL',
        label: 'Wicklow',
      },
      {
        value: 'WM',
        label: 'Westmeath',
      },
      {
        value: 'WXF',
        label: 'Wexford',
      },
    ],
  },
  IS: {
    value: 'IS',
    label: 'Iceland',
    statesOrCounty: [
      {
        value: 'NEC',
        label: 'Northeast Constituency',
      },
      {
        value: 'NWC',
        label: 'Northwest Constituency',
      },
      {
        value: 'RCN',
        label: 'Reykjavík Constituency North',
      },
      {
        value: 'RCS',
        label: 'Reykjavík Constituency South',
      },
      {
        value: 'SCH',
        label: 'South Constituency',
      },
      {
        value: 'SWC',
        label: 'Southwest Constituency',
      },
    ],
  },
  IT: {
    value: 'IT',
    label: 'Italy',
    statesOrCounty: [
      {
        value: 'ABZ',
        label: 'Abruzzo',
      },
      {
        value: 'AOA',
        label: 'Aosta Valley',
      },
      {
        value: 'API',
        label: 'Apulia',
      },
      {
        value: 'BAL',
        label: 'Basilicata',
      },
      {
        value: 'CAA',
        label: 'Campania',
      },
      {
        value: 'CAB',
        label: 'Calabria',
      },
      {
        value: 'EMI',
        label: 'Emilia-Romagna',
      },
      {
        value: 'FRL',
        label: 'Friuli-Venezia Giulia',
      },
      {
        value: 'IT',
        label: 'Italy',
      },
      {
        value: 'LAO',
        label: 'Lazio',
      },
      {
        value: 'LIR',
        label: 'Liguria',
      },
      {
        value: 'LOA',
        label: 'Lombardy',
      },
      {
        value: 'MAH',
        label: 'Marche',
      },
      {
        value: 'MOS',
        label: 'Molise',
      },
      {
        value: 'PIM',
        label: 'Piedmont',
      },
      {
        value: 'SAI',
        label: 'Sardinia',
      },
      {
        value: 'SIL',
        label: 'Sicily',
      },
      {
        value: 'TRT',
        label: 'Trentino-Alto Adige/Südtirol',
      },
      {
        value: 'TUA',
        label: 'Tuscany',
      },
      {
        value: 'UMI',
        label: 'Umbria',
      },
      {
        value: 'VET',
        label: 'Veneto',
      },
    ],
  },
  LT: {
    value: 'LT',
    label: 'Lithuania',
    statesOrCounty: [
      {
        value: 'LT',
        label: 'Lithuania',
      },
    ],
  },
  LU: {
    value: 'LU',
    label: 'Luxembourg',
    statesOrCounty: [
      {
        value: 'LU',
        label: 'Luxembourg',
      },
    ],
  },
  LV: {
    value: 'LV',
    label: 'Latvia',
    statesOrCounty: [
      {
        value: 'LV',
        label: 'Latvia',
      },
    ],
  },
  MT: {
    value: 'MT',
    label: 'Malta',
    statesOrCounty: [
      {
        value: 'CER',
        label: 'Central Region',
      },
      {
        value: 'GOR',
        label: 'Gozo Region',
      },
      {
        value: 'MT',
        label: 'Malta',
      },
      {
        value: 'NOR',
        label: 'Northern Region',
      },
      {
        value: 'SOH',
        label: 'South Eastern Region',
      },
      {
        value: 'SOR',
        label: 'Southern Region',
      },
    ],
  },
  NL: {
    value: 'NL',
    label: 'Netherlands',
    statesOrCounty: [
      {
        value: 'BOI',
        label: 'Bonaire',
      },
      {
        value: 'DRT',
        label: 'Drenthe',
      },
      {
        value: 'FLO',
        label: 'Flevoland',
      },
      {
        value: 'FRS',
        label: 'Friesland',
      },
      {
        value: 'GEE',
        label: 'Gelderland',
      },
      {
        value: 'GRI',
        label: 'Groningen',
      },
      {
        value: 'LIU',
        label: 'Limburg',
      },
      {
        value: 'NL',
        label: 'Netherlands',
      },
      {
        value: 'NOB',
        label: 'North Brabant',
      },
      {
        value: 'NOH',
        label: 'North Holland',
      },
      {
        value: 'OVI',
        label: 'Overijssel',
      },
      {
        value: 'SBA',
        label: 'Saba',
      },
      {
        value: 'SIE',
        label: 'Sint Eustatius',
      },
      {
        value: 'SOH',
        label: 'South Holland',
      },
      {
        value: 'UTC',
        label: 'Utrecht',
      },
      {
        value: 'ZEA',
        label: 'Zeeland',
      },
    ],
  },
  NO: {
    value: 'NO',
    label: 'Norway',
    statesOrCounty: [
      {
        value: 'AKS',
        label: 'Akershus',
      },
      {
        value: 'AUA',
        label: 'Aust-Agder',
      },
      {
        value: 'BUE',
        label: 'Buskerud',
      },
      {
        value: 'FIM',
        label: 'Finnmark',
      },
      {
        value: 'HEA',
        label: 'Hedmark',
      },
      {
        value: 'HOA',
        label: 'Hordaland',
      },
      {
        value: 'MGR',
        label: 'Møre og Romsdal',
      },
      {
        value: 'NOL',
        label: 'Nordland',
      },
      {
        value: 'OPA',
        label: 'Oppland',
      },
      {
        value: 'OSL',
        label: 'Oslo',
      },
      {
        value: 'OSO',
        label: 'Østfold',
      },
      {
        value: 'ROL',
        label: 'Rogaland',
      },
      {
        value: 'SGF',
        label: 'Sogn og Fjordane',
      },
      {
        value: 'TEM',
        label: 'Telemark',
      },
      {
        value: 'TRD',
        label: 'Trøndelag',
      },
      {
        value: 'TRS',
        label: 'Troms',
      },
      {
        value: 'VEA',
        label: 'Vest-Agder',
      },
      {
        value: 'VEF',
        label: 'Vestfold',
      },
    ],
  },
  PL: {
    value: 'PL',
    label: 'Poland',
    statesOrCounty: [
      {
        value: 'GRP',
        label: 'Greater Poland',
      },
      {
        value: 'KUP',
        label: 'Kuyavian-Pomeranian',
      },
      {
        value: 'LEP',
        label: 'Lesser Poland',
      },
      {
        value: 'LOD',
        label: 'Łódź',
      },
      {
        value: 'LOS',
        label: 'Lower Silesian',
      },
      {
        value: 'LUI',
        label: 'Lublin',
      },
      {
        value: 'LUS',
        label: 'Lubusz',
      },
      {
        value: 'MAV',
        label: 'Masovian',
      },
      {
        value: 'OPE',
        label: 'Opole',
      },
      {
        value: 'POA',
        label: 'Podlaskie',
      },
      {
        value: 'POR',
        label: 'Pomeranian',
      },
      {
        value: 'SIS',
        label: 'Silesian',
      },
      {
        value: 'SUA',
        label: 'Subcarpathian',
      },
      {
        value: 'ŚWT',
        label: 'Świętokrzyskie',
      },
      {
        value: 'WAM',
        label: 'Warmian-Masurian',
      },
      {
        value: 'WEP',
        label: 'West Pomeranian',
      },
    ],
  },
  PT: {
    value: 'PT',
    label: 'Portugal',
    statesOrCounty: [
      {
        value: 'AVR',
        label: 'Aveiro',
      },
      {
        value: 'AZE',
        label: 'Azores Autonomous Region',
      },
      {
        value: 'BJ',
        label: 'Beja',
      },
      {
        value: 'BRA',
        label: 'Braga',
      },
      {
        value: 'BRN',
        label: 'Bragança',
      },
      {
        value: 'CAB',
        label: 'Castelo Branco',
      },
      {
        value: 'COB',
        label: 'Coimbra',
      },
      {
        value: 'ÉVA',
        label: 'Évora',
      },
      {
        value: 'FR',
        label: 'Faro',
      },
      {
        value: 'GUD',
        label: 'Guarda',
      },
      {
        value: 'LEI',
        label: 'Leiria',
      },
      {
        value: 'LIO',
        label: 'Lisbon',
      },
      {
        value: 'MAI',
        label: 'Madeira Autonomous Region',
      },
      {
        value: 'POA',
        label: 'Portalegre',
      },
      {
        value: 'POO',
        label: 'Porto',
      },
      {
        value: 'PT',
        label: 'Portugal',
      },
      {
        value: 'SAA',
        label: 'Santarém',
      },
      {
        value: 'SEB',
        label: 'Setúbal',
      },
      {
        value: 'VDC',
        label: 'Viana do Castelo',
      },
      {
        value: 'VIR',
        label: 'Vila Real',
      },
      {
        value: 'VIU',
        label: 'Viseu',
      },
    ],
  },
  RO: {
    value: 'RO',
    label: 'Romania',
    statesOrCounty: [
      {
        value: 'TUE',
        label: 'Tulcea',
      },
      {
        value: 'ALB',
        label: 'Alba',
      },
      {
        value: 'ARD',
        label: 'Arad',
      },
      {
        value: 'ARȘ',
        label: 'Argeș',
      },
      {
        value: 'BAU',
        label: 'Bacău',
      },
      {
        value: 'BIN',
        label: 'Bistrița-Năsăud',
      },
      {
        value: 'BIR',
        label: 'Bihor',
      },
      {
        value: 'BOȘ',
        label: 'Botoșani',
      },
      {
        value: 'BRL',
        label: 'Brăila',
      },
      {
        value: 'BRO',
        label: 'Brașov',
      },
      {
        value: 'BUA',
        label: 'Bucharest',
      },
      {
        value: 'BUU',
        label: 'Buzău',
      },
      {
        value: 'CĂR',
        label: 'Călărași',
      },
      {
        value: 'CAS',
        label: 'Caraș-Severin',
      },
      {
        value: 'CLU',
        label: 'Cluj',
      },
      {
        value: 'COS',
        label: 'Covasna',
      },
      {
        value: 'COT',
        label: 'Constanța',
      },
      {
        value: 'DÂO',
        label: 'Dâmbovița',
      },
      {
        value: 'DLJ',
        label: 'Dolj',
      },
      {
        value: 'GAȚ',
        label: 'Galați',
      },
      {
        value: 'GIG',
        label: 'Giurgiu',
      },
      {
        value: 'GRJ',
        label: 'Gorj',
      },
      {
        value: 'HAH',
        label: 'Harghita',
      },
      {
        value: 'HUD',
        label: 'Hunedoara',
      },
      {
        value: 'IAM',
        label: 'Ialomița',
      },
      {
        value: 'ILV',
        label: 'Ilfov',
      },
      {
        value: 'LSI',
        label: 'Iași',
      },
      {
        value: 'MAM',
        label: 'Maramureș',
      },
      {
        value: 'MED',
        label: 'Mehedinți',
      },
      {
        value: 'MUȘ',
        label: 'Mureș',
      },
      {
        value: 'NEȚ',
        label: 'Neamț',
      },
      {
        value: 'OLT',
        label: 'Olt',
      },
      {
        value: 'PRO',
        label: 'Prahova',
      },
      {
        value: 'SĂJ',
        label: 'Sălaj',
      },
      {
        value: 'SAM',
        label: 'Satu Mare',
      },
      {
        value: 'SIU',
        label: 'Sibiu',
      },
      {
        value: 'SUA',
        label: 'Suceava',
      },
      {
        value: 'TEO',
        label: 'Teleorman',
      },
      {
        value: 'TIȘ',
        label: 'Timiș',
      },
      {
        value: 'VÂE',
        label: 'Vâlcea',
      },
      {
        value: 'VAU',
        label: 'Vaslui',
      },
      {
        value: 'VRC',
        label: 'Vrancea',
      },
    ],
  },
  SE: {
    value: 'SE',
    label: 'Sweden',
    statesOrCounty: [
      {
        value: 'BLI',
        label: 'Blekinge',
      },
      {
        value: 'DAR',
        label: 'Dalarna',
      },
      {
        value: 'GÄE',
        label: 'Gävleborg',
      },
      {
        value: 'GOA',
        label: 'Gotland',
      },
      {
        value: 'HAA',
        label: 'Halland',
      },
      {
        value: 'JÄL',
        label: 'Jämtland',
      },
      {
        value: 'JÖÖ',
        label: 'Jönköping',
      },
      {
        value: 'KAA',
        label: 'Kalmar',
      },
      {
        value: 'KRO',
        label: 'Kronoberg',
      },
      {
        value: 'NOB',
        label: 'Norrbotten',
      },
      {
        value: 'ÖRR',
        label: 'Örebro',
      },
      {
        value: 'ÖSR',
        label: 'Östergötland',
      },
      {
        value: 'SKE',
        label: 'Skåne',
      },
      {
        value: 'SÖR',
        label: 'Södermanland',
      },
      {
        value: 'STK',
        label: 'Stockholm',
      },
      {
        value: 'UPA',
        label: 'Uppsala',
      },
      {
        value: 'VÄE',
        label: 'Västerbotten',
      },
      {
        value: 'VÄG',
        label: 'Västra Götaland',
      },
      {
        value: 'VÄL',
        label: 'Värmland',
      },
      {
        value: 'VÄM',
        label: 'Västmanland',
      },
      {
        value: 'VLD',
        label: 'Västernorrland',
      },
    ],
  },
  SK: {
    value: 'SK',
    label: 'Banská Bystrica Region',
    statesOrCounty: [
      {
        value: 'BAK',
        label: 'Banská Bystrica Region',
      },
      {
        value: 'BRR',
        label: 'Bratislava Region',
      },
      {
        value: 'KOR',
        label: 'Košice Region',
      },
      {
        value: 'NIR',
        label: 'Nitra Region',
      },
      {
        value: 'PRR',
        label: 'Prešov Region',
      },
      {
        value: 'SK',
        label: 'Slovakia',
      },
      {
        value: 'TCR',
        label: 'Trenčín Region',
      },
      {
        value: 'TRR',
        label: 'Trnava Region',
      },
      {
        value: 'ŽIR',
        label: 'Žilina Region',
      },
    ],
  },
  SI: { label: 'Slovenia', value: 'SI' },
}

export const industrySector = [
  {
    label: 'Commodities',
    value: 'commodities',
    sub_category: [
      {
        label: 'Crops',
        value: 'IS214',
      },
      {
        label: 'Energy',
        value: 'IS216',
      },
      {
        label: 'Livestock and meat',
        value: 'IS215',
      },
      {
        label: 'Metals',
        value: 'IS217',
      },
      {
        label: 'Timber',
        value: 'IS218',
      },
    ],
  },
  {
    label: 'Consultancy',
    value: 'consultancy',
    sub_category: [
      {
        label: 'Financial advisory',
        value: 'IS209',
      },
      {
        label: 'Human resources',
        value: 'IS212',
      },
      {
        label: 'IT',
        value: 'IS211',
      },
      {
        label: 'Marketing',
        value: 'IS210',
      },
      {
        label: 'Strategy or business management',
        value: 'IS213',
      },
    ],
  },
  {
    label: 'Education',
    value: 'education',
    sub_category: [
      {
        label: 'Online',
        value: 'IS267',
      },
      {
        label: 'Eductional institutions',
        value: 'IS269',
      },
      {
        label: 'Tuition',
        value: 'IS268',
      },
    ],
  },
  {
    label: 'Entertainment',
    value: 'entertainment',
    sub_category: [
      {
        label: 'Adult',
        value: 'IS208',
      },
      {
        label: 'Tourist attractions',
        value: 'IS200',
      },
      {
        label: 'Broadcaster, radio or tv',
        value: 'IS197',
      },
      {
        label: 'Events management',
        value: 'IS203',
      },
      {
        label: 'Film',
        value: 'IS196',
      },
      {
        label: 'Gambling',
        value: 'IS207',
      },
      {
        label: 'Games',
        value: 'IS199',
      },
      {
        label: 'Cultural institutions',
        value: 'IS205',
      },
      {
        label: 'Music',
        value: 'IS202',
      },
      {
        label: 'Recreational parks',
        value: 'IS204',
      },
      {
        label: 'Performance art',
        value: 'IS201',
      },
      {
        label: 'Photography',
        value: 'IS198',
      },
      {
        label: 'Sport',
        value: 'IS206',
      },
    ],
  },
  {
    label: 'Financial services',
    value: 'financial_services',
    sub_category: [
      {
        label: 'Financial institutions',
        value: 'IS262',
      },
      {
        label: 'Crowdfunding',
        value: 'IS266',
      },
      {
        label: 'Cryptocurrency',
        value: 'IS258',
      },
      {
        label: 'Financial advisors',
        value: 'IS261',
      },
      {
        label: 'Insurance',
        value: 'IS263',
      },
      {
        label: 'Cross-border payments',
        value: 'IS260',
      },
      {
        label: 'Payment service providers',
        value: 'IS259',
      },
      {
        label: 'Securities and investments',
        value: 'IS264',
      },
      {
        label: 'Superannuation fund providers',
        value: 'IS265',
      },
    ],
  },
  {
    label: 'Manufacturing',
    value: 'manufacturing',
    sub_category: [
      {
        label: 'Office supplies',
        value: 'IS155',
      },
      {
        label: 'Art',
        value: 'IS156',
      },
      {
        label: 'Beauty',
        value: 'IS142',
      },
      {
        label: 'Non-alcoholic beverages',
        value: 'IS178',
      },
      {
        label: 'Alcoholic beverages',
        value: 'IS177',
      },
      {
        label: 'Print',
        value: 'IS146',
      },
      {
        label: 'Chemicals (non-pharmeceuticals)',
        value: 'IS183',
      },
      {
        label: 'Childcare',
        value: 'IS144',
      },
      {
        label: 'Garments',
        value: 'IS135',
      },
      {
        label: 'Designer clothing',
        value: 'IS134',
      },
      {
        label: 'Pharmaceuticals',
        value: 'IS150',
      },
      {
        label: 'Electronics',
        value: 'IS170',
      },
      {
        label: 'Firearms and other weapons',
        value: 'IS157',
      },
      {
        label: 'Food',
        value: 'IS161',
      },
      {
        label: 'Food and beverages',
        value: 'IS160',
      },
      {
        label: 'Household',
        value: 'IS141',
      },
      {
        label: 'Luxury jewellery or watches',
        value: 'IS136',
      },
      {
        label: 'Costume jewellery or watches',
        value: 'IS137',
      },
      {
        label: 'Heavy machinery',
        value: 'IS140',
      },
      {
        label: 'No predominant items',
        value: 'IS195',
      },
      {
        label: 'Medical devices or supplies',
        value: 'IS154',
      },
      {
        label: 'Musical instruments',
        value: 'IS149',
      },
      {
        label: 'Pet supplies',
        value: 'IS187',
      },
      {
        label: 'Sports equipment',
        value: 'IS143',
      },
      {
        label: 'Tobacco',
        value: 'IS181',
      },
      {
        label: 'Items not listed',
        value: 'IS194',
      },
      {
        label: 'Automotive',
        value: 'IS139',
      },
      {
        label: 'Vitamins and supplements',
        value: 'IS152',
      },
    ],
  },
  {
    label: 'Retail, e-commerce or wholesale',
    value: 'retail_e_commerce_or_wholesale',
    sub_category: [
      {
        label: 'Office supplies',
        value: 'IS155',
      },
      {
        label: 'Art',
        value: 'IS156',
      },
      {
        label: 'Beauty products',
        value: 'IS142',
      },
      {
        label: 'Non-alcoholic beverages',
        value: 'IS158',
      },
      {
        label: 'Alcoholic beverages',
        value: 'IS159',
      },
      {
        label: 'Print',
        value: 'IS146',
      },
      {
        label: 'Chemicals (non-pharmeceuticals)',
        value: 'IS151',
      },
      {
        label: 'Childcare',
        value: 'IS144',
      },
      {
        label: 'Garments',
        value: 'IS135',
      },
      {
        label: 'Computer software',
        value: 'IS163',
      },
      {
        label: 'Construction materials and equipment',
        value: 'IS166',
      },
      {
        label: 'Designer clothing',
        value: 'IS134',
      },
      {
        label: 'Pharmaceuticals',
        value: 'IS150',
      },
      {
        label: 'Electronics',
        value: 'IS138',
      },
      {
        label: 'Firearms and other weapons',
        value: 'IS157',
      },
      {
        label: 'Food',
        value: 'IS161',
      },
      {
        label: 'Food and beverages',
        value: 'IS160',
      },
      {
        label: 'Groceries',
        value: 'IS162',
      },
      {
        label: 'Household items',
        value: 'IS141',
      },
      {
        label: 'Luxury jewellery or watches',
        value: 'IS136',
      },
      {
        label: 'Costume jewellery or watches',
        value: 'IS137',
      },
      {
        label: 'Heavy machinery',
        value: 'IS140',
      },
      {
        label: 'Medical devices or supplies',
        value: 'IS154',
      },
      {
        label: 'Musical and audio equipment',
        value: 'IS149',
      },
      {
        label: 'Pet supplies',
        value: 'IS148',
      },
      {
        label: 'Pharmacy',
        value: 'IS153',
      },
      {
        label: 'No predominant items',
        value: 'IS165',
      },
      {
        label: 'Pets',
        value: 'IS147',
      },
      {
        label: 'Reseller',
        value: 'IS167',
      },
      {
        label: 'Sports equipment',
        value: 'IS143',
      },
      {
        label: 'Tobacco',
        value: 'IS145',
      },
      {
        label: 'Items not listed',
        value: 'IS164',
      },
      {
        label: 'Automotive',
        value: 'IS139',
      },
      {
        label: 'Vitamins and supplements',
        value: 'IS152',
      },
    ],
  },
  {
    label: 'Services',
    value: 'services',
    sub_category: [
      {
        label: 'Financial accounting',
        value: 'IS219',
      },
      {
        label: 'Agro-industry',
        value: 'IS222',
      },
      {
        label: 'Renewable energy',
        value: 'IS248',
      },
      {
        label: 'Architecture',
        value: 'IS238',
      },
      {
        label: 'Auction houses',
        value: 'IS233',
      },
      {
        label: 'Beauty',
        value: 'IS243',
      },
      {
        label: 'Casino hotels and lodging',
        value: 'IS232',
      },
      {
        label: 'Charity',
        value: 'IS256',
      },
      {
        label: 'Childcare',
        value: 'IS255',
      },
      {
        label: 'Religious and clergy',
        value: 'IS253',
      },
      {
        label: 'Construction',
        value: 'IS236',
      },
      {
        label: 'Dating escort',
        value: 'IS246',
      },
      {
        label: 'Defense',
        value: 'IS221',
      },
      {
        label: 'Engineering',
        value: 'IS237',
      },
      {
        label: 'Pest control',
        value: 'IS241',
      },
      {
        label: 'Florists',
        value: 'IS247',
      },
      {
        label: 'Funeral or cremation',
        value: 'IS257',
      },
      {
        label: 'Public sector',
        value: 'IS251',
      },
      {
        label: 'Healthcare',
        value: 'IS242',
      },
      {
        label: 'Hotels and lodging',
        value: 'IS231',
      },
      {
        label: 'Interior design',
        value: 'IS254',
      },
      {
        label: 'IT',
        value: 'IS244',
      },
      {
        label: 'Landscaping',
        value: 'IS239',
      },
      {
        label: 'Legal',
        value: 'IS250',
      },
      {
        label: 'Logistics or shipping',
        value: 'IS227',
      },
      {
        label: 'Media, marketing or advertising',
        value: 'IS224',
      },
      {
        label: 'Mining',
        value: 'IS234',
      },
      {
        label: 'Non-profit',
        value: 'IS252',
      },
      {
        label: 'Printing or publishing',
        value: 'IS225',
      },
      {
        label: 'Real estate sale, purchase or management',
        value: 'IS230',
      },
      {
        label: 'Recruitment agency',
        value: 'IS245',
      },
      {
        label: 'Rental of assets',
        value: 'IS229',
      },
      {
        label: 'Repair, mainenance or cleaning',
        value: 'IS235',
      },
      {
        label: 'Bars and restaurants',
        value: 'IS223',
      },
      {
        label: 'Security',
        value: 'IS220',
      },
      {
        label: 'Telecommunications',
        value: 'IS226',
      },
      {
        label: 'Travel and tourism',
        value: 'IS228',
      },
      {
        label: 'Utilities',
        value: 'IS249',
      },
      {
        label: 'Veterinary',
        value: 'IS240',
      },
    ],
  },
]

export const countyList = [
  {
    label: 'Aberdeenshire',
    value: 'Aberdeenshire',
  },
  {
    label: 'Anglesey',
    value: 'Anglesey',
  },
  {
    label: 'Angus',
    value: 'Angus',
  },
  {
    label: 'County Antrim',
    value: 'County Antrim',
  },
  {
    label: 'Argyllshire',
    value: 'Argyllshire',
  },
  {
    label: 'County Armagh',
    value: 'County Armagh',
  },
  {
    label: 'Ayrshire',
    value: 'Ayrshire',
  },
  {
    label: 'Banffshire',
    value: 'Banffshire',
  },
  {
    label: 'Bedfordshire',
    value: 'Bedfordshire',
  },
  {
    label: 'Berkshire',
    value: 'Berkshire',
  },
  {
    label: 'Berwickshire',
    value: 'Berwickshire',
  },
  {
    label: 'Brecknockshire',
    value: 'Brecknockshire',
  },
  {
    label: 'Buckinghamshire',
    value: 'Buckinghamshire',
  },
  {
    label: 'Buteshire',
    value: 'Buteshire',
  },
  {
    label: 'Caernarfonshire',
    value: 'Caernarfonshire',
  },
  {
    label: 'Caithness',
    value: 'Caithness',
  },
  {
    label: 'Cambridgeshire',
    value: 'Cambridgeshire',
  },
  {
    label: 'Cardiganshire',
    value: 'Cardiganshire',
  },
  {
    label: 'Carmarthenshire',
    value: 'Carmarthenshire',
  },
  {
    label: 'Cheshire',
    value: 'Cheshire',
  },
  {
    label: 'Clackmannanshire',
    value: 'Clackmannanshire',
  },
  {
    label: 'Cornwall',
    value: 'Cornwall',
  },
  {
    label: 'Cromartyshire',
    value: 'Cromartyshire',
  },
  {
    label: 'Cumberland',
    value: 'Cumberland',
  },
  {
    label: 'Denbighshire',
    value: 'Denbighshire',
  },
  {
    label: 'Derbyshire',
    value: 'Derbyshire',
  },
  {
    label: 'Devon',
    value: 'Devon',
  },
  {
    label: 'Dorset',
    value: 'Dorset',
  },
  {
    label: 'County Down',
    value: 'County Down',
  },
  {
    label: 'Dumfriesshire',
    value: 'Dumfriesshire',
  },
  {
    label: 'Dunbartonshire',
    value: 'Dunbartonshire',
  },
  {
    label: 'County Durham',
    value: 'County Durham',
  },
  {
    label: 'East Lothian',
    value: 'East Lothian',
  },
  {
    label: 'Essex',
    value: 'Essex',
  },
  {
    label: 'Fermanagh',
    value: 'Fermanagh',
  },
  {
    label: 'Fife',
    value: 'Fife',
  },
  {
    label: 'Flintshire',
    value: 'Flintshire',
  },
  {
    label: 'Glamorgan',
    value: 'Glamorgan',
  },
  {
    label: 'Gloucestershire',
    value: 'Gloucestershire',
  },
  {
    label: 'Hampshire',
    value: 'Hampshire',
  },
  {
    label: 'Herefordshire',
    value: 'Herefordshire',
  },
  {
    label: 'Hertfordshire',
    value: 'Hertfordshire',
  },
  {
    label: 'Huntingdonshire',
    value: 'Huntingdonshire',
  },
  {
    label: 'Invernessshire',
    value: 'Invernessshire',
  },
  {
    label: 'Kent',
    value: 'Kent',
  },
  {
    label: 'Kincardineshire',
    value: 'Kincardineshire',
  },
  {
    label: 'Kinrossshire',
    value: 'Kinrossshire',
  },
  {
    label: 'Kirkcudbrightshire',
    value: 'Kirkcudbrightshire',
  },
  {
    label: 'Lanarkshire',
    value: 'Lanarkshire',
  },
  {
    label: 'Lancashire',
    value: 'Lancashire',
  },
  {
    label: 'Leicestershire',
    value: 'Leicestershire',
  },
  {
    label: 'Lincolnshire',
    value: 'Lincolnshire',
  },
  {
    label: 'County Londonderry',
    value: 'County Londonderry',
  },
  {
    label: 'Merionethshire',
    value: 'Merionethshire',
  },
  {
    label: 'Middlesex',
    value: 'Middlesex',
  },
  {
    label: 'Midlothian',
    value: 'Midlothian',
  },
  {
    label: 'Montgomeryshire',
    value: 'Montgomeryshire',
  },
  {
    label: 'Monmouthshire',
    value: 'Monmouthshire',
  },
  {
    label: 'Morayshire',
    value: 'Morayshire',
  },
  {
    label: 'Nairnshire',
    value: 'Nairnshire',
  },
  {
    label: 'Norfolk',
    value: 'Norfolk',
  },
  {
    label: 'Northamptonshire',
    value: 'Northamptonshire',
  },
  {
    label: 'Northumberland',
    value: 'Northumberland',
  },
  {
    label: 'Nottinghamshire',
    value: 'Nottinghamshire',
  },
  {
    label: 'Orkney',
    value: 'Orkney',
  },
  {
    label: 'Oxfordshire',
    value: 'Oxfordshire',
  },
  {
    label: 'Peeblesshire',
    value: 'Peeblesshire',
  },
  {
    label: 'Pembrokeshire',
    value: 'Pembrokeshire',
  },
  {
    label: 'Perthshire',
    value: 'Perthshire',
  },
  {
    label: 'Radnorshire',
    value: 'Radnorshire',
  },
  {
    label: 'Renfrewshire',
    value: 'Renfrewshire',
  },
  {
    label: 'Rossshire',
    value: 'Rossshire',
  },
  {
    label: 'Roxburghshire',
    value: 'Roxburghshire',
  },
  {
    label: 'Rutland',
    value: 'Rutland',
  },
  {
    label: 'Selkirkshire',
    value: 'Selkirkshire',
  },
  {
    label: 'Shetland',
    value: 'Shetland',
  },
  {
    label: 'Shropshire',
    value: 'Shropshire',
  },
  {
    label: 'Somerset',
    value: 'Somerset',
  },
  {
    label: 'Staffordshire',
    value: 'Staffordshire',
  },
  {
    label: 'Stirlingshire',
    value: 'Stirlingshire',
  },
  {
    label: 'Suffolk',
    value: 'Suffolk',
  },
  {
    label: 'Surrey',
    value: 'Surrey',
  },
  {
    label: 'Sussex',
    value: 'Sussex',
  },
  {
    label: 'Sutherland',
    value: 'Sutherland',
  },
  {
    label: 'Tyrone',
    value: 'Tyrone',
  },
  {
    label: 'Warwickshire',
    value: 'Warwickshire',
  },
  {
    label: 'West Lothian',
    value: 'West Lothian',
  },
  {
    label: 'Westmorland',
    value: 'Westmorland',
  },
  {
    label: 'Wigtownshire',
    value: 'Wigtownshire',
  },
  {
    label: 'Wiltshire',
    value: 'Wiltshire',
  },
  {
    label: 'Worcestershire',
    value: 'Worcestershire',
  },
  {
    label: 'Yorkshire',
    value: 'Yorkshire',
  },
]

export const simplifiedFlowName = ''
