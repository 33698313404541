import styled, { css } from 'styled-components/macro'

import { ButtonBase, Title as ButtonTitle } from 'V2/components/atoms/Button/Styles'
import { Svg } from 'V2/components/atoms/Icon/CustomIcon'
import { BaseInput, InputWrapper } from 'V2/components/atoms/Input/Styles'
import { RadioWrapper } from 'V2/components/atoms/Radio/Styles'
import { Typography } from 'V2/components/atoms/Typography/Typography'
import { ModalFooter, ModalHeader } from 'V2/components/molecules/Modal/Styles'
import { createBetween, great, less } from 'V2/helpers/mediaquery'
import { Footer as ContactsFooter } from 'V2/pages/Contacts/Styles'

export const FormWrapper = styled.div`
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  ${BaseInput} {
    height: 48px;
  }
  ${ButtonBase} {
    margin-top: 24px;
    height: 36px;
    ${ButtonTitle} {
      font-weight: 600;
    }
    @media ${great.greaterThanMobile} {
      margin-top: 32px;
      height: 48px;
    }
  }
`
export const BankDetailsFormWrapper = styled.div<{ confirmButtonRequired?: boolean }>`
  width: 100%;
  padding: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '12px' : '0')};
  padding-top: 0;

  margin-bottom: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '24px' : '0')};
  @media ${great.greaterThanTablet} {
    width: 550px;
    margin-left: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '96px' : '0')};
  }
  ${InputWrapper} {
    margin-top: 24px;
  }
`
export const RadioButtonTitle = styled(Typography)`
  margin-bottom: 16px;
`
export const RadioContent = styled.div`
  display: flex;
  flex-direction: row;
  @media ${less.lessThanMobile} {
    flex-direction: column;
  }
`
export const RadioWrapperBaseDiv = styled.div`
  margin-top: 24px;
  ${RadioWrapper} {
    margin-bottom: 8px;
    margin-right: 24px;
    @media ${less.lessThanMobile} {
      margin-bottom: 16px;
    }
  }
`
export const RadioWrapperDiv = styled.div`
  ${RadioWrapper} {
    margin-bottom: 24px;
    margin-right: 24px;
  }
`
export const Header = styled.div`
  margin-bottom: 16px;
  @media ${less.lessThanMobile} {
    display: none;
  }
`
export const SubHeader = styled.div`
  margin-bottom: 24px;
  @media ${less.lessThanMobile} {
    margin-top: 24px;
  }
`

export const ButtonWrapper = styled.div<{ confirmButtonRequired?: boolean }>`
  display: flex;
  max-width: 768px;
  margin-top: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '24px' : '0')};

  border-top: 0.5px solid
    ${({ theme, confirmButtonRequired }): string =>
      confirmButtonRequired ? theme.revamp.primary.grey.light : 'transparent'};
  padding: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '16px' : '0')};
  padding-left: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '60%' : '0')};

  display: flex;
  justify-content: ${({ confirmButtonRequired }): string =>
    confirmButtonRequired ? 'space-around' : 'flex-start'};
  align-items: center;
  height: 67px;
  @media ${great.greaterThanMobile} {
    min-height: 83px;
  }
  @media ${createBetween('Mobile', 'Tablet')} {
    margin-left: 0;
    padding-left: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '60%' : '0')};
  }
  @media ${less.lessThanMobile} {
    max-width: ${({ confirmButtonRequired }): string =>
      confirmButtonRequired ? '360px' : 'unset'};
    margin: 0;
    margin-bottom: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '24px' : '0')};
    padding-left: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '48%' : '0')};
  }
  ${ButtonBase} {
    margin-top: 0;
    height: 36px;
    margin: ${({ confirmButtonRequired }): string => (confirmButtonRequired ? '0 8px' : '0')};
    ${ButtonTitle} {
      font-weight: 600;
    }
    @media ${great.greaterThanMobile} {
      margin-top: 0;
      height: 36px;
      max-width: 99px;
    }
  }
`
export const LinkButtonWrapper = styled.div`
  margin-top: 24px;
`
export const SaveButtonWrapper = styled.div`
  margin-top: 16px;
  @media ${less.lessThanMobile} {
    margin-top: 8px;
  }
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${less.lessThanMobile} {
    gap: 16px;
  }
`

export const AccountNameWrapper = styled.div`
  margin-bottom: 24px;
  margin-top: 16px;
  & > p {
    color: ${({ theme }): string => theme.revamp.primary.grey.dark};
    margin-left: 16px;
  }
  @media ${less.lessThanMobile} {
    margin-bottom: 16px;
  }
`

export const Footer = styled(ContactsFooter)`
  margin-top: 16px;
`
export const DeleteBankAccountContainer = styled.div`
  ${ModalHeader} {
    padding: 24px;
    @media ${less.lessThanMobile} {
      padding: 16px;
    }
  }
  ${ModalFooter} {
    @media ${less.lessThanMobile} {
      justify-content: flex-end;
      button: last-child {
        margin-left: 16px;
      }
    }
  }
`
export const BodyContent = styled.div`
  padding: 24px;
  @media ${less.lessThanMobile} {
    padding: 16px;
  }
`
export const BankDetailsCardMainDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light}};
  border-radius: 4px;
  padding: 16px;
  @media ${great.greaterThanTablet} {
    width: 550px;
    margin-left: 0px;
  }
`
export const BankDetailsEllipsisWrapper = styled.div`
  position: relative;
  cursor: pointer;
`
export const FeeMessageWrapper = styled.div`
  color: ${({ theme }): string => theme.revamp.primary.grey.dark};
  @media ${great.greaterThanMobile} {
    margin-top: 8px;
  }
`
export const IconSection = styled.div`
  margin-top: 8px;
  margin-left: 16px;
  @media ${great.greaterThanMobile} {
    margin-left: 24px;
  }
`
export const MainDivIconWrapper = styled.div`
  display: flex;
  width: 450px;
  height: auto;
  align-items: center;
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-top: 0;
  @media ${great.greaterThanMobile} {
    margin-top: 10px;
    margin-left: 16px;
  }
`
export const EllipsisMenu = styled.div<{ openEllipsisMenu: boolean }>`
  display: ${({ openEllipsisMenu }): string => (openEllipsisMenu ? 'flex' : 'none')};
  flex-direction: column;
  > svg {
    margin-top: 4px;
  }
`
export const MenuWrapper = styled.div`
  position: absolute;
  margin-right: 1.5rem;
  right: 0;
  background: ${({ theme }): string => theme.revamp.staticColors.white};
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(44, 44, 44, 0.08);
`
export const flexCenterAligned = css`
  display: flex;
  align-items: center;
`
export const MenuItem = styled.div<{ isDisabled?: boolean }>`
  ${flexCenterAligned}
  padding: 16px 24px;
  cursor: ${({ isDisabled }): string => (isDisabled ? 'not-allowed' : 'pointer')};

  pointer-events: ${({ isDisabled }): string => (isDisabled ? 'none' : 'auto')};
  > p {
    white-space: nowrap;
    margin-left: 16px;
    color: ${({ isDisabled, theme }): string =>
      isDisabled ? theme.revamp.primary.grey.root : theme.revamp.primary.blue.root};
  }
`
export const ShareEllipsisWrapper = styled(MenuItem)``
export const AutoSweepBannkDetailsCardWrapper = styled.div`
  margin-top: 24px;
`
export const MaxAccountWrapper = styled.div`
  margin-top: 16px;
  @media ${great.greaterThanMobile} {
    margin-top: 24px;
  }
`

export const NoteWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  ${Svg} {
    margin-right: 8px;
  }
  @media ${less.lessThanMobile} {
    margin-top: 24px;
  }
`
