// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const MoreIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <path
      d="M4.5 12C4.5 12.4641 4.68437 12.9092 5.01256 13.2374C5.34075 13.5656 5.78587 13.75 6.25 13.75C6.71413 13.75 7.15925 13.5656 7.48744 13.2374C7.81563 12.9092 8 12.4641 8 12C8 11.5359 7.81563 11.0908 7.48744 10.7626C7.15925 10.4344 6.71413 10.25 6.25 10.25C5.78587 10.25 5.34075 10.4344 5.01256 10.7626C4.68437 11.0908 4.5 11.5359 4.5 12Z"
      fill={stroke}
    />
    <path
      d="M16 12C16 12.2298 16.0453 12.4574 16.1332 12.6697C16.2212 12.882 16.3501 13.0749 16.5126 13.2374C16.6751 13.3999 16.868 13.5288 17.0803 13.6168C17.2926 13.7047 17.5202 13.75 17.75 13.75C17.9798 13.75 18.2074 13.7047 18.4197 13.6168C18.632 13.5288 18.8249 13.3999 18.9874 13.2374C19.1499 13.0749 19.2788 12.882 19.3668 12.6697C19.4547 12.4574 19.5 12.2298 19.5 12C19.5 11.7702 19.4547 11.5426 19.3668 11.3303C19.2788 11.118 19.1499 10.9251 18.9874 10.7626C18.8249 10.6001 18.632 10.4712 18.4197 10.3832C18.2074 10.2953 17.9798 10.25 17.75 10.25C17.5202 10.25 17.2926 10.2953 17.0803 10.3832C16.868 10.4712 16.6751 10.6001 16.5126 10.7626C16.3501 10.9251 16.2212 11.118 16.1332 11.3303C16.0453 11.5426 16 11.7702 16 12Z"
      fill={stroke}
    />
    <path
      d="M10.25 12C10.25 12.4641 10.4344 12.9092 10.7626 13.2374C11.0908 13.5656 11.5359 13.75 12 13.75C12.4641 13.75 12.9092 13.5656 13.2374 13.2374C13.5656 12.9092 13.75 12.4641 13.75 12C13.75 11.5359 13.5656 11.0908 13.2374 10.7626C12.9092 10.4344 12.4641 10.25 12 10.25C11.5359 10.25 11.0908 10.4344 10.7626 10.7626C10.4344 11.0908 10.25 11.5359 10.25 12Z"
      fill={stroke}
    />
  </>
)
