import moment from 'moment'

import { ActiveClient, FeatureFlagConfiguration } from 'interfaces/redux/Dependents.interface'
import { VFACountries } from 'V2/constants/vfaConstant'
import { clientLegalEntityMapper } from './clientLegalEntityMapper'

export const getVfaDisclaimerDisabled = (
  vfaDisclaimerFlagDisabled?: FeatureFlagConfiguration,
  activeClient?: ActiveClient
): boolean => {
  const clientLegalEntity = clientLegalEntityMapper(activeClient?.client_legal_entity as string)
  if (!vfaDisclaimerFlagDisabled?.enabled) {
    const isVfaSupportedClient = VFACountries.find(
      (item) => item.clientLegalEntity === clientLegalEntity
    )
    let flagStartDate
    if (vfaDisclaimerFlagDisabled?.metaData) {
      flagStartDate = JSON.parse(vfaDisclaimerFlagDisabled?.metaData || '')?.flagStartDate
    } else {
      flagStartDate = '1713463074'
    }

    const createdAt = moment(activeClient?.created_at).unix()
    if (isVfaSupportedClient && createdAt <= flagStartDate) {
      return false
    }
    return true
  }
  return vfaDisclaimerFlagDisabled.enabled
}
