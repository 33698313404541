/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'

export const InfoIconFilled: React.FC = (): JSX.Element => {
  return (
    <>
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#004288"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C8.55228 8 9 8.44772 9 9V11.4C9 11.9523 8.55228 12.4 8 12.4C7.44772 12.4 7 11.9523 7 11.4V9C7 8.44772 7.44772 8 8 8Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5.59998C7 5.04769 7.44772 4.59998 8 4.59998H8.006C8.55828 4.59998 9.006 5.04769 9.006 5.59998C9.006 6.15226 8.55828 6.59998 8.006 6.59998H8C7.44772 6.59998 7 6.15226 7 5.59998Z"
        fill="white"
      />
    </>
  )
}

export const InfoIconFilledV2: React.FC = (): JSX.Element => (
  <>
    <path
      d="M12 4C10.4178 4 8.87103 4.46919 7.55544 5.34824C6.23985 6.22729 5.21447 7.47672 4.60897 8.93853C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6569C7.46197 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5822 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12.1667 7.33333C12.3645 7.33333 12.5578 7.39198 12.7222 7.50186C12.8867 7.61174 13.0149 7.76792 13.0906 7.95065C13.1662 8.13338 13.186 8.33444 13.1475 8.52842C13.1089 8.7224 13.0136 8.90059 12.8738 9.04044C12.7339 9.18029 12.5557 9.27553 12.3618 9.31412C12.1678 9.3527 11.9667 9.3329 11.784 9.25721C11.6013 9.18153 11.4451 9.05335 11.3352 8.8889C11.2253 8.72445 11.1667 8.53111 11.1667 8.33333C11.1667 8.06812 11.272 7.81376 11.4596 7.62623C11.6471 7.43869 11.9015 7.33333 12.1667 7.33333ZM13.6667 16.3333H11C10.8232 16.3333 10.6536 16.2631 10.5286 16.1381C10.4036 16.013 10.3333 15.8435 10.3333 15.6667C10.3333 15.4899 10.4036 15.3203 10.5286 15.1953C10.6536 15.0702 10.8232 15 11 15H11.5C11.5442 15 11.5866 14.9824 11.6179 14.9512C11.6491 14.9199 11.6667 14.8775 11.6667 14.8333V11.8333C11.6667 11.7891 11.6491 11.7467 11.6179 11.7155C11.5866 11.6842 11.5442 11.6667 11.5 11.6667H11C10.8232 11.6667 10.6536 11.5964 10.5286 11.4714C10.4036 11.3464 10.3333 11.1768 10.3333 11C10.3333 10.8232 10.4036 10.6536 10.5286 10.5286C10.6536 10.4036 10.8232 10.3333 11 10.3333H11.6667C12.0203 10.3333 12.3594 10.4738 12.6095 10.7239C12.8595 10.9739 13 11.313 13 11.6667V14.8333C13 14.8775 13.0176 14.9199 13.0488 14.9512C13.0801 14.9824 13.1225 15 13.1667 15H13.6667C13.8435 15 14.0131 15.0702 14.1381 15.1953C14.2631 15.3203 14.3333 15.4899 14.3333 15.6667C14.3333 15.8435 14.2631 16.013 14.1381 16.1381C14.0131 16.2631 13.8435 16.3333 13.6667 16.3333Z"
      fill="#326499"
    />
  </>
)
