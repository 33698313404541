import { init as initApm } from '@elastic/apm-rum'

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: process.env.REACT_APP_APM_ENDPOINT,
  // Set service version (required for sourcemap feature)
  serviceVersion: '',
  environment: process.env.REACT_APP_ENV,
  active: process.env.REACT_APP_ENV === 'production',
})

export default apm
