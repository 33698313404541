// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import styled from 'styled-components/macro'

import { great } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'
import {
  AtomsTypographyStyleProps,
  AtomTypographyBaseProps,
} from 'V2/interfaces/components/Atoms.interface'
import { CommonTypographyStyles } from './CommonTypographyStyles'

const LargeText = styled.h1<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.lgt)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.lgt)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.lgt)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.lgt)};
  }
  ${CommonTypographyStyles}
`
const H1 = styled.h1<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.xl)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.xl)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.xl)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.xl)};
  }
  ${CommonTypographyStyles}
`
const H2 = styled.h2<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.l)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.l)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.l)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.l)};
  }
  ${CommonTypographyStyles}
`

const H3 = styled.h3<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.m)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.m)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.m)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.m)};
  }
  ${CommonTypographyStyles}
`
const Body = styled.p<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.root)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.root)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.root)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.root)};
  }
  ${CommonTypographyStyles}
  ${({ weight }): string => `font-weight: ${weight === 'medium' ? '500' : '600'};`}
`

const Caption = styled.p<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.sm)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.sm)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.sm)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.sm)};
  }
  ${CommonTypographyStyles}
  ${({ weight }): string =>
    `font-weight: ${weight === 'medium' ? '500' : weight === 'semibold' ? '600' : '400'};`}
`

const Tiny = styled.h6<AtomsTypographyStyleProps>`
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.xsm)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.xsm)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.xsm)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.xsm)};
  }
  ${CommonTypographyStyles}
  ${({ weight }): string =>
    `font-weight: ${weight === 'medium' ? '500' : weight === 'semibold' ? '600' : '400'};`}
`

export const Typography: React.FC<AtomTypographyBaseProps & AtomsTypographyStyleProps> = ({
  type,
  weight = 'regular',
  capitalize,
  whiteSpace,
  color,
  uppercase,
  underline,
  margin,
  padding,
  className,
  italic,
  ellipsis,
  ellipsisTextLimit,
  children,
  id,
}): JSX.Element => {
  const typographyStyleProps = {
    weight,
    capitalize,
    whiteSpace,
    color,
    className,
    uppercase,
    underline,
    margin,
    padding,
    italic,
    ellipsis,
    ellipsisTextLimit,
    id,
  }
  switch (type) {
    case 'huge':
      return <LargeText {...typographyStyleProps}>{children}</LargeText>
    case 'h1':
      return <H1 {...typographyStyleProps}>{children}</H1>
    case 'h2':
      return <H2 {...typographyStyleProps}>{children}</H2>
    case 'h3':
      return <H3 {...typographyStyleProps}>{children}</H3>
    case 'body':
      return <Body {...typographyStyleProps}>{children}</Body>
    case 'caption':
      return <Caption {...typographyStyleProps}>{children}</Caption>
    case 'tiny':
      return <Tiny {...typographyStyleProps}>{children}</Tiny>
    default:
      return <></>
  }
}
