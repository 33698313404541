/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'

export const SinglePersonIcon: React.FC = () => {
  return (
    <>
      <path
        d="M3 3.75C3 4.54565 3.31607 5.30871 3.87868 5.87132C4.44129 6.43393 5.20435 6.75 6 6.75C6.79565 6.75 7.55871 6.43393 8.12132 5.87132C8.68393 5.30871 9 4.54565 9 3.75C9 2.95435 8.68393 2.19129 8.12132 1.62868C7.55871 1.06607 6.79565 0.75 6 0.75C5.20435 0.75 4.44129 1.06607 3.87868 1.62868C3.31607 2.19129 3 2.95435 3 3.75V3.75Z"
        stroke="#F29100"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 13.5C11.25 12.1076 10.6969 10.7723 9.71231 9.78769C8.72774 8.80312 7.39239 8.25 6 8.25C4.60761 8.25 3.27226 8.80312 2.28769 9.78769C1.30312 10.7723 0.75 12.1076 0.75 13.5V15.75H3L3.75 23.25H8.25L9 15.75H11.25V13.5Z"
        stroke="#F29100"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  )
}

export const SinglePersonIconV2: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 3.75C9 4.54565 9.31607 5.30871 9.87868 5.87132C10.4413 6.43393 11.2044 6.75 12 6.75C12.7956 6.75 13.5587 6.43393 14.1213 5.87132C14.6839 5.30871 15 4.54565 15 3.75C15 2.95435 14.6839 2.19129 14.1213 1.62868C13.5587 1.06607 12.7956 0.75 12 0.75C11.2044 0.75 10.4413 1.06607 9.87868 1.62868C9.31607 2.19129 9 2.95435 9 3.75Z"
      stroke="#F29100"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 13.5C17.25 12.1076 16.6969 10.7723 15.7123 9.78769C14.7277 8.80312 13.3924 8.25 12 8.25C10.6076 8.25 9.27226 8.80312 8.28769 9.78769C7.30312 10.7723 6.75 12.1076 6.75 13.5V15.75H9L9.75 23.25H14.25L15 15.75H17.25V13.5Z"
      stroke="#F29100"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
