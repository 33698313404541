import styled from 'styled-components/macro'

import { pxToRem } from 'V2/helpers/pxToRem'

export const Wrapper = styled.div`
  border-radius: ${pxToRem(4)};
  border: ${pxToRem(0.5)} solid ${({ theme }): string => theme.revamp.primary.grey.root};
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  & tbody td {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  & tbody td:first-child {
    padding-left: 16px;
  }
  & tbody td:last-child {
    padding-right: 16px;
  }
`
