import apm from 'apm'
import { AxiosError } from 'axios'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'

import { PaymentTransactionError } from 'interfaces/redux/Dependents.interface'
import { defaultTimeout, snackBarAction } from 'V2/actions/snackbar'
import { errorSegment, paymentErrorMapper } from 'V2/constants/paymentErrorMapper'
import { ServicesPaymentErrorHandling } from 'V2/interfaces/Services.interface'
import { CustomError } from './customError'
import { Logger } from './logger'

const FILE_NAMESPACE = 'paymentErrorHandling'

export const paymentErrorHandling = ({
  error,
  dispatch,
  displaySnackBar = false,
}: ServicesPaymentErrorHandling): PaymentTransactionError => {
  // check if AxiosError or not
  const isAxiosError = (err: unknown): err is AxiosError => {
    return (err as AxiosError).isAxiosError !== undefined
  }

  // trigger snackBarAction
  const triggerErrorSnackBar = (
    trigger: boolean,
    message = 'There’s an issue with this corridor at the moment.'
  ): void => {
    if (trigger) snackBarAction(message, 'error', defaultTimeout, dispatch)
  }

  // case: AxiosError or CustomError
  if (error instanceof CustomError || isAxiosError(error)) {
    // case: Network Failure
    if ((!error.response && error.request) || (error.response && error.response.status > 400)) {
      triggerErrorSnackBar(displaySnackBar)

      apm.captureError(
        new CustomError(`paymentErrorHandling: Network Failure`, error, {
          errorMessage: (error.response?.data?.message as string) ?? error.message,
          maskedMessage: 'There’s an issue with this corridor at the moment.',
          errorDisplayed: true,
          inputDisabled: false,
          snackBarDisplayed: true,
          source: 'paymentErrorHandling',
        })
      )

      return {
        stack: error,
        message: 'There’s an issue with this corridor at the moment.',
        disableInput: true,
        displayError: true,
      }
    }

    // case: Check Error Segment
    let paymentError = paymentErrorMapper['default']
    const matchingMessage = pickBy(errorSegment, (errorMessageType) => {
      return some(errorMessageType, (errorMessageTypeItem) =>
        new RegExp(errorMessageTypeItem, 'gi').test(
          error instanceof CustomError ? error.message : error.response?.data.message
        )
      )
    })

    if (Object.keys(matchingMessage).length) {
      const errorSegmentKey = Object.keys(matchingMessage)[0]
      paymentError = paymentErrorMapper[errorSegmentKey]
    }

    Logger(FILE_NAMESPACE).error(`paymentErrorHandling: ${paymentError.message}`, error, {
      errorMessage:
        error instanceof CustomError ? error.message : (error.response?.data.message as string),
      maskedMessage: paymentError.message,
      errorDisplayed: paymentError.displayError,
      inputDisabled: paymentError.disableInput,
      snackBarDisplayed: paymentError.displaySnackBar,
      source: 'paymentErrorHandling',
    })

    apm.captureError(
      new CustomError(`paymentErrorHandling: ${paymentError.message}`, error, {
        errorMessage:
          error instanceof CustomError ? error.message : (error.response?.data.message as string),
        maskedMessage: paymentError.message,
        errorDisplayed: paymentError.displayError,
        inputDisabled: paymentError.disableInput,
        snackBarDisplayed: paymentError.displaySnackBar,
        source: 'paymentErrorHandling',
      })
    )

    triggerErrorSnackBar(displaySnackBar || paymentError.displaySnackBar, paymentError.message)

    return {
      stack: error,
      message: paymentError.message,
      disableInput: paymentError.disableInput,
      displayError: paymentError.displayError,
      remitterIdentificationErrorMessage: paymentError.remitterIdentificationErrorMessage,
    }
  }

  // case: neither AxiosError nor CustomError
  Logger(FILE_NAMESPACE).error(`paymentErrorHandling: ${error.message}`, error)

  apm.captureError(
    new CustomError(`paymentErrorHandling: ${error.message}`, error, {
      source: 'paymentErrorHandling',
    })
  )

  triggerErrorSnackBar(displaySnackBar)

  return {
    stack: error,
    message: 'There’s an issue with this corridor at the moment.',
    disableInput: false,
    displayError: false,
  }
}
