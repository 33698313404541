import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { Beneficiary, ExchangeValue } from 'interfaces/redux/Dependents.interface'
import { currencyWithoutCents } from 'V2/constants/currencyWithoutCents'
import { decimalFormatByCurrency } from 'V2/helpers/decimalFormatByCurrency'
import { toFixedDecimalWithoutRounding } from 'V2/helpers/toFixedDecimalWithoutRounding'
import { ServicesCalculateExchange } from 'V2/interfaces/Services.interface'
import { Logger } from './logger'

const FILE_NAMESPACE = 'calculateExchange'

export const calculateExchange = ({
  sourceAmount = 0,
  destinationAmount = 0,
  feeAmount = 0,
  topupPricing = 0,
  fxRate,
  scope = 'default',
  customErrorFunc,
  swiftFee = 0,
}: ServicesCalculateExchange): ExchangeValue => {
  try {
    const destinationDecimals = currencyWithoutCents.some(
      (currency) => currency === fxRate.destination_currency
    )
      ? 0
      : 2
    const sourceDecimals = currencyWithoutCents.some(
      (currency) => currency === fxRate.source_currency
    )
      ? 0
      : 2

    let resultingFromAmount = sourceAmount
    let resultingToAmount = destinationAmount
    let convertedAmount = sourceAmount
    let supplierPaymentsFee = 0
    let sourceAmountWithSupplierFee = 0
    const minimumToAmount = 1 / 10 ** destinationDecimals
    const totalFee = feeAmount + toFixedDecimalWithoutRounding(swiftFee, sourceDecimals)

    if (destinationAmount) {
      convertedAmount = fxRate.fx_rate !== 0 ? destinationAmount / fxRate.fx_rate : 0
      resultingFromAmount = convertedAmount + totalFee
    }

    if (sourceAmount) {
      if (sourceAmount * fxRate.fx_rate > Number.MAX_VALUE) {
        throw new Error('To amount is too large')
      }

      convertedAmount =
        sourceAmount - totalFee > 0
          ? toFixedDecimalWithoutRounding(sourceAmount - totalFee, sourceDecimals)
          : 0

      resultingToAmount = convertedAmount * fxRate.fx_rate || 0
      // If the resulting amount is lower than the physically possible one - set destinationAmount = minimumToAmount
      if (resultingToAmount < minimumToAmount) {
        return calculateExchange({
          destinationAmount: minimumToAmount,
          feeAmount,
          fxRate,
          scope,
          customErrorFunc,
          swiftFee,
        })
      }
    }

    supplierPaymentsFee = toFixedDecimalWithoutRounding(
      (resultingFromAmount * topupPricing) / 100,
      sourceDecimals
    )
    sourceAmountWithSupplierFee = resultingFromAmount + supplierPaymentsFee

    const response = {
      sourceAmount: {
        value: toFixedDecimalWithoutRounding(resultingFromAmount, sourceDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(resultingFromAmount, sourceDecimals),
          fxRate.source_currency
        ).toLocaleString(),
      },
      destinationAmount: {
        value: toFixedDecimalWithoutRounding(resultingToAmount, destinationDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(resultingToAmount, destinationDecimals),
          fxRate.destination_currency
        ).toLocaleString(),
      },
      convertedAmount: {
        value: toFixedDecimalWithoutRounding(convertedAmount, sourceDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(convertedAmount, sourceDecimals),
          fxRate.source_currency
        ).toLocaleString(),
      },
      feeAmount: {
        value: toFixedDecimalWithoutRounding(feeAmount, sourceDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(feeAmount, sourceDecimals),
          fxRate.source_currency
        ).toLocaleString(),
      },
      supplierPaymentsFee: {
        value: toFixedDecimalWithoutRounding(supplierPaymentsFee, sourceDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(supplierPaymentsFee, sourceDecimals),
          fxRate.source_currency
        ).toLocaleString(),
      },
      sourceAmountWithSupplierFee: toFixedDecimalWithoutRounding(
        sourceAmountWithSupplierFee,
        sourceDecimals
      ),
      swiftFeeAmount: {
        value: toFixedDecimalWithoutRounding(swiftFee, sourceDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(swiftFee, sourceDecimals),
          fxRate.source_currency
        ).toLocaleString(),
      },
      totalFeeAmount: {
        value: toFixedDecimalWithoutRounding(totalFee, sourceDecimals),
        display: decimalFormatByCurrency(
          toFixedDecimalWithoutRounding(totalFee, sourceDecimals),
          fxRate.source_currency
        ).toLocaleString(),
      },
    }
    Logger(FILE_NAMESPACE).silly(
      'debugger',
      {
        sourceAmount,
        destinationAmount,
        feeAmount,
        topupPricing,
        fxRate,
        swiftFee,
      },
      {
        resultingFromAmount,
        resultingToAmount,
        convertedAmount,
        supplierPaymentsFee,
        sourceAmountWithSupplierFee,
        minimumToAmount,
        totalFee,
      },
      response,
      scope
    )

    return response
  } catch (err) {
    customErrorFunc(err as Error)
    return calculateExchange({
      sourceAmount: 1000,
      feeAmount,
      fxRate,
      scope,
      customErrorFunc,
    })
  }
}

export const getSwiftFeeDetails = (
  sourceCurrencies: string,
  beneDetails: Beneficiary,
  clientId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.getSwiftFee}`,
    {
      params: {
        sourceCurrencies,
        destinationCurrency: beneDetails?.payouts[0]?.destination_currency,
        destinationCountry: beneDetails?.payouts[0]?.destination_country,
        beneficiaryAccountType:
          beneDetails.beneficiary_account_type === 'Company'
            ? 'CORPORATE'
            : beneDetails.beneficiary_account_type.toUpperCase(),
        clientId,
      },
    }
  )
  Logger(FILE_NAMESPACE).debug('Get Swift Fee Details response', response)
  return response
}
