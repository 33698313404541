import apm from 'apm'
import axios from 'axios'
import { Store } from 'redux'

import { apiRoutes } from 'constants/apiRoutes'
import { logout } from 'V2/actions/userClientDetails'
import { CustomError } from './customError'
import { Logger } from './logger'

const FILE_NAMESPACE = 'axiosInstanceV2'

let axiosCancelToken = axios.CancelToken.source()

export const cancelAxiosRequest = (): void => {
  axiosCancelToken.cancel()
  axiosCancelToken = axios.CancelToken.source()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const traceLogging = (error: any): void => {
  // except for card details we can capture all
  const ignoreUrls = [
    apiRoutes.cardPayment,
    apiRoutes.saveCard,
    apiRoutes.chargeOnCard,
    apiRoutes.login,
    apiRoutes.resetPassword,
    apiRoutes.generateOtp,
    apiRoutes.requestPassword,
    apiRoutes.setPassword,
  ]

  Logger(FILE_NAMESPACE).error(`TraceLogging from axios ${error?.response?.config?.url}`, error, {
    requestUrl: error?.response?.config?.url,
    requestBody: ignoreUrls.includes(error?.response?.config?.url)
      ? 'truncated body'
      : JSON.stringify(error?.response?.config?.data),
    requestParams: JSON.stringify(error?.response?.config?.params),
    response: JSON.stringify(error?.response?.data),
  })
  apm.setCustomContext({
    requestUrl: error?.response?.config?.url,
    requestBody: ignoreUrls.includes(error?.response?.config?.url)
      ? 'truncated body'
      : JSON.stringify(error?.response?.config?.data),
    requestParams: JSON.stringify(error?.response?.config?.params),
    response: JSON.stringify(error?.response?.data),
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const axiosInstance = (store: Store<any, any>): void => {
  // apply csrf token from sessionStorage to each axios request headers
  axios.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {}
    }
    if (sessionStorage.getItem('csrfToken'))
      config.headers.csrfToken = sessionStorage.getItem('csrfToken') as string
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers.source = 'sme'
    config.cancelToken = axiosCancelToken.token
    return config
  })

  axios.interceptors.response.use(
    (response) => {
      // do something with response data
      return response
    },
    async (error) => {
      // retry twice if account creation fails on adding bene to recipient list
      if (
        error?.response?.config?.url === apiRoutes.createAccount &&
        error?.response.status === 404
      ) {
        Logger(FILE_NAMESPACE).error('account creation:', error)
        const config = error.response.config
        config.retryCount = config?.retryCount + 1 || 1

        if (config.retryCount > 2) {
          traceLogging(error)
          throw error
        }

        return axios.request(config)
      }

      // Do something with error response
      if (error?.response?.data?.name === 'csrf_token_mismatch') {
        Logger(FILE_NAMESPACE).error('csrf_token_mismatch:', error)
        // cancel all ongoing requests
        cancelAxiosRequest()
        store.dispatch(logout())
      }

      if (error?.response?.data?.name === 'concurrent_login') {
        Logger(FILE_NAMESPACE).error('concurrent_login:', error)
        // cancel all ongoing requests
        cancelAxiosRequest()
        store.dispatch(logout())
      }

      if (
        error?.response?.config?.url !== apiRoutes.logout &&
        // if we get error json
        (error?.response?.data?.name === 'user_not_logged_in' ||
          // if we get error stack instead of error json
          (error?.response.status === 403 &&
            typeof error?.response?.data === 'string' &&
            error?.response?.data.indexOf('user_not_logged_in') > -1))
      ) {
        Logger(FILE_NAMESPACE).error('user_not_logged_in:', error)
        sessionStorage.setItem('redirectionUrl', window.location.href)
        // cancel all ongoing requests
        cancelAxiosRequest()
        store.dispatch(logout())
      }

      // case: Network Failure
      if (!error.response && !axios.isCancel(error)) {
        Logger(FILE_NAMESPACE).error('Unknown Axios Error', error)
        apm.captureError(new CustomError('Unknown Axios Error', error, { source: 'axiosInstance' }))
      } else {
        traceLogging(error)
      }

      throw error
    }
  )
}
