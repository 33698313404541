// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const TrusteeIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2716_43951)">
      <path
        d="M18.7501 14.25H16.7161C16.4045 14.2502 16.1027 14.3588 15.8625 14.5572C15.6222 14.7556 15.4585 15.0314 15.3994 15.3373C15.3403 15.6432 15.3894 15.9602 15.5384 16.2338C15.6874 16.5074 15.927 16.7207 16.2161 16.837L18.2791 17.662C18.5688 17.7779 18.809 17.9911 18.9585 18.265C19.108 18.5388 19.1574 18.8562 19.0983 19.1626C19.0391 19.4689 18.875 19.7451 18.6342 19.9435C18.3935 20.142 18.0911 20.2504 17.7791 20.25H15.7501"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 14.25V13.5"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 21V20.25"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 17.25C11.25 18.8413 11.8821 20.3674 13.0074 21.4926C14.1326 22.6179 15.6587 23.25 17.25 23.25C18.8413 23.25 20.3674 22.6179 21.4926 21.4926C22.6179 20.3674 23.25 18.8413 23.25 17.25C23.25 15.6587 22.6179 14.1326 21.4926 13.0074C20.3674 11.8821 18.8413 11.25 17.25 11.25C15.6587 11.25 14.1326 11.8821 13.0074 13.0074C11.8821 14.1326 11.25 15.6587 11.25 17.25Z"
        stroke="#F29100"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3.75C3 4.54565 3.31607 5.30871 3.87868 5.87132C4.44129 6.43393 5.20435 6.75 6 6.75C6.79565 6.75 7.55871 6.43393 8.12132 5.87132C8.68393 5.30871 9 4.54565 9 3.75C9 2.95435 8.68393 2.19129 8.12132 1.62868C7.55871 1.06607 6.79565 0.75 6 0.75C5.20435 0.75 4.44129 1.06607 3.87868 1.62868C3.31607 2.19129 3 2.95435 3 3.75Z"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.555 10.8868C9.97906 9.8835 9.08792 9.09848 8.02001 8.65367C6.95209 8.20886 5.76717 8.12917 4.6493 8.42696C3.53144 8.72475 2.5432 9.38337 1.83809 10.3005C1.13299 11.2176 0.750486 12.3419 0.75 13.4988V15.7488H3L3.75 23.2488H8.25L8.573 20.0158"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 8.25V14.25"
        stroke="#2C2C2C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2716_43951">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
