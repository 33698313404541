/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { FpxPayoutRequest } from 'V2/interfaces/Services.interface'
import { config } from './bulkPayment'

export const initiateFPXPayout = (
  fpxPayoutRequest: FpxPayoutRequest,
  clientId: string
): Promise<any> => {
  return axios.post(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.initiateFPXPayout}`,
    fpxPayoutRequest,
    config(clientId)
  )
}

export const updateTransactionPaymentId = (
  paymentId: string,
  data: any,
  clientId: string
): Promise<any> => {
  return axios.post(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${apiRoutes.updateTransactionPaymentId(
      paymentId
    )}`,
    data,
    config(clientId)
  )
}

export const getFpxTransactionStatus = (transactionId: string, clientId: string): Promise<any> => {
  const params = {
    transactionIds: transactionId,
  }
  return axios.get(
    `${process.env.REACT_APP_SME_BACKEND_BASE_URL}/${apiRoutes.getTransactionStatus}`,
    config(clientId, params)
  )
}
