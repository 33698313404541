import { StakeholderScreens } from './useStakeholderType'

// Form default values for AUForm.
export const defaultValues = {
  businessDetails: {
    businessName: 'Kellie Hendricks',
    tradingName: 'Arthur Nichols',
    addresses: {
      registeredAddress: {
        addressLine1: '701 Fabien Extension',
        addressLine2: '',
        city: 'Possimus qui esse cum lorem totam irure quam quod',
        state: 'Blanditiis sunt minima omnis quia ratione providen',
        postCode: 'Dolores ducimus dolores aut necessitatibus nisi',
        principalBusiness: true,
      },
    },
    companyWebsite: 'Silva and Simmons LLC',
    noWebsite: true,
  },
  businessProfile: {
    sizeOfBusiness: {
      label: 'Less than AUD 10,000',
      value: 'Less than AUD 10,000',
    },
    noOfEmployees: {
      label: 'Less than or equal 5',
      value: 'Less than or equal 5',
    },
    industryCategory: {
      label: 'Entertainment Industry',
      value: 'Entertainment Industry',
    },
    industrySubCategory: {
      label: 'Freelancer',
      value: 'Freelancer',
    },
    dateOfIncorporation: '04 - Jan - 2023',
    countryOfIncorporation: {
      label: 'Australia',
      value: 'AU',
    },
    countriesOfOperation: [
      {
        label: 'India',
        value: 'IN',
      },
    ],
  },
  stakeholderType: 'soleStakeholder',
  stakeholders: [
    {
      isApplicant: true,
      stakeholderRole: 'director',
      stakeholderDetails: {
        firstName: 'Daria',
        middleName: 'Phillip Alford',
        lastName: 'Vargas',
        dob: '02 - Jan - 2023',
        nationality: {
          label: 'Australia',
          value: 'AU',
        },
        email: 'hirafyca@mailinator.com',
        code: {
          label: '+61',
          value: '+61',
        },
        mobile: 'Ullamco pariatur Et in odit est molestias et iste',
        address: {
          streetName: 'Buffy May',
          streetNumber: '618',
          unitNumber: '72',
          streetType: 'Iste explicabo Consectetur dolorem veritatis fug',
          suburb: 'Iusto recusandae Sed magna error occaecat velit d',
          state: 'Ea non facere enim temporibus cillum est sed',
          postCode: 'Quam eligendi est illum ex autem laboriosam sed',
        },
      },
    },
    {
      isApplicant: false,
      stakeholderRole: 'significantShareholder',
      stakeholderDetails: {
        firstName: 'Petra',
        middleName: 'Carla Roy',
        lastName: 'Hutchinson',
        dob: '11 - Jan - 2023',
        nationality: {
          label: 'Australia',
          value: 'AU',
        },
        email: 'paquq@mailinator.com',
        code: {
          label: '+61',
          value: '+61',
        },
        mobile: 'Alias sit et tempora est culpa deleniti recusandae',
        address: {
          streetName: 'Remedios Weber',
          streetNumber: '494',
          unitNumber: '674',
          streetType: 'Velit iure possimus nisi ipsum',
          suburb: 'Non quibusdam rerum et porro nihil rerum corporis ',
          state: 'Eum ad fugit voluptas quam minima ullamco laboris',
          postCode: 'Modi iste voluptates odit quo pariatur Dolore min',
        },
      },
    },
  ],
  documentDetails: {},
  termsAndConditions1: true,
  termsAndConditions2: true,
}

export const automationIds = {
  formStepsHeader: 'sme-onboarding-form-steps-header',
  formStepsHeaderCancelIcon: 'sme-onboarding-form-steps-header-cancel-icon',
  continueBtn: 'sme-onboarding-continuebtn',
  backBtn: 'sme-onboarding-backbtn',
  stepperBackBtn: 'sme-onboarding-stepper-backbtn',
  stepperCancelBtn: 'sme-onboarding-stepper-cancelbtn',
  // register form
  businessName: 'sme-onboarding-business-name',
  email: 'sme-onboarding-email',
  createPassword: 'sme-onboarding-create-password',
  countryOfResidence: 'sme-onboarding-country-of-residence',
  mobileCode: 'sme-onboarding-mobile-code',
  mobileNumber: 'sme-onboarding-mobile-number',
  termsAndConditions: 'sme-onboarding-terms-and-conditions',
  submit: 'sme-onboarding-submit',
  registerForm: 'sme-onboarding-register-form',
  signUpHeader: 'sme-onboarding-signup-text',
  // /start page
  getStartedHeader: 'sme-onboarding-welcome-header',
  getStartedSecondaryText: 'sme-onboarding-secondary-text',
  getStartedList: 'sme-onboarding-list-details',
  getStartedInfo: 'sme-onboarding-info-text',
  getStartedSubmitBtn: 'sme-onboarding-submit',
  // lookup page
  lookupHeader: 'sme-onboarding-lookup-header',
  lookupSecondaryText: 'sme-onboarding-lookup-text',
  lookupInput: 'sme-onboarding-lookup-input',
  lookupCheckbox: 'sme-onboarding-lookup-checkbox',
  lookupSubmitBtn: 'sme-onboarding-lookup-submit',
  // businessNamePage
  businessNameHeader: 'sme-onboarding-businessname-text',
  businessEntityType: 'sme-onboarding-business-entitytype',
  dateOfIncorporation: 'sme-onboarding-dateof-incorporation',
  regulatedTrustType: 'sme-onboarding-regulated_trusttype',
  unregulatedTrustType: 'sme-onboarding-unregulated_trusttype',
  legislationType: 'sme-onboarding-legislationType',
  formerName: 'sme-onboarding-formerName',

  //businessReason
  businessReasonHeader: 'sme-onboarding-businessreason-header',
  businessReasonError: 'sme-onboarding-businessreason-error',
  registeredBusinessAddressHeader: 'sme-onboarding-registered-business-address',
  businessAddressHeader: 'sme-onboarding-businessaddress-header',
  businessRegisteredAddressCountry: 'sme-onboarding-registered-business-address-country',
  businessRegisteredAddressState: 'sme-onboarding-registered-business-address-state',

  //businessAddress
  businessAddressCountry: 'sme-onboarding-business-address-country',
  businessAddressState: 'sme-onboarding-business-address-state',
  isPrincipalPlaceOfBusiness: 'is-principal-place-of-business',

  //businessProfile
  businessProfileHeader: 'sme-onboarding-businessprofile-header',
  businessProfileTurnover: 'sme-onboarding-businessprofile-turnover',
  businessProfileNoofEmployees: 'sme-onboarding-businessprofile-noof-employees',
  businessProfileIndustrySectorCategory: 'sme-onboarding-businessprofile-industry-sector-category',
  businessProfileIndustrySector: 'sme-onboarding-businessprofile-industry-sector',
  businessProfileIntendeduseofAccount: 'sme-onboarding-businessprofile-intenededuseof-account',
  //businessWebsiteHeader
  businessWebsiteHeader: 'sme-onboarding-businesswebsite-header',
  businessWebsiteInfoHeader: 'sme-onboarding-businesswebsite-info-header',
  //businessCountries
  businessCountriesHeader: 'sme-onboarding-businesscountries-header',
  businessCountriesofOperations: 'sme-onboarding-businesscountries-of-operations',
  businessTransactionCountries: 'sme-onboarding-business-transaction-countries',
  businessTaxCountry: 'sme-onboarding-business-tax-country',
  businessTaxNumber: 'sme-onboarding-business-tax-number',
  //stakeholders
  stakeHoldersOption1: 'sme-onboarding-stakeholder-1',
  stakeHoldersOption2: 'sme-onboarding-stakeholder-2',
  stakeHolderOption3: 'sme-onboarding-stakeholder-3',
  stakeHoldersHeader: 'sme-onboarding-stakeholder-header',
  stakeHoldersRoleHeader: 'sme-onboarding-stakeholder-role-header',
  stakeHolderSecondaryText: 'sme-onboarding-stakeholder-text',

  //stakeholders business profile
  stakeHoldersBusinessProfileHeader: 'sme-onboarding-stakeholder-business-profile-header',
  stakeholdersDescribeProfileHeader: 'sme-onboarding-stakehodler-describe-profile-header',
  stakeholdersAddOptions: 'sme-onboarding-stakeholder-add-options',
  websiteCheckbox: 'sme-onboarding-websiteCheckbox',
  //addStakeHolders
  addStakeHoldersHeader: 'sme-onboarding-addstakeholder-header',
  addStakeHoldersHeaderRole: 'sme-onboarding-addstakeholder-header-role',
  addStakeHoldersSecondaryText: 'sme-onboarding-addstakeholder-text',
  addStakeHolderBtn: 'sme-onboarding-addstakeholder-btn',
  onlyStakeholderTitle: 'sme-onboarding-only-stakeholder-title',
  onlyStakeholderDetails: 'sme-onboarding-only-stakeholder-details',
  oneOfStakeholderTitle: 'sme-onboarding-one-of-stakeholder-title',
  oneOfStakeholderDetails: 'sme-onboarding-one-of-stakeholder-details',
  representingStakeholderTitle: 'sme-onboarding-representing-stakeholder-title',
  representingStakeholderDetails: 'sme-onboarding-representing-stakeholder-details',
  //Add stakeHolderPage
  stakeHolderPageBtn: 'sme-onboarding-stakeholderpage-btn',
  stakeHolderDirector: 'sme-onboarding-stakeholder-director',
  stakeHolderDirectorDetails: 'sme-onboarding-stakeholder-director-details',
  stakeHolderShareHolder: 'sme-onboarding-shareholder',
  stakeHolderTrustee: 'sme-onboarding-trustee',
  stakeHolderTrusteeDetails: 'sme-onboarding-trustee-details',
  stakeHolderUBO: 'sme-onboarding-ubo',
  stakeHolderUBODetails: 'sme-onboarding-ubo-details',
  stakeHolderSettlor: 'sme-onboarding-settlor',
  stakeHolderSettlorDetails: 'sme-onboarding-settlor-details',
  stakeHolderPartner: 'sme-onboarding-partner',
  stakeHolderPartnerDetails: 'sme-onboarding-partner-details',
  stakeHolderSignatory: 'sme-onboarding-signatory',
  stakeHolderSignatoryDetails: 'sme-onboarding-signatory-details',
  stakeHolderRepresentative: 'sme-onboarding-representative',
  stakeHolderRepresentativeDetails: 'sme-onboarding-representative-details',
  stakeHolderExecutor: 'sme-onboarding-executor',
  stakeHolderExecutorDetails: 'sme-onboarding-executor-details',
  stakeHolderMembers: 'sme-onboarding-members',
  stakeHolderMembersDetails: 'sme-onboarding-members-details',
  stakeHolderProtector: 'sme-onboarding-protector',
  stakeHolderProtectorDetails: 'sme-onboarding-protector-details',
  stakeHolderShareHolderDetails: 'sme-onboarding-shareholder-details',
  stakeholderApplicantCheckbox: 'sme-onboarding-stakeholder-applicant-checkbox',
  stakeholderApplicantError: 'sme-onboarding-stakeholder-applicant-error',
  stakeholderPreview: 'sme-onboarding-stakeholder-preview',
  stakeholderPreviewName: 'sme-onboarding-stakeholder-preview-name',
  stakeholderPreviewRole: 'sme-onboarding-stakeholder-preview-role',
  stakeholderPreviewEdit: 'sme-onboarding-stakeholder-preview-edit',
  stakeholderPreviewDelete: 'sme-onboarding-stakeholder-preview-delete',
  // Stakeholders Personal Details
  addressDetailsHeader: 'sme-onboarding-addressdetails-header',
  personalDetailsHeader: 'sme-onboarding-personaldetails-header',
  stakeholdersDateofbirth: 'sme-onboarding-stakeholders-dateofbirth',
  stakeholdersNationality: 'sme-onboarding-stakeholders-nationality',
  stakeholdersBirthCountry: 'sme-onboarding-stakeholders-birth-country',
  stakeholdersGender: 'sme-onboarding-stakeholders-gender',
  stakeholderResidency: 'sme-onboarding-stakeholders-residency',
  stakeholderTaxCountry: 'sme-onboarding-stakeholders-tax-country',
  stakeholderTaxNumber: 'sme-onboarding-stakeholders-tax-number',
  stakeholderPEP: 'sme-onboarding-stakeholders-pep',

  // OnlyApplicant Details
  onlyApplicantPersonalDetailsHeader: 'sme-onboarding-onlyapplicant-personaldetails-header',
  onlyApplicantAddressDetailsHeader: 'sme-onboarding-onlyapplicant-addressdetails-header',
  onlyApplicantResidency: 'sme-onboarding-onlyapplicant-residency',

  //stakeholders address
  stakeholderisAustrailianResident: 'sme-onboarding-stakeholder-isaustralian-resident',
  stakeholderStreetType: 'sme-onboarding-stakeholder-street-type',
  stakeholdersState: 'sme-onboarding-stakeholder-state',
  stakeholderCountry: 'sme-onboarding-stakeholder-country',
  stakeholderisPEP: 'sme-onboarding-stakeholder-pep',

  //document upload
  documentUploadHeader: 'sme-onboarding-document-header',
  documentUploadSecondaryText: 'sme-onboarding-document-text',
  documentUploadInfo: 'sme-onboarding-document-info',
  documentUploadFileDialog: 'sme-onboarding-file-dialog',
  documentUploadFileName: 'sme-onboarding-file-name',
  loaDocumentUploadHelperText: 'sme-onboarding-loaDocumentUploadHelperText',
  stakeholderVerifyDocumentHeaderText: 'sme-onboarding-stakeholderVerifyDocumentHeaderText',
  fileUploadComponent: 'sme-onboarding-fileUploadComponent',
  brdDocumentUploadHeaderText: 'sme-onboarding-brdDocumentUploadHeaderText',
  uploadDocumentIcon: 'sme-onboarding-uploadDocumentIcon',
  uploadAnyOneofDocumentText: 'sme-onboarding-uploadAnyOneofDocumentText',
  uploadBusinessCertificateText: 'sme-onboarding-uploadBusinessCertificateText',

  // Document Upload Page
  verifyviaMyinfodocumentUploadSubHeader: 'sme-onboarding-verifyViaMyInfo-subheader',
  verifywithDocumentdocumentUploadSubHeader: 'sme-onboarding-verifyWithDocument-subheader',
  listofInformationWrapper: 'sme-onboarding-listofInformation',
  verifyViaMyInfoCta: 'sme-onboarding-verifyViaMyInfoCta-button',
  uploadDocCta: 'sme-onboarding-uploadDocCta-button',
  nricInput: 'sme-onboarding-nric-input',
  identificationNumberInput: 'sme-onboarding-identificationNumber-input',
  passportNumberInput: 'sme-onboarding-passportNumber-input',
  driverLicenseNumberInput: 'sme-onboarding-driver-license-number-input',
  driverLicenseCardNumberInput: 'sme-onboarding-driver-license-card-number-input',
  passportExpiry: 'sme-onboarding-passportExpiry',
  countryofIssuance: 'sme-onboarding-countryofIssuance-dropdown',
  passportCountryOfIssue: 'sme-onboarding-passport-country-of-issue-dropdown',

  medicareCardColor: 'sme-onboarding-medicare-card-color-dropdown',
  medicareNameOnCard: 'sme-onboarding-medicare-name-on-card-input',
  medicareCardNumber: 'sme-onboarding-medicare-card-number-input',
  medicareIRN: 'sme-onboarding-medicare-irn-input',
  medicareExpiryDate: 'sme-onboarding-medicare-expiry-date-input',

  driversLicenceState: 'sme-onboarding-driver-license-state-dropdown',
  documentUploadContinue: 'sme-onboarding-documentUploadContinue',
  documentUploadTypeHeader: 'sme-onboarding-document-upload-type-header',
  supportedFilesSection: 'sme-onboarding-supportedFilesSection-message',
  uploadSubHeader: 'sme-onboarding-uploadSubHeader',
  moreinformationHeader: 'sme-onboarding-moreinformationHeader',
  retreiveInformationHeader: 'sme-onboarding-retreiveInformationHeader',
  someDetailsHeader: 'sme-onboarding-someDetailsHeader',
  loaDocumentHeader: 'sme-onboarding-loaDocumentHeader',
  poaDocumentHeader: 'sme-onboarding-poaDocumentHeader',
  breadCrumbText: 'sme-onboarding-breadCrumbText',
  breadCrumbRightArrow: 'sme-onboarding-breadCrumbRightArrow',

  // final page
  acknowledgementPageHeader: 'sme-onboarding-acknowledgement-header',
  isTnCAccepted: 'is-tnc-accepted',
  isDeclarationGiven: 'is-declaration-given',

  //logout
  logoutModalHeaderSecondaryText: 'sme-onboarding-logout-modal-secondary-text',
  logoutModalSubSecondaryText: 'sme-onboarding-logout-modal-sub-secondary-text',

  // success screen
  sucessScreenHeader: 'sme-onboarding-success-header',
  succesScreenSecondaryText: 'sme-onboarding-success-secondary-text',
  successScreenCloseButton: 'sme-onboarding-success-clsoe-button',
  successScreenSubmitButton: 'sme-onboarding-success-submit-button',
  pendingDocumentsHeader: 'sme-onboarding-pendingDocumentsHeader',
  listOfDocumentsWrapper: 'sme-onboarding-listOfDocumentsWrapper',

  // Feedback

  feedbackInputField: 'sme-onboarding-feedbackInputField',
  submitFeedbackCta: 'sme-onboarding-submitFeedbackCta',

  // snackbar
  snackBarCloseIcon: 'sme-onboarding-snackbar_close_icon',

  // Onboarding RFI
  rfiVerifyDocumentHeaderText: 'sme-onboarding-rfiVerifyDocumentHeaderText',
  rfiComment: 'sme-onboarding-rfi-comment',
}

export const URLRegex = /^(?!\s*$).+/

export const PRIVATE_COMPANY = 'PRIVATE_COMPANY'
export const SOLE_TRADER = 'SOLE_TRADER'
export const PUBLIC_COMPANY = 'PUBLIC_COMPANY'
export const PARTNERSHIP = 'PARTNERSHIP'
export const REGULATED_TRUST = 'REGULATED_TRUST'
export const UNREGULATED_TRUST = 'UNREGULATED_TRUST'
export const ASSOCIATIONS = 'ASSOCIATIONS'
export const GOVERNMENT_BODY = 'GOVERNMENT_ENTITY'

export enum businessTypes {
  PRIVATE_COMPANY = 'PRIVATE_COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  PUBLIC_COMPANY = 'PUBLIC_COMPANY',
  PARTNERSHIP = 'PARTNERSHIP',
  REGULATED_TRUST = 'REGULATED_TRUST',
  UNREGULATED_TRUST = 'UNREGULATED_TRUST',
  ASSOCIATIONS = 'ASSOCIATIONS',
  GOVERNMENT_BODY = 'GOVERNMENT_ENTITY',
  TRUST = 'TRUST',
}

export const regex = {
  firstName: /^[a-zA-Z ]+$/,
  // allow alphabates and space in between only
  middleName: /^(?!\s*$).*/,
  lastName: /^[a-zA-Z ]+$/,
  streetNumber: /^-?\d*\.?\d+$/,
  contactNo: /^(?:\d+)?$/,
  // unitNumber will be alphanumeric and optional
  unitNumber: /^[a-zA-Z0-9\s]*$/,
  businessRegistrationNumber: /^[0-9]+$/,
  partnershipName: /^[a-zA-Z0-9 ]+$/,
  associationNumber: /^[0-9]+$/,
  associationChairperson: /^[a-zA-Z0-9 ]+$/,
  email: /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})?$/,
  city: /^(?!^\d+$)(?!^[^a-zA-Z\s]+$)[a-zA-Z\s]*$/,
  tradeName: /^[a-zA-Z0-9& ]*$/,
  streetName: /^[a-zA-Z ]+$/,
  associationName: /^[a-zA-Z0-9&.\-\s]*$/,
  postalCode: /^[a-zA-Z0-9]+$/,
  postalCodeWithSpace: /^[a-zA-Z0-9 ]+$/,
  postalCodeWithSpaceandHyphen: /^[a-zA-Z0-9 -]+$/,
  sharePercentage: /^\d+(\.\d{1,2})?$/,
  onlyAlphaNumeric: /^[a-zA-Z0-9]+$/,
  onlyNumbers: /^[0-9]+$/,
  onlyFloat: /^[0-9]+(\.[0-9]+)?$/,
  yyyyMMdd: /^\d{4}-\d{2}-\d{2}$/,
}

export const stepName: {
  [key: number]: string
} = {
  1: 'BUSINESS_DETAILS',
  2: 'BUSINESS_PROFILE',
  3: 'STAKEHOLDERS',
  4: 'ACKNOWLEDGEMENT',
}

export const signupRegex = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  password:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?/~`])[A-Za-z\d!@#$%^&*()_\-+={}[\]\\|:;"'<>,.?/~]{8,}$/,
  contactnumber: /^4\d*$/,
  businessName: /^[A-Za-z0-9 &.-]*$/,
  generalContactNumber: /^[0-9]*$/,
}

export type RefDataFields =
  | 'businessType'
  | 'legislationType'
  | 'countryName'
  | 'unregulatedTrustType'
  | 'listedExchange'
  | 'industrySector'
  | 'annualTurnover'
  | 'totalEmployees'
  | 'streetType'
  | 'countryOfOperation'

export const StakeholderBreadcrumbScreens: StakeholderScreens[] = [
  'stakeholderRole',
  'stakeholderPersonalDetails',
  'onlyApplicantPersonalDetails',
  'stakeholderAddressDetails',
  'onlyApplicantAddressDetails',
  'stakeholderDocumentVerification',
  'stakeholderDocumentType',
  'stakeholderDocumentInfo',
]
