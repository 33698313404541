/* eslint-disable @typescript-eslint/camelcase */
import { Reducer } from 'redux'

import { CurrencyCalculator } from 'interfaces/redux/Reducers.interface'
import { CurrencyCalculatorTypes } from 'store/actionTypes'

const InitialState = {
  fxRate: undefined,
  sendCurrency: undefined,
  receiveCurrency: undefined,
  loading: {
    fxRateLoading: false,
    netSettlementLoading: false,
  },
  errors: {
    getFxRateError: null,
    getNetSettlementError: null,
  },
  inputUpdating: false,
  inputTouched: false,
  exchangeValue: {
    sourceAmount: {
      value: 0,
      display: '0',
    },
    destinationAmount: {
      value: 0,
      display: '0',
    },
    convertedAmount: {
      value: 0,
      display: '0',
    },
    supplierPaymentsFee: {
      value: 0,
      display: '0',
    },
    feeAmount: {
      value: 0,
      display: '0',
    },
    swiftFeeAmount: {
      value: 0,
      display: '0',
    },
    totalFeeAmount: {
      value: 0,
      display: '0',
    },
    sourceAmountWithSupplierFee: 0,
  },
}

const currencyCalculatorReducer: Reducer<CurrencyCalculator> = (state = InitialState, action) => {
  switch (action.type) {
    case CurrencyCalculatorTypes.FX_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          fxRateLoading: action.payload,
        },
      }
    case CurrencyCalculatorTypes.FEE_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          netSettlementLoading: action.payload,
        },
      }
    case CurrencyCalculatorTypes.GET_FX_RATE_REQUEST:
      return {
        ...state,
        fxRate: action.payload,
        inputUpdating: false,
        inputTouched: false,
        errors: InitialState.errors,
        exchangeValue: InitialState.exchangeValue,
      }
    case CurrencyCalculatorTypes.GET_FX_RATE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          getFxRateError: action.payload,
        },
      }
    case CurrencyCalculatorTypes.GET_FEE_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          getNetSettlementError: action.payload,
        },
      }
    case CurrencyCalculatorTypes.EXCHANGE_VALUE_SET:
      return {
        ...state,
        exchangeValue: action.payload.exchangeValue,
        inputUpdating: action.payload.isUpdating,
        inputTouched: action.payload.inputTouched,
      }
    case CurrencyCalculatorTypes.SEND_CURRENCY_UPDATE:
      return {
        ...state,
        sendCurrency: action.payload,
      }
    case CurrencyCalculatorTypes.RECEIVE_CURRENCY_UPDATE:
      return {
        ...state,
        receiveCurrency: action.payload,
      }
    default:
      return state
  }
}

export { currencyCalculatorReducer as currencyCalculator }
