/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { catchErrorHandler } from 'V2/helpers/catchErrorHandler'

// fetches beneficary validation schema

export const fetchBankLookup = async ({
  country_code,
  search_key,
  search_value,
  routing_code_type,
  payout_method,
  currency_code,
  bank_code,
}: any): Promise<any> => {
  try {
    const {
      data: {
        data: [bankData],
      },
    } = await axios.post(apiRoutes.bankSearchByRoutingCode, {
      country_code,
      search_key,
      search_value,
      routing_code_type,
      payout_method,
      currency_code,
      bank_code,
    })
    return bankData
  } catch (err) {
    return catchErrorHandler(err)
  }
}
