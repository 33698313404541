// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const PayBillsIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <path
      d="M21.75 21.75C21.75 22.1478 21.592 22.5294 21.3107 22.8107C21.0294 23.092 20.6478 23.25 20.25 23.25H3.75C3.35218 23.25 2.97064 23.092 2.68934 22.8107C2.40804 22.5294 2.25 22.1478 2.25 21.75V2.25C2.25 1.85218 2.40804 1.47064 2.68934 1.18934C2.97064 0.908035 3.35218 0.75 3.75 0.75H14.379C14.7765 0.750085 15.1578 0.907982 15.439 1.189L21.311 7.061C21.592 7.3422 21.7499 7.72345 21.75 8.121V21.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 8.25H15.75C15.3522 8.25 14.9706 8.09196 14.6893 7.81066C14.408 7.52936 14.25 7.14782 14.25 6.75V0.75"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.2 11.594C6.41657 11.8784 6.69852 12.1065 7.02195 12.2588C7.34539 12.4111 7.70078 12.4832 8.058 12.469C9.197 12.469 10.121 11.776 10.121 10.922C10.121 10.068 9.2 9.376 8.062 9.376C6.924 9.376 6 8.683 6 7.828C6 6.973 6.924 6.281 8.062 6.281C8.41926 6.26652 8.77473 6.3385 9.09821 6.49084C9.42169 6.64318 9.7036 6.87136 9.92 7.156"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06201 12.469V13.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06201 5.25V6.281"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15H18"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 19.5H18"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
