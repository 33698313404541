export const VFACountries = [
  {
    clientLegalEntity: 'AU',
    vfaCurrencies: ['AUD'],
  },
  {
    clientLegalEntity: 'HK',
    vfaCurrencies: ['HKD'],
  },
  {
    clientLegalEntity: 'US',
    vfaCurrencies: ['USD'],
  },
  {
    clientLegalEntity: 'GB',
    vfaCurrencies: ['GBP'],
  },
  {
    clientLegalEntity: 'LT',
    vfaCurrencies: ['EUR'],
  },
]
