import { css } from 'styled-components/macro'

import styledInterpolation from 'V2/helpers/styledInterpolation'
import { AtomsTypographyStyleProps } from 'V2/interfaces/components/Atoms.interface'

export const CommonTypographyStyles = css<AtomsTypographyStyleProps>`
  ${({ margin }): string => `margin: ${margin ? margin : 0};`}

  ${({ padding }): string => `padding: ${padding ? padding : 0};`}

  ${({ weight }): string => `font-weight: ${weight === 'regular' ? '400' : '600'};`}

  ${({ whiteSpace }): string => (whiteSpace ? `white-space: ${whiteSpace};` : '')}

  ${({ color }): string => (color ? `color: ${color};` : '')}

  ${({ capitalize }): string =>
    styledInterpolation(
      capitalize,
      `
    text-transform:capitalize;
    `
    )}

  ${({ uppercase }): string =>
    styledInterpolation(
      uppercase,
      `
    text-transform:uppercase;
    `
    )}

    ${({ underline }): string =>
    styledInterpolation(
      underline,
      `
    text-decoration: underline;
    `
    )}

    ${({ italic }): string =>
    styledInterpolation(
      italic,
      `
    font-style: italic;
    `
    )}

  ${({ ellipsis, ellipsisTextLimit }): string =>
    styledInterpolation(
      ellipsis,
      `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: ${ellipsisTextLimit ? `${ellipsisTextLimit}ch` : '100%'};
    `
    )}
`
