// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const WithdrawIcon: React.FC<{ stroke?: string }> = ({ stroke = 'white' }) => (
  <>
    <path
      d="M18.63 19.75H5.37C5.26237 19.7497 5.15606 19.7263 5.05829 19.6813C4.96052 19.6363 4.87358 19.5708 4.80338 19.4892C4.73318 19.4076 4.68136 19.3119 4.65144 19.2085C4.62153 19.1051 4.61421 18.9965 4.63 18.89L6.75 4.75H17.25L19.37 18.89C19.3858 18.9965 19.3785 19.1051 19.3486 19.2085C19.3186 19.3119 19.2668 19.4076 19.1966 19.4892C19.1264 19.5708 19.0395 19.6363 18.9417 19.6813C18.8439 19.7263 18.7376 19.7497 18.63 19.75V19.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 23.25H19.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 9.24999H11.5C11.1857 9.24589 10.88 9.3524 10.6363 9.5509C10.3926 9.7494 10.2264 10.0273 10.1668 10.3359C10.1072 10.6445 10.158 10.9642 10.3103 11.2392C10.4627 11.5142 10.7068 11.7268 11 11.84L13.06 12.66C13.3532 12.7732 13.5974 12.9858 13.7497 13.2608C13.902 13.5357 13.9528 13.8555 13.8932 14.1641C13.8336 14.4727 13.6675 14.7506 13.4237 14.9491C13.18 15.1476 12.8743 15.2541 12.56 15.25H10.56"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.25V7.75"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 16.75V15.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.75 8.25H1.5C1.30189 8.24741 1.11263 8.16756 0.972534 8.02747C0.832439 7.88737 0.75259 7.69811 0.75 7.5V1.5C0.75259 1.30189 0.832439 1.11263 0.972534 0.972534C1.11263 0.832439 1.30189 0.75259 1.5 0.75H22.5C22.6981 0.75259 22.8874 0.832439 23.0275 0.972534C23.1676 1.11263 23.2474 1.30189 23.25 1.5V7.5C23.2474 7.69811 23.1676 7.88737 23.0275 8.02747C22.8874 8.16756 22.6981 8.24741 22.5 8.25H21.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
