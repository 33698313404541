import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'

import { localForage, persistConfig } from 'constants/persistConfig'
import { ach } from './achPaymentMethod'
import { addMoney } from './addmoney'
import { creditCard } from './creditCard'
import { currencyCalculator } from './currencyCalculator'
import { currencyExchange } from './currencyExchange'
import { currentFormDataReducer as currentFormData } from './currentFormDataReducers'
import { editFlowReducer as editFlow } from './editFlowReducers'
import { formDataReducer as formData } from './formDataReducers'
import { lookUpReducer as lookUp } from './lookupReducer'
import { multiPayments } from './multiPayments'
import { riskAssessmentInfoReducer as riskAssessmntInfo } from './riskInfoReducers'
import { screenInfoReducer as screenInfo } from './screenInfoReducer'
import { simplifiedFlowReducer as simplifiedFlow } from './simplifiedReducers'
import { snackbar } from './snackbar'
import { transactionHistory } from './transactionHistory'
import { userClientDetails } from './userClientDetails'
import { withdraw } from './withdraw'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  currencyCalculator,
  snackbar,
  transactionHistory,
  multiPayments,
  creditCard,
  addMoney,
  withdraw,
  userClientDetails,
  currencyExchange,
  ach,
  lookUp,
  formData,
  riskAssessmntInfo,
  screenInfo,
  editFlow,
  simplifiedFlow,
  currentFormData,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducers = (state: any, action: any): any => {
  // TODO: if (action.type === AuthTypes.USER_LOGOUT) {
  if (action.type === 'USER_LOGOUT') {
    localForage.removeItem(`persist:${persistConfig.key}`)
    state = undefined
  }
  return rootReducer(state, action)
}

export { history, reducers }
