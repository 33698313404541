//used by onboarding lookup page

import { Reducer } from 'react'

import { Adddresses } from 'Onboarding/interfaces/Onboarding.interfaces'
import { BusinessType } from 'Onboarding/sections/businessdetails/Constant'
import { LookUpActions } from 'store/actionTypes'

export interface LookupInitialState {
  addresses?: Adddresses
  businessName?: string
  businessRegistrationNumber?: string
  businessType?: BusinessType
}

// const initialState: LookupInitialState[] = null

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lookUpReducer: Reducer<any, any> = (state = null, action) => {
  switch (action.type) {
    case LookUpActions.UPDATE_FORM_VALUES:
      return action.payload ? { ...state, ...action.payload } : null
    default:
      return state
  }
}
