/* eslint-disable @typescript-eslint/camelcase */
import { Reducer } from 'redux'

import { ACH } from 'interfaces/redux/Reducers.interface'
import { ACHTypes } from 'store/actionTypes'

const initialState = {
  savedBankDetails: [],
  selectedBank: {
    accountId: '',
    accountDetails: { accountNumber: '', routingValue: '', routingType: '', type: '' },
  },
  rollingLimit: { limit: 0, total_amount: 0, allowed_amount: 0 },
  loading: false,
  redirection: false,
}

const achPaymentMethodReducer: Reducer<ACH> = (state = initialState, action) => {
  switch (action.type) {
    case ACHTypes.ROLLING_LIMIT:
      return {
        ...state,
        rollingLimit: action.payload,
      }
    case ACHTypes.ADD_LIST_OF_LINKED_BANK_ACCOUNTS:
      return {
        ...state,
        savedBankDetails: action.payload,
      }
    case ACHTypes.ADD_NEW_BANK_ACCOUNT_PLAID:
      return {
        ...state,
        savedBankDetails: [...state.savedBankDetails, action.payload],
      }
    case ACHTypes.UPDATE_SELECTED_BANK_ACCOUNT:
      return {
        ...state,
        selectedBank: action.payload,
      }
    case ACHTypes.DELETE_SELECTED_BANK_ACCOUNT:
      return {
        ...state,
        savedBankDetails: action.payload,
      }
    case ACHTypes.RESET_SELECTED_BANK_ACCOUNT:
      return {
        ...state,
        selectedBank: { accountId: '' },
      }
    case ACHTypes.LOADING_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      }
    case ACHTypes.LOADING_DONE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}

export { achPaymentMethodReducer as ach }
