// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import styled from 'styled-components/macro'

import { pxToRem } from 'V2/helpers/pxToRem'
import { AtomsIcon } from 'V2/interfaces/components/Atoms.interface'

const CustomIcon: React.FC<AtomsIcon> = ({
  defaultSize = 24,
  fill = 'none',
  viewBox,
  className,
  width,
  height,
  children,
  id,
}) => {
  return (
    <Svg
      className={className}
      width={`${pxToRem(width ?? defaultSize)}`}
      height={`${pxToRem(height ?? defaultSize)}`}
      viewBox={viewBox ?? `0 0 ${width ?? defaultSize} ${height ?? defaultSize}`}
      fill={fill}
      id={id}
      textAnchor="middle"
    >
      {children}
    </Svg>
  )
}
export default CustomIcon
export const Svg = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``
