import {
  accountTypeDisabledSendCurrencies,
  disabledBizpaySendCurrencies,
  minimumAmountForCurrency,
} from 'V2/constants/bizpaySendCurrencies'
import { decimalFormat } from 'V2/helpers/decimalFormat'
import { decimalFormatByCurrency } from 'V2/helpers/decimalFormatByCurrency'

export const calculateTotalAmountWithFees = (
  amount: number,
  fee = 0,
  currency?: string
): string => {
  const amountWithFees = amount + fee
  return currency
    ? decimalFormatByCurrency(amountWithFees, currency)
    : decimalFormat(amountWithFees)
}
export const lessThanMinimumAmount = (currency: string, amount: number): boolean => {
  return minimumAmountForCurrency.some((item) => item.amount > amount && item.currency === currency)
}

export const disabledCurrency = (currency: string, cnyEnabled: boolean): boolean => {
  if (currency === 'CNY') {
    return disabledBizpaySendCurrencies.some((item) => item.value === currency) && !cnyEnabled
  }
  return disabledBizpaySendCurrencies.some((item) => item.value === currency)
}

export const accountTypeDisabledSendCurrency = (currency: string, accountType: string): boolean => {
  return accountTypeDisabledSendCurrencies[currency]?.accountType === accountType || false
}
