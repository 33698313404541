/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */

import axios from 'axios'

import { apiRoutes } from 'constants/apiRoutes'
import { catchErrorHandler } from 'V2/helpers/catchErrorHandler'
import { Logger } from './logger'

const FILE_NAMESPACE = 'beneficiary'

// fetches beneficiary validation schema
export const createBeneficiary = async (id: string, data: any): Promise<any> => {
  try {
    const response = await axios.post(apiRoutes.createBeneficiaries(id), data)
    return response
  } catch (err) {
    return catchErrorHandler(err)
  }
}

export const getUsers = async (id: string, isActive = true): Promise<any> => {
  try {
    const payload = {
      start: 0,
      length: 500,
      client: [`${id ?? ''}`],
      fullName: '',
      email: '',
      status: isActive,
    }
    return axios.post(apiRoutes.getlistOfUsers, payload)
  } catch (err) {
    return catchErrorHandler(err)
  }
}

export const updateBeneficiaryPayout = async (
  clientId: string,
  beneficaryId: string,
  payoutId: string,
  data: any
): Promise<any> => {
  try {
    const response = await axios.post(
      apiRoutes.updateBeneficiaryPayout(clientId, beneficaryId, payoutId),
      data
    )
    Logger(FILE_NAMESPACE).debug('updateBeneficiaryPayout', response)
    return response
  } catch (err) {
    return catchErrorHandler(err)
  }
}

export const updateBeneficiary = async (
  clientId: string,
  beneficaryId: string,
  data: any
): Promise<any> => {
  try {
    const response = await axios.post(apiRoutes.updateBeneficiary(clientId, beneficaryId), data)
    Logger(FILE_NAMESPACE).debug('updateBeneficiary', response)
    return response
  } catch (err) {
    return catchErrorHandler(err)
  }
}

export const deleteBeneficiary = async (clientId: string, beneficiaryId: string): Promise<any> => {
  const response = await axios.delete(apiRoutes.deleteBeneficiary(clientId, beneficiaryId))
  return response
}

export const deleteBankAccountDetails = async (
  clientId: string,
  bankId: string,
  autoSweepBankParams: any
): Promise<any> => {
  try {
    await axios.post(apiRoutes.clientUpdate, autoSweepBankParams)
    const response = await axios.put(apiRoutes.EditDeleteBank(clientId, bankId), {
      isActive: false,
    })
    return response
  } catch (err) {
    return catchErrorHandler(err)
  }
}

export const getBankAccountDetails = async (clientId: string): Promise<any> => {
  try {
    const response = await axios.get(apiRoutes.addAndGetBankDetails(clientId))
    Logger(FILE_NAMESPACE).silly('AutoSweepBankAccountSuccess', response.data)
    return response?.data
  } catch (err) {
    Logger(FILE_NAMESPACE).error('AutoSweepBankAccountFailed', err)
    return catchErrorHandler(err)
  }
}
