// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import { AtomsSpinner } from 'V2/interfaces/components/Atoms.interface'
import { SpinnerContainer, StyledSpinner } from './Styles'

export const Spinner: React.FC<AtomsSpinner> = ({
  show,
  color,
  size = '48px',
  borderWidth = '6px',
}) => {
  return (
    <SpinnerContainer show={show}>
      <StyledSpinner size={size} borderWidth={borderWidth} color={color} />
    </SpinnerContainer>
  )
}
