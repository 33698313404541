import styled from 'styled-components/macro'

import { pxToRem } from 'V2/helpers/pxToRem'

export const Card = styled.div`
  height: auto;
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  border-radius: ${pxToRem(4)};
  border: ${pxToRem(1)} solid ${({ theme }): string => theme.revamp.primary.grey.light};
`
