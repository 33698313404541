// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import { AtomButton } from 'V2/interfaces/components/Atoms.interface'
import CustomIcon from '../Icon/CustomIcon'
import { ButtonBase, IconWrapper, Title } from './Styles'

export const Button: React.FC<AtomButton> = ({
  title,
  variant = 'primary',
  size = 'large',
  disabled,
  iconPath,
  iconPosition,
  iconWidth,
  iconHeight,
  iconViewBox,
  ...props
}): JSX.Element => {
  return (
    <ButtonBase size={size} variant={variant} disabled={disabled} {...props}>
      {iconPath && (
        <IconWrapper iconPosition={iconPosition}>
          <CustomIcon
            children={iconPath}
            width={iconWidth}
            height={iconHeight}
            viewBox={iconViewBox}
          />
        </IconWrapper>
      )}
      <Title>{!!title && title}</Title>
    </ButtonBase>
  )
}
