import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import { apiRoutes } from 'constants/apiRoutes'
import { persistConfig } from 'constants/persistConfig'
import { history, reducers } from './reducers/reducer'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const storeConfig = () => {
  const persistedReducer = persistReducer(persistConfig, reducers)
  const customMiddlewares = applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    thunk.withExtraArgument(apiRoutes)
    // ... other middlewares ...
  )
  const customEnhancer =
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools(customMiddlewares)
      : compose(customMiddlewares)

  const store = createStore(persistedReducer, customEnhancer)

  const persistor = persistStore(store)
  return { store, persistor }
}

export { history, storeConfig }
