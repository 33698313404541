import styled from 'styled-components/macro'

import { great } from 'V2/helpers/mediaquery'
import { Svg } from '../Icon/CustomIcon'
import { Typography } from '../Typography/Typography'

export const BoxWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }): string => theme.revamp.primary.blue.lightest};
  padding: 16px;
  border: 0.5px solid ${({ theme }): string => theme.revamp.primary.grey.light};
  border-radius: 4px;
  @media ${great.greaterThanMobile} {
    padding: 24px;
  }
`

export const TextWrapper = styled.div`
  margin-left: 8px;
`

export const IconWrapper = styled.div`
  ${Svg} {
    width: 24px !important;
    height: 24px !important;
  }
`

export const Text = styled(Typography)`
  display: inline;
`
