import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components/macro'

import { less } from 'V2/helpers/mediaquery'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  color: ${({ theme }): string => theme.revamp.primary.grey.root};
  overflow-x: auto;
`
export const tabIsActive = css`
  color: ${({ theme }): string => theme.revamp.staticColors.black};
  border-bottom: 4px solid ${({ theme }): string => theme.revamp.secondary.tangerine.root};
`
export const tabIsNotActive = css`
  border-bottom: 1px solid ${({ theme }): string => theme.revamp.primary.grey.root};
`

export const Tab = styled.div<{ isActive: boolean; width: number }>`
  ${({ isActive }): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    isActive ? tabIsActive : tabIsNotActive}
  display: flex;
  justify-content: center;
  flex: 0 0 ${({ width }): string => `${width}%`};
  padding-top: 24px;
  padding-bottom: 20px;
  cursor: pointer;
  @media ${less.lessThanMobile} {
    padding-right: 16px;
    padding-left: 16px;
    text-align: center;
  }
`
