/* eslint-disable react/jsx-no-duplicate-props */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const LinkBankAccountIcon: React.FC<{ stroke?: string; fill?: string }> = () => (
  <>
    <path
      d="M17 45C17 52.4261 19.95 59.548 25.201 64.799C30.452 70.05 37.5739 73 45 73C52.4261 73 59.548 70.05 64.799 64.799C70.05 59.548 73 52.4261 73 45C73 37.5739 70.05 30.452 64.799 25.201C59.548 19.95 52.4261 17 45 17C37.5739 17 30.452 19.95 25.201 25.201C19.95 30.452 17 37.5739 17 45Z"
      fill="#FFF9C7"
    />
    <path
      d="M26.5935 96.2693C26.5935 96.5673 27.3901 96.8624 28.9378 97.1377C30.4856 97.4131 32.7541 97.6632 35.614 97.874C38.4738 98.0847 41.8689 98.2518 45.6055 98.3659C49.342 98.4799 53.3469 98.5386 57.3913 98.5386C61.4357 98.5386 65.4405 98.4799 69.1771 98.3659C72.9136 98.2518 76.3087 98.0847 79.1686 97.874C82.0284 97.6632 84.297 97.4131 85.8447 97.1377C87.3924 96.8624 88.189 96.5673 88.189 96.2693C88.189 95.9713 87.3924 95.6762 85.8447 95.4009C84.297 95.1256 82.0284 94.8754 79.1686 94.6647C76.3087 94.4539 72.9136 94.2868 69.1771 94.1727C65.4405 94.0587 61.4357 94 57.3913 94C53.3469 94 49.342 94.0587 45.6055 94.1727C41.8689 94.2868 38.4738 94.4539 35.614 94.6647C32.7541 94.8754 30.4856 95.1256 28.9378 95.4009C27.3901 95.6762 26.5935 95.9713 26.5935 96.2693Z"
      fill="#EEF6FF"
    />
    <path
      d="M28.9336 55.1538H83.9054V83.2421H28.9336V55.1538Z"
      fill="#EEF6FF"
      stroke="#004288"
      strokeWidth="1.08062"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M88.8374 88.86H24V84.6471C23.9995 84.4623 24.0356 84.2793 24.1062 84.1085C24.1768 83.9377 24.2805 83.7825 24.4114 83.6516C24.5423 83.5208 24.6978 83.417 24.869 83.3461C25.0402 83.2752 25.2237 83.2386 25.4091 83.2385H87.4283C87.6134 83.2384 87.7967 83.2746 87.9677 83.3452C88.1387 83.4157 88.2941 83.5191 88.425 83.6495C88.5558 83.7799 88.6596 83.9347 88.7304 84.1051C88.8012 84.2755 88.8375 84.4581 88.8374 84.6425V88.86Z"
      fill="white"
    />
    <path
      d="M88.8374 88.86H24V84.6471C23.9995 84.4623 24.0356 84.2793 24.1062 84.1085C24.1768 83.9377 24.2805 83.7825 24.4114 83.6516C24.5423 83.5208 24.6978 83.417 24.869 83.3461C25.0402 83.2752 25.2237 83.2386 25.4091 83.2385H87.4283C87.6134 83.2384 87.7967 83.2746 87.9677 83.3452C88.1387 83.4157 88.2941 83.5191 88.425 83.6495C88.5558 83.7799 88.6596 83.9347 88.7304 84.1051C88.8012 84.2755 88.8375 84.4581 88.8374 84.6425V88.86Z"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.057 80.4331H50.7804C50.4128 80.4424 50.0634 80.5945 49.8066 80.8568C49.5499 81.1192 49.4063 81.4711 49.4062 81.8376C49.4063 82.204 49.5499 82.5559 49.8066 82.8183C50.0634 83.0807 50.4128 83.2327 50.7804 83.242H62.057C62.4246 83.2327 62.7741 83.0807 63.0308 82.8183C63.2875 82.5559 63.4312 82.204 63.4312 81.8376C63.4312 81.4711 63.2875 81.1192 63.0308 80.8568C62.7741 80.5945 62.4246 80.4424 62.057 80.4331Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.057 55.1538H50.7804C50.4128 55.1631 50.0634 55.3152 49.8066 55.5775C49.5499 55.8399 49.4062 56.1918 49.4062 56.5583C49.4062 56.9247 49.5499 57.2766 49.8066 57.539C50.0634 57.8014 50.4128 57.9534 50.7804 57.9627H62.057C62.4246 57.9534 62.7741 57.8014 63.0308 57.539C63.2875 57.2766 63.4312 56.9247 63.4312 56.5583C63.4312 56.1918 63.2875 55.8399 63.0308 55.5775C62.7741 55.3152 62.4246 55.1631 62.057 55.1538Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M52.1908 57.9626H60.6482V80.4331H52.1908V57.9626Z" fill="white" />
    <path
      d="M52.1908 57.9626H60.6482V80.4331H52.1908V57.9626Z"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.7994 80.4333H27.5201C27.1606 80.4537 26.8225 80.6104 26.5752 80.8712C26.328 81.132 26.1902 81.4772 26.1902 81.836C26.1902 82.1948 26.328 82.5399 26.5752 82.8008C26.8225 83.0616 27.1606 83.2182 27.5201 83.2386H38.7967C39.1643 83.2293 39.5137 83.0772 39.7704 82.8149C40.0272 82.5525 40.1708 82.2006 40.1708 81.8342C40.1708 81.4677 40.0272 81.1158 39.7704 80.8534C39.5137 80.5911 39.1643 80.439 38.7967 80.4297L38.7994 80.4333Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.8 55.1538H27.5207C27.153 55.1631 26.8036 55.3152 26.5469 55.5775C26.2902 55.8399 26.1465 56.1918 26.1465 56.5583C26.1465 56.9247 26.2902 57.2766 26.5469 57.539C26.8036 57.8014 27.153 57.9534 27.5207 57.9627H38.7972C39.1649 57.9534 39.5143 57.8014 39.771 57.539C40.0277 57.2766 40.1714 56.9247 40.1714 56.5583C40.1714 56.1918 40.0277 55.8399 39.771 55.5775C39.5143 55.3152 39.1649 55.1631 38.7972 55.1538H38.8Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M28.9336 57.9626H37.391V80.4331H28.9336V57.9626Z" fill="white" />
    <path
      d="M74.0376 80.4331H85.3142C85.6818 80.4424 86.0313 80.5945 86.288 80.8568C86.5447 81.1192 86.6884 81.4711 86.6884 81.8376C86.6884 82.204 86.5447 82.5559 86.288 82.8183C86.0313 83.0807 85.6818 83.2327 85.3142 83.242H74.0376C73.67 83.2327 73.3206 83.0807 73.0638 82.8183C72.8071 82.5559 72.6635 82.204 72.6635 81.8376C72.6635 81.4711 72.8071 81.1192 73.0638 80.8568C73.3206 80.5945 73.67 80.4424 74.0376 80.4331Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.08062"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.0376 55.1538H85.3142C85.6818 55.1631 86.0313 55.3152 86.288 55.5775C86.5447 55.8399 86.6884 56.1918 86.6884 56.5583C86.6884 56.9247 86.5447 57.2766 86.288 57.539C86.0313 57.8014 85.6818 57.9534 85.3142 57.9627H74.0376C73.67 57.9534 73.3206 57.8014 73.0638 57.539C72.8071 57.2766 72.6635 56.9247 72.6635 56.5583C72.6635 56.1918 72.8071 55.8399 73.0638 55.5775C73.3206 55.3152 73.67 55.1631 74.0376 55.1538Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M75.4476 57.9626H83.905V80.4331H75.4476V57.9626Z" fill="white" />
    <path
      d="M75.4476 57.9626H83.905V80.4331H75.4476V57.9626Z"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9348 50.2385C27.6694 50.6595 27.5259 51.1454 27.5204 51.6425V55.1539H85.314V51.6425C85.3106 51.1459 85.169 50.6599 84.9051 50.2385H27.9348Z"
      fill="white"
    />
    <path
      d="M27.9348 50.2385C27.6694 50.6595 27.5259 51.1454 27.5204 51.6425V55.1539H85.314V51.6425C85.3106 51.1459 85.169 50.6599 84.9051 50.2385H27.9348Z"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.8113 50.2385L54.7727 37.3737C55.2526 37.0307 55.8282 36.8463 56.4187 36.8463C57.0092 36.8463 57.5848 37.0307 58.0646 37.3737L76.0251 50.2385H84.9052C84.717 49.9207 84.4652 49.6448 84.1655 49.428L58.0455 31.0216C57.5698 30.6864 57.0014 30.5063 56.4187 30.5063C55.836 30.5063 55.2676 30.6864 54.7919 31.0216L28.6746 49.428C28.3749 49.6448 28.1231 49.9207 27.9349 50.2385H36.8113Z"
      fill="white"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.7729 37.3736L36.8115 50.2384H76.0244L58.0648 37.3736C57.585 37.0306 57.0094 36.8462 56.4189 36.8462C55.8284 36.8462 55.2527 37.0306 54.7729 37.3736Z"
      fill="#EEF6FF"
    />
    <path
      d="M54.7729 37.3736L36.8115 50.2384H76.0244L58.0648 37.3736C57.585 37.0306 57.0094 36.8462 56.4189 36.8462C55.8284 36.8462 55.2527 37.0306 54.7729 37.3736Z"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.9336 57.9626H37.391V80.4331H28.9336V57.9626Z"
      stroke="#004288"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M70 87.5C70 92.1413 71.8437 96.5925 75.1256 99.8744C78.4075 103.156 82.8587 105 87.5 105C92.1413 105 96.5925 103.156 99.8744 99.8744C103.156 96.5925 105 92.1413 105 87.5C105 82.8587 103.156 78.4075 99.8744 75.1256C96.5925 71.8437 92.1413 70 87.5 70C82.8587 70 78.4075 71.8437 75.1256 75.1256C71.8437 78.4075 70 82.8587 70 87.5Z"
      fill="#326499"
    />
    <path
      d="M65 82.5C65 87.1413 66.8437 91.5925 70.1256 94.8744C73.4075 98.1563 77.8587 100 82.5 100C87.1413 100 91.5925 98.1563 94.8744 94.8744C98.1562 91.5925 100 87.1413 100 82.5C100 77.8587 98.1562 73.4075 94.8744 70.1256C91.5925 66.8437 87.1413 65 82.5 65C77.8587 65 73.4075 66.8437 70.1256 70.1256C66.8437 73.4075 65 77.8587 65 82.5Z"
      fill="#EEF6FF"
      stroke="#004288"
      strokeWidth="1.5"
    />
    <path
      opacity="0.2"
      d="M93 80.8076C93 80.4483 92.8573 80.1038 92.6033 79.8497C92.3492 79.5957 92.0047 79.453 91.6454 79.453H86.2265C86.0468 79.453 85.8744 79.3816 85.7474 79.2545C85.6203 79.1275 85.5489 78.9551 85.5489 78.7754V73.3546C85.5489 73.1767 85.5139 73.0005 85.4458 72.8362C85.3777 72.6719 85.2779 72.5225 85.1522 72.3967C85.0264 72.271 84.877 72.1712 84.7127 72.1031C84.5483 72.035 84.3722 72 84.1943 72H80.8076C80.4483 72 80.1038 72.1427 79.8497 72.3967C79.5957 72.6508 79.453 72.9953 79.453 73.3546V78.7735C79.453 78.9532 79.3816 79.1256 79.2545 79.2526C79.1275 79.3797 78.9551 79.4511 78.7754 79.4511H73.3565C73.1783 79.4509 73.0018 79.4858 72.8371 79.5539C72.6724 79.6219 72.5228 79.7218 72.3967 79.8478C72.2707 79.9739 72.1708 80.1235 72.1027 80.2882C72.0347 80.4529 71.9998 80.6294 72 80.8076V84.1943C72 84.3722 72.035 84.5483 72.1031 84.7127C72.1712 84.877 72.271 85.0264 72.3967 85.1521C72.5225 85.2779 72.6719 85.3777 72.8362 85.4458C73.0005 85.5139 73.1767 85.5489 73.3546 85.5489H78.7735C78.9532 85.5489 79.1256 85.6203 79.2526 85.7474C79.3797 85.8744 79.4511 86.0468 79.4511 86.2265V91.6454C79.4511 92.0047 79.5938 92.3492 79.8478 92.6033C80.1019 92.8573 80.4464 93 80.8057 93H84.1924C84.5517 93 84.8962 92.8573 85.1503 92.6033C85.4043 92.3492 85.547 92.0047 85.547 91.6454V86.2246C85.547 86.0449 85.6184 85.8725 85.7455 85.7455C85.8725 85.6184 86.0449 85.547 86.2246 85.547H91.6435C92.0028 85.547 92.3473 85.4043 92.6014 85.1503C92.8554 84.8962 92.9981 84.5517 92.9981 84.1924L93 80.8076Z"
      fill="#326499"
    />
  </>
)
