import { Reducer } from 'react'

import { FlowType } from 'Onboarding/pages/corridors/au/AuForm'
import { FormdataActions } from 'store/actionTypes'

export interface FormdataIntialState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contactDetails: any
  businessRegistrationNumber?: string
  _flow: FlowType
  _registeredCountry?: string
  region?: string
  editFlow?: {
    isEditFlow?: boolean
  }
  currentLocation?: {
    currentStepName?: string
    currentScreen?: number
  }
  businessDetails?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  kycMode?: string
  binNumber?: string
}
// The structure needs to be refactored
const initialFormData = {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formDataReducer: Reducer<any, any> = (state = initialFormData, action) => {
  switch (action.type) {
    case FormdataActions.UPDATE_FORMDATA_VALUES:
      return {
        ...state,
        ...action.payload,
      }
    case FormdataActions.UPDATE_KYC_MODE:
      return {
        ...state,
        kycMode: action.payload,
      }
    case FormdataActions.RESET_FORM_DATA:
      return { ...state, businessDetails: { businessName: action.payload }, ...initialFormData }
    default:
      return state
  }
}
