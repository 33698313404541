export const bizpaySendCountries = [
  // Bizpay
  { value: 'AR', label: 'Argentina', contactCode: '+54' },
  { value: 'AU', label: 'Australia', contactCode: '+61' },
  { value: 'BR', label: 'Brazil', contactCode: '+55' },
  { value: 'CA', label: 'Canada', contactCode: '+1' },
  { value: 'CL', label: 'Chile', contactCode: '+56' },
  { value: 'CO', label: 'Colombia', contactCode: '+57' },
  { value: 'HK', label: 'Hong Kong', contactCode: '+852' },
  { value: 'IN', label: 'India', contactCode: '+91' },
  { value: 'MY', label: 'Malaysia', contactCode: '+60' },
  { value: 'MX', label: 'Mexico', contactCode: '+52' },
  { value: 'NP', label: 'Nepal', contactCode: '+977' },
  { value: 'PK', label: 'Pakistan', contactCode: '+92' },
  { value: 'PE', label: 'Peru', contactCode: '+51' },
  { value: 'PH', label: 'Philippines', contactCode: '+63' },
  { value: 'SG', label: 'Singapore', contactCode: '+65' },
  { value: 'KR', label: 'South Korea', contactCode: '+82' },
  { value: 'LK', label: 'Sri Lanka', contactCode: '+94' },
  { value: 'TH', label: 'Thailand', contactCode: '+66' },
  { value: 'GB', label: 'United Kingdom', contactCode: '+44' },
  { value: 'US', label: 'United States of America', contactCode: '+1' },
  { value: 'UY', label: 'Uruguay', contactCode: '+598' },
  // Send
  { value: 'ID', label: 'Indonesia', contactCode: '+62' },
  { value: 'VN', label: 'Vietnam', contactCode: '+84' },
  { value: 'PL', label: 'Poland', contactCode: '+48' },
  { value: 'JP', label: 'Japan', contactCode: '+81' },
  { value: 'SE', label: 'Sweden', contactCode: '+46' },
  { value: 'CZ', label: 'Czechia', contactCode: '+420' },
  { value: 'DK', label: 'Denmark', contactCode: '+45' },
  { value: 'RO', label: 'Romania', contactCode: '+40' },
  { value: 'HU', label: 'Hungary', contactCode: '+36' },
  { value: 'CH', label: 'Switzerland', contactCode: '+41' },
  { value: 'TR', label: 'Turkey', contactCode: '+90' },
  { value: 'AE', label: 'United Arab Emirates', contactCode: '+971' },
  { value: 'NO', label: 'Norway', contactCode: '+47' },
  { value: 'NZ', label: 'New Zealand', contactCode: '+64' },
  { value: 'BG', label: 'Bulgaria', contactCode: '+359' },
  { value: 'HR', label: 'Croatia', contactCode: '+385' },
  { value: 'ZA', label: 'South Africa', contactCode: '+27' },
  { value: 'TW', label: 'Taiwan', contactCode: '+886' },
  //Non-Supported Local
  { value: 'BD', label: 'Bangladesh', contactCode: '+880' },
  { value: 'PG', label: 'Papua New Guinea', contactCode: '+675' },
  { value: 'IL', label: 'Israel', contactCode: '+972' },
  { value: 'IS', label: 'Iceland', contactCode: '+354' },
  { value: 'KE', label: 'Kenya', contactCode: '+254' },
  { value: 'KH', label: 'Cambodia', contactCode: '+855' },
  { value: 'KW', label: 'Kuwait', contactCode: '+965' },
  { value: 'MO', label: 'Macao', contactCode: '+853' },
  { value: 'NG', label: 'Nigeria', contactCode: '+234' },
  { value: 'OM', label: 'Oman', contactCode: '+968' },
  { value: 'BH', label: 'Bahrain', contactCode: '+973' },
  { value: 'QA', label: 'Qatar', contactCode: '+974' },
  { value: 'SA', label: 'Saudi Arabia', contactCode: '+966' },
  { value: 'SC', label: 'Seychelles', contactCode: '+248' },
  { value: 'LI', label: 'Liechtenstein', contactCode: '+423' },
  { value: 'CN', label: 'China', contactCode: '+86' },
  { value: 'DE', label: 'Germany', contactCode: '+49' },
  { value: 'IT', label: 'Italy', contactCode: '+39' },
  { value: 'IE', label: 'Ireland', contactCode: '+353' },
  //Non-Supported Local 2
  { value: 'AT', label: 'Austria', contactCode: '+43' },
  { value: 'BE', label: 'Belgium', contactCode: '+32' },
  { value: 'CY', label: 'Cyprus', contactCode: '+357' },
  { value: 'ES', label: 'Spain', contactCode: '+34' },
  { value: 'NL', label: 'Netherlands', contactCode: '+31' },
  { value: 'EE', label: 'Estonia', contactCode: '+372' },
  { value: 'FI', label: 'Finland', contactCode: '+358' },
  { value: 'GR', label: 'Greece', contactCode: '+30' },
  { value: 'LT', label: 'Lithuania', contactCode: '+370' },
  { value: 'LU', label: 'Luxembourg', contactCode: '+352' },
  { value: 'LV', label: 'Latvia', contactCode: '+371' },
  { value: 'MT', label: 'Malta', contactCode: '+356' },
  { value: 'PT', label: 'Portugal', contactCode: '+351' },
  { value: 'SI', label: 'Slovenia', contactCode: '+386' },
  { value: 'SK', label: 'Slovakia', contactCode: '+421' },
  { value: 'FR', label: 'France', contactCode: '+33' },

  { value: 'AL', label: 'Albania', contactCode: '+355' },
  { value: 'AD', label: 'Andorra', contactCode: '+376' },
  { value: 'AO', label: 'Angola', contactCode: '+244' },
  { value: 'AI', label: 'Anguilla', contactCode: '+1264' },
  { value: 'AG', label: 'Antigua and Barbuda', contactCode: '+1268' },
  { value: 'AM', label: 'Armenia', contactCode: '+374' },
  { value: 'AW', label: 'Aruba', contactCode: '+297' },
  { value: 'AZ', label: 'Azerbaijan', contactCode: '+994' },
  { value: 'BJ', label: 'Benin', contactCode: '+229' },
  { value: 'BM', label: 'Bermuda', contactCode: '+1441' },
  { value: 'BT', label: 'Bhutan', contactCode: '+975' },
  { value: 'BN', label: 'Brunei', contactCode: '+673' },
  { value: 'BF', label: 'Burkina Faso', contactCode: '+226' },
  { value: 'CV', label: 'Cabo Verde', contactCode: '+238' },
  { value: 'CM', label: 'Cameroon', contactCode: '+237' },
  { value: 'KY', label: 'Cayman Islands', contactCode: '+1345' },
  { value: 'CF', label: 'Central African Republic', contactCode: '+236' },
  { value: 'CR', label: 'Costa Rica', contactCode: '+506' },
  { value: 'CW', label: 'Curaçao', contactCode: '+599 9' },
  { value: 'CK', label: 'Cook Islands', contactCode: '+682' },
  { value: 'DJ', label: 'Djibouti', contactCode: '+253' },
  { value: 'DM', label: 'Dominica', contactCode: '+1767' },
  { value: 'DO', label: 'Dominican Republic', contactCode: '+1849' },
  { value: 'EG', label: 'Egypt', contactCode: '+20' },
  { value: 'SV', label: 'El Salvador', contactCode: '+503' },
  { value: 'SZ', label: 'Eswatini', contactCode: '+268' },
  { value: 'ET', label: 'Ethiopia', contactCode: '+251' },
  { value: 'FK', label: 'Falkland Islands', contactCode: '+500' },
  { value: 'FJ', label: 'Fiji', contactCode: '+679' },
  { value: 'GF', label: 'French Guiana', contactCode: '+594' },
  { value: 'PF', label: 'French Polynesia', contactCode: '+689' },
  { value: 'GA', label: 'Gabon', contactCode: '+241' },
  { value: 'GM', label: 'Gambia', contactCode: '+220' },
  { value: 'GE', label: 'Georgia', contactCode: '+995' },
  { value: 'GH', label: 'Ghana', contactCode: '+233' },
  { value: 'GI', label: 'Gibraltar', contactCode: '+350' },
  { value: 'GL', label: 'Greenland', contactCode: '+299' },
  { value: 'GD', label: 'Grenada', contactCode: '+1473' },
  { value: 'GP', label: 'Guadeloupe', contactCode: '+590' },
  { value: 'GG', label: 'Guernsey', contactCode: '+44 1481' },
  { value: 'HN', label: 'Honduras', contactCode: '+504' },
  { value: 'IM', label: 'Isle of Man', contactCode: '+44 1624' },
  { value: 'JE', label: 'Jersey', contactCode: '+44  1534' },
  { value: 'JO', label: 'Jordan', contactCode: '+962' },
  { value: 'KZ', label: 'Kazakhstan', contactCode: '+997' },
  { value: 'KI', label: 'Kiribati', contactCode: '+686' },
  { value: 'KG', label: 'Kyrgyzstan', contactCode: '+996' },
  { value: 'LA', label: 'Laos', contactCode: '+856' },
  { value: 'LS', label: 'Lesotho', contactCode: '+226' },
  { value: 'MG', label: 'Madagascar', contactCode: '+261' },
  { value: 'MW', label: 'Malawi', contactCode: '+265' },
  { value: 'MV', label: 'Maldives', contactCode: '+960' },
  { value: 'MQ', label: 'Martinique', contactCode: '+596' },
  { value: 'MR', label: 'Mauritania', contactCode: '+222' },
  { value: 'YT', label: 'Mayotte', contactCode: '+262' },
  { value: 'MC', label: 'Monaco', contactCode: '+377' },
  { value: 'ME', label: 'Montenegro', contactCode: '+382' },
  { value: 'MS', label: 'Montserrat', contactCode: '+1664' },
  { value: 'MA', label: 'Morocco', contactCode: '+212' },
  { value: 'MZ', label: 'Mozambique', contactCode: '+258' },
  { value: 'NA', label: 'Namibia', contactCode: '+264' },
  { value: 'NR', label: 'Nauru', contactCode: '+674' },
  { value: 'NC', label: 'New Caledonia', contactCode: '+687' },
  { value: 'MK', label: 'North Macedonia', contactCode: '+389' },
  { value: 'PY', label: 'Paraguay', contactCode: '+595' },
  { value: 'RE', label: 'Réunion', contactCode: '+262' },
  { value: 'KN', label: 'Saint Kitts and Nevis', contactCode: '+1869' },
  { value: 'LC', label: 'Saint Lucia', contactCode: '+1758' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines', contactCode: '+1784' },
  { value: 'WS', label: 'Samoa', contactCode: '+685' },
  { value: 'SM', label: 'San Marino', contactCode: '+378' },
  { value: 'ST', label: 'Sao Tome and Principe', contactCode: '+239' },
  { value: 'SN', label: 'Senegal', contactCode: '+221' },
  { value: 'SL', label: 'Sierra Leone', contactCode: '+232' },
  { value: 'SB', label: 'Solomon Islands', contactCode: '+677' },
  { value: 'SR', label: 'Suriname', contactCode: '+597' },
  { value: 'TJ', label: 'Tajikistan', contactCode: '+992' },
  { value: 'TZ', label: 'Tanzania', contactCode: '+255' },
  { value: 'TG', label: 'Togo', contactCode: '+228' },
  { value: 'TO', label: 'Tonga', contactCode: '+676' },
  { value: 'TN', label: 'Tunisia', contactCode: '+216' },
  { value: 'TM', label: 'Turkmenistan', contactCode: '+993' },
  { value: 'TV', label: 'Tuvalu', contactCode: '+688' },
  { value: 'UG', label: 'Uganda', contactCode: '+256' },
  { value: 'UZ', label: 'Uzbekistan', contactCode: '+998' },
  { value: 'VA', label: 'Vatican City', contactCode: '+379' },
  { value: 'WF', label: 'Wallis and Futuna', contactCode: '+681' },
  { value: 'ZM', label: 'Zambia', contactCode: '+260' },
  { value: 'CI', label: 'Ivory Coast', contactCode: '+225' },

  // TODO: uncomment once validate payout issues is resolved

  //   { value: 'FM', label: 'Micronesia', contactCode: '+691' },
  //   { value: 'MP', label: 'Northern Mariana Islands', contactCode: '+1670' },
  //   { value: 'PW', label: 'Palau', contactCode: '+680' },
  //   { value: 'PR', label: 'Puerto Rico', contactCode: '+1787' },
  //   { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha', contactCode: '+290' },
  //   { value: 'PM', label: 'Saint Pierre and Miquelon', contactCode: '+508' },
  //   { value: 'SX', label: 'Sint Maarten', contactCode: '+1721' },
  //   { value: 'VI', label: 'United States Virgin Islands', contactCode: '+1340' },
  //   { value: 'XK', label: 'Kosovo', contactCode: '+383' },
]

export const disabledCountriesForClientLegalEntities: { [key: string]: string[] } = {
  MY: ['IL'],
}

export const processingTimes: {
  [key: string]: string[]
} = {
  3: [
    'China',
    'Hong Kong',
    'India',
    'Nepal',
    'Philippines',
    'Singapore',
    'South Korea',
    'Sri Lanka',
    'Thailand',
    'Vietnam',
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Monaco',
    'Netherlands',
    'Norway',
    'Poland',
    'Portugal',
    'Romania',
    'San Marino',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'United Kingdom',
    'Turkey',
  ],
  4: [
    'United States of America',
    'Canada',
    'Brazil',
    'Bangladesh',
    'Indonesia',
    'Malaysia',
    'Pakistan',
    'Qatar',
    'Saudi Arabia',
    'Taiwan',
    'United Arab Emirates',
    'Switzerland',
    'Australia',
    'New Zealand',
    'Algeria',
    'Egypt',
    'Ghana',
    'Kenya',
    'Morocco',
    'Nigeria',
    'South Africa',
    'Tanzania',
    'Tunisia',
    'Uganda',
  ],
}

export const invalidCountriesForJapanType1 = [
  'AR',
  'CL',
  'CO',
  'MX',
  'PE',
  'UY',
  'JP',
  'PG',
  'IL',
  'IS',
  'KH',
  'KW',
  'MO',
  'OM',
  'BH',
  'SC',
  'LI',
  'AL',
  'AD',
  'AO',
  'AI',
  'AG',
  'AM',
  'AW',
  'AZ',
  'BJ',
  'BM',
  'BT',
  'BN',
  'BF',
  'CV',
  'CM',
  'KY',
  'CF',
  'CR',
  'CW',
  'CK',
  'DJ',
  'DM',
  'DO',
  'SV',
  'SZ',
  'ET',
  'FK',
  'FJ',
  'GF',
  'PF',
  'GA',
  'GM',
  'GE',
  'GI',
  'GL',
  'GD',
  'GP',
  'GG',
  'HN',
  'IM',
  'JE',
  'JO',
  'KZ',
  'KI',
  'KG',
  'LA',
  'LS',
  'MG',
  'MW',
  'MV',
  'MQ',
  'MR',
  'YT',
  'ME',
  'MS',
  'MZ',
  'NA',
  'NR',
  'NC',
  'MK',
  'PY',
  'RE',
  'KN',
  'LC',
  'VC',
  'WS',
  'ST',
  'SN',
  'SL',
  'SB',
  'SR',
  'TJ',
  'TG',
  'TO',
  'TM',
  'TV',
  'UZ',
  'VA',
  'WF',
  'ZM',
  'CI',
]
