import styled from 'styled-components/macro'

import theme from 'styles/theme'
import { pxToRem } from 'V2/helpers/pxToRem'

export const Container = styled.div<{ isLastStep: boolean; backgroundColorNotRequired?: boolean }>`
  display: flex;
  align-items: center;
  height: ${pxToRem(3)};
  width: 100%;
  background-color: ${({ isLastStep, backgroundColorNotRequired }): string =>
    isLastStep || backgroundColorNotRequired ? 'none' : theme.revamp.primary.grey.light};
  border-bottom: ${pxToRem(0.5)} solid
    ${({ backgroundColorNotRequired }): string =>
      backgroundColorNotRequired ? theme.revamp.primary.grey.light : 'transparent'};
`

export const ProgressIndicator = styled.div<{
  progressBarWidth: number
  progressBarColor: string
}>`
  height: 100%;
  width: ${({ progressBarWidth }): string => `${progressBarWidth}%`};
  background-color: ${({ progressBarColor }): string => progressBarColor};
`

export const Dot = styled.span<{ fillColor: string }>`
  height: ${pxToRem(8)};
  width: ${pxToRem(8)};
  border-radius: 50%;
  background-color: ${({ fillColor }): string => fillColor};
`
