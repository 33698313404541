import { ThunkWithApi } from 'react-redux'
import axios, { AxiosError } from 'axios'
import { CallHistoryMethodAction, push, replace } from 'connected-react-router'
import { ActionCreator } from 'redux'

import { apiRoutes } from 'constants/apiRoutes'
import {
  ActiveClient,
  CurrentClientAccounts,
  FeatureFlagConfiguration,
  Menu,
  PayinBank,
  UserDetails,
  UserTrait,
} from 'interfaces/redux/Dependents.interface'
import { VFABankDetails } from 'interfaces/redux/Reducers.interface'
import {
  AccountTypes,
  AuthTypes,
  BankNameTypes,
  BlockerBannerClosed,
  HeaderTypes,
  SaveURLTypes,
  VFAActionTypes,
} from 'store/actionTypes'
import { appRoutes } from 'V2/constants/appRoutes'
import { AUTH_CONSTANTS, notAllowedStatusesToForm, statusArr } from 'V2/constants/auth'
import {
  ACCOUNTING,
  clientSpecificFeatureFlag,
  HK_RECEIVE_ENABLED,
  VFA_DISCLAIMER_DISABLED,
} from 'V2/constants/userClientDetails'
import { VFACountries } from 'V2/constants/vfaConstant'
import {
  clientLegalEntityMapper,
  showCurrencyAccountForLegalEntity,
} from 'V2/helpers/clientLegalEntityMapper'
import { currencyOptionsMapper } from 'V2/helpers/currencyOptionsMapper'
import GA from 'V2/helpers/gaTracker'
import { getVfaDisclaimerDisabled } from 'V2/helpers/getVfaDisclaimerDisabled'
import { cancelAxiosRequest } from 'V2/services/axiosInstance'
import { Logger } from 'V2/services/logger'
import apm from '../../apm'

const FILE_NAMESPACE = 'redux.actions.userClientDetails'

export const setClientUserData: ActionCreator<ThunkWithApi<void>> = (
  activeClient: ActiveClient,
  userDetails: UserDetails,
  generatedPasswordChanged: boolean,
  menus: Menu[]
) => {
  return async function (dispatch, _state, api): Promise<void> {
    try {
      // Fetch Payin Bank List
      dispatch({
        type: BankNameTypes.GET_BANK_NAME_REQUEST,
      })
      let payinRes
      if (!statusArr.includes((localStorage.getItem('status') as string) ?? '')) {
        payinRes = await axios.get(api.payinBank)
        dispatch({
          type: BankNameTypes.GET_BANK_NAME_SUCCESS,
          payload: payinRes.data.data,
        })
      }

      // Fetch Recieved offered currency  List
      if (!statusArr.includes((localStorage.getItem('status') as string) ?? '')) {
        try {
          const receiveOfferedCurrencyList = await axios.get(
            api.receiveOfferedCurrencies(activeClient._id)
          )
          // adding jpy and nzd to show wallets for clients
          const receiveWallets = receiveOfferedCurrencyList?.data
          receiveWallets.push(
            {
              currency: 'NZD',
              label: ['GLOBAL'],
            },
            {
              currency: 'JPY',
              label: ['GLOBAL'],
            }
          )
          dispatch({
            type: HeaderTypes.SET_RECEIVE_OFFERED_CURRENIES,
            payload: receiveWallets ?? [],
          })
        } catch {
          Logger(FILE_NAMESPACE).error('receiveOfferedCurrencies', 'err')
        }
      }

      // adding custom tag for client and user on apm
      apm.setUserContext({
        id: userDetails._id,
        username: userDetails.name,
        email: userDetails.email,
      })
      GA.userId = activeClient._id

      apm.setCustomContext({
        client: {
          id: activeClient._id,
          name: activeClient.client_name,
          acquisitionType: activeClient.acquisition_type,
          country: activeClient.client_country,
          legalEntity: activeClient.client_legal_entity,
          institutionType: activeClient.institution_type,
          lockAndHoldFx: activeClient.lockAndHoldFx,
          supplierPaymentsCustomer: activeClient.supplier_payments_customer,
          topUpCurrency: activeClient.topup_currency,
          topUpPricing: activeClient.topup_pricing,
          utcOffset: activeClient.utcOffset,
          isAutoBookFx: activeClient.is_auto_book_fx,
        },
      })

      // eslint-disable-next-line prefer-const
      let { defaultCurrency, currencyOptions } = currencyOptionsMapper(
        activeClient.client_legal_entity,
        (payinRes?.data.data as PayinBank[])?.filter(
          (bank) =>
            bank.is_active &&
            clientLegalEntityMapper(bank.bank_country) ===
              clientLegalEntityMapper(activeClient.client_legal_entity)
        )
      )

      //   Fetch Feature flag Client Configurations
      const featureFlagConfiguration = await getFeatureFlags(
        activeClient._id,
        api.setFeatureConfiguration
      )
      const vfaDisclaimerFlagDisabled = featureFlagConfiguration.data.data.find(
        (item: FeatureFlagConfiguration) => item.feature === VFA_DISCLAIMER_DISABLED
      )

      if (vfaDisclaimerFlagDisabled) {
        vfaDisclaimerFlagDisabled.enabled = getVfaDisclaimerDisabled(
          vfaDisclaimerFlagDisabled,
          activeClient
        )
      }

      dispatch({
        type: HeaderTypes.SET_FEATURE_FLAG_CLIENT_CONFIGURATION,
        payload: {
          featureFlagInfo: featureFlagConfiguration?.data.data || [],
          userTraits: featureFlagConfiguration?.data.traits || [],
        },
      })

      const filteredMenus = menus
        // This condition is to enable/disable receive money section for hk clients based on flagsmith configuration SME-2042
        .filter((item) => {
          if (clientLegalEntityMapper(activeClient.client_legal_entity) === 'HK') {
            return !featureFlagConfiguration?.data.data.filter(
              (item) => item.feature === HK_RECEIVE_ENABLED
            )[0].enabled
              ? item.key !== 'SME_navbar_myactivity-navlink'
              : true
          }
          return true
        })
        ?.filter(
          (menu) =>
            menu.tags.includes('sme') &&
            (menu.key === 'SME_navbar_myactivity-navlink'
              ? showCurrencyAccountForLegalEntity(activeClient.client_legal_entity)
              : true)
        )
        ?.filter((menu) =>
          menu.url === appRoutes.payBills
            ? featureFlagConfiguration.data.data.find(
                (featureFlag: { feature: string }) => featureFlag.feature === ACCOUNTING
              )?.enabled
            : true
        )
        ?.filter(
          (menu) =>
            !(
              ([appRoutes.myAccounts, appRoutes.payBills] as string[]).includes(menu.url) &&
              activeClient.license_type === 'TYPE_1' &&
              clientLegalEntityMapper(activeClient.client_legal_entity) === 'JP'
            )
        )

      dispatch({
        type: HeaderTypes.SET_USER_CLIENT_DATA,
        payload: {
          generatedPasswordChanged,
          activeClient,
          defaultCurrency,
          currencyOptions,
          userDetails,
          menus: [...filteredMenus],
        },
      })
      dispatch({
        type: HeaderTypes.UPDATE_MAKER_CHECKER_FEATURE,
        payload: activeClient.is_api_payout_approval_required,
      })
      dispatch({
        type: HeaderTypes.UPDATE_TOP_UP_PRICING,
        payload: activeClient.topup_pricing,
      })
      dispatch({
        type: HeaderTypes.UPDATE_AUTO_SWEEP_BANK_DETAILS,
        payload: activeClient.autosweep_bank_account,
      })
      if (
        !statusArr
          .concat(['COMPLIANCE_IN_PROGRESS'])
          .includes(localStorage.getItem('status') as string)
      ) {
        const redirectionUrlLocal = sessionStorage.getItem('redirectionUrl')
        let route = ''
        if (redirectionUrlLocal) {
          const url = new URL(redirectionUrlLocal)
          route = url.pathname
        } else {
          route = appRoutes.dashboard
        }
        sessionStorage.removeItem('redirectionUrl')

        dispatch(push(generatedPasswordChanged ? route : appRoutes.authSetPassword))
      }
    } catch (err) {
      Logger(FILE_NAMESPACE).error('setClientUserData', err)
      dispatch({
        type: AuthTypes.USER_LOGIN_ERROR,
        payload: {
          message:
            (err as AxiosError)?.response?.data?.message ||
            AUTH_CONSTANTS.SERVER_ERROR_MESSAGES.UNKNOWN_ERROR,
        },
      })
    }
  }
}

export const logout: ActionCreator<ThunkWithApi<void>> = (callback: () => void) => {
  return async function (
    dispatch,
    _getState,
    api
  ): Promise<void | CallHistoryMethodAction<[string, unknown?]>> {
    cancelAxiosRequest()
    try {
      await axios.get(api.logout, {
        headers: {
          'Cache-Control': 'max-age=2',
        },
      })
    } catch (err) {
      // logger
    } finally {
      sessionStorage.removeItem('csrfToken')
      sessionStorage.removeItem('transactionFund')
      sessionStorage.removeItem('transactionData')
    }

    dispatch({ type: AuthTypes.USER_LOGOUT })
    return callback ? callback() : dispatch(replace(appRoutes.authLogin))
  }
}

export const getCurrentClientAccountList: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch, getState): Promise<void> {
    try {
      dispatch({
        type: AccountTypes.LOADING,
        payload: true,
      })
      const { userClientDetails } = getState()
      const accountsData = await axios
        .get(apiRoutes.accountSummary, {
          params: {
            client: userClientDetails.activeClient?._id,
          },
        })
        .then((res) => res.data.accounts)
      const clientLegalEntity = clientLegalEntityMapper(
        userClientDetails.activeClient?.client_legal_entity as string
      )
      const vfaDetails = VFACountries.find((vfa) => vfa.clientLegalEntity === clientLegalEntity)
      if (notAllowedStatusesToForm.includes(localStorage.getItem('status') || '')) {
        if (vfaDetails) {
          for (const currency of vfaDetails.vfaCurrencies) {
            const data = accountsData?.find(
              (accountData: { currency: string }) => accountData.currency === currency
            )
            // Check if VFA does not exist for the account with the currency
            if (!data?.VFA) {
              try {
                // Make API request to create VFA
                const createAccRes = await axios.post(apiRoutes.createVfa, {
                  accountId: data._id,
                  clientId: data.client,
                })
                // Append the API response to the account object with matching currency
                // This will eventually update the payload for the ACCOUNT_ACCOUNT_LIST action type
                data.VFA = createAccRes.data.response
              } catch (err) {
                Logger(FILE_NAMESPACE).error('VFACreateErrorMessage', err)
              }
            }
          }
        }
      }

      dispatch({
        type: AccountTypes.ACCOUNT_ACCOUNT_LIST,
        payload: (accountsData as CurrentClientAccounts[])?.filter(
          (account) => !!account.is_active
        ),
      })
      vfaDetails && dispatch(getVFADetails())
    } catch (err) {
      dispatch({
        type: AccountTypes.ACCOUNT_ACCOUNT_LIST_ERROR,
        payload: {
          message: (err as AxiosError)?.response?.data?.message || null,
        },
      })
    } finally {
      dispatch({
        type: AccountTypes.LOADING,
        payload: false,
      })
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUserDetails = (payload: any): { type: string; payload: any } => {
  return {
    type: HeaderTypes.USER_DETAILS,
    payload,
  }
}

export const updateFlagTrait: ActionCreator<ThunkWithApi<void>> = (
  traitKey: string,
  traitValue: string | boolean
) => {
  return async function (dispatch, getState, api): Promise<void> {
    const { userClientDetails } = getState()
    await axios.post(
      `${process.env.REACT_APP_SME_BACKEND_BASE_URL}${api.setFeatureConfiguration}`,
      {
        traitKey,
        traitValue,
        clientId: userClientDetails?.activeClient?._id,
      }
    )
    //   Fetch Feature flag Client Configurations
    const featureFlagConfiguration = await getFeatureFlags(
      userClientDetails?.activeClient?._id || '',
      api.setFeatureConfiguration
    )
    const vfaDisclaimerFlagDisabled = featureFlagConfiguration.data.data.find(
      (item: FeatureFlagConfiguration) => item.feature === VFA_DISCLAIMER_DISABLED
    )

    if (vfaDisclaimerFlagDisabled) {
      vfaDisclaimerFlagDisabled.enabled = getVfaDisclaimerDisabled(
        vfaDisclaimerFlagDisabled,
        userClientDetails.activeClient
      )
    }

    dispatch({
      type: HeaderTypes.SET_FEATURE_FLAG_CLIENT_CONFIGURATION,
      payload: {
        featureFlagInfo: featureFlagConfiguration?.data.data || [],
        userTraits: featureFlagConfiguration?.data.traits || [],
      },
    })
  }
}

export const getFeatureFlags = async (
  clientId: string,
  url: string
): Promise<{
  data: {
    data: FeatureFlagConfiguration[]
    traits: UserTrait[]
  }
}> => {
  return await axios.get(`${process.env.REACT_APP_SME_BACKEND_BASE_URL}${url}`, {
    params: {
      flag: clientSpecificFeatureFlag,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: clientId,
    },
  })
}

export const getVFADetails: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch, getState): Promise<void> {
    const { userClientDetails } = getState()
    const vfaDetails = VFACountries.find(
      (vfa) =>
        vfa.clientLegalEntity ===
        clientLegalEntityMapper(userClientDetails.activeClient?.client_legal_entity || '')
    )

    let vfaCreatedList = userClientDetails.currentClientAccounts
      ?.filter((item) => item.VFA?.status === 'COMPLETED')
      .map((item) => item.currency)
    if (vfaCreatedList && vfaCreatedList?.length > 0) {
      //to get VFA bank details
      const response = await axios
        .get(apiRoutes.getVirtualReceivingAccounts(userClientDetails.activeClient?._id || ''), {
          params: { source: 'MASSPAY' },
        })
        .then((res) => res.data)

      const enabledVfaBankDetails = response.filter((item: VFABankDetails) =>
        vfaDetails?.vfaCurrencies.includes(item.currencyCode || '')
      )
      dispatch({
        type: VFAActionTypes.SET_VFA_BANK_DETAILS,
        payload: enabledVfaBankDetails,
      })
      //in some cases we get vfa status as completed but no bank details for that vfa are available
      vfaCreatedList = vfaCreatedList.filter((item) =>
        enabledVfaBankDetails.some((data: VFABankDetails) => data.currencyCode === item)
      )
      dispatch({
        type: VFAActionTypes.SET_VFA_ENABLED_LIST,
        payload: vfaCreatedList,
      })
    }
  }
}

export const saveLastVisitedUrl: ActionCreator<ThunkWithApi<void>> = (url) => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: SaveURLTypes.SAVE_LAST_VISITED_URL,
      payload: url,
    })
  }
}

export const resetLastVisitedUrl: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: SaveURLTypes.RESET_LAST_VISITED_URL,
    })
  }
}

export const isBlockerPopupClosed: ActionCreator<ThunkWithApi<void>> = () => {
  return async function (dispatch): Promise<void> {
    dispatch({
      type: BlockerBannerClosed.IS_BLOCKER_BANNER_CLOSED,
    })
  }
}
