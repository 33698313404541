import styled, { css, DefaultTheme } from 'styled-components/macro'

import { great, less } from 'V2/helpers/mediaquery'
import { pxToRem } from 'V2/helpers/pxToRem'
import styledInterpolation from 'V2/helpers/styledInterpolation'

const relativeElement = css`
  position: relative;
  width: 100%;
`

export const transform = css`
  top: 0;
  transform: translateY(-50%) scale(0.9);
  background-color: white;
`

const LabelStyles = css`
  position: absolute;
  font-size: ${pxToRem(15)};
  left: ${pxToRem(0)};
  top: 50%;
  color: ${({ theme }): string => theme.revamp.primary.grey.root};
  padding: 0 ${pxToRem(8)};
  margin: 0 ${pxToRem(8)};
  transition: 0.1s ease-out;
  transform: translateY(-50%);
  transform-origin: left top;
  pointer-events: none;
`

const InputStyles = css`
  width: 100%;
  outline: none;
  border: ${pxToRem(1)} solid
    ${({ isError, theme }: { isError?: boolean; theme: DefaultTheme }): string =>
      isError ? theme.revamp.staticColors.redPepperCorn : theme.revamp.primary.grey.root};
  border-radius: ${pxToRem(3)};
  padding: ${pxToRem(16)} ${pxToRem(16)};
  transition: 0.1s ease-out;

  &::placeholder {
    color: ${({ theme }: { theme: DefaultTheme }): string => theme.revamp.primary.grey.root};
  }

  &:not(:placeholder-shown) + label {
    color: ${({ isError, theme }: { isError?: boolean; theme: DefaultTheme }): string =>
      isError ? theme.revamp.staticColors.redPepperCorn : theme.revamp.staticColors.black};
    ${transform}
  }

  &:focus {
    border-color: ${({ isError, theme }: { isError?: boolean; theme: DefaultTheme }): string =>
      isError ? theme.revamp.staticColors.redPepperCorn : theme.revamp.primary.blue.root};
    & + label {
      color: ${({ isError, theme }: { isError?: boolean; theme: DefaultTheme }): string =>
        isError ? theme.revamp.staticColors.redPepperCorn : theme.revamp.primary.blue.root};
      ${transform}
    }
  }

  &::after {
    content: Hi;
  }

  ${({ isError, theme }): string =>
    styledInterpolation(
      isError,
      `
  & + label {
    color: ${theme.revamp.staticColors.redPepperCorn};
    ${transform}
  }
  `
    )}

  &:not(:focus)::placeholder {
    opacity: 0;
  }

  &:disabled + label {
    line-height: ${pxToRem(13)};
  }

  font-weight: 500;
  font-size: ${({ theme }): string => pxToRem(theme.revamp.mobileFont.root)};
  line-height: ${({ theme }): string => pxToRem(theme.revamp.mobileLineHeight.root)};
  @media ${great.greaterThanMobile} {
    font-size: ${({ theme }): string => pxToRem(theme.revamp.desktopFont.root)};
    line-height: ${({ theme }): string => pxToRem(theme.revamp.desktopLineHeight.root)};
  }
`

export const Wrapper = styled.div`
  ${relativeElement}
`

export const InputWrapper = styled.div`
  ${relativeElement}
`
interface BaseInputProps {
  isError?: boolean
  labelBackground?: string
  height?: string
  width?: string
  ref?: React.Ref<HTMLInputElement>
}
export const BaseInput = styled.input<BaseInputProps>`
  ${InputStyles}
  height: ${(props): string => props.height || ''};
  ${({ labelBackground }): string => {
    return labelBackground
      ? `
    &:not(:placeholder-shown) + label {
      background: ${labelBackground};
    }
    &:focus + label {
      background: ${labelBackground};
    }
    `
      : ''
  }}
  ${({ height }): string => (height ? `height: ${height};` : '')}
  ${({ width }): string => (width ? `width: ${width}px !important;` : '')}
`

export const Label = styled.label`
  ${LabelStyles}
`

export const Message = styled.p<{ textColor: string }>`
  margin: 0;
  font-size: ${pxToRem(13)};
  line-height: ${pxToRem(13)};
  word-wrap: break-word;
  color: ${({ textColor, theme }): string =>
    textColor ? textColor : theme.revamp.staticColors.black};
  margin-left: ${pxToRem(16)};
  margin-top: ${pxToRem(4)};
`

export const BaseTextArea = styled.textarea<{ isError?: boolean }>`
  resize: none;
  ${InputStyles}
`

export const TextAreaLabel = styled.label`
  ${LabelStyles}
  top: 46%;
  @media ${less.lessThanMobile} {
    white-space: nowrap;
    font-size: ${pxToRem(12)};
  }
`

export const WordLimit = styled.span<{ disabled?: boolean }>`
  position: absolute;
  bottom: ${pxToRem(12)};
  right: ${pxToRem(12)};
  color: ${({ theme }): string => theme.revamp.primary.grey.root};
  font-size: ${pxToRem(10)};
  background: ${({ disabled }): string => (disabled ? 'none' : 'white')};
`

export const DropDownStyle = styled.div`
  background-color: ${({ theme }): string => theme.staticColors.white};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.3s;
  padding: 10px;
  &:hover {
    background-color: ${({ theme }): string => theme.revamp.primary.yellow.light};
    cursor: pointer;
  }
`
