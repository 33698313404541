import styled from 'styled-components/macro'

import CustomIcon from 'V2/components/atoms/Icon/CustomIcon'
import { floors } from 'V2/constants/floors'
import { great, less } from 'V2/helpers/mediaquery'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 360px;
  height: 100vh;
  @media ${less.lessThanMobile} {
    height: calc(var(--vh, 1vh) * 100);
  }
  @media ${great.greaterThanTablet} {
    flex-direction: row;
  }
`

export const ContentWrapper = styled.main<{ open: boolean }>`
  flex: 1;
  background: ${({ theme }): string => theme.revamp.primary.grey.lightest};
  overflow-y: overlay;
  @media ${great.greaterThanTablet} {
    margin-top: 96px;
  }
  @media ${great.greaterThanMobile} {
    height: calc(100vh - 96px);
  }
  @media ${less.lessThanMobile} {
    height: calc(100vh - 68px);
    height: calc((var(--vh, 1vh) * 100) - 68px);
  }
`
export const Content = styled.div`
  max-width: 1204px;
`

export const Header = styled.header`
  display: flex;
  align-items: center;

  height: 96px;
  padding: 24px;
  max-width: 1440px;
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};

  @media ${less.lessThanMobile} {
    display: none;
  }
  @media ${great.greaterThanTablet} {
    background-color: ${({ theme }): string => theme.revamp.primary.grey.lightest};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${floors.first};
    padding-right: 56px;
  }
`

export const LogOutContainer = styled.span`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  & div {
    cursor: pointer;
  }
`

export const CustomMenuIcon = styled(CustomIcon)`
  cursor: pointer;
  @media ${great.greaterThanTablet} {
    display: none;
  }
`
export const TemplateWrapper = styled.nav`
  display: flex;
  flex: 1;

  background: ${({ theme }): string => theme.revamp.primary.grey.lightest};
`
// Desktop CSS

export const NavContentContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const BlurDiv = styled.div`
  width: 100vw;
  height: calc(100vh - 96px);
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }): string => theme.revamp.staticColors.black};
  opacity: 0.8;
  z-index: ${floors.first};
  @media ${less.lessThanMobile} {
    display: none;
  }
  @media ${great.greaterThanTablet} {
    display: none;
  }
`

export const FlagCurrencyName = styled.span`
  display: flex;
  align-items: center;
`

export const MobileNavWrapper = styled.div`
  background-color: ${({ theme }): string => theme.revamp.staticColors.white};
  filter: drop-shadow(0px -3px 10px rgba(44, 44, 44, 0.08));
  @media ${great.greaterThanMobile} {
    display: none;
  }
`
