// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const CloseIcon: React.FC<{ stroke?: string }> = ({ stroke = '#2C2C2C' }) => (
  <>
    <path
      d="M6.70312 17.3033L17.3097 6.6967"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.70312 6.6967L17.3097 17.3033"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
