// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const TransactionNavigationIcon: React.FC<{ stroke?: string }> = ({ stroke = 'white' }) => (
  <>
    <path
      d="M8.25 3H23.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12H23.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 21H23.25"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 0.75H1.5C1.08579 0.75 0.75 1.08579 0.75 1.5V4.5C0.75 4.91421 1.08579 5.25 1.5 5.25H4.5C4.91421 5.25 5.25 4.91421 5.25 4.5V1.5C5.25 1.08579 4.91421 0.75 4.5 0.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 9.75H1.5C1.08579 9.75 0.75 10.0858 0.75 10.5V13.5C0.75 13.9142 1.08579 14.25 1.5 14.25H4.5C4.91421 14.25 5.25 13.9142 5.25 13.5V10.5C5.25 10.0858 4.91421 9.75 4.5 9.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 18.75H1.5C1.08579 18.75 0.75 19.0858 0.75 19.5V22.5C0.75 22.9142 1.08579 23.25 1.5 23.25H4.5C4.91421 23.25 5.25 22.9142 5.25 22.5V19.5C5.25 19.0858 4.91421 18.75 4.5 18.75Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)
