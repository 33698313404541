import { useState } from 'react'

const useDeviceWidth = (): { width: number; height: number } => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  window.addEventListener('resize', (): void => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  })
  return { width: windowWidth, height: windowHeight }
}

export default useDeviceWidth
