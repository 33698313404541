// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const LogoIcon: React.FC<{ fill?: string }> = ({ fill = '#2C2C2C' }) => (
  <>
    <path d="M4.39152 0H-0.000457764V20.6764H4.39152V0Z" fill={fill} />
    <path
      d="M20.4015 12.4679V20.6763H16.2174V13.1671C16.2174 11.1603 15.1259 10.008 13.3977 10.008C12.2413 10.008 11.0848 10.6553 10.4871 11.6652V20.6763H6.31598V6.17568H10.5001V7.88469C11.3057 6.64177 12.839 5.8779 14.4762 5.8779C18.1535 5.8779 20.4015 8.38962 20.4015 12.4679Z"
      fill={fill}
    />
    <path
      d="M21.9082 19.1486L23.1556 16.2485C24.52 17.2584 26.3001 17.7892 28.0803 17.7892C29.3797 17.7892 30.2113 17.2843 30.2113 16.611C30.1854 14.3971 22.35 16.0543 22.298 10.7072C22.272 7.98832 24.7019 5.8909 28.1453 5.8909C30.1854 5.8909 32.2384 6.39583 33.6937 7.54812L32.5113 10.5648C31.3808 9.71027 29.5487 9.06292 27.9764 9.06292C26.9109 9.06292 26.1962 9.56785 26.1962 10.2152C26.2222 12.3126 34.1095 11.1603 34.2005 16.365C34.2005 19.058 31.8226 21.0001 28.4442 21.0001C25.9493 20.9742 23.6624 20.3786 21.9082 19.1486Z"
      fill={fill}
    />
    <path
      d="M44.9672 20.508C44.4344 20.7799 43.6028 20.9223 42.8362 20.9223C39.4317 20.9223 37.0278 18.7602 37.0278 15.0185V10.1375H34.3251V6.17569H37.0278V2.36926H41.1989V6.17569H44.7853V10.1375H41.1989V14.8113C41.1989 16.1708 42.2384 17.0253 43.4469 17.0253C43.8627 17.0253 44.4214 16.9347 44.7463 16.8181L44.9672 20.508Z"
      fill={fill}
    />
    <path
      d="M61.3392 6.17568V20.6763H57.1551V18.9673C56.4405 20.1455 54.6993 20.9741 52.8541 20.9741C49.0598 20.9741 46.0582 17.6079 46.0582 13.4131C46.0582 9.21823 49.0469 5.8779 52.8541 5.8779C54.6603 5.8779 56.4405 6.70651 57.1551 7.88469V6.17568H61.3392ZM57.1551 15.2127V11.6652C56.5314 10.6036 55.232 9.80084 53.7767 9.80084C51.7366 9.80084 50.1643 11.3415 50.1643 13.4001C50.1643 15.4716 51.7366 16.9994 53.7767 16.9994C55.232 17.0123 56.5314 16.2485 57.1551 15.2127Z"
      fill={fill}
    />
    <path
      d="M73.4421 6.14968L73.2082 10.0467C72.6495 9.83958 71.9348 9.72306 71.3371 9.72306C70.3885 9.72306 68.8812 10.4869 68.1665 11.9111V20.6892H63.9825V6.18852H68.1665V7.89753C68.8812 6.65462 70.4535 5.89074 71.6619 5.89074C72.4026 5.8778 73.0003 5.96843 73.4421 6.14968Z"
      fill={fill}
    />
    <path
      d="M88.1253 14.423H77.6521C78.0419 16.4039 79.4063 17.4396 81.3944 17.4396C82.6938 17.4396 84.0581 16.8441 84.5649 15.9637L87.8264 17.9446C86.7609 19.8607 84.331 20.9871 81.3034 20.9871C77.0284 20.9871 73.6499 17.6727 73.6499 13.3613C73.6499 9.1406 76.9764 5.8909 81.2125 5.8909C85.1496 5.8909 88.2422 8.93345 88.2422 12.8046C88.2422 13.2966 88.2162 13.8533 88.1253 14.423ZM84.0581 11.7559C83.8762 10.3058 82.5508 9.24418 81.0046 9.24418C79.2504 9.24418 78.0939 10.1893 77.7171 11.7559H84.0581Z"
      fill={fill}
    />
    <path
      d="M112.204 11.6652V20.6763H107.994V12.5197C107.994 10.7719 107.019 9.71021 105.473 9.71021C104.434 9.71021 103.368 10.3576 102.861 11.2509V20.6763H98.6772V12.5197C98.6772 10.7719 97.6376 9.71021 96.2473 9.71021C95.0259 9.71021 94.0253 10.3835 93.5835 11.3415V20.6763H89.3994V6.17568H93.5835V7.8329C94.2592 6.88777 95.6626 5.8779 97.7286 5.8779C99.5608 5.8779 100.899 6.47346 101.796 7.91058C102.679 6.87482 104.317 5.8779 106.24 5.8779C109.709 5.8779 112.204 8.18247 112.204 11.6652Z"
      fill={fill}
    />
    <path
      d="M114.569 18.3201C114.569 16.8441 115.751 15.6918 117.271 15.6918C118.818 15.6918 120 16.8441 120 18.3201C120 19.796 118.818 20.9742 117.271 20.9742C115.751 20.9742 114.569 19.796 114.569 18.3201Z"
      fill={fill}
    />
  </>
)
