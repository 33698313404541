// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

import AlertCircleIcon from 'V2/components/iconPaths/Custom/BasicIcons/AlertCircleIcon'
import { AlertTriangleIcon } from 'V2/components/iconPaths/Custom/BasicIcons/AlertTriangleIcon'
import { CheckboxCircleIcon } from 'V2/components/iconPaths/Custom/BasicIcons/CheckboxCircleIcon'
import { InfoBigIcon } from 'V2/components/iconPaths/Custom/BasicIcons/InfoBigIcon'

export const snackBarPaths: { [a: string]: JSX.Element } = {
  success: <CheckboxCircleIcon stroke={'#FFFFFF'} />,
  warn: <AlertTriangleIcon stroke={'#FFFFFF'} />,
  info: <InfoBigIcon stroke={'#FFFFFF'} />,
  error: <AlertCircleIcon stroke={'#FFFFFF'} />,
}
