// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'

export const InfoIcon: React.FC = () => (
  <>
    <path
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#EEF6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.862C12.5523 11.862 13 12.3097 13 12.862V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V12.862C11 12.3097 11.4477 11.862 12 11.862Z"
      fill="#004288"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 7C11 6.44772 11.4477 6 12 6H12.0103C12.5626 6 13.0103 6.44772 13.0103 7C13.0103 7.55228 12.5626 8 12.0103 8H12C11.4477 8 11 7.55228 11 7Z"
      fill="#004288"
    />
  </>
)
